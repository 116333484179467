import { Step, Steps as StepsList } from "./styles";
import { StepsProps } from "./type";

export const Steps = ({ stepOne, stepTwo, stepThree }: StepsProps) => {
  return (
    <StepsList>
      <Step className={stepOne}>
        <span>1 – Escolha a hospedagem</span>
        <span>1</span>
      </Step>
      <Step className={stepTwo}>
        <span>2 – Identifique-se</span>
        <span>2</span>
      </Step>
      <Step className={stepThree}>
        <span>3 – Finalizar Reserva</span>
        <span>3</span>
      </Step>
    </StepsList>
  );
};
