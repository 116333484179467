import { Form } from "@unform/web";
import styled from "styled-components";

export const OverLay = styled.div`
  background-color: rgba(0, 0, 0, 0.48);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerModal = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: var(--white);
  height: auto;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin: 0px 12px;
`;

export const HeaderModal = styled.div`
  width: 100%;
  max-width: 530px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 1rem;
    color: var(--purple600);
    font-weight: bold;
    text-transform: uppercase;
  }

  img {
    width: 20px;
    cursor: pointer;
  }
`;

export const BodyModal = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormItem = styled(Form)`
  width: 100%;
  max-width: 530px;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BtnKeep = styled.button`
  width: 100%;
  height: 40px;
  background-color: var(--pink600);
  border-radius: 25px;
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  margin: 12px 0px;
  cursor: pointer;

  border: 0;
  &:hover {
    background-color: var(--pink400);
  }
`;
