import { useField } from "@unform/core";
import { Fragment, useEffect, useRef, useState } from "react";
import {
  CardValidateText,
  ErroMessage,
  FieldSet,
  IconCarditem,
  Input,
  InputSelect,
  OptionSelect,
  TextCvv,
  WrapperIcon,
} from "./styles";
import IconCard from "./assets/cartaoPurple.svg";
import React from "react";
import { FieldPaymentProps } from "./types";

const FieldsPaymentCard = ({
  name,
  placeholder,
  id,
  type,
  options,
  total,
  autoComplete,
}: FieldPaymentProps) => {
  const { fieldName, registerField, error, defaultValue } = useField(name);
  const [selectOption, setSelectOption] = useState<string>("");
  const [inputValue, setInputvalue] = useState<string>("");
  const [totalValue, setTotalValue] = useState<number>(0);
  const inputRef = useRef(null);

  const handleSelect = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setSelectOption(e.target.value);
  };

  const handleChangeValue = (e: any, name: string) => {
    if (name === "numeroDoCartao") {
      mask_cardNational(e.currentTarget.value);
      return;
    }
    if (name === "ano") {
      mask_ano(e.currentTarget.value);
      return;
    }
    if (name === "mes") {
      mask_mes(e.currentTarget.value);
      return;
    }
    if (name === "cvv") {
      mask_cvv(e.currentTarget.value);
      return;
    }

    setInputvalue(e.target.value);
  };

  const mask_ano = (text: string) => {
    const result = text.replace(/\D/g, "");
    setInputvalue(result);
  };
  const mask_mes = (text: string) => {
    let value = text.replace(/\D/g, "");
    if (Number(value) > 12) {
      value = value.charAt(0);
    }
    setInputvalue(value.replace(/^([3456789])/g, "0$1"));
  };

  const mask_cvv = (text: string) => {
    const result = text.replace(/\D/g, "");
    setInputvalue(result);
  };

  const mask_cardNational = (text: string) => {
    let value = text.replace(/\D/g, "");
    let result = value.replace(/^(\d{4})(\d)/g, "$1 $2");
    result = result.replace(/^(\d{4})\s(\d{4})(\d)/g, "$1 $2 $3");
    result = result.replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g, "$1 $2 $3 $4");

    setInputvalue(result);
  };

  const definePortion = (item?: string) => {
    if (item) {
      if (item === "1x") {
        return `${item} ${totalValue.toLocaleString("pt-Br", {
          style: "currency",
          currency: "BRL",
        })} (sem juros)`;
      }
      if (item === "2x") {
        const total = totalValue / 2;
        return `${item} ${total.toLocaleString("pt-Br", {
          style: "currency",
          currency: "BRL",
        })} (sem juros)`;
      }
      if (item === "3x") {
        const total = totalValue / 3;
        return `${item} ${total.toLocaleString("pt-Br", {
          style: "currency",
          currency: "BRL",
        })} (sem juros)`;
      }

      if (item === "4x") {
        const total = totalValue / 4;
        return `${item} ${total.toLocaleString("pt-Br", {
          style: "currency",
          currency: "BRL",
        })} (sem juros)`;
      }
      if (item === "5x") {
        const total = totalValue / 5;
        return `${item} ${total.toLocaleString("pt-Br", {
          style: "currency",
          currency: "BRL",
        })} (sem juros)`;
      }
      if (item === "6x") {
        const total = totalValue / 6;
        return `${item} ${total.toLocaleString("pt-Br", {
          style: "currency",
          currency: "BRL",
        })} (sem juros)`;
      }
    }
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  const maxCharacter = (field: string) => {
    if (field === "ano" || field === "mes") {
      return 2;
    }

    if (field === "numeroDoCartao") {
      return 19;
    }

    if (field === "nome") {
      return 40;
    }

    return 4;
  };

  useEffect(() => {
    setTotalValue(total);
  }, [total]);

  return (
    <Fragment>
      {type === "select" && (
        <FieldSet name={name}>
          <InputSelect
            defaultValue={defaultValue}
            onChange={(e) => handleSelect(e)}
            valueSelect={selectOption === "" ? defaultValue : selectOption}
            ref={inputRef}
            name={name}
            errorMessage={error}
          >
            {options?.map((option) => (
              <OptionSelect key={option.value}>
                {option.label !== "Número de parcelas"
                  ? `${definePortion(option.label)} `
                  : "Número de parcelas"}
              </OptionSelect>
            ))}
          </InputSelect>
          {error && <ErroMessage>{error}</ErroMessage>}
        </FieldSet>
      )}
      {type === "text" && (
        <Fragment>
          {name === "mes" && (
            <CardValidateText>Validade do cartão</CardValidateText>
          )}
          <FieldSet name={name}>
            <Input
              ref={inputRef}
              name={name}
              id={id}
              placeholder={placeholder}
              type={type}
              fieldName={name}
              onChange={(e) => handleChangeValue(e, name)}
              maxLength={maxCharacter(name)}
              errorMessage={error}
              value={inputValue}
              autoComplete={autoComplete}
            />

            {name === "numeroDoCartao" && (
              <WrapperIcon>
                <IconCarditem src={IconCard} />
              </WrapperIcon>
            )}
            {name === "cvv" && (
              <TextCvv>
                Código de 3 a 4 dígitos impresso na frente ou verso do seu
                cartão.
              </TextCvv>
            )}
            {error && <ErroMessage>{error}</ErroMessage>}
          </FieldSet>
        </Fragment>
      )}
    </Fragment>
  );
};

export default FieldsPaymentCard;
