import styled from "styled-components";
import { InputStyleProps, SelectStyleProps } from "./types";

export const InputItem = styled.input<InputStyleProps>`
  width: ${({ name }) => name === "ageUser" && "155px"};
  min-width: ${({ name }) =>
    name === "addressUser" || name === "nameUser" || name === "emailUser"
      ? 285
      : 0}px;
  flex: ${({ name }) => name !== "ageUser" && 1};
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  border-radius: 12px;
  padding-left: 10px;
  border: 2px solid
    ${({ error }) => (!!error ? "var(--red500)" : "var(--gray500Opacity)")};
  margin: 4px;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  outline: none;
  color: var(--black);
  font-weight: 500;

  ::placeholder {
    color: var(--black);
    font-size: 14px;
    font-weight: 500;
  }
`;

export const Fildset = styled.fieldset`
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-top: 2px;
`;

export const Select = styled.select<SelectStyleProps>`
  width: 155px;
  margin-top: 4px;
  height: 40px;
  min-height: 40px;
  outline: none;
  border: 2px solid
    ${({ error }) => (!!error ? "var(--red500)" : "var(--gray500Opacity)")};
  border-radius: 12px;
  padding-left: 10px;
  padding-right: 20px;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  color: var(--black);
  font-weight: 500;
  position: relative;
`;
