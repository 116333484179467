import { useState, useEffect, useRef } from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { apiGetTestimonial } from "../../../services";
import { removeHtmlInString } from "../../../utils/FormatText";
import {
  Section,
  TitleSection,
  ParagraphReview,
  NameUserReview,
  WrapperReviews,
  WrapperLastContent,
  WrapperLastSlider,
  WrapperSlides,
  PrevArrowDiv,
  NextArrowDiv,
} from "../styles";

type TestimonialType = {
  testimonial: string;
  name: string;
};

type TestimonialSectionProps = {
  settings: Settings;
};

function TestimonialSection({ settings }: TestimonialSectionProps) {
  const lastSlider = useRef<any>(null);

  const [testimonials, setTestimonials] = useState<TestimonialType[]>([]);

  useEffect(() => {
    apiGetTestimonial
      .get<TestimonialType[]>(`/VendaSemAdesao/GetListTestimonial`)
      .then((resp) => {
        if (resp.status === 200) {
          setTestimonials(resp.data);
        }
      })
      .catch((error) => {
        console.error({ component: "TestimonialSection", error: error });
      });
  }, []);

  if (testimonials?.length) {
    return (
      <Section id="Depoimentos">
        <TitleSection>veja a opinião de quem viaja com a gente</TitleSection>

        <WrapperLastContent>
          <WrapperLastSlider>
            <Slider
              ref={lastSlider}
              {...settings}
              aria-hidden="true"
              prevArrow={<PrevArrowDiv />}
              nextArrow={<NextArrowDiv />}
              autoplay={false} //remover
            >
              {testimonials.map(({ name, testimonial }, i) => (
                <div key={`${i}-${name}`}>
                  <WrapperSlides>
                    <WrapperReviews>
                      <ParagraphReview>
                        {removeHtmlInString(testimonial)}
                      </ParagraphReview>
                      <NameUserReview>{name}</NameUserReview>
                    </WrapperReviews>
                  </WrapperSlides>
                </div>
              ))}
            </Slider>
          </WrapperLastSlider>
        </WrapperLastContent>
      </Section>
    );
  }

  // if (valueTestimonial !== "" && nameTestimonial !== "") {
  //   return (
  //     <Section id="Depoimentos">
  //       <TitleSection>veja a opinião de quem viaja com a gente</TitleSection>
  //       <WrapperReviews>
  //         <ParagraphReview>
  //           {removeHtmlInString(valueTestimonial)}
  //         </ParagraphReview>
  //         <NameUserReview>{nameTestimonial}</NameUserReview>
  //       </WrapperReviews>
  //     </Section>
  //   );
  // }
  return <></>;
}

export default TestimonialSection;
