import create from "zustand";
import { DataProps } from "./types";

export const useSearchStore = create<DataProps>((set) => ({
  data: {
    rooms: [
      {
        adducts: 2,
        childrens: {
          number: 0,
          children: [],
        },
      },
    ],
    numRoom: 1,
    visitant: 2,
  },
  setData: (item) => set((state) => ({ data: (state.data = item) })),
}));
