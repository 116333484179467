import { useEffect } from "react";
import IconCLoseDArk from "../../assets/icones/icons dense (24px)/fechar.svg";
import { BodyModal, ContainerModal, HeaderModal, OverLay } from "./styles";
import useCreateToken from "../../hooks/createToken";
import FirstAccessForm from "../FirstAccessForm";

interface ModalFirstAccessProps {
  onClose: () => void;
}

const ModalFirstAccess = ({ onClose }: ModalFirstAccessProps) => {
  const { createToken, tokenApi } = useCreateToken();

  useEffect(() => {
    createToken();
  }, [createToken]);
  return (
    <OverLay>
      <ContainerModal>
        <HeaderModal>
          <span>primeiro acesso</span>
          <img src={IconCLoseDArk} alt="fechar-modal" onClick={onClose} />
        </HeaderModal>

        <BodyModal>
          <FirstAccessForm tokenApi={tokenApi} />
        </BodyModal>
      </ContainerModal>
    </OverLay>
  );
};

export default ModalFirstAccess;
