import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import Loading from "../../components/loading";
import { api } from "../../services";
import animationData from "./assets/check-success.json";
import {
  BtnKeep,
  Container,
  Content,
  Paragraph,
  SubTitle,
  Title,
} from "./styles";
import { FormRecoverPassword } from "./FormRecoverPassword";
import { FormRecoverPasswordFieldsProps } from "./types";

const MESSAGE_SUCCESS = "SUCCESS";

export const RecoverPassword = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const params = useParams();
  const [name, setName] = useState("");
  const [messageReturn, setMessageReturn] = useState<string>("");
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getNameByToken = useCallback(
    (token: string) => {
      setLoading(true);
      api
        .get(`/wsCoobrastur/ConsultaHoteis.asmx/C63?token=${token}`)
        .then((response) => {
          if (response?.data?.[0]?.resultado !== "1") {
            throw new Error("Erro ao requisitar dados ");
          }
          if (response.data[0].assNome) {
            setName(`, ${response.data[0].assNome}`);
          }
          setLoading(false);
        })
        .catch(() => {
          navigate(`${process.env.PUBLIC_URL}/`);
          // setLoading(false);
        });
    },
    [navigate]
  );

  useEffect(() => {
    if (!params?.token) {
      navigate(`${process.env.PUBLIC_URL}/`);
      return;
    }
    getNameByToken(params.token);
  }, [getNameByToken, navigate, params?.token]);

  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    handleGoToTop();
  }, []);

  const handleRecoverPassword = async (
    data: FormRecoverPasswordFieldsProps
  ) => {
    setLoading(true);
    api
      .get(`/wsCoobrastur/ConsultaHoteis.asmx/C64`, {
        params: {
          token: params.token,
          resSenha: data.password,
        },
      })
      .then((response) => {
        if (
          response?.data?.[0]?.resultado === 1 ||
          response?.data?.[0]?.resultado === "1"
        ) {
          setMessageReturn(MESSAGE_SUCCESS);
          return;
        }
        if (!response?.data?.[0]?.mensagem) {
          throw response;
        }
        setMessageReturn(response.data[0].mensagem);
      })
      .catch((response) =>
        setMessageReturn(response.data || "Ops, algo deu errado")
      );
    setLoading(false);
  };

  return (
    <Container>
      {!!loading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      )}

      <Content hide={!!messageReturn || loading}>
        <Title>REDEFINIR SENHA</Title>
        <SubTitle>{`Olá${name}`}</SubTitle>
        <Paragraph>
          Recebemos uma solicitação de redefinição de senha do portal do usuário
          Coobrastur. Para concluir, insira sua nova senha e clique em 'ALTERAR
          MINHA SENHA'.
        </Paragraph>
        <FormRecoverPassword onSuccess={handleRecoverPassword} />
      </Content>

      {/* On success */}
      <Content hide={loading || messageReturn !== MESSAGE_SUCCESS}>
        <Lottie options={defaultOptions} width={250} />
        <Title>Sucesso</Title>
        <Paragraph>Senha atualizada com sucesso.</Paragraph>
        <BtnKeep onClick={() => navigate(`${process.env.PUBLIC_URL}/`)}>
          VOLTAR PARA HOME
        </BtnKeep>
      </Content>

      {/* On Fail */}
      <Content
        hide={loading || !messageReturn || messageReturn === MESSAGE_SUCCESS}
      >
        <Title>Aviso</Title>
        <Paragraph>{messageReturn}</Paragraph>
        <BtnKeep onClick={() => setMessageReturn("")}>TENTAR NOVAMENTE</BtnKeep>
      </Content>
    </Container>
  );
};

export default RecoverPassword;
