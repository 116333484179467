import { FormHandles } from "@unform/core";

export interface ChoosePaymentsProps {
  formRef: React.RefObject<FormHandles>;
  value: number;
  confirmationTickets: () => Promise<void>;
  showTotalValue: boolean;
  resetRadio?: boolean;
  setResetRadio?: (value: boolean) => void;
  onFinish?: () => void;
  onErrorInResLocalizador: () => void;
}

export interface DataPaymentProps {
  parcelas: string;
  numeroDoCartao: string;
  mes: string;
  ano: string;
  cvv: string;
  nome: string;
}

export enum Month {
  Jan = "01",
  Feb = "02",
  Mar = "03",
  Apr = "04",
  May = "05",
  Jun = "06",
  Jul = "07",
  Aug = "08",
  Sep = "09",
  Oct = "10",
  Nov = "11",
  Dez = "12",
}
