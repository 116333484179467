import styled from "styled-components";

export const OverLay = styled.div`
  background-color: rgba(0, 0, 0, 0.48);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 482px;
  height: 577px;
  border-radius: 12px;
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

export const HeaderModal = styled.header`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HeaderTop = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  span {
    font-size: 14px;
    color: var(--gray500);
    margin-left: 49px;
  }
`;

export const HeaderBottom = styled.div`
  width: 100%;
  height: 50%;
  text-align: center;
  color: var(--purple600);
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconClose = styled.img`
  width: 20px;
  cursor: pointer;
`;

export const BodyContent = styled.div`
  width: 120%;
  flex: 1;
  overflow-y: scroll;
  position: relative;
  padding-left: 50px;
  text-align: justify;
`;

export const FooterModal = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
`;

export const BtnClose = styled.button`
  width: 100%;
  height: 40px;
  background-color: var(--pink600);
  border-radius: 25px;
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  margin: 12px 0px;
  padding: 9px 16px;
  cursor: pointer;
  border: 0;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  letter-spacing: 1.2px;
`;
