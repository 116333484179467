import { Scope } from "@unform/core";
import { Fragment, useEffect, useState } from "react";
import InputPassenger from "./InputPassenger";
import { InputProps } from "./InputPassenger/types";
import { FormItem } from "./styles";
import * as Yup from "yup";
import { Errors } from "../../Identify/types";
import { DataFormProps, FormPassengerProps } from "./types";
import {
  inputPassengerdata,
  inputPassengerdataH2,
  inputPassengerdataH3,
  inputPassengerdataQ2H1,
  inputPassengerdataQ2H2,
  inputPassengerdataQ2H3,
  inputPassengerdataQ3H1,
  inputPassengerdataQ3H2,
  inputPassengerdataQ3H3,
  inputPassengerdataQ4H1,
  inputPassengerdataQ4H2,
  inputPassengerdataQ4H3,
} from "../constants";

import { validadeFormPassengers, validadeHasChildren } from "./validateForm";

export function idade(nascimento: Date, hoje: Date): number {
  let diferencaAnos = hoje.getFullYear() - nascimento.getFullYear();
  if (
    new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()) <
    new Date(hoje.getFullYear(), nascimento.getMonth(), nascimento.getDate())
  ) {
    diferencaAnos--;
  }

  return diferencaAnos;
}

export function getGender(data: any, room: number, numberOfPassenger: number) {
  if (room === 1) {
    if (numberOfPassenger === 1) {
      return data.gender;
    }
    if (numberOfPassenger === 2) {
      return data.genderH2;
    }
    if (numberOfPassenger === 3) {
      return data.genderH3;
    }
  }
  return data[`genderH${numberOfPassenger}Q${room}`];
}

const FormPassenger = ({
  formPassengerRef,
  numberOfPassenger,
  numberOfChildrenThisRoom,
  ageChildrenThisRoom,
  room,
}: FormPassengerProps) => {
  const [passengers, setPassengers] = useState<any[]>([]);
  const [errorRoom, setErrorRoom] = useState("");

  const handleFailureHasChildren = async (message: string) => {
    setErrorRoom(message);
  };

  const handleSubmitPassenger = async (data: DataFormProps) => {
    try {
      await validadeFormPassengers(data);
      formPassengerRef?.current?.setErrors({});
      await validadeHasChildren(
        data,
        numberOfChildrenThisRoom,
        ageChildrenThisRoom,
        handleFailureHasChildren
      );
      setErrorRoom("");
      formPassengerRef?.current?.setErrors({});
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        console.error({
          path: err.path,
          message: err.message,
          value: err.value,
          type: err.type,
          inner: err.inner,
        });
        const errorMessage: Errors = {};
        err.inner.forEach((error) => {
          if (error.path) {
            // console.error({ path: error.path, message: error.message });
            errorMessage[error.path] = error.message;
          }
          return formPassengerRef?.current?.setErrors(errorMessage);
        });
      } else {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (room === "quarto 1") {
      if (numberOfPassenger === 1) {
        let definePassangers = [
          {
            passanger: "hospede 1 quarto 1",
            form: inputPassengerdata,
            id: "quarto 1",
            formRef: formPassengerRef,
          },
        ];
        setPassengers(definePassangers);
        return;
      }

      if (numberOfPassenger === 2) {
        let definePassangers = [
          {
            form: inputPassengerdata,
            formRef: formPassengerRef,
            id: "quarto 1",
            passanger: "hospede 1 quarto 1",
          },

          {
            form: inputPassengerdataH2,
            formRef: formPassengerRef,
            id: "quarto 1 ",
            passanger: "hospede 2 quarto 1",
          },
        ];

        setPassengers(definePassangers);
        return;
      }

      if (numberOfPassenger === 3) {
        let definePassangers = [
          {
            form: inputPassengerdata,
            formRef: formPassengerRef,
            id: "quarto 1",
            passanger: "hospede 1 quarto 1",
          },

          {
            form: inputPassengerdataH2,
            id: "quarto 1 ",
            formRef: formPassengerRef,
            passanger: "hospede 2 quarto 1",
          },
          {
            form: inputPassengerdataH3,
            id: "quarto 1 ",
            formRef: formPassengerRef,
            passanger: "hospede 3 quarto 1",
          },
        ];

        setPassengers(definePassangers);
        return;
      }
    }

    if (room === "quarto 2") {
      if (numberOfPassenger === 1) {
        let definePassangers = [
          {
            passanger: "hospede 1 quarto 2",
            form: inputPassengerdataQ2H1,
            id: "quarto 2",
            formRef: formPassengerRef,
          },
        ];
        setPassengers(definePassangers);
        return;
      }

      if (numberOfPassenger === 2) {
        let definePassangers = [
          {
            form: inputPassengerdataQ2H1,
            formRef: formPassengerRef,
            id: "quarto 2",
            passanger: "hospede 1 quarto 2",
          },

          {
            form: inputPassengerdataQ2H2,
            formRef: formPassengerRef,
            id: "quarto 2 ",
            passanger: "hospede 2 quarto 2",
          },
        ];
        setPassengers(definePassangers);
        return;
      }

      if (numberOfPassenger === 3) {
        let definePassangers = [
          {
            form: inputPassengerdataQ2H1,
            formRef: formPassengerRef,
            id: "quarto 2",
            passanger: "hospede 1 quarto 2",
          },

          {
            form: inputPassengerdataQ2H2,
            formRef: formPassengerRef,
            id: "quarto 2 ",
            passanger: "hospede 2 quarto 2",
          },
          {
            form: inputPassengerdataQ2H3,
            formRef: formPassengerRef,
            id: "quarto 2 ",
            passanger: "hospede 3 quarto 2",
          },
        ];
        setPassengers(definePassangers);
        return;
      }
    }

    if (room === "quarto 3") {
      if (numberOfPassenger === 1) {
        let definePassangers = [
          {
            passanger: "hospede 1 quarto 3",
            form: inputPassengerdataQ3H1,
            id: "quarto 3",
            formRef: formPassengerRef,
          },
        ];
        setPassengers(definePassangers);
        return;
      }

      if (numberOfPassenger === 2) {
        let definePassangers = [
          {
            form: inputPassengerdataQ3H1,
            formRef: formPassengerRef,
            id: "quarto 3",
            passanger: "hospede 1 quarto 3",
          },

          {
            form: inputPassengerdataQ3H2,
            formRef: formPassengerRef,
            id: "quarto 3 ",
            passanger: "hospede 2 quarto 3",
          },
        ];
        setPassengers(definePassangers);
        return;
      }

      if (numberOfPassenger === 3) {
        let definePassangers = [
          {
            form: inputPassengerdataQ3H1,
            formRef: formPassengerRef,
            id: "quarto 3",
            passanger: "hospede 1 quarto 3",
          },

          {
            form: inputPassengerdataQ3H2,
            formRef: formPassengerRef,
            id: "quarto 3 ",
            passanger: "hospede 2 quarto 3",
          },
          {
            form: inputPassengerdataQ3H3,
            formRef: formPassengerRef,
            id: "quarto 3 ",
            passanger: "hospede 3 quarto 3",
          },
        ];
        setPassengers(definePassangers);
        return;
      }
    }

    if (room === "quarto 4") {
      if (numberOfPassenger === 1) {
        let definePassangers = [
          {
            passanger: "hospede 1 quarto 4",
            form: inputPassengerdataQ4H1,
            id: "quarto 4",
            formRef: formPassengerRef,
          },
        ];
        setPassengers(definePassangers);
        return;
      }

      if (numberOfPassenger === 2) {
        let definePassangers = [
          {
            passanger: "hospede 1 quarto 4",
            form: inputPassengerdataQ4H1,
            id: "quarto 4",
            formRef: formPassengerRef,
          },
          {
            passanger: "hospede 2 quarto 4",
            form: inputPassengerdataQ4H2,
            id: "quarto 4",
            formRef: formPassengerRef,
          },
        ];
        setPassengers(definePassangers);
        return;
      }
      if (numberOfPassenger === 3) {
        let definePassangers = [
          {
            passanger: "hospede 1 quarto 4",
            form: inputPassengerdataQ4H1,
            id: "quarto 4",
            formRef: formPassengerRef,
          },
          {
            passanger: "hospede 2 quarto 4",
            form: inputPassengerdataQ4H2,
            id: "quarto 4",
            formRef: formPassengerRef,
          },
          {
            passanger: "hospede 3 quarto 4",
            form: inputPassengerdataQ4H3,
            id: "quarto 4",
            formRef: formPassengerRef,
          },
        ];
        setPassengers(definePassangers);
        return;
      }
    }
  }, [numberOfPassenger, formPassengerRef, room]);

  return (
    <FormItem onSubmit={handleSubmitPassenger} ref={formPassengerRef}>
      {errorRoom && <p style={{ color: "var(--red600)" }}>{errorRoom}</p>}
      {passengers.map((element, index) => (
        <Fragment key={element.passanger}>
          <span
            style={{
              color: "var(--purple600)",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            {" "}
            hóspede {index + 1}
          </span>
          <Scope path={`hospede[${index}]`}>
            {element.form.map((item: InputProps) => (
              <InputPassenger
                description={item.description}
                id={item.id}
                label={item.label}
                name={item.name}
                type={item.type}
                key={item.id}
                options={item.options}
                maxLength={item.maxLength}
              />
            ))}
          </Scope>
        </Fragment>
      ))}
    </FormItem>
  );
};

export default FormPassenger;
