import { Steps } from "../../components/steps";

import {
  Container,
  SectionPayment,
  Wrapper,
  WrapperSteps,
  WrapperTextSteps,
} from "./styles";
import Roons from "./Roons";
import DetailsOfReserve from "./DetailsOfReserve";
import { Fragment, useEffect, useState } from "react";
import Modal from "../../components/modal";
import { contentModalPolitics, contentModalTerms } from "./constants";

const Payments = () => {
  const [OpenModalTerms, setOpenModalTerms] = useState<boolean>(false);
  const [openModalPolitics, setOpenModalPolitics] = useState<boolean>(false);

  const handleOpenModal = () => {
    setOpenModalTerms(!OpenModalTerms);
  };

  const handleOpenModalPolitics = () => {
    setOpenModalPolitics(!openModalPolitics);
  };

  const handleGoTotop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    handleGoTotop();
  }, []);

  return (
    <Fragment>
      {OpenModalTerms && (
        <Modal
          close={handleOpenModal}
          title={"Termos Gerais"}
          content={contentModalTerms}
        />
      )}
      {openModalPolitics && (
        <Modal
          close={handleOpenModalPolitics}
          title="Política de Cancelamento"
          content={contentModalPolitics}
        />
      )}
      <Container>
        <WrapperSteps>
          <WrapperTextSteps>
            <h4> Etapas para concluir sua reserva</h4>
            <span>
              Você está na terceira etapa. Escolha a sua hospedagem dentro os
              resultados abaixo para prosseguir.
            </span>
          </WrapperTextSteps>
          <Steps stepOne="disabled" stepTwo="disabled" stepThree="active" />
        </WrapperSteps>

        <SectionPayment>
          <Wrapper>
            <DetailsOfReserve />
            <Roons
              openModal={handleOpenModal}
              openModalPolitics={handleOpenModalPolitics}
            />
          </Wrapper>
        </SectionPayment>
      </Container>
    </Fragment>
  );
};

export default Payments;
