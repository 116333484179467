import { cpf, cnpj } from "cpf-cnpj-validator";

export function formatDate(date: Date | null | undefined) {
  if (!date) {
    return null;
  }

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };

  return date.toLocaleDateString("pt-br", options);
}

export const cpfValidator = (number: string | undefined): boolean => {
  if (!number) return false;
  return cpf.isValid(number.padStart(11, "0"));
};

export const cpfOrCNPJValidator = (number: string | undefined): boolean => {
  if (!number) return false;
  if (number.length > 11) return cnpj.isValid(number.padStart(14, "0"));
  return cpf.isValid(number.padStart(11, "0"));
};

export const showWarningModal = (dateIn: Date): boolean => {
  return (
    Math.ceil((dateIn.getTime() - new Date().getTime()) / (1000 * 3600 * 24)) <=
    30
  );
};
