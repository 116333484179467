import styled from "styled-components";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  max-height: 100%;
  min-width: 295px;
  @media (max-width: 300px) {
    min-width: 100%;
  }
`;

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1rem 0.65rem 1rem;
  & svg {
    marging: 15px;
  }
`;
export const ContainerButtonCloseModal = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 16px 16px 0 16px;
  width: 100%;
`;
export const ButtonCloseModal = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  outline: 0;
  padding: 0;

  svg {
    display: block;
    width: 24px;
    height: 24px;
  }
`;

export const ModalHeaderTilte = styled.h2`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  text-align: left;
  color: #706f6f;
`;

export const ModalBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  scroll-behavior: smooth;
  padding: 0 2rem;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Text = styled.p`
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin: 0;
  margin-bottom: 12px;
  text-align: center;
  & .orange {
    color: var(--orange200);
  }
`;

export const Link = styled.button`
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin: 0;
  margin-bottom: 12px;
  text-align: center;

  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
`;

export const ModalFooter = styled.div`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ButtonConfirmation = styled.button`
  cursor: pointer;
  display: block;
  background-color: var(--white);
  border-color: var(--pink600);
  border-width: 3px;
  color: var(--pink600);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  border-radius: 20px;
  width: 100%;
  max-width: 100%;
  min-height: 40px;
  margin: 0 auto;

  &:disabled {
    opacity: 0.15;

    &:hover {
      background-color: var(--gray300);
    }
  }

  &:hover {
    background-color: var(--pink400);
  }
`;

export const ButtonSelectOtherDate = styled.button`
  cursor: pointer;
  display: block;
  background-color: var(--pink600);
  border: none;
  color: var(--white);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  border-radius: 20px;
  width: 100%;
  max-width: 100%;
  min-height: 40px;
  margin: 0 auto;

  &:disabled {
    opacity: 0.15;

    &:hover {
      background-color: var(--pink600);
    }
  }

  &:hover {
    background-color: var(--pink500);
  }
`;
