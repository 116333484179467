import { useRef } from "react";
import { FormHandles, FormHelpers } from "@unform/core";
import * as Yup from "yup";
import Input from "../../components/input";
import { Errors } from "../Identify/types";
import { FormItem, BtnKeep } from "./styles";
import {
  FormRecoverPasswordFieldsProps,
  FormRecoverPasswordProps,
} from "./types";

export const FormRecoverPassword = ({
  onSuccess,
}: FormRecoverPasswordProps) => {
  const formRef = useRef<FormHandles>(null);
  const handleSubmit = async (
    data: FormRecoverPasswordFieldsProps,
    { reset }: FormHelpers
  ) => {
    try {
      const schema = Yup.object().shape({
        password: Yup.string().required("Informe a sua nova senha"),
        confirmPassword: Yup.string()
          .required("Informe a confirmação de sua nova senha")
          .oneOf(
            [Yup.ref("password"), null],
            "As senhas informadas devem ser iguais"
          ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      await onSuccess(data);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessage: Errors = {};
        err.inner.forEach((error) => {
          if (error.path) {
            errorMessage[error.path] = error.message;
          }
          return formRef.current?.setErrors(errorMessage);
        });
        return;
      }
    }

    formRef.current?.setErrors({});
  };
  return (
    <FormItem ref={formRef} onSubmit={handleSubmit} initialData={{}}>
      <Input
        name="password"
        label="Nova senha"
        id="password"
        description="Digite sua nova senha"
        type="password"
        autoComplete="new-password"
      />
      <Input
        name="confirmPassword"
        label="Confirmação de nova senha"
        id="confirmPassword"
        description="Repita sua nova senha"
        type="password"
        autoComplete="new-password"
      />

      <BtnKeep type="submit">ALTERAR MINHA SENHA</BtnKeep>
    </FormItem>
  );
};
