import styled from "styled-components";

export const ContainerCookie = styled.div`
  background-color: var(--purple500);
  width: 100%;
  max-width: 900px;
  height: auto;
  position: fixed;
  z-index: 5;
  bottom: 4px;
  left: 4px;
  border-radius: 24px;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;

  p {
    color: var(--white);
  }

  p a {
    color: var(--white);
  }

  @media screen and (max-width: 1024px) {
    max-width: 600px;
  }

  @media screen and (max-width: 800px) {
    max-width: 450px;
  }

  @media screen and (max-width: 500px) {
    max-width: 350px;
    margin-bottom: 80px;
    left: 10px;
  }
`;

export const BtnAccept = styled.button`
  width: 100%;
  max-width: 200px;
  height: 40px;
  background-color: var(--pink600);
  border-radius: 25px;
  font-size: 0.8rem;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  margin: 12px 0px;
  cursor: pointer;
  opacity: 1;
  border: 0;
  &:hover {
    background-color: var(--pink400);
  }
`;
