import Modal from "react-modal";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import PopImg from "../assets/COOB-POPUP-ATENCAO.png";
import IconClose from "../../../assets/icones/icons dense (24px)/fechar - white.svg";
import { Link, WrapperClose } from "./styles";

interface PopUpProps {
  isOpen: boolean;
  onClose: () => void;
}
const PopUp = ({ isOpen, onClose }: PopUpProps) => {
  const mobile = useMediaQuery("(max-width: 500px)");
  return (
    <Modal
      isOpen={isOpen}
      style={{
        content: {
          width: "95vw",
          maxWidth: mobile ? "95vw" : "600px",
          maxHeight: mobile ? "70%" : "608px",
          height: mobile ? "fit-content" : "100%",
          padding: "0",
          left: "50%",
          top: "50%",
          transform: mobile ? "translate(-50%, -50%)" : "translate(-50%, -50%)",
          overflow: "hidden",
          zIndex: 5,
          borderRadius: "20px",
          backgroundColor: "transparent",
          borderColor: "transparent",
        },
        overlay: {
          zIndex: 5,
          backgroundColor: "rgba(0,0,0,0.48)",
        },
      }}
      onRequestClose={onClose}
    >
      <Link href="mailto:atendimento@coobmais.com.br" target="_blanck">
        <img
          src={PopImg}
          alt="Atenção. Entre em contato por e-mail atendimento@coobmais.com.br"
          width="100%"
        />
      </Link>
      <WrapperClose>
        <img
          src={IconClose}
          alt="fechar-popUp"
          onClick={onClose}
          width="100%"
        />
      </WrapperClose>
    </Modal>
  );
};

export default PopUp;
