import { useEffect } from "react";
import BannerPagesInst from "../../components/BannerPagesInst";
import {
  Container,
  ContentArea,
  WrapperQuestion,
  Title,
  Button,
} from "./styles";

const NotFound = () => {
  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    handleGoToTop();
  }, []);
  return (
    <Container>
      <BannerPagesInst title="Página Não Encontrada" />
      <ContentArea>
        <WrapperQuestion>
          <Title>A página procurada não pode ser encontrada!!</Title>
          <Button href="/" rel="noreferrer">
            Voltar Para Home
          </Button>
        </WrapperQuestion>
      </ContentArea>
    </Container>
  );
};

export default NotFound;
