import Modal from "react-modal";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { ReactComponent as IconFechar } from "../../assets/icones/icons dense (24px)/fechar.svg";
import { ReactComponent as IconAttention } from "../../assets/icones/icons dense (24px)/icon-park-solid_attention.svg";
import {
  ButtonCloseModal,
  ButtonSelectOtherDate,
  ButtonConfirmation,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderTilte,
  Text,
  ContainerButtonCloseModal,
  Link,
} from "./styles";

type ModalWarningProps = {
  onClose: () => void;
  isOpen: boolean;
  showCloseIconHeader?: boolean;
  handleConfirmation: () => void;
  handleSelectOtherDate: () => void;
  onClickInformation: () => void;
};

export const ContentModalWarning = ({
  onClose,
  showCloseIconHeader = false,
  handleConfirmation = onClose,
  handleSelectOtherDate,
  onClickInformation,
}: Omit<ModalWarningProps, "isOpen">) => (
  <ModalContent>
    <ContainerButtonCloseModal>
      {showCloseIconHeader && (
        <ButtonCloseModal type="button" title="fechar" onClick={onClose}>
          <IconFechar onClick={onClose} />
        </ButtonCloseModal>
      )}
    </ContainerButtonCloseModal>

    <ModalHeader>
      <IconAttention />
      <ModalHeaderTilte>Atenção:</ModalHeaderTilte>
    </ModalHeader>

    <ModalBody>
      <Text>
        O período selecionado{" "}
        <strong className="orange">
          não cumpre a antecedência mínima de 30 dias
        </strong>{" "}
        exigida pela política de reservas Coob+.
      </Text>

      <Text>
        Sua reserva será encaminhada para uma análise do hotel selecionado e
        poderá ser <strong>aprovada</strong> ou <strong>recusada</strong>.
      </Text>

      <Text>
        <Link onClick={onClickInformation}>Saiba mais</Link>
      </Text>
    </ModalBody>

    <ModalFooter>
      <ButtonSelectOtherDate onClick={handleSelectOtherDate}>
        Selecionar outro período
      </ButtonSelectOtherDate>
      <ButtonConfirmation onClick={handleConfirmation}>
        Quero solicitar uma reserva
      </ButtonConfirmation>
    </ModalFooter>
  </ModalContent>
);

export const ModalWarning = ({ isOpen, ...props }: ModalWarningProps) => {
  const mobile = useMediaQuery("(max-width: 990px)");

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      style={{
        content: {
          width: "95%",
          maxWidth: "400px",
          height: mobile ? "400px" : "400px",
          padding: "0",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "hidden",
          zIndex: 999,
          borderRadius: "20px",
        },
        overlay: {
          zIndex: 999,
          backgroundColor: "rgba(0,0,0,0.48)",
        },
      }}
    >
      <ContentModalWarning {...props} />
    </Modal>
  );
};
