import { Form } from "@unform/web";
import styled, { keyframes } from "styled-components";

export const Rotate = keyframes`
from{
    transform:rotate(0deg);
}
to{
    transform:rotate(360deg); 
}
`;
export const LoadingBtn = styled.div`
  width: 30px;
  height: 30px;
  background-color: var(--pink600);
  border-radius: 50%;
  border: 4px solid var(--white);
  border-top-color: var(--pink600);
  animation: ${Rotate} 1s linear infinite;
`;
export const FormItem = styled(Form)`
  width: 100%;
  max-width: 530px;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Btn = styled.button`
  width: 100%;
  height: 40px;
  background-color: var(--pink600);
  border-radius: 25px;
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  margin: 12px 0px;
  cursor: pointer;

  border: 0;
  &:hover {
    background-color: var(--pink400);
  }
  &:disabled {
    background-color: var(--pink400);
    cursor: not-allowed;
  }
`;
