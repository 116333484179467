import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Steps } from "../../components/steps";
import Input from "../../components/input";
import * as Yup from "yup";
import ReCaptcha from "react-google-recaptcha";
import { Errors, FormProps } from "./types";
import { FormHandles, FormHelpers } from "@unform/core";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { dataInput, initialData } from "./constants";
import IconCLoseDArk from "../../assets/icones/icons dense (24px)/fechar.svg";
import {
  BtnAssociated,
  BtnKeep,
  Container,
  ForgotThePassword,
  FormItem,
  LoadingBtn,
  Paragraph,
  SectionForm,
  TextError,
  TitleLogin,
  WrapperRecaptcha,
  WrapperSteps,
  WrapperTextSteps,
} from "./styles";
import { FormItem as FormItemForgotPass } from "../../components/header/styles";
import { api } from "../../services";

import { ModalFormConfirmation } from "../../components/modalFormConfirmation";
import { ModalAlert } from "../../components/modalAlert";
import { MessageAlertPropsWithBody } from "../../components/modalAlert/types";
import {
  BodyModal,
  BtnLoginUser,
  ContainerModal,
  HeaderModal,
} from "../../components/header/styles";
import { dataInputForgottPass } from "../../components/header/constants";
import { FormForgottPassProps } from "../../components/header/types";
import { ModalFormFinalizeAssociateRegistration } from "../../components/FormFinalizarCadastroAssociado";
import { FinalizeAssociateRegistrationData } from "../../components/FormFinalizarCadastroAssociado/types";
import { cpfOrCNPJValidator } from "../../utils";

const Identify = () => {
  const formRefForgot = useRef<FormHandles>(null);
  const formRefLogin = useRef<FormHandles>(null);
  const [disable, setDisable] = useState<boolean>(true);
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState<MessageAlertPropsWithBody>(
    {} as MessageAlertPropsWithBody
  );
  const [modalAlert, setModalAlert] = useState<boolean>(false);
  const handleCloseModalAlert = () => setModalAlert(false);
  const [openLogin, setOpenLogin] = useState<boolean>(false);
  const [openModalForgotPassword, setOpenModalForgotPassWord] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const [errorLogin, setErrorLogin] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [succesLogin, setSuccessLogin] = useState<string>("");
  const [showModalFinalizeRegis, setShowModalFinalizeRegis] = useState(false);
  const [initialDataFinalizeRegis, setInitialDataFinalizeRegis] =
    useState<any>(undefined);
  const token = localStorage.getItem("token");
  const hotelCodeString = localStorage.getItem("codigoHotel");
  const country = localStorage.getItem("pais");
  const { id } = useParams();
  const url = useLocation();

  const handleCloseModalFinalizeRegis = () => {
    setShowModalFinalizeRegis(false);
    setLoading(false);
    formRefLogin.current?.submitForm();
  };

  const needFinalizeRegistration = (data: any): boolean => {
    // Se retornar true abre o modal para finalizar cadastro
    if (!data?.assRG) return true;

    // if (!data?.assDTNascimento || !data?.civCodigo) {
    //   return true;
    // }
    // if (!data?.assNumCelular) return true;

    return false;
  };

  const handleLogin = async (
    reset: () => void,
    data: { password: string; user: string }
  ) => {
    const senha = data.password;
    const usuario = data.user;
    setLoading(true);
    api
      .post(`/wsCoobrastur/Hotel.asmx/login_1`, {
        token:
          url.pathname === `${process.env.PUBLIC_URL}/identifique-se`
            ? token
            : id,
        senha,
        usuario,
      })
      .then(async (respLogin1) => {
        if (
          respLogin1.data.Table[0].Texto ===
          "Login Inválido. Por favor Verifique!"
        ) {
          setErrorLogin(respLogin1.data.Table[0].Texto);
          setMessage({
            title: "Ops. Algo Deu Errado!",
            body: respLogin1.data.Table[0].Texto,
          });
          setModalAlert(true);
          setLoading(false);
          return;
        }
        if (respLogin1.data?.Table?.[0]?.situacao === 0) {
          setErrorLogin(respLogin1.data.Table[0].Texto);
          setMessage({
            title: "Ops. Algo Deu Errado!",
            body:
              respLogin1.data?.Table?.[0]?.Texto ||
              "A página será redirecionada para Home.",
          });
          setModal(true);
          setLoading(false);
          return;
        }

        // get assnic
        const inToken = token;
        const respC8A = await api.get(
          `wsCoobrastur/ConsultaHoteis.asmx/C8A_1`,
          {
            params: {
              senha,
              token: inToken,
              usuario,
            },
          }
        );
        if (respC8A.data[0].situacao !== "1" || !respC8A?.data?.[0]?.assNic) {
          throw Error("Erro on C8A_1");
        }
        const assNic = respC8A.data[0].assNic;
        if (inToken) localStorage.setItem("tokenPortalAssociado", inToken);
        localStorage.setItem("assnic", assNic); // from page "/pagamentos" and "formFinalizeRegis"
        localStorage.setItem("nameUser", respC8A.data[0].nome); // from page "/pagamentos"
        localStorage.setItem("usuarioLogin", usuario);

        const respPU_1 = await api.get(
          `/wsCoobrastur/ConsultaHoteis.asmx/PU_1_2`,
          { params: { nic: assNic, in_token: inToken } }
        );
        if (!respPU_1?.data?.[0]) {
          throw Error("Erro on PU_1");
        }

        if (
          needFinalizeRegistration(respPU_1.data[0]) ||
          !!respC8A.data[0]?.possuiPendenciasCadastrais
        ) {
          const dateString = respPU_1.data[0]?.assDTNascimento.split("/");
          setInitialDataFinalizeRegis({
            assRG: respPU_1.data[0]?.assRG,
            assOrgExpRG: respPU_1.data[0]?.assOrgExpRG,
            assDTNascimento:
              dateString.length < 3
                ? undefined
                : `${dateString[2]}-${dateString[1]}-${dateString[0]}`,
            assSexo: respPU_1.data[0]?.assSexo,
            civCodigo: respPU_1.data[0]?.civCodigo,
            assNomeMae: respPU_1.data[0]?.assNomeMae,
            CEPRes: respPU_1.data[0]?.endCEP,
            logradouroRes: respPU_1.data[0]?.endLogradouro,
            numeroRes: respPU_1.data[0]?.endNumero,
            complementoRes: respPU_1.data[0]?.endComplemento,
            bairroRes: respPU_1.data[0]?.endBairro,
            cidadeRes: respPU_1.data[0]?.endCidade,
            UFRes: respPU_1.data[0]?.endUF,
            assNumCelular: !!respPU_1.data[0]?.assNumCelular
              ? `${respPU_1.data[0].assNumCelularDDD}${respPU_1.data[0].assNumCelular}`
              : undefined,
            assFoneRes: !!respPU_1.data[0]?.assFoneRes
              ? `${respPU_1.data[0].assFoneResDDD}${respPU_1.data[0].assFoneRes}`
              : undefined,
            assFoneComl: !!respPU_1.data[0]?.assFoneComl
              ? `${respPU_1.data[0].assFoneComlDDD}${respPU_1.data[0].assFoneComl}`
              : undefined,
          } as FinalizeAssociateRegistrationData);
          setShowModalFinalizeRegis(true);
          return;
        }

        localStorage.setItem("user", usuario); // remove from if used on PIX
        if (country === "BR" || country === null) {
          setLoading(false);
          navigate(`${process.env.PUBLIC_URL}/pagamentos`);
          return;
        }

        if (country !== "BR" && country !== null) {
          setLoading(false);
          navigate(`${process.env.PUBLIC_URL}/pagamentos-internacional`);
          return;
        }
      })
      .catch((err) => {
        setLoading(false);
        if (
          err?.response?.data?.Message ===
            "Você ficou muito tempo sem navegar pelo site, a página será atualizada!?" ||
          err?.response?.data?.Message.toLowerCase().includes(
            "a página será atualizada"
          )
        ) {
          setErrorLogin(err.response.data.Message);
          setMessage({
            title: "Ops. Algo Deu Errado!",
            body: err.response.data.Message,
          });
          setModal(true);
          setLoading(false);
          return;
        }
        setErrorLogin(
          err?.response?.data?.Message || "Algo deu errado, tente mais tarde"
        );
        setLoading(false);
        throw err;
      });
  };

  const handleSubmit = async (data: FormProps, { reset }: FormHelpers) => {
    try {
      const schema = Yup.object().shape({
        user: Yup.string()
          .required("campo é obrigatório")
          .test(
            "invalid-cpf",
            "* CPF ou CNPJ inválido. Por favor, tente novamente.",
            (cpf) => cpfOrCNPJValidator(cpf)
          ),
        password: Yup.string().required("a senha é obrigatória"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      await handleLogin(reset, data);
      formRefLogin.current?.setErrors({});
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessage: Errors = {};
        err.inner.forEach((error) => {
          if (error.path) {
            errorMessage[error.path] = error.message;
          }
          return formRefLogin.current?.setErrors(errorMessage);
        });
        return;
      }
    }
  };

  const handleRecaptcha = (value: string | null) => {
    if (value !== null) {
      setDisable(false);
      return;
    }
  };

  const handleCloseModal = () => {
    setModal(false);
    window.location.href = `${process.env.PUBLIC_URL}/`;
  };

  const handleForgotPass = () => {
    setOpenModalForgotPassWord(true);
  };

  const handleToggleModaLforgotPass = () => {
    setOpenModalForgotPassWord(!openModalForgotPassword);
    setErrorLogin("");
    setSuccessLogin("");
    setOpenLogin(false);
  };

  const handleSubmitForgotPass = async (data: FormForgottPassProps) => {
    try {
      const schema = Yup.object().shape({
        email: Yup.string().email("campo obrigatório"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      api
        .get(
          `/wsCoobrastur/ConsultaHoteis.asmx/C62_1?email=${data.email}&token=${token}`
        )
        .then((resp) => {
          if (resp.status === 200) {
            if (resp.data[0].resultado === "0") {
              setErrorLogin(resp.data[0].mensagem);
              setSuccessLogin("");
              return;
            }

            if (resp.data[0].resultado === "1") {
              setErrorLogin("");
              setSuccessLogin(resp.data[0].mensagem);
              return;
            }
          }
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessage: Errors = {};
        err.inner.forEach((error) => {
          if (error.path) {
            errorMessage[error.path] = error.message;
          }
          return formRefForgot.current?.setErrors(errorMessage);
        });
        return;
      }
      formRefForgot.current?.setErrors({});
    }
  };

  useEffect(() => {
    if (url.pathname === `${process.env.PUBLIC_URL}/identifique-se`) {
      if (hotelCodeString) {
        const codeHotel = parseInt(hotelCodeString);

        api
          .post(`wsCoobrastur/Hotel.asmx/updateToken_1`, {
            hotCodigo: codeHotel,
            token: token,
          })
          .then((resp) => {
            if (resp.data.Table[0].situacao === 0) {
              setMessage({
                title: "Você ficou muito tempo sem navegar",
                body: "A página será redirecionada para Home.",
              });
              setModal(true);

              return;
            }
          })
          .catch((err) => {
            console.error({ component: "identify", erro: err.message });
          });
      }
    }
  }, [token, hotelCodeString, url.pathname]);

  //http://localhost:3000/novosite/identificacao/1/064F2D02-5BF3-4147-8AE0-2B1508FB7760

  const getDisponibilityHotel = useCallback(async () => {
    if (id) {
      const text = `O prazo para concluir esta disponibilidade extra encerrou, por favor faça uma nova consulta!`;
      await api
        .get(`/wsCoobrastur/Hotel.asmx/returnInfosDisp?token=${id}`)
        .then(async (resp) => {
          if (resp.status === 200) {
            if (resp.data.Table[0].situacao === 0) {
              setMessage({
                title: "Consulta indisponivel",
                body: text,
              });
              setModal(true);

              return;
            }

            localStorage.setItem("hotCodDisp", resp.data.Table[0].hotCodigo);

            let ageChild: string = resp.data.Table[0].idade_criancas;
            const dataNewSearch = {
              in_token: token,
              dataInicial: resp.data.Table[0].Periodo_Inicial,
              dataFinal: resp.data.Table[0].Periodo_Final,
              qtdeQuartos: resp.data.Table[0].Qtde_quartos,
              adultosQ1: resp.data.Table[0].Qtde_adultosq1,
              adultosQ2: resp.data.Table[0].Qtde_adultosq2,
              adultosQ3: resp.data.Table[0].Qtde_adultosq3,
              adultosQ4: resp.data.Table[0].Qtde_adultosq4,
              criancasQ1: resp.data.Table[0].Qtde_criancasq1,
              criancasQ2: resp.data.Table[0].Qtde_criancasq2,
              criancasQ3: resp.data.Table[0].Qtde_criancasq3,
              criancasQ4: resp.data.Table[0].Qtde_criancasq4,
              idadeC1:
                ageChild.substring(0, 1) === ""
                  ? "-1"
                  : ageChild.substring(0, 1) ?? "-1",
              idadeC2:
                ageChild.substring(2, 3) === ""
                  ? "-1"
                  : ageChild.substring(2, 3) ?? "-1",
              idadeC3:
                ageChild.substring(3, 4) === ""
                  ? "-1"
                  : ageChild.substring(3, 4) ?? "-1",
              idadeC4:
                ageChild.substring(4, 5) === ""
                  ? "-1"
                  : ageChild.substring(4, 5) ?? "-1",
              consultaInternacional: 0,
              in_Codigo: "",
              hotCodigoSelecionado: resp.data.Table[0].hotCodigo,
              fotoHotelSelecionado: "",
              enderecoHotelSelecionado: resp.data.Table[0].hotEndereco,
              nomeHotelSelecionado: resp.data.Table[0].hotNomefantasia,
              nomeDestino: resp.data.Table[0].hotEndereco,
              usuarioLogin: 0,
              tktCodigo1: 0,
              tktCodigo2: 0,
              tktCodigo3: 0,
              tktCodigo4: 0,
              totalAdultos: resp.data.Table[0].Qtde_adultos,
              totalCriancas: resp.data.Table[0].Qtde_criancas,
            };
            await api
              .post(`/wsCoobrastur/Hotel.asmx/InsertPesquisa_1`, {
                dados: JSON.stringify(dataNewSearch),
              })
              .then((resp) => {
                if (resp.status === 200) {
                  if (resp.data.Table[0].situacao === 1) {
                    console.log("disponibilidade ok");

                    return;
                  }
                }
              })
              .catch((err) =>
                console.error({ component: "identify", erro: err.message })
              );
          }
        })
        .catch((err) =>
          console.error({ component: "Identify", erro: err.message })
        );
    }
  }, [id, token]);

  useEffect(() => {
    if (url.pathname !== `${process.env.PUBLIC_URL}/identifique-se`) {
      getDisponibilityHotel();
    }
  }, [getDisponibilityHotel, url.pathname]);

  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    localStorage.removeItem("hotCodDisp");
    handleGoToTop();
  }, []);

  useEffect(() => {
    if (url.pathname !== `${process.env.PUBLIC_URL}/identifique-se`) {
      localStorage.setItem("token", id as string);
    }
  }, [id, url.pathname]);

  return (
    <Fragment>
      {openModalForgotPassword && (
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.48)",
            position: "fixed",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 4,
          }}
        >
          <ContainerModal>
            <HeaderModal>
              <span>{!openLogin ? "Redefinir senha" : "Acessar conta"}</span>
              <img
                src={IconCLoseDArk}
                alt="fechar-modal"
                onClick={handleToggleModaLforgotPass}
              />
            </HeaderModal>
            {!openLogin && (
              <BodyModal>
                <div style={{ paddingLeft: "16px" }}>
                  Insira o endereço de email associado à sua conta que lhe
                  enviaremos um link de redefinição de senha.
                </div>
                <FormItemForgotPass
                  ref={formRefForgot}
                  onSubmit={handleSubmitForgotPass}
                >
                  {dataInputForgottPass.map((item) => (
                    <Input
                      name={item.name}
                      label={item.label}
                      key={item.id}
                      id={item.id}
                      description={item.description}
                      type={item.type}
                    />
                  ))}

                  {/* <div style={{ width: "100%" }}>
                    <span style={{ color: "var(--purple500)" }}>
                      Você já possui conta?
                      <strong
                        style={{ color: "var(--pink500)", cursor: "pointer" }}
                        onClick={handleLoginInIdentify}
                      >
                        {" "}
                        Entre
                      </strong>
                    </span>
                  </div> */}

                  {succesLogin !== "" && (
                    <span style={{ color: "var(--green600)" }}>
                      {succesLogin}
                    </span>
                  )}

                  {errorLogin !== "" && (
                    <span style={{ color: "var(--red600)" }}>{errorLogin}</span>
                  )}

                  <BtnLoginUser type="submit">redefinir senha</BtnLoginUser>
                </FormItemForgotPass>
              </BodyModal>
            )}

            {/* {openLogin && (
              <BodyModal>
                <FormItemForgotPass
                  ref={formRef}
                  onSubmit={handleSubmitLoginInIdentify}
                >
                  {dataInput.map((item) => (
                    <Input
                      name={item.name}
                      label={item.label}
                      key={item.id}
                      id={item.id}
                      description={item.description}
                      type={item.type}
                      maxLength={item?.maxLength}
                      onChange={item?.onChange}
                    />
                  ))}

                  <div style={{ width: "100%" }}>
                    <span style={{ color: "var(--purple500)" }}>
                      esqueceu sua senha?
                      <strong
                        style={{ color: "var(--pink500)", cursor: "pointer" }}
                        onClick={handleLoginInIdentify}
                      >
                        {" "}
                        Clique aqui
                      </strong>
                    </span>
                  </div>

                  {succesLogin !== "" && (
                    <span style={{ color: "var(--green600)" }}>
                      {succesLogin}
                    </span>
                  )}

                  {errorLogin !== "" && (
                    <span style={{ color: "var(--red600)" }}>{errorLogin}</span>
                  )}

                  <BtnLoginUser type="submit">acessar conta</BtnLoginUser>
                </FormItemForgotPass>
              </BodyModal>
            )} */}
          </ContainerModal>
        </div>
      )}
      <Container>
        <WrapperSteps>
          <WrapperTextSteps>
            <h4> Etapas para concluir sua reserva</h4>
            <span>
              Você está na segunda etapa. Faça o seu login para prosseguir.
            </span>
          </WrapperTextSteps>
          <Steps stepOne="disabled" stepTwo="active" stepThree="disabled" />
        </WrapperSteps>
        <SectionForm>
          <TitleLogin>Insira seus dados de login</TitleLogin>
          {errorLogin !== "" && <TextError>{errorLogin}</TextError>}
          <FormItem
            initialData={initialData}
            onSubmit={handleSubmit}
            ref={formRefLogin}
          >
            {dataInput.map((item) => (
              <Input
                name={item.name}
                label={item.label}
                key={item.id}
                id={item.id}
                description={item.description}
                type={item.type}
                maxLength={item?.maxLength}
                onChange={item?.onChange}
              />
            ))}

            <ForgotThePassword onClick={handleForgotPass}>
              Esqueci minha senha
            </ForgotThePassword>
            <Paragraph>
              Para garantir sua segurança, complete o reCaptcha abaixo
            </Paragraph>
            <WrapperRecaptcha>
              <ReCaptcha
                sitekey="6LcO4ZQiAAAAAJ89a0mxNgk4hkrU1PUOmvWITgCy"
                onChange={handleRecaptcha}
              />
            </WrapperRecaptcha>
            <BtnKeep disabled={disable}>
              {loading ? <LoadingBtn /> : "continuar"}
            </BtnKeep>
            <TitleLogin style={{ textAlign: "center" }}>
              Ainda não fez sua assinatura?
            </TitleLogin>
            <BtnAssociated
              type="button"
              onClick={() =>
                window.open("https://www.coobmais.com.br/lp/planos/", "_blanck")
              }
            >
              ASSOCIE-SE À COOB+
            </BtnAssociated>
          </FormItem>
        </SectionForm>
      </Container>
      <ModalFormConfirmation
        modalIsOpen={modal}
        setIsOpen={handleCloseModal}
        message={message}
      />
      <ModalAlert
        isOpen={modalAlert}
        message={message}
        onClose={handleCloseModalAlert}
      />
      <ModalFormFinalizeAssociateRegistration
        isOpen={showModalFinalizeRegis}
        onClose={handleCloseModalFinalizeRegis}
        initialData={initialDataFinalizeRegis}
      />
    </Fragment>
  );
};

export default Identify;
