import { FormHelpers, FormProps } from "@unform/core";
import * as Yup from "yup";
import { Errors, FormOtherPersonProps } from "./types";
import { ContainerForm, FormItem, Paragraph, WrapperInputs } from "./styles";
import Input from "./Input";
import { useFormOtherPerson } from "../../../../store/useFormOtherPerson";
import { validadeFormOtherPerson } from "./validateForm";

const FormOtherPerson = ({
  optiosFildSelect,
  formRefOtherPerson,
}: FormOtherPersonProps) => {
  const setErrors = useFormOtherPerson((state) => state.setError);

  const handleSubmit = async (data: FormProps, { reset }: FormHelpers) => {
    try {
      await validadeFormOtherPerson(data);

      localStorage.setItem("formOtherPerson", JSON.stringify(data));
      setErrors([]);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessage: Errors = {};

        setErrors(err.errors);
        err.inner.forEach((error) => {
          if (error.path) {
            errorMessage[error.path] = error.message;
          }
          return formRefOtherPerson?.current?.setErrors(errorMessage);
        });

        return;
      }
    }

    // reset();
    formRefOtherPerson?.current?.setErrors({});
  };

  return (
    <ContainerForm>
      <Paragraph>
        Preencha abaixo o formulário de autorização com os{" "}
        <strong>dados do terceiro</strong> que utilizará a reserva.:
      </Paragraph>

      <FormItem onSubmit={handleSubmit} ref={formRefOtherPerson}>
        <WrapperInputs>
          {optiosFildSelect.map((item) => (
            <Input
              type={item.type}
              placeholder={item.placeholder}
              key={item.id}
              name={item.name}
              id={item.id}
            />
          ))}
        </WrapperInputs>
      </FormItem>
    </ContainerForm>
  );
};

export default FormOtherPerson;
