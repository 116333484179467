import styled from "styled-components";

export const OverLay = styled.div`
  background-color: rgba(0, 0, 0, 0.48);
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
`;

export const Content = styled.div`
  background-color: var(--white);
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 12px;
  padding: 16px;
  margin: 0px 12px;
`;

export const WrapperImage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    max-height: 300px;
    object-fit: cover;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: var(--purple500);
  font-weight: 700;
`;

export const BtnClose = styled.button`
  width: 100%;
  height: 40px;
  background-color: var(--pink600);
  border-radius: 25px;
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  margin: 12px 0px;
  padding: 9px 16px;
  cursor: pointer;
  border: 0;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  letter-spacing: 1.2px;
`;

export const BtnReserve = styled.button`
  width: 100%;
  height: 40px;
  background-color: var(--green500);
  border-radius: 25px;
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  margin: 12px 0px;
  padding: 9px 16px;
  cursor: pointer;
  border: 0;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  letter-spacing: 1.2px;

  &:hover {
    background-color: var(--green600);
  }
`;
