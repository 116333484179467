import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  margin-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ContentArea = styled.section`
  width: 100%;
  max-width: 650px;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px;
`;

export const ItemParagraph = styled.p`
  color: var(--black);
  font-size: 1rem;
  margin: 10px 0px;
`;
