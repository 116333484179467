import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { FormHandles, FormHelpers } from "@unform/core";
import * as Yup from "yup";
import Cookie from "js-cookie";
import { api } from "../../services";
import { Errors } from "../../pages/Identify/types";
import { cpfOrCNPJValidator } from "../../utils";
import Input from "../input";
import { ModalAlert } from "../modalAlert";
import { MessageAlertPropsWithBody } from "../modalAlert/types";
import { BtnFirstAccess, BtnLoginUser, FormItem, LoadingBtn } from "./styles";
import {
  FormLoginProps,
  InitialDataLoginProps,
  LoginFormComponentProps,
} from "./types";

const LoginForm = ({
  tokenApi,
  onSuccessLogin = () => {
    window.location.href = `${process.env.PUBLIC_URL}/`;
  },
  onClickFirstAccess,
  onClickForgotPassword,
  initialData = {
    user: "",
    password: "",
  },
}: LoginFormComponentProps) => {
  /** Handle Remember Login */
  const [initialDataLogin, setInitialDataLogin] =
    useState<InitialDataLoginProps>(initialData);
  const [isCheckedRemember, setIsCheckedRemember] = useState<boolean>(false);
  const rememberdataLogin = localStorage.getItem("rememberLogin");
  useEffect(() => {
    if (rememberdataLogin) {
      const dataLogin: InitialDataLoginProps = JSON.parse(rememberdataLogin);
      setInitialDataLogin({
        user: dataLogin.user,
        password: dataLogin.password,
      });
    }
  }, [rememberdataLogin]);
  const rememberInput = localStorage.getItem("rememberIput");
  useEffect(() => {
    if (rememberInput) {
      const remember = JSON.parse(rememberInput);
      setIsCheckedRemember(remember);
    }
  }, [rememberInput]);

  /** Form Login */
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [errorLogin, setErroLogin] = useState<string>("");
  const [message, setMessage] = useState<MessageAlertPropsWithBody>(
    {} as MessageAlertPropsWithBody
  );
  const [modalAlert, setModalAlert] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const handleCloseModalAlert = () => {
    setModalAlert(false);
    if (!!reload) window.location.href = `${process.env.PUBLIC_URL}/`;
  };

  const handleRemember = () => {
    setIsCheckedRemember(!isCheckedRemember);
  };

  const maskCpfOrCNPJ = (input: SyntheticEvent<HTMLInputElement>) => {
    input.currentTarget.value = input.currentTarget.value
      .trim()
      .replace(/\D/g, "")
      .substring(0, 14); //max numbers
  };
  const setCookies = (
    token: string,
    userName: string,
    id: string,
    level: number
  ) => {
    const data = {
      currentUser: {
        token: token,
        nomePessoa: userName,
        assnic: id,
        nivelUser: level,
      },
    };
    Cookie.set("usuario_coob", JSON.stringify(data), { expires: 1 });
  };

  const handleLogin = (data: FormLoginProps) => {
    setLoading(true);
    const tokenLocalStorage = localStorage.getItem("token");
    const inToken = tokenLocalStorage || tokenApi; //has to send only the save in localstorage except if not exist
    api
      .get(`/wsCoobrastur/ConsultaHoteis.asmx/C8A`, {
        params: {
          dados: Object.values({
            t: "C8A",
            in_token: inToken,
            in_usuario: data.user,
            in_senha: data.password,
          }).join(";"),
        },
      })
      .then(async (resp) => {
        if (resp.status === 200) {
          if (
            resp.data ===
            "Você ficou muito tempo sem navegar pelo site, a página será atualizada!?"
          ) {
            setMessage({
              title: "Você ficou muito tempo sem navegar",
              body: `${resp.data}`,
            });
            setReload(true);
            setModalAlert(true);
            setLoading(false);
            return;
          }
          if (resp.data?.[0]?.situacao === "1") {
            localStorage.setItem("tokenPortalAssociado", inToken);
            localStorage.setItem("assnic", resp.data[0].assNic); //this means is loged
            localStorage.setItem("usuarioLogin", data.user);
            localStorage.setItem("nameUser", resp.data[0].nome);

            if (!isCheckedRemember) {
              localStorage.removeItem("rememberLogin");
              localStorage.removeItem("rememberIput");
            } else {
              localStorage.setItem(
                "rememberLogin",
                JSON.stringify({ user: data.user, password: data.password })
              );
              localStorage.setItem("rememberIput", JSON.stringify(true));
            }

            setCookies(tokenApi, resp.data[0]?.nome, resp.data[0]?.assNic, 1);
            await onSuccessLogin(
              {
                Texto: resp.data[0]?.Texto || "",
                assNic: resp.data[0]?.assNic,
                nome: resp.data[0]?.nome || "",
                possuiPendenciasCadastrais:
                  resp.data[0]?.possuiPendenciasCadastrais || false,
                situacao: resp.data[0].situacao,
              },
              inToken
            );

            setLoading(false);
            return;
          }
          setMessage({
            title: "Ops",
            body: "",
            htmlBody: !resp?.data?.[0]?.mensagem
              ? !resp?.data?.mensagem
                ? resp.data
                : resp.data.mensagem
              : resp.data[0].mensagem,
          });
          setReload(false);
          setModalAlert(true);
          setErroLogin("Login Inválido,tente novamente.");
          setLoading(false);
        }
      })
      .catch((err) => {
        const messageResponse =
          typeof err?.response?.data === "string"
            ? err?.response?.data
            : err?.response?.data?.mensagem;
        const messageData = err?.data?.mensagem || err?.data || undefined;
        const message =
          messageResponse || messageData || err?.mensagem || "Algo deu Errado";

        if (
          message ===
          "Você ficou muito tempo sem navegar pelo site, a página será atualizada!?"
        ) {
          setMessage({
            title: "Você ficou muito tempo sem navegar",
            body: `${message}`,
          });
          setReload(true);
          setModalAlert(true);
          setLoading(false);
          return;
        }

        setLoading(false);
        console.error({ messageResponse, messageData, message, error: err });
        setMessage({
          title: "Ops",
          body: "",
          htmlBody: message,
        });
        setReload(false);
        setModalAlert(true);
        setErroLogin("Login Inválido,tente novamente.");
      });
  };

  const handleSubmit = async (data: FormLoginProps, { reset }: FormHelpers) => {
    try {
      const schema = Yup.object().shape({
        user: Yup.string()
          .required("campo é obrigatório")
          .test(
            "invalid-cpf",
            "* CPF ou CNPJ inválido. Por favor, tente novamente.",
            (cpf) => cpfOrCNPJValidator(cpf)
          ),
        password: Yup.string().required("a senha é obrigatória"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      handleLogin(data);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessage: Errors = {};
        err.inner.forEach((error) => {
          if (error.path) {
            errorMessage[error.path] = error.message;
          }
          return formRef.current?.setErrors(errorMessage);
        });
        return;
      }
    }

    formRef.current?.setErrors({});
  };

  return (
    <FormItem
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={initialDataLogin}
    >
      <Input
        name="user"
        label="Usuário(seu CPF/CNPJ)"
        id="usuario"
        description="Mesmo CPF/CNPJ do Portal do Usuário"
        type="text"
        maxLength={18}
        onChange={maskCpfOrCNPJ}
      />
      <Input
        name="password"
        label="Senha"
        id="senha"
        description="Mesma senha do Portal do Usuário"
        type="password"
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <input
          type="checkbox"
          id="remember"
          name="remember"
          style={{ cursor: "pointer" }}
          onChange={handleRemember}
          checked={isCheckedRemember}
        />
        <label
          htmlFor="remember"
          style={{
            cursor: "pointer",
            color: "var(--purple600)",
            fontWeight: "600",
            fontSize: "14px",
          }}
        >
          lembrar-me
        </label>
      </div>
      {!!onClickForgotPassword && (
        <div
          style={{
            width: "100%",
            color: "var(--purple600)",
            fontSize: "14px",
            fontWeight: "bold",
            cursor: "pointer",
          }}
          onClick={onClickForgotPassword}
        >
          esqueci minha senha
        </div>
      )}
      <div
        style={{
          width: "100%",
          color: "var(--purple600)",
          fontSize: "14px",
          fontWeight: "bold",
        }}
      >
        <span>
          Você ainda não é um viajante Coob+
          <strong
            style={{ color: "var(--pink600)", cursor: "pointer" }}
            onClick={() =>
              window.open(`https://www.coobmais.com.br/lp/planos/`, "_blanck")
            }
          >
            {" "}
            Assine
          </strong>
        </span>
      </div>
      {errorLogin !== "" && (
        <span style={{ color: "var(--red600)" }}>{errorLogin}</span>
      )}
      <BtnLoginUser type="submit" disabled={loading}>
        {loading ? <LoadingBtn /> : "acessar conta"}
      </BtnLoginUser>
      {!!onClickFirstAccess && (
        <BtnFirstAccess type="button" onClick={onClickFirstAccess}>
          primeiro acesso
        </BtnFirstAccess>
      )}
      <ModalAlert
        isOpen={modalAlert}
        message={message}
        onClose={handleCloseModalAlert}
      />
    </FormItem>
  );
};

export default LoginForm;
