import IconLocation from "../../../assets/icones/icons dense (24px)/localização.svg";
import IconCalendaryIn from "../../../assets/icones/icons dense (24px)/calendario in.svg";
import IconCalendaryOut from "../../../assets/icones/icons dense (24px)/calendario out.svg";
// import IconVip from "../../../assets/planos/Vip.png";
// import IconMaster from "../../../assets/planos/Master.png";
import IconPeople from "../../../assets/icones/icons dense (24px)/pessoas.svg";
import {
  AdressHotel,
  BoxCalendary,
  BoxDate,
  BoxDetails,
  Dates,
  DetailsConfig,
  DetailsHotel,
  DetaisHotelImage,
  DetaisHotelInfo,
  DurationText,
  TitleHotel,
  Typography,
  Typrography,
  WrapperDates,
  WrapperDays,
  WrapperDetails,
  WrapperLocation,
  WrapperPlan,
  WrapperRooms,
} from "./styles";

import { HotelDetailsProps } from "../types";
import { Fragment, useCallback, useEffect, useState } from "react";
import { api } from "../../../services";

const DetailsOfReserve = () => {
  const token = localStorage.getItem("token");
  const [planHotel, setPlanHotel] = useState<string>("");
  const [hotelDetails, setHotelDetails] = useState<HotelDetailsProps>();
  const [totalClients, setTotalClients] = useState<number>(0);
  const [imgHotDisp, setImgHotDisp] = useState<string[]>([]);
  const codeHotel = localStorage.getItem("hotCodDisp");
  // const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    if (token) {
      api
        .post(`wsCoobrastur/Hotel.asmx/returnPesquisa_1`, { token: token })
        .then((resp) => {
          if (resp.status === 200) {
            setHotelDetails(resp.data.Table[0]);

            const returnPesquisa: HotelDetailsProps = resp.data.Table[0];
            setPlanHotel(String(returnPesquisa?.tipCodigo || ""));

            return;
          }
        })
        .catch((e) => {
          console.log("returnPesquisa_1");
          console.log(e);
          // setErrorMessage(e?.message || "");
        });
    }
  }, [token]);

  // useEffect(() => {
  //   if (hotelDetails?.dataInicial && hotelDetails.dataFinal) {
  //     localStorage.setItem("dataInicial", hotelDetails?.dataInicial);
  //     localStorage.setItem("dataFinal", hotelDetails?.dataFinal);
  //   }
  // }, [hotelDetails]);

  useEffect(() => {
    if (hotelDetails) {
      setTotalClients(hotelDetails?.totalAdultos + hotelDetails?.totalCriancas);
    }
  }, [hotelDetails]);

  const getImageDisp = useCallback(async () => {
    if (codeHotel !== null) {
      const baseImageHotel = "https://www.coobmais.com.br/images/hotel";

      await api
        .get(`${baseImageHotel}/${codeHotel}`, {
          responseType: "document",
        })
        .then(async (resp) => {
          const images = [];
          const elements = resp.data.getElementsByTagName("a");
          for (let element of elements) {
            if (element.href.match(/\.(jpe?g|png|gif)$/i)) {
              images.push(element.href);
            }
          }
          setImgHotDisp(images);
        })
        .catch((err) => {
          console.error({ component: "detailsOfreserve", erro: err.message });
        });

      return () => localStorage.removeItem("hotCodDisp");
    }
  }, [codeHotel]);

  useEffect(() => {
    getImageDisp();
  }, [getImageDisp]);

  return (
    <Fragment>
      <WrapperDetails>
        <BoxDetails>
          <Typography>Detalhes da sua reserva</Typography>
          <DetailsHotel>
            {codeHotel !== null && (
              <DetaisHotelImage src={imgHotDisp[0]} alt="image hotel" />
            )}{" "}
            {codeHotel === null && (
              <DetaisHotelImage
                src={hotelDetails?.fotoHotelSelecionado}
                alt="image hotel"
              />
            )}
            <DetaisHotelInfo>
              <TitleHotel>{hotelDetails?.nomeHotelSelecionado}</TitleHotel>
              <WrapperLocation>
                <img src={IconLocation} alt="endereço" style={{ width: 15 }} />
                <div>
                  <AdressHotel>
                    {hotelDetails?.enderecoHotelSelecionado}
                  </AdressHotel>
                  <AdressHotel>{hotelDetails?.nomeDestino}</AdressHotel>
                </div>
              </WrapperLocation>
              <WrapperPlan>
                {!!planHotel && (
                  <img
                    src={`https://www.coobmais.com.br/images/planos/${planHotel}.png`}
                    alt="plano"
                    style={{ width: 70 }}
                  />
                )}
              </WrapperPlan>
            </DetaisHotelInfo>
          </DetailsHotel>
          <DetailsConfig>
            <WrapperDates>
              <BoxCalendary>
                <img src={IconCalendaryIn} alt="data de entrada" />
                <BoxDate>
                  <Typrography>ENTRADA</Typrography>
                  <Dates>{hotelDetails?.dataInicial}</Dates>
                </BoxDate>
              </BoxCalendary>
              <BoxCalendary>
                <img src={IconCalendaryOut} alt="data de entrada" />
                <BoxDate>
                  <Typrography>SAÍDA</Typrography>
                  <Dates>{hotelDetails?.dataFinal}</Dates>
                </BoxDate>
              </BoxCalendary>
            </WrapperDates>
            <WrapperRooms>
              <img src={IconPeople} alt="numero de hospedes" />

              <span>
                {" "}
                {hotelDetails?.qtdeQuartos && hotelDetails.qtdeQuartos > 1
                  ? `${hotelDetails.qtdeQuartos} quartos`
                  : `${hotelDetails?.qtdeQuartos} quarto`}
                ,{" "}
                {`${
                  totalClients > 1
                    ? `${totalClients} hóspedes`
                    : `${totalClients} hóspede`
                } `}
              </span>
            </WrapperRooms>
            <WrapperDays>
              <DurationText>
                Duração total da reserva:{" "}
                <strong>{hotelDetails?.qtdeDiarias} diárias</strong>
              </DurationText>
            </WrapperDays>
          </DetailsConfig>
        </BoxDetails>
      </WrapperDetails>
    </Fragment>
  );
};

export default DetailsOfReserve;
