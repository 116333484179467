import styled from "styled-components";

export const Alert = styled.p`
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
  color: var(--red500);
  text-align: center;
  width: 100%;
  max-width: 100%;
  margin: 8px auto;

  span {
    text-align: left;
  }

  @media screen and (max-width: 990px) {
    font-size: 0.85rem;
    text-align: left;
    width: 100%;
    max-width: 256px;
    margin: 0.75rem auto;
    gap: 0.75rem;

    &.modal {
      display: none;
    }
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
`;

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem 1.25rem;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.08);

  @media screen and (max-width: 990px) {
    padding: 0.75rem 1.25rem;
  }
`;

export const ModalHeaderTilte = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--purple400);
  margin: 0;

  @media screen and (max-width: 990px) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

export const ButtonCloseModal = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: 0;
  padding: 0;
  margin-right: 15px;

  svg {
    display: block;
    width: 24px;
    height: 24px;
  }
`;

export const ModalBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  padding: 1rem 1.5rem;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 990px) {
    padding: 0;

    .splide__slide img {
      border-radius: 0;
    }
  }
`;

export const ContainerContact = styled.div`
  display: flex;
  gap: 1rem;
  background-color: rgba(218, 218, 218, 0.24);
  border-radius: 12px;
  padding: 1rem;
  margin-top: 1rem;

  div {
    flex-grow: 1;
  }

  @media screen and (max-width: 990px) {
    margin: 1rem;
    flex-direction: column;
    gap: 0;
  }
`;

export const TextPhone = styled.p`
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0;

  svg {
    vertical-align: bottom;
    margin-right: 6px;
  }

  @media screen and (max-width: 990px) {
    margin-bottom: 0.35rem;
  }
`;

export const TextLink = styled.a`
  display: grid;
  grid-template-columns: auto 1fr;
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--black);
  text-decoration: none;

  svg {
    vertical-align: bottom;
    margin-right: 6px;
  }

  @media screen and (max-width: 990px) {
    margin-bottom: 0.35rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }
`;

export const Body = styled.div`
  width: 100%;
  max-width: 80%;
  margin: 0 auto;

  @media screen and (max-width: 990px) {
    width: 100%;
    max-width: 100%;
    padding: 0 1rem;
  }
`;

export const Separator = styled.hr`
  height: 1px;
  background-color: var(--gray400);
  opacity: 0.32;
  border: none;

  @media screen and (max-width: 990px) {
    margin: 0.75rem 0;
  }
`;

export const Title = styled.h3`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: var(--red500);
  margin: 0;
  margin-bottom: 5px;

  svg {
    vertical-align: sub;
    margin-right: 8px;
  }
`;

export const Subtitle = styled.h3`
  display: grid;
  grid-template-columns: auto 1fr;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: var(--purple400);

  svg {
    vertical-align: sub;
    margin-right: 5px;
  }

  @media screen and (max-width: 990px) {
    margin: 0;
    margin-bottom: 0.75rem;
  }
`;

export const Text = styled.p`
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin: 0;
  margin-bottom: 10px;

  @media screen and (max-width: 990px) {
    &.textWarnning {
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
`;

export const ContentAPI = styled.div`
  font-size: 0.875rem;
  line-height: 1.125rem;

  .text-center {
    font-size: 1rem !important;
    font-weight: 700;
    line-height: 1.5rem !important;
    color: var(--purple400) !important;
    margin-bottom: 10px;
  }
`;

export const Group = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin: 2rem 0;

  @media screen and (max-width: 990px) {
    display: flex;
    flex-direction: column-reverse;
    margin: 1rem;
  }
`;

export const GroupTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--purple400);
  margin: 0;
  margin-bottom: 10px;
`;

export const GroupBenefitsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
`;

export const GroupBenefitsListItem = styled.li`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;

  @media screen and (max-width: 990px) {
    font-size: 0.875rem;
  }
`;

export const GroupLocation = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  height: 290px;

  @media screen and (max-width: 990px) {
    height: 340px;
  }
`;

export const GroupLocationTitle = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  font-size: 1.25rem;
  line-height: 1.5rem;
  background-color: var(--purple600);
  color: var(--white);
  margin: 0;
`;

export const GroupLocationMap = styled.div`
  flex-grow: 1;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

export const ModalFooter = styled.div`
  border-top: 1px solid var(--gray400);
  background-color: rgba(75, 72, 183, 0.04);
  padding: 1rem;
`;

export const ButtonReserve = styled.button`
  cursor: pointer;
  display: block;
  background-color: var(--green600);
  border: none;
  color: var(--white);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  border-radius: 20px;
  width: 100%;
  max-width: 400px;
  min-height: 40px;
  margin: 0 auto;

  &:disabled {
    opacity: 0.15;

    &:hover {
      background-color: var(--pink600);
    }
  }

  &:hover {
    background-color: var(--pink500);
  }
`;

export const ModalWrapperGroup = styled.div`
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  align-items: center;

  @media screen and (max-width: 990px) {
    grid-template-columns: 1fr;
  }
`;

export const ModalButtonRequest = styled.button`
  flex-grow: 1;
  cursor: pointer;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 700;
  color: var(--yellow600);
  background-color: transparent;
  border: 2px solid var(--yellow600);
  border-radius: 20px;
  padding: 6px 10px;
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.5;
    border-color: var(--gray400);
    color: var(--gray400);
  }

  &:hover {
    background-color: rgba(229, 0, 118, 0.08);
  }

  @media screen and (max-width: 990px) {
    width: 100%;
    font-size: 0.813rem;
    text-transform: uppercase;
    padding: 9px 10px;
  }
`;

export const ModalButtonAvailable = styled.button`
  flex-grow: 1;
  cursor: pointer;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 700;
  color: var(--white);
  background-color: var(--orange600);
  border-radius: 20px;
  padding: 6px 10px;
  border: 2px solid var(--orange600);
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.5;
    background-color: transparent;
    border-color: var(--gray400);
    color: var(--gray400);
  }

  &:hover {
    background-color: var(--pink500);
    border-color: var(--pink500);
  }

  @media screen and (max-width: 990px) {
    width: 100%;
    font-size: 0.813rem;
    text-transform: uppercase;
    padding: 9px 10px;
  }
`;
