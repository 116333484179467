import Modal from "react-modal";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { ReactComponent as IconFechar } from "../../assets/icones/icons dense (24px)/fechar.svg";
import {
  ButtonCloseModal,
  ButtonSelectOtherDate,
  ButtonConfirmation,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderTilte,
  Text,
  ContainerButtonCloseModal,
} from "./styles";

type ModalPolicyProps = {
  onClose: () => void;
  isOpen: boolean;
  handleConfirmation: () => void;
  handleSelectOtherDate: () => void;
};

export const ModalPolicy = ({
  isOpen,
  onClose,
  handleConfirmation = onClose,
  handleSelectOtherDate,
}: ModalPolicyProps) => {
  const mobile = useMediaQuery("(max-width: 990px)");

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      style={{
        content: {
          width: "95%",
          maxWidth: "592px",
          height: mobile ? "571px" : "485px",
          padding: "0",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "hidden",
          zIndex: 999,
          borderRadius: "20px",
        },
        overlay: {
          zIndex: 999,
          backgroundColor: "rgba(0,0,0,0.48)",
        },
      }}
    >
      <ModalContent>
        <ContainerButtonCloseModal>
          <ButtonCloseModal type="button" title="fechar" onClick={onClose}>
            <IconFechar onClick={onClose} />
          </ButtonCloseModal>
        </ContainerButtonCloseModal>

        <ModalHeader>
          <ModalHeaderTilte>Sobre nossa política de reservas</ModalHeaderTilte>
        </ModalHeader>

        <ModalBody>
          <Text>
            As solicitações de reserva pela Coob+ precisam ser feitas com o
            prazo mínimo de <strong>30 dias de antecedência</strong> devido ao
            nosso acordo com os nossos hotéis credenciados.
          </Text>

          <Text>
            Por essa razão que conseguimos disponibilizar os nossos planos com
            mensalidades que chegam até 60% de desconto em relação a tarifa
            balcão dos hotéis.
          </Text>

          <Text>
            Mas não se preocupe, se sua reserva for para entrada com data
            inferior a 30 dias, você poderá
            <strong> solicitar disponibilidade de vaga aos hotéis </strong>
            diretamente pelo nosso site.
          </Text>

          <Text>
            <strong>
              Mas, essa disponibilidade ficará a critério do hotel aceitar ou
              não e a Coob+ não poderá interferir.
            </strong>
          </Text>

          <Text>
            Como essa disponibilidade com menos de 30 dias não é contemplada
            pelo plano, você precisará pagar uma taxa extra por estar fora do
            prazo.
          </Text>

          <Text>
            <strong>Segue abaixo os valores cobrados:</strong> <br />
            Planos Go : R$ 25 por diária <br />
            Planos Vip/Master, Gold e Diamante : R$ 50 por diária <br />
          </Text>
        </ModalBody>

        <ModalFooter>
          <ButtonSelectOtherDate onClick={handleSelectOtherDate}>
            Selecionar outro período
          </ButtonSelectOtherDate>
          <ButtonConfirmation onClick={handleConfirmation}>
            Quero solicitar uma reserva
          </ButtonConfirmation>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
