import styled from "styled-components";

export const CardContent = styled.div`
  width: 100%;
  max-height: 315px;
  display: grid;
  grid-template-columns: 232px 1fr;
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  overflow: hidden;
  transition: all 0.3s ease;

  & + & {
    margin-top: 25px;
  }

  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 990px) {
    grid-template-columns: 1fr;
    max-height: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 315px;

  @media screen and (max-width: 990px) {
    max-height: 100%;
  }
`;

export const CardBody = styled.div`
  flex: 1 1 auto;
  padding: 1rem;
`;

export const Hgroup = styled.div`
  @media screen and (max-width: 990px) {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 0.85rem;
  }
`;

export const ContentPlans = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 990px) {
    justify-content: space-between;
  }
`;

export const ContentPlansItem = styled.li`
  display: block;

  & + & {
    margin-left: 8px;
  }
`;

export const HotelCategory = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 148px;
  height: 36px;
  border: 2px solid var(--purple600);
  border-radius: 12px;
`;

export const HotelCategoryTitle = styled.span`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -65%);
  background-color: var(--white);
  padding: 0 8px;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
  color: var(--purple600);
`;

export const HotelStars = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &.star-1 {
    svg {
      &:nth-child(1) {
        path {
          transition: all 0.3s ease;
          fill: var(--yellow600);
          stroke: var(--yellow600);
        }
      }
    }
  }

  &.star-2 {
    svg {
      &:nth-child(1),
      &:nth-child(2) {
        path {
          transition: all 0.3s ease;
          fill: var(--yellow600);
          stroke: var(--yellow600);
        }
      }
    }
  }

  &.star-3 {
    svg {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        path {
          transition: all 0.3s ease;
          fill: var(--yellow600);
          stroke: var(--yellow600);
        }
      }
    }
  }

  &.star-4 {
    svg {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        path {
          transition: all 0.3s ease;
          fill: var(--yellow600);
          stroke: var(--yellow600);
        }
      }
    }
  }

  &.star-5 {
    svg {
      path {
        transition: all 0.3s ease;
        fill: var(--yellow600);
        stroke: var(--yellow600);
      }
    }
  }
`;

export const PlansImage = styled.img`
  display: block;
  width: 82px;
  height: 36px;
`;

export const ContentTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
  margin: 0;
  margin-top: 20px;

  @media screen and (max-width: 990px) {
    margin-top: 0;
    margin-bottom: 0.15rem;
  }
`;

export const Locations = styled.p`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
  gap: 5px;

  font-size: 0.75rem;
  line-height: 0.875rem;
  margin: 0;
  margin-bottom: 15px;

  span {
    margin-top: 5px;
  }

  @media screen and (max-width: 990px) {
    margin-bottom: 0.75rem;
  }
`;

export const WrapperGroup = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 990px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const ContentFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  background-color: rgba(75, 72, 183, 0.04);
  border-top: 1px solid var(--gray400);
  padding: 0 1rem;

  @media screen and (max-width: 990px) {
    background-color: transparent;
    border: none;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 90%;
      height: 1px;
      background-color: rgba(218, 218, 218, 0.24);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export const ButtonReservation = styled.button`
  cursor: pointer;
  background-color: var(--green600);
  border: none;
  color: var(--white);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  border-radius: 20px;
  width: 100%;
  max-width: 250px;
  min-height: 40px;
  margin: 1rem 0;

  &:disabled {
    opacity: 0.15;
  }

  &:hover {
    background-color: var(--pink500);
  }

  @media screen and (max-width: 990px) {
    max-width: 100%;
    margin-top: 0;
  }
`;

export const ButtonDetails = styled.button`
  cursor: pointer;
  width: 200px;
  padding: 5px 15px;
  background-color: transparent;
  border: 2px solid var(--gray400);
  border-radius: 20px;
  color: var(--purple600);
  min-height: 40px;
  margin: 1rem 0;

  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.125rem;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(229, 0, 118, 0.08);
    color: var(--pink600);
    border-color: var(--pink600);
  }

  &:disabled {
    cursor: default;
    background-color: transparent;
    color: var(--gray400);
    border-color: var(--gray400);
  }

  @media screen and (max-width: 990px) {
    font-size: 0.875rem;
    width: 100%;
    max-width: 100%;
    border-color: transparent;
    background-color: transparent;

    &:active,
    &:hover {
      border-color: transparent;
      background-color: rgba(218, 218, 218, 0.33);
      color: var(--purple600);
    }
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
`;

export const LocationRef = styled.div`
  flex-grow: 1;
  max-width: 248px;
  display: flex;
  align-items: center;
  background-color: rgba(218, 218, 218, 0.24);
  border-radius: 12px;
  padding: 1rem;
  margin-right: 0.45rem;

  @media screen and (max-width: 990px) {
    width: 100%;
    max-width: 100%;
    margin: 0;
    margin-bottom: 0.45rem;
  }
`;

export const LocationRefIcon = styled.div`
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: var(--white);
  margin-right: 1rem;
`;

export const LocationRefText = styled.p`
  font-size: 0.75rem;
  color: var(--purple600);
  margin: 0;
`;

export const Details = styled.div`
  flex-grow: 1;
  max-width: 202px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 990px) {
    max-width: 100%;
    border: 2px solid rgba(218, 218, 218, 0.64);
    border-radius: 12px;
    padding: 1rem;
    text-align: center;

    button {
      margin: 0.35rem 0;
      margin-bottom: 0;
    }
  }
`;

export const DetailsTitle = styled.h4`
  font-size: 0.75rem;
  font-weight: 700;
  color: var(--purple600);
  margin: 0;
`;

export const DetailsText = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin: 0;
`;

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem 1.25rem;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.08);

  @media screen and (max-width: 990px) {
    padding: 0.75rem 1.25rem;
  }
`;

export const ModalHeaderTilte = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--purple400);
  margin: 0;

  @media screen and (max-width: 990px) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

export const ButtonCloseModal = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: 0;
  padding: 0;
  margin-right: 15px;

  svg {
    display: block;
    width: 24px;
    height: 24px;
  }
`;

export const ModalBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  padding: 1rem 1.5rem;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 990px) {
    padding: 0;

    .splide__slide img {
      border-radius: 0;
    }
  }
`;

export const ContainerContact = styled.div`
  display: flex;
  gap: 1rem;
  background-color: rgba(218, 218, 218, 0.24);
  border-radius: 12px;
  padding: 1rem;
  margin-top: 1rem;

  div {
    flex-grow: 1;
  }

  @media screen and (max-width: 990px) {
    margin: 1rem;
    flex-direction: column;
    gap: 0;
  }
`;

export const TextPhone = styled.p`
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0;

  svg {
    vertical-align: bottom;
    margin-right: 6px;
  }

  @media screen and (max-width: 990px) {
    margin-bottom: 0.35rem;
  }
`;

export const Body = styled.div`
  width: 100%;
  max-width: 80%;
  margin: 0 auto;

  @media screen and (max-width: 990px) {
    width: 100%;
    max-width: 100%;
    padding: 0 1rem;
  }
`;

export const Title = styled.h3`
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: var(--purple600);
  margin: 0;
  margin-bottom: 5px;

  svg {
    vertical-align: sub;
    margin-right: 8px;
  }
`;

export const Text = styled.p`
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin: 0;
  margin-bottom: 10px;

  @media screen and (max-width: 990px) {
    &.textWarnning {
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
`;

export const GroupLocation = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  height: 290px;
  margin: 0.75rem 0;
  margin-bottom: 30px;

  @media screen and (max-width: 990px) {
    height: 340px;
    margin: 1rem;
    margin-bottom: 2rem;
  }
`;

export const GroupLocationTitle = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  font-size: 1.25rem;
  line-height: 1.5rem;
  background-color: var(--purple600);
  color: var(--white);
  margin: 0;
`;

export const GroupLocationMap = styled.div`
  flex-grow: 1;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

export const ModalFooter = styled.div`
  border-top: 1px solid var(--gray400);
  background-color: rgba(75, 72, 183, 0.04);
  padding: 1rem;
`;

export const ButtonReserve = styled.button`
  cursor: pointer;
  display: block;
  background-color: var(--green600);
  border: none;
  color: var(--white);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  border-radius: 20px;
  width: 100%;
  max-width: 400px;
  min-height: 40px;
  margin: 0 auto;

  &:disabled {
    opacity: 0.15;

    &:hover {
      background-color: var(--pink600);
    }
  }

  &:hover {
    background-color: var(--pink500);
  }
`;
