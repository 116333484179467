import { Steps } from "../../components/steps";
import DetailsOfReserve from "../Payments/DetailsOfReserve";
import IconAttention from "../../assets/icones/icons dense (24px)/aviso - red.svg";
import IconCopy from "../../assets/icones/icons dense (24px)/copiar.svg";
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { api, apiCrateTokenAuthPix } from "../../services";
import axios from "axios";
// import useCreateToken from "../../hooks/createToken";

import {
  AreaPix,
  AreaPixItem,
  AttentionText,
  BtnCancelPix,
  BtnCopyCode,
  Container,
  ContentPixItem,
  Section,
  Title,
  TotalValue,
  TotalValueItem,
  WrapperPix,
  WrapperQrCode,
  WrapperSteps,
  WrapperTextSteps,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { Loading } from "../Accommodation/styles";

const Pix = () => {
  const [tokenAuth, setTokenAuth] = useState<string>("");
  const [qrCodeImg, setQrCodeImg] = useState<string>("");
  const [pixCopiaECola, setPixCopiaECola] = useState<string>("");
  const [timeElapsed, setTimeElapsed] = useState<number | undefined>(undefined);
  const [showCount, setShowCount] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [txID, setTxid] = useState<string>("");
  const navigate = useNavigate();
  const [totalValue, setTotalValue] = useState<string>("0");
  const tokenHttp = localStorage.getItem("token");
  const cpfUser = localStorage.getItem("user");
  const nameUserLocal = localStorage.getItem("nameUser");
  const idUser = localStorage.getItem("assnic");
  const [isPayment, setIsPayment] = useState<boolean>(false);
  const [messagePix, setMesagePix] = useState<string>("");
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  // const data = `<h4>Falha de comunicação S2. <br />(NÃO TENTE NOVAMENTE)</h4> <br />Sua reserva está parcialmente concluída.
  // Por favor, entre em contato com a nossa Central de Atendimento finalização da reserva:<br />
  // <span style="color:#0C83B3"> <br />Canela/RS: 0300 300 1010<span>`;
  const handleCopyCode = useCallback(async () => {
    await navigator.clipboard.writeText(pixCopiaECola);
    alert("código copiado");
  }, [pixCopiaECola]);

  const handleBackToHome = () => {
    window.location.href = `${process.env.PUBLIC_URL}/`;
  };

  const countDown = (value?: number) => {
    const item = value || 0;
    let minutes = Math.floor(item / 60);
    let seconds = item % 60;
    return `${minutes.toString().padStart(2, "")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleCancelPix = useCallback(() => {
    axios
      .post(
        `https://apiprod.coobrastur.com.br/ChatBot/api/Customer/CancelarPixItauReservaSite`,
        { token: tokenHttp, sitCodigo: 3, in_idPix: txID },
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Bearer: tokenAuth,
          },
        }
      )
      .then((resp) => {
        if (resp.status === 200) {
          if (resp.data === true) {
            alert("solicitação cancelada");
            navigate(`${process.env.PUBLIC_URL}/`);
            return;
          }
        }
      })
      .catch((erro) => {
        console.error(erro);
      });
  }, [navigate, tokenAuth, tokenHttp, txID]);

  const createPix = useCallback(
    (token: string) => {
      const data = Date.now();
      const dataFormat = new Date(data);
      const dataISO = dataFormat.toISOString();

      if (totalValue && cpfUser) {
        axios
          .post(
            `https://apiprod.coobrastur.com.br/ChatBot/api/Customer/CriarPixItauReservaSite`,
            {
              calendario: {
                expiracao: 1800,
                criacao: `${dataISO}`,
              },
              devedor: {
                cpf: cpfUser.padStart(11, "0"),
                nome: nameUserLocal,
              },
              valor: {
                original: totalValue,
                modalidadeAlteracao: 0,
              },
              solicitacaoPagador: "Pagamento realizado pelo site",
              infoAdicionais: [
                {
                  nome: "Site",
                  valor: "Pagamento realizado pelo site",
                },
              ],
              token: tokenHttp,
            },
            {
              headers: {
                "Content-Type": "application/json; charset=utf-8",
                Bearer: token,
              },
            }
          )
          .then((resp) => {
            if (resp.data.status === "ATIVA") {
              setQrCodeImg(resp.data.imagem_base64);
              setPixCopiaECola(resp.data.emv);
              setTxid(resp.data.txid);

              setShowCount(true);
              setTimeElapsed(10 * 60);
              setLoading(false);

              let id = setInterval(async () => {
                await axios
                  .post(
                    `https://apiprod.coobrastur.com.br/ChatBot/api/Customer/PesquisarPix/${resp.data.txid}`,
                    {},
                    {
                      headers: {
                        "Content-Type": "text/plain; charset=utf-8",
                        Bearer: token,
                      },
                    }
                  )
                  .then(async (response) => {
                    if (response.data === "CONCLUIDA") {
                      setIsPayment(true);
                      return;
                    }
                  })
                  .catch((err) => console.error(err));
              }, 2000);

              intervalRef.current = id;

              return;
            } else {
              console.error({
                component: "Pix",
                function: "createPix CriarPixItau",
                error: resp,
              });
              setLoading(false);
              setMesagePix("Não foi possível gerar o pix, tente novamente!");
              setOpenAlert(true);
            }
          })
          .catch((err) => {
            console.error({
              component: "Pix",
              function: "createPix CriarPixItauError",
              error: err,
            });
            setLoading(false);
            setMesagePix("Não foi possível gerar o pix, tente novamente!");
            setOpenAlert(true);
          });
        return;
      }
      console.error({
        component: "Pix",
        function: "createPix",
        error: !totalValue ? "Pix without value" : "Pix without CPF",
      });
      setMesagePix("Não foi possível gerar o pix, tente novamente!");
      setOpenAlert(true);
      setLoading(false);
    },
    [tokenHttp, cpfUser, nameUserLocal, totalValue]
  );

  const secureReservation = useCallback(
    async (token: string) => {
      setLoading(true);

      const inToken = localStorage.getItem("tokenPortalAssociado");
      await api
        .post(`/wsCoobrastur/ConsultaHoteis.asmx/PU_1_2`, {
          nic: idUser,
          in_token: inToken,
        })
        .then(async (resp) => {
          if (resp.status === 200) {
            await axios
              .post(
                `https://apiprod.coobrastur.com.br/ChatBot/api/Customer/SecureReservation`,
                {
                  in_EmailPessoal: resp.data[0].assEmailPessoal,
                  in_discodigo: resp.data[0].civCodigo,
                  in_resTelefone: resp.data[0].assNumCelular,
                  in_resTelefoneDDD: resp.data[0].assNumCelularDDD,
                  in_senha: "",
                  in_token: tokenHttp,
                }
              )
              .then((resp) => {
                if (resp.data.situacao === 1) {
                  createPix(token);

                  return;
                }
                console.error(resp.data);
                setMesagePix(resp.data.texto);
                setOpenAlert(true);
                setLoading(false);
                return () => clearInterval(intervalRef.current);
              })
              .catch((err) => {
                console.error(err);
                setMesagePix("Não foi possível gerar o pix, tente novamente!");
                setOpenAlert(true);
                setLoading(false);
              });
            return;
          } else {
            console.error(resp.data);
            setMesagePix("Não foi possível gerar o pix, tente novamente!");
            setOpenAlert(true);
            setLoading(false);
          }
        })
        .catch((erro) => {
          console.error(erro);
          setMesagePix("Não foi possível gerar o pix, tente novamente!");
          setOpenAlert(true);
          setLoading(false);
        });
    },
    [idUser, tokenHttp, createPix]
  );

  const handleGoTotop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // primeiro Gera TokenAuth para poder fazer requisição do Pix
  useEffect(() => {
    handleGoTotop();
    const total = localStorage.getItem("total");

    if (total) {
      setTotalValue(parseFloat(total).toFixed(2).toString());
    }

    apiCrateTokenAuthPix
      .post(`/Users/Authenticate`, {
        accessKey: "C6AjgA4IY0R00UV2comvrWEdsmHbirmu4Flc3To8YmZkq9KxgP",
        password: "C00fgwe32@",
      })
      .then((resp) => {
        if (resp.status === 200) {
          setTokenAuth(resp.data.token);

          return;
        }
      })
      .catch((err) => {
        console.error({
          component: "Pix",
          function: "useEffect",
          erro: err,
        });
      });

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!txID && tokenAuth) {
      secureReservation(tokenAuth);
    }
  }, [txID, secureReservation, tokenAuth]);

  // Faz a contagem regressiva e cancela caso acabe o tempo
  useEffect(() => {
    if (timeElapsed === undefined || isNaN(timeElapsed)) return;
    setTimeout(() => {
      setTimeElapsed(timeElapsed <= 0 ? 0 : timeElapsed - 1);
      if (timeElapsed <= 0) {
        handleCancelPix();
        return;
      }
    }, 1000);
  }, [timeElapsed, handleCancelPix]);

  useEffect(() => {
    if (isPayment) {
      axios
        .post(
          `https://apiprod.coobrastur.com.br/ChatBot/api/Customer/MakePayment`,
          {
            in_bolValor: null,
            in_confCodRet: null,
            in_fmCodigo: 13,
            in_numAutent: txID,
            in_numAutor: "PIX",
            in_numCV: "PIX",
            in_numSQN: null,
            in_numeroParc: null,
            in_senha: "",
            in_token: tokenHttp,
          },
          {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              Bearer: tokenAuth,
            },
          }
        )
        .then((resp) => {
          if (resp.data.situacao === 3) {
            setMesagePix(resp.data.texto);
            setOpenAlert(true);
            return () => clearInterval(intervalRef.current);
          }

          if (resp.data.situacao === 1) {
            localStorage.setItem("resLocale", resp.data.localizador);
            const resLocalizador = resp?.data?.resLocalizador || "";
            navigate(`${process.env.PUBLIC_URL}/reserva/${resLocalizador}`, {
              state: {
                resLocalizador: String(resp.data.localizador),
              },
            });
            return () => clearInterval(intervalRef.current);
          }
        })
        .catch((err) => console.error(err.message));

      return;
    }
  }, [isPayment, tokenAuth, txID, tokenHttp, navigate]);

  return (
    <Fragment>
      {openAlert && (
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.48)",
            position: "fixed",
            width: "100%",
            height: "100%",
            zIndex: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "var(--white)",
              width: "100%",
              maxWidth: "500px",
              height: "auto",
              borderRadius: "16px",
              margin: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 12,
            }}
          >
            <img
              src={IconAttention}
              alt="aviso"
              style={{ marginTop: "20px", width: "40px" }}
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: 4,
                paddingBottom: 10,
                paddingLeft: 20,
                paddingRight: 14,
              }}
              dangerouslySetInnerHTML={{ __html: messagePix }}
            />
            <BtnCancelPix onClick={handleBackToHome}>fechar</BtnCancelPix>
          </div>
        </div>
      )}
      <Container>
        <WrapperSteps>
          <WrapperTextSteps>
            <h4> Etapas para concluir sua reserva</h4>
            <span>
              Você está na terceira etapa. Escolha a sua hospedagem dentro os
              resultados abaixo para prosseguir.
            </span>
          </WrapperTextSteps>
          <Steps stepOne="disabled" stepTwo="disabled" stepThree="active" />
        </WrapperSteps>
        <Section>
          <DetailsOfReserve />
          {loading ? (
            <AreaPix>
              <Loading active={loading}>
                <span></span>
              </Loading>
              {!!timeElapsed && (
                <span
                  style={{
                    color: "var(--black)",
                    fontSize: "2rem",
                    fontWeight: "bold",
                    letterSpacing: "1.2px",
                    marginTop: "8px",
                  }}
                >
                  {countDown(timeElapsed)}
                </span>
              )}
            </AreaPix>
          ) : (
            <AreaPix>
              <TotalValue>
                <TotalValueItem>
                  <span>Valor total a pagar:</span>
                  <strong>
                    {" "}
                    {parseFloat(totalValue).toLocaleString("pt-Br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </strong>
                </TotalValueItem>
              </TotalValue>
              <AreaPixItem>
                <Title>Código PIX gerado com sucesso!</Title>
                <WrapperPix>
                  <p>
                    Agora basta escanear o QRCode ou copiar o código para
                    inserir no seu aplicativo de pagamento.
                  </p>
                  <p>
                    Você tem <strong>10 minutos</strong> para realizar o
                    pagamento.
                    <span>
                      {""} Se este tempo expirar, sua reserva não será
                      finalizada.
                    </span>
                  </p>
                  {showCount && (
                    <span
                      style={{
                        color: "var(--black)",
                        fontSize: "2rem",
                        fontWeight: "bold",
                        letterSpacing: "1.2px",
                        marginTop: "8px",
                      }}
                    >
                      {countDown(timeElapsed)}
                    </span>
                  )}
                  <AttentionText>
                    <img src={IconAttention} alt="aviso" />
                    <div>
                      <span>Atenção!</span> Não feche essa página antes de
                      concluir o pagamento, se <span>não</span> sua reserva não
                      será concluída.
                    </div>
                  </AttentionText>
                  <ContentPixItem>
                    {qrCodeImg !== "" && (
                      <Fragment>
                        <WrapperQrCode>
                          <img
                            src={`data:image/jpeg;base64,${qrCodeImg}`}
                            style={{ width: "100%", maxWidth: "300px" }}
                            alt="pix"
                          />
                        </WrapperQrCode>
                        {/* <WrapperCode>{pixCopiaECola}</WrapperCode> */}
                        <BtnCopyCode onClick={handleCopyCode}>
                          <div
                            style={{
                              width: 40,
                              marginRight: "10px",
                            }}
                          >
                            <img src={IconCopy} alt="copiar código" />
                          </div>
                          <span>COPIAR CÓDIGO PIX</span>
                        </BtnCopyCode>
                      </Fragment>
                    )}
                  </ContentPixItem>
                </WrapperPix>
              </AreaPixItem>

              <BtnCancelPix onClick={handleCancelPix}>
                CANCELAR SOLICITAÇÃO
              </BtnCancelPix>
            </AreaPix>
          )}
        </Section>
      </Container>
    </Fragment>
  );
};

export default memo(Pix);
