import { useState, useCallback } from "react";
import { apiAdministracao as apiCreateToken } from "../../services";

const API_URL_NO_QUERY = "/GerarToken?tipoUsuario=0";
const API_URL_W_QUERY = "/GerarToken?tipoUsuario=C0ob123Intern0";

const useCreateToken = () => {
  const [tokenApi, setTokenApi] = useState<string>("");

  const createToken = useCallback(() => {
    const tipoUsuario = window.location.search.includes(
      "tipoUsuario=C0ob123Intern0"
    )
      ? 1
      : 0;
    const apiTokenUrl = tipoUsuario ? API_URL_W_QUERY : API_URL_NO_QUERY;
    apiCreateToken.get(apiTokenUrl).then((resp) => {
      if (resp.status === 200) {
        setTokenApi(resp.data);
      }
    });
  }, []);

  return {
    createToken,
    tokenApi,
  };
};

export default useCreateToken;
