import styled from "styled-components";
import { Form } from "@unform/web";
import { Visible } from "./types";

export const Container = styled.div`
  width: 100%;
  height: 550px;
  padding-top: 120px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled.div<Visible>`
  width: 100%;
  max-width: 500px;
  height: auto;
  display: ${({ hide }) => (hide ? "none" : "flex")};
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
`;

export const Title = styled.h5`
  width: 100%;
  max-width: 350px;
  text-align: center;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-size: 1.5rem;
  color: var(--purple600);
  font-weight: bold;
  margin-top: -15px;
  line-height: 30px;
`;

export const SubTitle = styled.h4`
  width: 100%;
  max-width: 350px;
  text-align: center;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-size: 1.2rem;
  color: var(--purple600);
  font-weight: bold;
  margin-top: -15px;
  line-height: 30px;
`;

export const Paragraph = styled.p`
  width: 100%;
  max-width: 370px;
  text-align: center;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-size: 1rem;
  color: var(--black);
  text-align: center;
  margin-top: -15px;
  line-height: 30px;
  letter-spacing: 0.51px;
  line-height: 20px;
  margin-top: -30px;
`;

export const BtnKeep = styled.button`
  width: 100%;
  max-width: 296px;
  height: 40px;
  background-color: var(--pink600);
  border-radius: 25px;
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  margin: 12px 0px;
  padding: 9px 16px;
  cursor: pointer;
  border: 0;
`;

export const FormItem = styled(Form)`
  width: 100%;
  max-width: 530px;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
