import create from "zustand";
// type filedError = {
//   nameField: string;
//   errorField: string;
// };
type DataProps = {
  error: string[] | null;
  setError: (error: string[]) => void;
};
export const useFormOtherPerson = create<DataProps>((set) => ({
  error: null,
  setError: (error) => set((state) => ({ error: (state.error = error) })),
}));
