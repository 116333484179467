import Header from "./components/header";
import Footer from "./components/footer";
import RoutesComp from "./routes";
import { Container } from "./styles";
import { FiltersProvider } from "./hooks/useFilters";
import TagManager from "react-gtm-module";
import { UrlParamsProvider } from "./hooks/useUrlParams";

const tagManagerArgs = {
  gtmId: "GTM-PSL358N",
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <FiltersProvider>
      <UrlParamsProvider>
        <Container>
          <Header />
          <RoutesComp />
          <Footer />
        </Container>
      </UrlParamsProvider>
    </FiltersProvider>
  );
}

export default App;
