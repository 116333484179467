// import IconChairSolar from "./assets/guardasol.svg";
// import IconAirPlane from "./assets/aviao.svg";
// import IconBuilding from "./assets/building.svg";
// import IconSafeBox from "./assets/cofre.svg";
// import IconMoutain from "./assets/mountain.svg";
// import IconPlates from "./assets/plates.svg";
import Icon01 from "./assets/ico-01.png";
import Icon02 from "./assets/ico-02.png";
import Icon03 from "./assets/ico-03.png";
import Icon04 from "./assets/ico-04.png";

export const contentSectionBenefits = [
  {
    id: 1,
    icon: Icon01,
    description: `+ de 1.900 hotéis, pousadas e resorts credenciados em todo o Brasil e + de 500.000 hotéis no exterior.`,
  },
  {
    id: 2,
    icon: Icon02,
    description: `As assinaturas cobrem 100% das diárias, sempre com café da manhã para 2 ou três pessoas.`,
  },
  // {
  //   id: 3,
  //   icon: IconSafeBox,
  //   description: `Com as nossas assinaturas, você economiza até 60% do valor das diárias nos hotéis.`,
  // },
  {
    id: 3,
    icon: Icon03,
    description: `Assinaturas personalizadas com o número de pessoas em hotéis do perfil do viajante.`,
  },
  {
    id: 4,
    icon: Icon04,
    description: `Até 60% de economia em relação à outros sites de hospedagem.`,
  },
  // {
  //   id: 6,
  //   icon: IconChairSolar,
  //   description: ` Exclusividade do seguro viagem incluso em todas as assinaturas,
  //     sem custo adicional.`,
  // },
];
