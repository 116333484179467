/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useState, useEffect } from "react";
import { Carrosel } from "../carrosel";

import { ReactComponent as IconPlace } from "../../assets/icones/icons minimal (20px)/place.svg";
import { ReactComponent as IconAlert } from "../../assets/icones/icons dense (24px)/aviso - red.svg";
import { ReactComponent as IconMore } from "../../assets/icones/icons dense (24px)/mais.svg";

import {
  Alert,
  Benefits,
  BenefitsList,
  BenefitsListItem,
  BenefitsTitle,
  ButtonAvailable,
  ButtonDetails,
  ButtonRequest,
  ButtonReservation,
  CardBody,
  CardContent,
  Content,
  ContentFooter,
  ContentPlans,
  ContentPlansItem,
  ContentTitle,
  Helpers,
  Hgroup,
  Locations,
  PlansImage,
  Promotion,
  PromotionSpotlight,
  Tags,
  WrapperGroup,
  WrapperGroupMobile,
  LoadingBtn,
} from "./styles";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { CardProps, Hotels } from "./types";
import axios from "axios";
import { ModalDetails } from "../modalDetails";
import { ModalDateAvailable } from "../modalDateAvailable";
import { ModalFormAvailability } from "../modalFormAvailability";
import { useCommodity } from "../../store/useComodidades";
import { useSearchStore } from "../../store/useSearch";
import useCreateToken from "../../hooks/createToken";
import { formatDate } from "../../utils";
import { api } from "../../services";
import { useNavigate } from "react-router-dom";
import { ModalAlert } from "../modalAlert";
import SaintAndrewssite from "../../assets/paginas_adicionais/Saint-andrewssite.png";

export const Card = ({ hotel, click }: CardProps) => {
  const [modalSpotlight, setModalSpotlight] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalDateAvailable, setModalDateAvailable] = useState(false);
  const [modalFormAvailable, setModalFormAvailable] = useState(false);
  const [images, setImage] = useState<string[]>([]);
  const [limit, setLimit] = useState(true);
  const mobile = useMediaQuery("(max-width: 990px)");
  const { data: commodityStore } = useCommodity();
  const { data: searchStore } = useSearchStore();
  const navigate = useNavigate();
  // const { tokenApi, createToken } = useCreateToken();
  const { tokenApi } = useCreateToken();
  const [numAttempts, setNumAttempts] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleReserve = (hotel: Hotels) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    // localStorage.setItem("planHotel", JSON.stringify(hotel.plan)); // now return in returnPesquisa_1
    localStorage.setItem("codigoHotel", JSON.stringify(hotel.hotCodigo));

    const data = {
      in_token: token,
      dataInicial: formatDate(searchStore.dateIn),
      dataFinal: formatDate(searchStore.dateOut),
      qtdeQuartos: searchStore.numRoom,
      adultosQ1:
        searchStore?.rooms && searchStore?.rooms[0]
          ? searchStore?.rooms[0]?.adducts
          : 0,
      adultosQ2:
        searchStore?.rooms && searchStore?.rooms[1]
          ? searchStore?.rooms[1]?.adducts
          : 0,
      adultosQ3:
        searchStore?.rooms && searchStore?.rooms[2]
          ? searchStore?.rooms[2]?.adducts
          : 0,
      adultosQ4:
        searchStore?.rooms && searchStore?.rooms[3]
          ? searchStore?.rooms[3]?.adducts
          : 0,
      criancasQ1:
        searchStore.rooms && searchStore?.rooms[0]
          ? searchStore?.rooms[0]?.childrens?.number
          : 0,
      criancasQ2:
        searchStore.rooms && searchStore?.rooms[1]
          ? searchStore?.rooms[1]?.childrens?.number
          : 0,
      criancasQ3:
        searchStore.rooms && searchStore?.rooms[2]
          ? searchStore?.rooms[2]?.childrens?.number
          : 0,
      criancasQ4:
        searchStore.rooms && searchStore?.rooms[3]
          ? searchStore?.rooms[3]?.childrens?.number
          : 0,
      idadeC1:
        searchStore.rooms && searchStore.rooms[0]
          ? searchStore.rooms[0]?.childrens?.children
              .map((child) => child.year.toString() || "")
              .join(",")
          : 0,
      idadeC2:
        searchStore.rooms && searchStore.rooms[1]
          ? searchStore.rooms[1]?.childrens?.children
              .map((child) => child.year.toString() || "")
              .join(",")
          : 0,
      idadeC3:
        searchStore.rooms && searchStore.rooms[2]
          ? searchStore.rooms[2]?.childrens?.children
              .map((child) => child.year.toString() || "")
              .join(",")
          : 0,
      idadeC4:
        searchStore.rooms && searchStore.rooms[3]
          ? searchStore.rooms[3]?.childrens?.children
              .map((child) => child.year.toString() || "")
              .join(",")
          : 0,
      consultaInternacional: 0,
      in_Codigo: searchStore.place?.Codigo,
      hotCodigoSelecionado: hotel.hotCodigo,
      fotoHotelSelecionado: images[0] || "",
      enderecoHotelSelecionado: hotel.address,
      nomeHotelSelecionado: hotel.name,
      nomeDestino: searchStore.place?.Descricao,
      usuarioLogin: 0,
      tktCodigo1: 0,
      tktCodigo2: 0,
      tktCodigo3: 0,
      tktCodigo4: 0,
      totalAdultos: searchStore?.rooms
        ?.map((room) => room.adducts)
        ?.reduce((acc, curr) => (acc += curr), 0),
      totalCriancas: searchStore?.rooms
        ?.map((room) => room.childrens.children.length)
        .reduce((acc, curr) => (acc += curr), 0),
    };

    api
      .post("/wsCoobrastur/Hotel.asmx/InsertPesquisa_1", {
        dados: JSON.stringify(data),
      })
      .then((response) => {
        setLoading(false);
        const { Table } = response.data;

        if (Table[0].situacao === 1) {
          navigate(`${process.env.PUBLIC_URL}/identifique-se`);
          return;
        }

        if (Table[0].situacao === 1 && numAttempts < 3) {
          localStorage.setItem("token", tokenApi);
          handleReserve(hotel);
          setNumAttempts(numAttempts + 1);

          return;
        }

        setErrorMessage(
          Table?.[0]?.Texto
            ? Table[0].Texto
            : "Ops. Algo deu errado, por favor reinicie sua pesquisa!"
        );
      })
      .catch((e) => {
        setLoading(false);
        console.error(e?.response?.data);
        const message = String(e?.response?.data);
        setErrorMessage(
          message.includes("Could not convert string to integer") &&
            message.includes("Path 'idadeC")
            ? "Quantidade de crianças por quarto inválida!"
            : "Ops. Algo deu errado, por favor reinicie sua pesquisa!"
        );
      });
  };

  useEffect(() => {
    const baseImageHotel = "https://www.coobmais.com.br/images/hotel";

    axios
      .get(`${baseImageHotel}/${hotel.hotCodigo}`, {
        responseType: "document",
      })
      .then((response) => {
        const { data } = response;
        const images = [];

        const elements = data.getElementsByTagName("a");

        for (let element of elements) {
          if (element.href.match(/\.(jpe?g|png|gif)$/i)) {
            images.push(element.href);
          }
        }

        setImage(images);
      })
      .catch(console.error);
  }, [hotel.hotCodigo]);

  // useEffect(() => {
  //   createToken();
  // }, [createToken]);

  return (
    <Fragment>
      <CardContent>
        <Tags>
          {hotel.Promotion === 1 && <Promotion>promoção</Promotion>}
          {hotel.hotCodigo === 7428 && (
            <PromotionSpotlight onClick={() => setModalSpotlight(true)}>
              Pacote de experiências
            </PromotionSpotlight>
          )}
        </Tags>

        <ModalAlert
          isOpen={modalSpotlight}
          onClose={() => setModalSpotlight(false)}
          message={{
            htmlBody: `<img src="${SaintAndrewssite}" alt="Saint Andrews" style="max-width: 100%;" />`,
          }}
          maxWidth="735px"
        ></ModalAlert>

        <Carrosel images={images} />

        <Content>
          <CardBody>
            <Hgroup>
              <ContentPlans>
                <ContentPlansItem>
                  <PlansImage
                    src={`//www.coobmais.com.br/images/planos/${hotel.plan}.png`}
                    alt={`${hotel.plan}`}
                  />
                </ContentPlansItem>
              </ContentPlans>

              <div>
                <ContentTitle>{hotel.name}</ContentTitle>

                <Locations>
                  <IconPlace />
                  <span>{hotel.address}</span>
                </Locations>
              </div>
            </Hgroup>

            <BenefitsTitle>Comodidades</BenefitsTitle>
            <WrapperGroup>
              <Benefits>
                <BenefitsList>
                  {hotel.features_hotel
                    .split(",")
                    .slice(0, limit ? 5 : hotel.features_hotel.length)
                    .map((feature) => (
                      <BenefitsListItem key={feature}>
                        {commodityStore?.commodities
                          ?.filter((commodity) => commodity.id === feature)
                          .map((commodity) => (
                            <span key={commodity.id} title={commodity.name}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                width="20"
                                height="20"
                              >
                                <defs>
                                  <filter id="colorMask1">
                                    <feFlood
                                      floodColor="#3a2e83"
                                      result="flood"
                                    />
                                    <feComposite
                                      in="SourceGraphic"
                                      in2="flood"
                                      operator="arithmetic"
                                      k1="1"
                                      k2="0"
                                      k3="0"
                                      k4="0"
                                    />
                                  </filter>
                                </defs>
                                <image
                                  width="100%"
                                  height="100%"
                                  xlinkHref={`https://www.coobmais.com.br/images/car_hotel/${feature}.png`}
                                  filter="url(#colorMask1)"
                                />
                              </svg>
                            </span>
                          ))}
                      </BenefitsListItem>
                    ))}
                  {hotel.features_hotel.length > 0 && (
                    <BenefitsListItem
                      onClick={() => setLimit(!limit)}
                      title={`${limit ? "mostrar mais" : "mostrar menos"}`}
                    >
                      <IconMore />
                    </BenefitsListItem>
                  )}
                </BenefitsList>
              </Benefits>
              <ButtonDetails onClick={() => setIsOpen(!modalIsOpen)}>
                ver detalhes do hotel
              </ButtonDetails>
            </WrapperGroup>
          </CardBody>

          <ContentFooter>
            {Number(hotel.btnReserve) === 1 &&
            Number(hotel.availability) === 1 ? (
              <WrapperGroup>
                <ButtonRequest
                  onClick={() => setModalFormAvailable((current) => !current)}
                >
                  Solicite aqui sua disponibilidade
                </ButtonRequest>
                <ButtonReservation
                  onClick={() => handleReserve(hotel)}
                  disabled={loading}
                >
                  {loading ? <LoadingBtn /> : "RESERVAR"}
                </ButtonReservation>
              </WrapperGroup>
            ) : (
              <>
                {hotel.btnReserve === 1 ? (
                  <>
                    <ButtonReservation
                      onClick={() => handleReserve(hotel)}
                      disabled={loading}
                    >
                      {loading ? <LoadingBtn /> : "RESERVAR"}
                    </ButtonReservation>
                  </>
                ) : (
                  <Fragment>
                    {hotel.warning && (
                      <Alert>
                        <IconAlert />
                        <span>{hotel.warning}</span>
                      </Alert>
                    )}
                    <WrapperGroup>
                      {hotel.availability === 1 && hotel.btnReserve === 0 && (
                        <ButtonRequest
                          onClick={() =>
                            setModalFormAvailable((current) => !current)
                          }
                        >
                          Solicite aqui sua disponibilidade
                        </ButtonRequest>
                      )}

                      {mobile && (
                        <WrapperGroupMobile>
                          {((hotel.btnReserve === 0 && hotel.dates === 1) ||
                            (hotel.dates === 0 &&
                              hotel.availability === 0 &&
                              hotel.btnReserve === 0)) && (
                            <ButtonAvailable
                              onClick={() => setModalDateAvailable(true)}
                            >
                              ver datas disponíveis
                            </ButtonAvailable>
                          )}

                          {hotel.availability === 1 &&
                            hotel.btnReserve === 0 && (
                              <Helpers>
                                Não sabe como funciona a Solicitação de
                                disponibilidade?
                                <span onClick={click}>Entenda mais aqui.</span>
                              </Helpers>
                            )}
                        </WrapperGroupMobile>
                      )}

                      {!mobile && (
                        <Fragment>
                          {((hotel.btnReserve === 0 && hotel.dates === 1) ||
                            (hotel.dates === 0 &&
                              hotel.availability === 0 &&
                              hotel.btnReserve === 0)) && (
                            <ButtonAvailable
                              onClick={() => setModalDateAvailable(true)}
                            >
                              ver datas disponíveis
                            </ButtonAvailable>
                          )}
                        </Fragment>
                      )}
                    </WrapperGroup>

                    {!mobile && (
                      <Fragment>
                        {hotel.availability === 1 && hotel.btnReserve === 0 && (
                          <Helpers>
                            Não sabe como funciona a Solicitação de
                            disponibilidade?
                            <span onClick={click}>Entenda mais aqui.</span>
                          </Helpers>
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                )}
                {
                  <div
                    style={{
                      color: "var(--red600)",
                      width: "100%",
                      textAlign: "center",
                      marginTop: "-16px",
                    }}
                  >
                    {errorMessage}
                  </div>
                }
              </>
            )}
          </ContentFooter>
        </Content>
      </CardContent>

      <ModalDetails
        hotel={hotel}
        images={images}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        openModalDateAvailable={setModalDateAvailable}
        openModalFormAvailability={setModalFormAvailable}
        handleReserve={handleReserve}
      />

      <ModalFormAvailability
        setIsOpen={setModalFormAvailable}
        modalIsOpen={modalFormAvailable}
        hotel={hotel}
      />

      <ModalDateAvailable
        setIsOpen={setModalDateAvailable}
        modalIsOpen={modalDateAvailable}
        hotel={hotel}
        dateStart={searchStore?.dateIn}
      />
    </Fragment>
  );
};
