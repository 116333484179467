import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useCreateToken from "../../hooks/createToken";
import LoginForm from "../../components/LoginForm";
import { Container } from "./styles";
import { LoginPageProps } from "./types";
import ForgotPasswordForm from "../../components/ForgotPasswordForm";
import FirstAccessForm from "../../components/FirstAccessForm";

const LoginPage = ({ stage = "LOGIN" }: LoginPageProps) => {
  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    handleGoToTop();
  }, []);

  const idUser = localStorage.getItem("assnic");
  const [searchParams] = useSearchParams();
  const route = searchParams.get("r");

  const cleanURL = (url: string): string => {
    return url.replace(/['"]/g, "").replace(/^\//, "");
  };

  const handleRedirect = useCallback(() => {
    if (!!route) {
      window.location.href = `${process.env.PUBLIC_URL}/${cleanURL(route)}`;
    }
  }, [route]);

  useEffect(() => {
    if (!!route && !!idUser) {
      handleRedirect();
    }
  }, [idUser, route, handleRedirect]);

  const cpf = searchParams.get("cpf");
  const email = searchParams.get("email");
  const rg = searchParams.get("rg");
  const nascimento = searchParams.get("nascimento"); // yyyy-mm-dd

  const [currentStage, setCurrentStage] = useState(stage);
  const handleClickFirstAccess = () => {
    setCurrentStage("FIRST_ACCESS");
  };
  const handleClickForgotPassword = () => {
    setCurrentStage("FORGOT_PASSWORD");
  };
  const handleClickLogin = () => {
    setCurrentStage("LOGIN");
  };

  const { tokenApi } = useCreateToken();
  const tokenLocalStorage = localStorage.getItem("token");

  return (
    <Container>
      {currentStage !== "FORGOT_PASSWORD" &&
        currentStage !== "FIRST_ACCESS" && (
          <LoginForm
            tokenApi={tokenLocalStorage || tokenApi}
            onClickForgotPassword={handleClickForgotPassword}
            onClickFirstAccess={handleClickFirstAccess}
            initialData={
              !cpf
                ? undefined
                : {
                    user: cpf.trim().replace(/\D/g, ""),
                    password: "",
                  }
            }
            onSuccessLogin={!route ? undefined : handleRedirect}
          />
        )}
      {currentStage === "FORGOT_PASSWORD" && (
        <ForgotPasswordForm
          tokenApi={tokenLocalStorage || tokenApi}
          onClickLogin={handleClickLogin}
          onClickFirstAccess={handleClickFirstAccess}
          initialData={!email ? undefined : { email }}
        />
      )}
      {currentStage === "FIRST_ACCESS" && (
        <FirstAccessForm
          tokenApi={tokenLocalStorage || tokenApi}
          onClickLogin={handleClickLogin}
          initialData={
            !cpf && !rg && !nascimento
              ? undefined
              : {
                  userRegister: cpf || "",
                  rg: rg || "",
                  dataNasc: nascimento || "",
                }
          }
        />
      )}
    </Container>
  );
};

export default LoginPage;
