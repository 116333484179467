import styled from "styled-components";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  max-height: 70vh;
  height: 100%;
`;

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem 1.25rem;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.08);

  @media screen and (max-width: 990px) {
    padding: 0.75rem 1.25rem;
  }
`;

export const ModalHeaderTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--purple400);
  margin: 0;

  @media screen and (max-width: 990px) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

export const ButtonCloseModal = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: 0;
  padding: 0;
  margin-right: 15px;

  svg {
    display: block;
    width: 24px;
    height: 24px;
  }
`;

export const ModalBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  scroll-behavior: smooth;
  padding: 1rem 1.5rem;

  @media screen and (max-width: 990px) {
    padding: 0;

    .splide__slide img {
      border-radius: 0;
    }
  }
`;

export const Body = styled.div`
  width: 100%;
  max-width: 80%;
  margin: 0 auto;

  @media screen and (max-width: 990px) {
    width: 100%;
    max-width: 100%;
    padding: 1rem 1rem;
  }
`;

export const Text = styled.p`
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin: 0;
  margin-bottom: 10px;

  @media screen and (max-width: 990px) {
    &.textWarnning {
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
`;

export const ModalFooter = styled.div`
  border-top: 1px solid var(--gray400);
  background-color: rgba(75, 72, 183, 0.04);
  padding: 1rem;
`;

export const Button = styled.button`
  cursor: pointer;
  display: block;
  background-color: var(--pink600);
  border: none;
  color: var(--white);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  border-radius: 20px;
  width: 100%;
  max-width: 400px;
  min-height: 40px;
  margin: 0 auto;

  &:disabled {
    opacity: 0.15;

    &:hover {
      background-color: var(--pink600);
    }
  }

  &:hover {
    background-color: var(--pink500);
  }
`;
