export const FormatTextForInitial = (item: string) => {
  const otherLetter = item?.substring(1, item.length) || "";
  const lowerCase = otherLetter.toLowerCase();
  const replaceText = item?.replace(otherLetter, lowerCase) || "";

  return replaceText;
};

export const removeHtmlInString = (text: string) => {
  const newText = text.replaceAll("<br>", "");
  return newText;
};

export const FormaNameUser = (text: string) => {
  let name = text.substring(0, 14);
  return `${name}...`;
};
