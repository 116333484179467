import styled from "styled-components";

export const BtnSendEmailNews = styled.button`
  width: 120px;
  height: 50px;
  background-color: #e50076;
  outline: none;
  border: 0;
  border-radius: 25px;
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  text-transform: uppercase;
  right: 0px;

  z-index: 1;
  &:hover {
    background-color: #f489ba;
  }
`;

export interface InputWithError {
  hasError?: boolean;
}
export const InputEmail = styled.input<InputWithError>`
  width: 100%;
  max-width: 348px;
  height: 48px;
  outline: none;
  border: ${({ hasError }) => (hasError ? "2px solid #e50076" : "0")};
  border-radius: 24px;
  padding: 4px 12px;
  color: ${({ hasError }) => (hasError ? "#e50076" : "var(--black)")};
  font-size: 1.2rem;
  font-family: Tahoma, Geneva, Verdana, sans-serif;
  text-align: left;
  padding-right: 122px;

  ::placeholder {
    font-weight: 400;
    text-align: left;
    padding-left: 4px;
  }
  @media (max-width: 500px) {
    font-size: 0.9rem;
    ::placeholder {
      font-size: 0.9rem;
    }
  }
`;

export const WhiteSpan = styled.span`
  font-size: 0.9rem;
  font-weight: bold;
  color: #fff;
  @media (max-width: 500px) {
    width: 350px;
    text-align: center;
  }
`;

export const WrapperInput = styled.div`
  position: relative;
  width: 100%;
  max-width: 350px;
`;

export const ErrorSpan = styled.span`
  position: absolute;
  width: 100% !important;
  max-width: 350px;
  text-align: start !important;
  padding-left: 10px;
  color: #e50076 !important;
`;
