import { useEffect } from "react";
import BannerPagesInst from "../../components/BannerPagesInst";
import { Container, ContentArea, WrapperQuestion, Image } from "./styles";

const ReserveLessThan30Days = () => {
  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    handleGoToTop();
  }, []);
  return (
    <Container>
      <BannerPagesInst title="" />
      <ContentArea>
        <WrapperQuestion>
          <Image
            src="images/ajuda/Infografico-reserva 30 dias-2022.png"
            alt=""
          />
        </WrapperQuestion>
      </ContentArea>
    </Container>
  );
};

export default ReserveLessThan30Days;
