import styled, { keyframes } from "styled-components";
interface LoadingProps {
  active: boolean;
}

export const TicketContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 10px 0px;
  align-items: flex-start;
  justify-content: center;
`;

interface InputProps {
  isUsed: number;
}

export const Input = styled.input<InputProps>`
  margin: 0;
  appearance: none;
  border: 2px solid var(--gray600);
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: ${({ isUsed }) => (isUsed === 0 ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.8s ease-in-out;

  &:before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 4px;
    background-color: var(--purple600);
    opacity: 0;
  }

  &:checked:before {
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }

  &:checked {
    opacity: 1;
    transition: all 0.5s;
    border: 2px solid var(--purple600);
  }
  &:checked ~ label {
    background-color: var(--yellow600);
  }
  &:checked ~ label {
    &:hover {
      background-color: var(--orange500);
    }
  }

  &:disabled ~ label {
    background-color: var(--gray500);
  }
`;

interface LabelProps {
  sugestion: number;
  isUsed: number;
}

export const Label = styled.label<LabelProps>`
  width: 100%;
  height: auto;
  background-color: ${({ sugestion }) =>
    sugestion === 1 ? `var(--yellow500)` : `var(--purple600)`};
  padding-top: 6px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px 8px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  cursor: ${({ isUsed }) => (isUsed === 0 ? "not-allowed" : "pointer")};

  &:hover {
    background-color: var(--purple300);
  }
`;

export const TopInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 4px;
`;

export const WrapperLabel = styled.div`
  display: flex;
`;

export const Divider = styled.div`
  width: 1px;
  height: 20px;
  border: 1px solid black;
  margin: 0px 8px;
  opacity: 0.16;
`;

export const WrapperInfo = styled.div`
  font-size: 14px;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  color: var(--white);
  font-weight: bold;
`;

export const Dayli = styled.span`
  color: var(--white);
  font-size: 14px;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
`;

export const Validate = styled.div`
  width: 100%;
  background-color: grey;
  font-size: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background: #f2f2f2;
  color: var(--purple600);
  padding-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div<LoadingProps>`
  display: ${({ active }) => (active ? "flex" : "none")};
  align-items: center;
  flex-direction: column;

  span {
    width: 60px;
    height: 60px;
    border-radius: 60%;
    border: 6px solid #c9c8ea;
    border-top-color: #3a2e83;
    animation: ${rotate} 1s linear infinite;
  }
`;
