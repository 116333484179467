import styled from "styled-components";
import { InputWithError } from "./types";

export const WrapperInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InputText = styled.input<InputWithError>`
  width: 100%;
  //max-width: 450px;
  min-width: 285px;
  flex: 1;
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  border-radius: 12px;
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid
    ${({ error }) => (!!error ? "var(--red500)" : "var(--gray500Opacity)")};
  margin: 4px;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  outline: none;
  color: var(--black);
  font-weight: 500;

  ::placeholder {
    color: var(--black);
    font-size: 14px;
    font-weight: 500;
  }
`;

export const LabelText = styled.label`
  color: var(--gray600);
  font-size: 0.7rem;
  font-weight: bold;
  padding-left: 10px;
  cursor: pointer;
  text-transform: uppercase;
`;

export const BoxInputradio = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 10px 0px;
  padding-left: 10px;
`;

export const InputRadio = styled.input`
  margin: 0;
  appearance: none;
  border: 2px solid var(--gray600);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.8s;

  &:before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--purple300);
    opacity: 0;
  }

  &:checked:before {
    opacity: 1;
    transition: all 0.5s;
  }

  &:checked {
    opacity: 1;
    transition: all 0.5s;
    border: 2px solid var(--purple300);
  }
  &:checked ~ label {
    color: var(--purple600);
  }
`;

export const Label = styled.label`
  color: var(--gray600);
  font-size: 0.9rem;
  font-weight: bold;
  padding-left: 25px;
  cursor: pointer;
`;

export const ErrorText = styled.span`
  font-size: 0.8rem;
  color: var(--red600);
  width: 100%;
  padding: 2px 8px;
`;
