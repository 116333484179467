import { useEffect } from "react";
import BannerPagesInst from "../../components/BannerPagesInst";
import AboutImg from "./assets/img-casal.png";

import {
  AreaContent,
  Container,
  ContentInfos,
  ImageWorks,
  TitleContent,
} from "./styles";

const About = () => {
  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    handleGoToTop();
  }, []);
  return (
    <Container>
      <BannerPagesInst title="A empresa" />
      <AreaContent>
        <ImageWorks src={AboutImg} alt="como funciona" />
        <ContentInfos>
          <TitleContent>Sobre a coob+</TitleContent>
          <p>
          Sua Assinatura de Hotéis Coob+ com os melhores preços para você se hospedar! 
          </p>
          <p>
          Somos uma assinatura de hotéis que você pode personalizar o número de diárias 
          e o número de pessoas para se hospedar de acordo com o seu perfil de viajante, 
          economizando até 60% em relação à sites de hospedagens! As assinaturas cobrem 
          100% das diárias com café da manhã incluso para duas ou três pessoas.
          </p>
          <p>
            Faça agora mesmo a sua Assinatura Coob+ e hospede-se em um dos + de 500.000 hotéis, 
            pousadas e resorts no mundo!
          </p>
          <p>“Viaje mais, viaje Coob+”</p>
        </ContentInfos>
      </AreaContent>
    </Container>
  );
};

export default About;
