import styled from "styled-components";
import { ButtonAccordionProps, FilterListProps } from "./types";

export const FilterList = styled.ul<FilterListProps>`
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  max-height: ${({ open }) => (open ? "300rem" : "10rem")};
  transition-duration: 0.5s;
  transition-property: max-height;
  transition-timing-function: ${({ open }) =>
    open ? `cubic-bezier(0.895, 0.03, 0.685, 0.22)` : ``};
`;

export const FilterItem = styled.li`
  width: 100%;
  & + & {
    margin-top: 8px;
  }
`;

export const Filter = styled.label`
  cursor: pointer;
  display: grid;
  grid-template-columns: 24px auto;
  gap: 12px;
`;

export const Input = styled.input`
  display: none;

  &:checked ~ span {
    border-color: var(--purple600);

    &::before {
      opacity: 1;
    }
  }
`;

export const PseudoInput = styled.span`
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  border: 2px solid var(--gray500);
  border-radius: 4px;
  transition: border-color 0.3s ease;

  &::before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--purple600);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
`;

export const TextFilter = styled.p`
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  user-select: none;
  text-transform: capitalize;
`;

export const ButtonAccordion = styled.button<ButtonAccordionProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--purple600);
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  padding: 8px 12px;
  letter-spacing: 1px;
  margin-top: 8px;

  svg {
    transform: ${({ open }) => (open ? "rotate(90deg)" : "rotate(270deg)")};
    margin-left: 10px;
    transition: all 0.5s ease;
  }
`;
