import { Form } from "@unform/web";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Header = styled.header`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  box-sizing: border-box;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 3;
  background-color: #fff;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.08);

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const BoxContentHeader = styled.div`
  display: flex;
  max-width: 1280px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
`;

export const HeaderAreaLogo = styled.div`
  width: 100%;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderLogo = styled.img`
  width: 200px;
  cursor: pointer;
`;

export const HeaderAreaMenu = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  list-style: none;
  color: #3a2e83;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  padding: 0;

  @media (max-width: 1050px) {
    font-size: 0.8rem;
  }
`;

export const ItemMenu = styled.li`
  cursor: pointer;

  span {
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    color: var(--purple600);
    transition: all 0.3s ease;

    &:hover {
      text-decoration: underline;
      color: var(--purple300);
    }
  }

  a {
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    color: var(--purple600);
    transition: all 0.3s ease;

    &:hover {
      text-decoration: underline;
      color: var(--purple300);
    }
  }

  @media (max-width: 1024px) {
    margin: 10px 0px;
    animation: ${fadeIn} 0.5s ease-in;
    font-size: 18px;
    color: var(--white);
  }
`;

export const HeaderAreaLogin = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  justify-content: space-evenly;
  position: relative;
`;

export const BtnAssociated = styled.button`
  width: 120px;
  height: 40px;
  background-color: #e50076;
  outline: none;
  border: 0;
  border-radius: 25px;
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: #f489ba;
  }
`;

export const BtnLogin = styled.button`
  width: 148px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #e50076;
  outline: none;
  border-radius: 25px;
  font-size: 1rem;
  color: #e50076;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: #e50076;
    color: #fff;
    border: 0;
  }
`;

export const HeaderMobile = styled.header`
  display: none;
  @media (max-width: 1024px) {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 99;
    height: 80px;
    padding: 0px 20px;
  }
`;

export const HeaderAreaMenuMobile = styled.div`
  width: 100px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const HeaderMenuMobile = styled.ul`
  width: 100%;
  height: 100vh;
  color: #3a2e83;
  list-style: none;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  display: flex;
  padding: 10px;
  background-color: var(--purple400);
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const WrapperIconClose = styled.div`
  width: 100%;
  display: flex;
  height: 68px;
  justify-content: flex-end;
  align-items: center;
  animation: ${fadeIn} 0.5s ease-in;
  padding-right: 30px;
  box-sizing: border-box;
  background-color: var(--purple300);
  border-bottom-left-radius: 68px;
`;

export const WrapperListItemMenuMobile = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  span {
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    color: var(--white);
    transition: all 0.3s ease;

    &:hover {
      text-decoration: underline;
    }
  }

  a {
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    color: var(--white);
    transition: all 0.3s ease;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const BtnLoginMobile = styled.button`
  width: 100%;
  max-width: 328px;
  height: 40px;
  background-color: transparent;
  border: 2px solid var(--white);
  outline: none;
  border-radius: 25px;
  font-size: 1rem;
  color: var(--white);
  font-weight: bold;
  margin-top: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";

  cursor: pointer;
`;

export const BtnAssociatedMobile = styled.button`
  width: 100%;
  max-width: 328px;
  height: 40px;
  background-color: var(--white);
  outline: none;
  border: 0;
  border-radius: 25px;
  font-size: 1rem;
  color: var(--purple400);
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  letter-spacing: 1px;
  margin-top: 20px;
  cursor: pointer;
  &:hover {
    background-color: var(--yellow600);
    color: var(--white);
  }
`;

export const WrapperLoggedUser = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  position: relative;

  svg path {
    cursor: pointer;

    &:hover {
      fill: var(--purple600);
    }
  }
`;

export const ContainerSubMenu = styled.div`
  width: 100%;
  max-width: 229px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  border-radius: 20px;
  background-color: #fff;
  z-index: 3;
  right: 0;
  margin-top: 320px;
  box-shadow: 0px 0px 12px #0000001f;
  padding: 8px 0px;
`;

export const SubMenuItemHeader = styled.div`
  width: 100%;
  height: 40px;
  border-bottom: 2px solid var(--gray500Opacity);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  color: var(--black);
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.38px;
  &:hover {
    a {
      color: var(--purple600);
    }

    color: var(--purple600);
  }

  a {
    text-decoration: none;
    cursor: pointer;
    margin-left: 4px;
    color: var(--black);
  }
`;

export const SubMenuItemList = styled.a`
  width: 100%;
  color: var(--black);
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.38px;
  padding: 4px 16px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: var(--purple600);
  }
`;
export const SubMenuItemBottom = styled.div`
  width: 100%;
  height: 30px;
  border-top: 2px solid var(--gray500Opacity);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  color: var(--black);
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.38px;
  &:hover {
    span {
      color: var(--purple400);
    }

    color: var(--purple400);
  }
  img {
    cursor: pointer;
  }
  span {
    cursor: pointer;
    margin-left: 4px;
    color: var(--black);
  }
`;

export const WrapperLoggedUserMobile = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;

  a {
    text-decoration: none;
    margin-top: 8px;
  }
`;

export const Name = styled.span`
  width: 100%;
  max-width: 120px;
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--purple600);
  text-transform: inherit;
  margin-right: 18px;
`;

export const NameMobile = styled.span`
  width: 100%;
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: inherit;
  text-align: center;
  margin-top: 10px;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
`;

export const Dividir = styled.div`
  width: 2px;
  height: 40px;
  background-color: var(--gray500Opacity);
`;

export const OverLay = styled.div`
  background-color: rgba(0, 0, 0, 0.48);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerModal = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: var(--white);
  height: auto;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin: 0px 12px;
`;

export const HeaderModal = styled.div`
  width: 100%;
  max-width: 530px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 1rem;
    color: var(--purple600);
    font-weight: bold;
    text-transform: uppercase;
  }

  img {
    width: 20px;
    cursor: pointer;
  }
`;

export const BodyModal = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormItem = styled(Form)`
  width: 100%;
  max-width: 530px;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BtnLoginUser = styled.button`
  width: 100%;
  height: 40px;
  background-color: var(--pink600);
  border-radius: 25px;
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  margin: 12px 0px;
  cursor: pointer;

  border: 0;
  &:hover {
    background-color: var(--pink400);
  }
`;
interface WrapperIconSubmenuProps {
  opened: boolean;
}
export const WrapperIconSubmenu = styled.div<WrapperIconSubmenuProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;

  img {
    transform: ${({ opened }) => (opened ? "rotate(270deg)" : "rotate(90deg)")};
    transition: all 0.2s linear;
    cursor: pointer;
  }
`;

export const ContainerOptionsLogin = styled.div`
  width: 100%;
  max-width: 120px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 0px;
  background-color: var(--white);
  border-radius: 20px;
  position: absolute;
  bottom: -85px;
  right: 0;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.08);
  border: 2px solid var(--purple600);

  @media screen and (max-width: 1024px) {
    position: relative;
    bottom: auto;
    max-width: 328px;
    margin-top: 20px;
  }
`;

export const UserOption = styled.div`
  width: 100%;
  border-bottom: 2px solid var(--gray500Opacity);
  height: 30px;
  padding: 4px;
  color: var(--purple600);
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
`;

export const HotelOption = styled.div`
  width: 100%;
  padding: 4px;
  color: var(--purple600);
  height: 30px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
`;

export const BtnFirstAccess = styled.button`
  width: 100%;
  height: 40px;
  background-color: var(--white);
  border: 2px solid var(--pink600);
  border-radius: 25px;
  font-size: 1rem;
  color: var(--pink600);
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  margin: 12px 0px;
  cursor: pointer;

  &:hover {
    background-color: var(--pink600);
    color: var(--white);
  }
`;
