import { Fragment, useEffect } from "react";
import BannerPagesInst from "../../components/BannerPagesInst";
import { content } from "./constants";
import {
  Container,
  ContentArea,
  ItemParagraph,
  ItemParagraphStrong,
  ItemTitle,
  List,
  Subtitle,
  WrapperItem,
} from "./styles";

const PoliticsOfUse = () => {
  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    handleGoToTop();
  }, []);
  return (
    <Container>
      <BannerPagesInst title="Políticas de uso" />
      <ContentArea>
        <Subtitle>
          Faça sua adesão ao Sistema de Turismo Fácil Coob+ e viaje do seu jeito
        </Subtitle>
        <div>
          {content.map((item) => (
            <WrapperItem key={item.title}>
              <ItemTitle>{item.title}</ItemTitle>
              {item.title !== "Onde consultar os hotéis credenciados" && (
                <ItemParagraph>
                  {item.paragraph}
                  {item.paragraphBold && (
                    <ItemParagraphStrong>
                      {item.paragraphBold}
                    </ItemParagraphStrong>
                  )}
                </ItemParagraph>
              )}
              {item.title === "Onde consultar os hotéis credenciados" && (
                <Fragment>
                  <ItemParagraph>{item.paragraph}</ItemParagraph>
                  <List>
                    <li>{item.itemList1}</li>
                    <li>{item.itemList2}</li>
                    <li>{item.itemList1}</li>
                  </List>
                </Fragment>
              )}
            </WrapperItem>
          ))}
        </div>
      </ContentArea>
    </Container>
  );
};

export default PoliticsOfUse;
