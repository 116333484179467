import { Fragment, useState, SyntheticEvent } from "react";
import { ReactComponent as ArrowIcon } from "../../assets/icones/icons minimal (20px)/seta_esquerda.svg";
import { useFilters } from "../../hooks/useFilters";
import {
  ButtonAccordion,
  Filter,
  FilterItem,
  FilterList,
  Input,
  PseudoInput,
  TextFilter,
} from "./styles";
import { FilterAccordionProps } from "./types";

export const FilterAccordion = ({ filters, type }: FilterAccordionProps) => {
  const [open, setOpen] = useState(false);

  const { handleSelectFilter, handleRemoverSelectFilter } = useFilters();

  const handleChangeInput = (event: SyntheticEvent<HTMLInputElement>) => {
    const { currentTarget: input } = event;

    if (input.checked) {
      handleSelectFilter(event);
    } else {
      handleRemoverSelectFilter(event);
    }
  };

  return (
    <Fragment>
      <FilterList open={open}>
        {filters.map((filter) => (
          <FilterItem key={filter.name.replaceAll(" ", "")}>
            <Filter>
              <Input
                className="input-filter"
                type="checkbox"
                value={filter.value}
                data-type={type}
                onChange={handleChangeInput}
              />
              <PseudoInput />
              <TextFilter>{filter.name}</TextFilter>
            </Filter>
          </FilterItem>
        ))}
      </FilterList>
      {filters.length > 5 && (
        <ButtonAccordion open={open} onClick={() => setOpen(!open)}>
          {open ? "veja menos" : "veja mais"} <ArrowIcon />
        </ButtonAccordion>
      )}
    </Fragment>
  );
};
