import ReactModal from "react-modal";
import FormFinalizeAssociateRegistration from "..";
import { FinalizeAssociateRegistrationPayload } from "../types";
import {
  Body,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalHeaderTitle,
} from "./styles";
import { ModalAlertProps } from "./types";

export const ModalForm = ({
  isOpen,
  onClose,
  onSuccess = () => {},
  ...formProps
}: ModalAlertProps) => {
  const handleSuccess = (data: FinalizeAssociateRegistrationPayload) => {
    onSuccess(data);
    onClose();
  };
  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        content: {
          width: "fit-content",
          maxWidth: "1300px",
          height: "90vh",
          padding: "0",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "hidden",
          zIndex: 999,
          borderRadius: "20px",
        },
        overlay: {
          zIndex: 999,
          backgroundColor: "rgba(0,0,0,0.48)",
        },
      }}
      ariaHideApp={false}
      id="Modal"
    >
      <ModalContent id="ModalContent">
        <ModalHeader>
          <ModalHeaderTitle>INFORMAÇÕES CADASTRAIS</ModalHeaderTitle>
        </ModalHeader>

        <ModalBody>
          <Body>
            <ModalHeaderTitle
              style={{ textAlign: "center", color: "var(--black)" }}
            >
              POR FAVOR, FINALIZE O SEU CADASTRO PARA CONTINUAR
            </ModalHeaderTitle>
            <FormFinalizeAssociateRegistration
              onSuccess={handleSuccess}
              {...formProps}
            />
          </Body>
        </ModalBody>
      </ModalContent>
    </ReactModal>
  );
};

export default ModalForm;
