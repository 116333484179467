import { useField } from "@unform/core";
import { useEffect, useRef } from "react";
import {
  WrapperInputs,
  LabelText,
  InputText as InputStyled,
  ErrorText,
} from "./styles";
import { InputTextProps } from "./types";

import RadioComponent from "./Radio/radio";
import SelectComponent from "./Select";
export const Radio = RadioComponent;
export const Select = SelectComponent;

export const Input = ({
  label,
  name,
  type = "text",
  classNameWrapper,
  ...props
}: InputTextProps) => {
  const { fieldName, registerField, error } = useField(name);
  const inputTextRef = useRef(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputTextRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <WrapperInputs className={classNameWrapper} id={name}>
      <LabelText>
        {label}
        {props?.required ? "*" : ""}
      </LabelText>
      <InputStyled
        ref={inputTextRef}
        type={type}
        name={name}
        errorText={error}
        {...props}
      />
      <ErrorText>{error}</ErrorText>
    </WrapperInputs>
  );
};
