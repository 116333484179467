import styled from "styled-components";

export const Steps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0 30px;

  @media screen and (max-width: 990px) {
    padding: 0 1rem;
  }
`;

export const Step = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 315px;
  min-width: 48px;
  height: 48px;
  border: 2px solid var(--purple600);
  font-size: 0.875rem;
  line-height: 1.313rem;
  font-weight: 600;
  color: var(--purple600);
  border-radius: 12px;
  user-select: none;

  &.active {
    background-color: var(--purple600);
    color: var(--white);

    span {
      display: block;

      &:nth-of-type(2) {
        display: none;
      }
    }
  }

  &.disabled {
    border-color: var(--gray400);
    color: var(--gray400);
  }

  & + & {
    margin-left: 15px;
  }

  span {
    display: block;

    &:nth-of-type(2) {
      display: none;
    }
  }

  @media screen and (max-width: 990px) {
    max-width: 205px;

    &.active {
      flex-grow: 2;
    }

    span {
      display: none;

      &:nth-of-type(2) {
        display: block;
      }
    }
  }
`;
