import { Fragment, useCallback, useEffect, useState } from "react";
import { api } from "../../services";
import {
  BodyModal,
  ContainerModal,
  HeaderModal,
  OverLay,
} from "../header/styles";
import LogoWhite from "./assets/logoWhite.png";
import CoobrasturLogo from "./assets/Coobrastur.png";
import IconTel from "./assets/telefone.svg";
import IconCLoseDArk from "../../assets/icones/icons dense (24px)/fechar.svg";
import { IconSocial } from "./constants";
import {
  AreaBottomFooter,
  AreaLeftFooter,
  AreaRightFooter,
  ContentLeftFooter,
  FooterContainer,
  FooterContainerMobile,
  IconSocialItem,
  InfosMenuFooter,
  ItensMenuFooter,
  ItensMenuFooterOther,
  ParagraphItemFooter,
  ParagraphItemFooterCenter,
  TextBottomFooter,
  TitleInfo,
  TitleInfoPaddingTop,
  WrapperIconSocial,
  WrapperInfosLeft,
  WrapperInfosRight,
  WrapperPhone,
  LinkNoUnderline,
} from "./styles";
import useCreateToken from "../../hooks/createToken";
import Cookie from "js-cookie";
import ModalFirstAccess from "../FirstAccess";
import NewsletterInput from "../NewsletterInput";
import LoginForm from "../LoginForm";
import ForgotPasswordForm from "../ForgotPasswordForm";

const Footer = () => {
  const [modalLogin, setModalLogin] = useState<boolean>(false);
  const [forgotPassOpen, setForGottPassOpen] = useState<boolean>(false);
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const idUser = localStorage.getItem("assnic");
  // const [nameUser, setNameUser] = useState<string>("");
  // const [avatar, setAvatar] = useState<string>("");

  const [openFirstAccess, setOpenFirstAccess] = useState<boolean>(false);
  const idUserHotelier = localStorage.getItem("IdUserHotelier");

  const { tokenApi, createToken } = useCreateToken();

  useEffect(() => {
    createToken();
  }, []);

  const handleToggleModallogin = () => {
    setModalLogin(!modalLogin);
    setForGottPassOpen(false);
    setOpenFirstAccess(false);
    //setOpenOptionsLogin(false);
  };

  const handleFirstAccess = () => {
    setOpenFirstAccess(true);
    setModalLogin(false);
  };

  const handleCloseFirstAccess = () => {
    setModalLogin(false);
    setOpenFirstAccess(false);
  };

  const handleRedirectIsLogged = () => {
    if (isLogged) {
      window.location.href = `https://www.coobmais.com.br/portal_associado.html#/minhasReservas`;
      return;
    }
  };

  const handleRedirectIsLoggedReserve = () => {
    if (isLogged) {
      window.location.href = `https://www.coobmais.com.br/portal_associado.html#/minhasReservas`;
      return;
    }
  };

  const handlePU1 = useCallback(
    async (assNic: string, inToken: string) => {
      const resp = await api.post(`/wsCoobrastur/ConsultaHoteis.asmx/PU_1_2`, {
        nic: assNic,
        in_token: inToken,
      });
      if (resp.status === 200) {
        setCookies(inToken, resp.data[0].assnome_razaosocial, assNic, 1);
        setIsLogged(true);
        return;
      }
      console.error(resp.data);
    },
    [] //remove tokenApi
  );
  const handleIsLogged = useCallback(
    async (assNic: string, inToken: string) => {
      setIsLogged(true);
      try {
        await handlePU1(assNic, inToken);
      } catch (err) {
        console.error(err);
      }
    },
    [handlePU1]
  );

  const handleSuccessLogin = async (
    { assNic }: { assNic: string },
    inToken: string
  ) => {
    await handlePU1(assNic, inToken);
    setIsLogged(true);
    handleToggleModallogin();
    debugger;
    window.location.href = `https://www.coobmais.com.br/portal_associado.html#/minhasReservas`;
  };

  const setCookies = (
    token: string,
    userName: string,
    id: string,
    level: number
  ) => {
    const data = {
      currentUser: {
        token: token,
        nomePessoa: userName,
        assnic: id,
        nivelUser: level,
      },
    };
    Cookie.set("usuario_coob", JSON.stringify(data), { expires: 1 });
  };

  const handleForGotPassword = () => {
    setForGottPassOpen(true);
  };

  useEffect(() => {
    const inToken = localStorage.getItem("tokenPortalAssociado");
    if (idUser !== null && inToken !== null) {
      handleIsLogged(idUser, inToken);
    }

    if (idUserHotelier !== null) {
      const nameUseHotelier = localStorage.getItem("nameUserHotelier");
      if (nameUseHotelier) {
        // setNameUser(nameUseHotelier);
        setIsLogged(true);
        return;
      }
    }
  }, [idUser, idUserHotelier, handleIsLogged]);

  return (
    <Fragment>
      {modalLogin && (
        <OverLay>
          <ContainerModal>
            <HeaderModal>
              <span>
                {!forgotPassOpen ? "acessar sua conta" : "redefinir senha"}
              </span>
              <img
                src={IconCLoseDArk}
                alt="fechar-modal"
                onClick={handleToggleModallogin}
              />
            </HeaderModal>

            {!forgotPassOpen && (
              <BodyModal>
                <LoginForm
                  onSuccessLogin={handleSuccessLogin}
                  tokenApi={tokenApi}
                  onClickFirstAccess={handleFirstAccess}
                  onClickForgotPassword={handleForGotPassword}
                />
              </BodyModal>
            )}
            {forgotPassOpen && (
              <BodyModal>
                <ForgotPasswordForm
                  tokenApi={tokenApi}
                  onClickLogin={() => setForGottPassOpen(false)}
                />
              </BodyModal>
            )}
          </ContainerModal>
        </OverLay>
      )}
      {openFirstAccess && <ModalFirstAccess onClose={handleCloseFirstAccess} />}
      <FooterContainer>
        <AreaLeftFooter>
          <ContentLeftFooter>
            <img src={LogoWhite} alt="logo-coob" style={{ width: 300 }} />
            <NewsletterInput />
            <img
              src={CoobrasturLogo}
              alt="logo-coobrastur"
              style={{ width: 300 }}
            />
          </ContentLeftFooter>
        </AreaLeftFooter>
        <AreaRightFooter>
          <WrapperInfosLeft>
            <TitleInfo>Sobre Nós</TitleInfo>
            <ItensMenuFooter to={`${process.env.PUBLIC_URL}/sobreaempresa`}>
              A Coob+
            </ItensMenuFooter>
            <ItensMenuFooter to={`${process.env.PUBLIC_URL}/comofunciona`}>
              Como funciona
            </ItensMenuFooter>
            <ItensMenuFooter to={`${process.env.PUBLIC_URL}/politicadeuso`}>
              Políticas de uso
            </ItensMenuFooter>
            <ItensMenuFooter to={`${process.env.PUBLIC_URL}/seguranca`}>
              Confiança e segurança
            </ItensMenuFooter>
            {/* <ItensMenuFooterOther
              href="https://www.coobmais.com.br/info.html#/trabalhe-conosco"
              target="_blank"
            >
              Trabalhe Conosco
            </ItensMenuFooterOther> */}
            <ItensMenuFooter to={`${process.env.PUBLIC_URL}/Ajuda`}>
              Ajuda
            </ItensMenuFooter>
            <ItensMenuFooterOther
              href="https://viajantecoobmais.com.br/"
              target="_blank"
            >
              Veja nosso blog
            </ItensMenuFooterOther>
            <TitleInfo>Assinante</TitleInfo>
            <div
              onClick={
                isLogged ? handleRedirectIsLogged : handleToggleModallogin
              }
            >
              Acesse sua conta
            </div>
            <div
              onClick={
                isLogged
                  ? handleRedirectIsLoggedReserve
                  : handleToggleModallogin
              }
            >
              Minhas reservas
            </div>
            <ItensMenuFooterOther
              href="https://www.coobmais.com.br/lp/indiquemais/"
              target="_blank"
            >
              Indique mais
            </ItensMenuFooterOther>
            <TitleInfo>Seja Coob+</TitleInfo>
            <ItensMenuFooterOther
              href="https://www.coobmais.com.br/lp/planos/"
              target="_blank"
            >
              Assine
            </ItensMenuFooterOther>
            {/* <ItensMenuFooterOther
              href="https://www.coobmais.com.br/hotel-credenciado/#/"
              target="_blank"
            >
              Seja um Hotel Credenciado
            </ItensMenuFooterOther> */}
          </WrapperInfosLeft>

          <WrapperInfosRight>
            <TitleInfo>Atendimento ao Assinante</TitleInfo>
            <ParagraphItemFooter>Central de Atendimento</ParagraphItemFooter>
            <WrapperPhone>
              <img src={IconTel} alt="telefone" />
              <InfosMenuFooter>(11) 3192-2020</InfosMenuFooter>
            </WrapperPhone>
            <WrapperPhone>
              <img src={IconTel} alt="telefone" />
              <InfosMenuFooter>(61) 3770-0400</InfosMenuFooter>
            </WrapperPhone>
            <WrapperPhone>
              <img src={IconTel} alt="telefone" />
              <InfosMenuFooter>(31) 2116-5770</InfosMenuFooter>
            </WrapperPhone>
            <WrapperPhone>
              <img src={IconTel} alt="telefone" />
              <InfosMenuFooter>(81) 3787-7660</InfosMenuFooter>
            </WrapperPhone>
            <WrapperPhone>
              <img src={IconTel} alt="telefone" />
              <InfosMenuFooter>(51) 3018-6900</InfosMenuFooter>
            </WrapperPhone>
            <ParagraphItemFooter>Cobrança*</ParagraphItemFooter>
            <WrapperPhone>
              <img src={IconTel} alt="telefone" />
              <InfosMenuFooter>(54) 3278-9899</InfosMenuFooter>
            </WrapperPhone>
            <ParagraphItemFooter>* Horário de Atendimento</ParagraphItemFooter>
            <ParagraphItemFooterCenter>
              Segunda à Sexta-feira das 8h às 12h e das 13h às 17h30m.
            </ParagraphItemFooterCenter>
            <LinkNoUnderline
              href="https://www.coobmais.com.br/lp/agencia-de-viagens/"
              target="_blank"
            >
              <TitleInfoPaddingTop>
                Agência Parceira Credenciada
              </TitleInfoPaddingTop>
            </LinkNoUnderline>
            <ParagraphItemFooter>Nacional turismo</ParagraphItemFooter>
            <WrapperPhone>
              <img src={IconTel} alt="telefone" />
              <InfosMenuFooter>(54) 3278-9880</InfosMenuFooter>
            </WrapperPhone>
            <WrapperPhone>
              <img src={IconTel} alt="telefone" />
              <InfosMenuFooter>(81) 3202-1440</InfosMenuFooter>
            </WrapperPhone>
          </WrapperInfosRight>
        </AreaRightFooter>
        <AreaBottomFooter>
          <TextBottomFooter>
            <h5>Siga a COOb+</h5> nas redes sociais!
          </TextBottomFooter>
          <WrapperIconSocial>
            {IconSocial.map(({ icon, id, name, link }) => (
              <a href={link} target="_blanck" key={id}>
                <IconSocialItem src={icon} alt={name} />
              </a>
            ))}
          </WrapperIconSocial>
        </AreaBottomFooter>
      </FooterContainer>
      <FooterContainerMobile>
        <img src={LogoWhite} alt="logo-coob" style={{ width: 300 }} />
        <TextBottomFooter>
          <h5>Siga a COOb+</h5> nas redes sociais!
        </TextBottomFooter>
        <WrapperIconSocial>
          {IconSocial.map(({ icon, id, name, link }) => (
            <a href={link} target="_blanck" key={id}>
              <IconSocialItem src={icon} alt={name} />
            </a>
          ))}
        </WrapperIconSocial>
        <ContentLeftFooter>
          <NewsletterInput />
        </ContentLeftFooter>
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "center",
          }}
        >
          <WrapperInfosRight>
            <WrapperInfosLeft>
              <TitleInfo>Sobre Nós</TitleInfo>
              <ItensMenuFooter to={`${process.env.PUBLIC_URL}/sobreaempresa`}>
                A Coob+
              </ItensMenuFooter>
              <ItensMenuFooter to={`${process.env.PUBLIC_URL}/comofunciona`}>
                Como funciona
              </ItensMenuFooter>
              <ItensMenuFooter to={`${process.env.PUBLIC_URL}/politicadeuso`}>
                Políticas de uso
              </ItensMenuFooter>
              <ItensMenuFooter to={`${process.env.PUBLIC_URL}/seguranca`}>
                Confiança e segurança
              </ItensMenuFooter>
              {/* <ItensMenuFooterOther
                href="https://www.coobmais.com.br/info.html#/trabalhe-conosco"
                target="_blank"
              >
                Trabalhe Conosco
              </ItensMenuFooterOther> */}
              <ItensMenuFooter to={`${process.env.PUBLIC_URL}/Ajuda`}>
                Ajuda
              </ItensMenuFooter>
              <ItensMenuFooterOther
                href="https://viajantecoobmais.com.br/"
                target="_blank"
              >
                Veja nosso blog
              </ItensMenuFooterOther>
            </WrapperInfosLeft>
            <WrapperInfosLeft>
              <LinkNoUnderline
                href="https://www.coobmais.com.br/lp/agencia-de-viagens/"
                target="_blank"
              >
                <TitleInfo>Agência Parceira Credenciada</TitleInfo>
              </LinkNoUnderline>
              <ParagraphItemFooter>Nacional turismo</ParagraphItemFooter>
              <WrapperPhone>
                <img src={IconTel} alt="telefone" />
                <InfosMenuFooter>(54) 3278-9880</InfosMenuFooter>
              </WrapperPhone>
              <WrapperPhone>
                <img src={IconTel} alt="telefone" />
                <InfosMenuFooter>(81) 3202-1440</InfosMenuFooter>
              </WrapperPhone>
              <br />
              <ParagraphItemFooter>
                * Horário de Atendimento
              </ParagraphItemFooter>
              <ParagraphItemFooterCenter>
                Segunda à Sexta-feira das 8h às 12h e das 13h às 17h30m.
              </ParagraphItemFooterCenter>
            </WrapperInfosLeft>
          </WrapperInfosRight>
          <WrapperInfosRight>
            <WrapperInfosLeft>
              <TitleInfo>Assinante</TitleInfo>
              <div
                onClick={
                  isLogged ? handleRedirectIsLogged : handleToggleModallogin
                }
                style={{ fontSize: "0.8rem" }}
              >
                Acesse sua conta
              </div>
              <div
                style={{ fontSize: "0.8rem" }}
                onClick={
                  isLogged
                    ? handleRedirectIsLoggedReserve
                    : handleToggleModallogin
                }
              >
                Minhas reservas
              </div>
              <ItensMenuFooterOther
                href="https://www.coobmais.com.br/lp/indiquemais/"
                target="_blank"
              >
                Indique mais
              </ItensMenuFooterOther>
              <TitleInfo>Seja Coob+</TitleInfo>
              <ItensMenuFooterOther
                href="https://www.coobmais.com.br/lp/planos/"
                target="_blank"
              >
                Assine
              </ItensMenuFooterOther>
              {/* <ItensMenuFooterOther
                href="https://www.coobmais.com.br/hotel-credenciado/#/"
                target="_blank"
              >
                Seja um Hotel Credenciado
              </ItensMenuFooterOther> */}
            </WrapperInfosLeft>
            <WrapperInfosLeft>
              <TitleInfo id="attendencTitle">
                Atendimento ao Assinante{" "}
              </TitleInfo>
              <ParagraphItemFooter id="attendenceCenter">
                Central de Atendimento
              </ParagraphItemFooter>
              <WrapperPhone>
                <img src={IconTel} alt="telefone" />
                <InfosMenuFooter>(11) 3192-2020</InfosMenuFooter>
              </WrapperPhone>
              <WrapperPhone>
                <img src={IconTel} alt="telefone" />
                <InfosMenuFooter>(61) 3770-0400</InfosMenuFooter>
              </WrapperPhone>
              <WrapperPhone>
                <img src={IconTel} alt="telefone" />
                <InfosMenuFooter>(31) 2116-5770</InfosMenuFooter>
              </WrapperPhone>
              <WrapperPhone>
                <img src={IconTel} alt="telefone" />
                <InfosMenuFooter>(81) 3787-7660</InfosMenuFooter>
              </WrapperPhone>
              <WrapperPhone>
                <img src={IconTel} alt="telefone" />
                <InfosMenuFooter>(51) 3018-6900</InfosMenuFooter>
              </WrapperPhone>
              <ParagraphItemFooter>Cobrança*</ParagraphItemFooter>
              <WrapperPhone>
                <img src={IconTel} alt="telefone" />
                <InfosMenuFooter>(54) 3278-9899</InfosMenuFooter>
              </WrapperPhone>
            </WrapperInfosLeft>
          </WrapperInfosRight>
        </div>
      </FooterContainerMobile>
    </Fragment>
  );
};

export default Footer;
