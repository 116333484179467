import styled, { keyframes } from "styled-components";

export const Tags = styled.ul`
  position: absolute;
  z-index: 1;
  list-style: none;
  padding: 0px;
`;

export const Promotion = styled.li`
  background-color: var(--pink600);
  width: 120px;
  text-align: center;
  min-height: 35px;
  padding-top: 6px;
  margin-left: 12px;
  border-radius: 10px;
  color: var(--white);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.9rem;
  margin-bottom: 5px;
`;

export const PromotionSpotlight = styled.li`
  cursor: pointer;
  background-color: var(--pink600);
  width: 120px;
  text-align: center;
  min-height: 35px;
  padding: 4px 0px;
  margin-left: 12px;
  border-radius: 10px;
  color: var(--white);
  text-transform: uppercase;
  font-size: 0.8rem;
  line-height: 1rem;
`;

export const CardContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 232px 1fr;
  align-items: stretch;
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;

  & + & {
    margin-top: 25px;
  }

  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 990px) {
    grid-template-columns: 1fr;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 990px) {
    height: auto;
  }
`;

export const CardBody = styled.div`
  flex: 1 1 auto;
  padding: 1rem;
`;

export const Hgroup = styled.div`
  @media screen and (max-width: 990px) {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 0.85rem;
  }
`;

export const ContentPlans = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  display: flex;
  align-items: flex-start;
`;

export const ContentPlansItem = styled.li`
  display: block;

  & + & {
    margin-left: 8px;
  }
`;

export const PlansImage = styled.img`
  display: block;
  width: 82px;
  height: auto;
`;

export const ContentTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
  margin: 0;
  margin-top: 20px;

  @media screen and (max-width: 990px) {
    margin-top: 0;
    margin-bottom: 0.75rem;
  }
`;

export const Locations = styled.p`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
  gap: 5px;

  font-size: 0.75rem;
  line-height: 0.875rem;
  margin: 0;
  margin-bottom: 15px;

  @media screen and (max-width: 990px) {
    margin-bottom: 0.75rem;
  }
`;

export const WrapperGroup = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 990px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const Benefits = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 990px) {
    background-color: rgba(218, 218, 218, 0.24);
    width: 100%;
    align-items: center;
    border-radius: 12px;
    padding: 8px;
    margin-bottom: 0.75rem;
  }
`;

export const BenefitsTitle = styled.p`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.125rem;
  color: var(--purple600);
  margin: 0;
`;

export const BenefitsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: repeat(6, auto);
  gap: 0.75rem;
  align-content: center;
  align-items: center;
`;

export const BenefitsListItem = styled.li`
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }

  img {
    display: block;
    width: 24px;
    height: 24px;
  }
`;

export const ButtonDetails = styled.button`
  cursor: pointer;
  min-width: 200px;
  padding: 5px 15px;
  background-color: transparent;
  border: 2px solid var(--gray400);
  border-radius: 20px;
  color: var(--purple600);
  height: auto;

  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.125rem;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(229, 0, 118, 0.08);
    color: var(--pink600);
    border-color: var(--pink600);
  }

  &:disabled {
    cursor: default;
    background-color: transparent;
    color: var(--gray400);
    border-color: var(--gray400);
  }

  @media screen and (max-width: 990px) {
    font-size: 0.875rem;
    width: 100%;
    max-width: 100%;
    border-color: transparent;
    background-color: transparent;

    &:active,
    &:hover {
      border-color: transparent;
      background-color: rgba(218, 218, 218, 0.33);
      color: var(--purple600);
    }
  }
`;

export const ContentFooter = styled.div`
  background-color: rgba(75, 72, 183, 0.04);
  border-top: 1px solid var(--gray400);
  padding: 1rem;

  @media screen and (max-width: 990px) {
    background-color: transparent;
    border: none;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 90%;
      height: 1px;
      background-color: rgba(218, 218, 218, 0.24);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export const ButtonReservation = styled.button`
  cursor: pointer;
  background-color: var(--green600);
  border: none;
  color: var(--white);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  border-radius: 20px;
  width: 100%;
  max-width: 250px;
  min-height: 40px;
  margin: 1rem 0;

  justify-content: center;
  display: flex;
  align-items: center;

  &:disabled {
    opacity: 0.15;
  }

  &:hover {
    background-color: var(--pink500);
  }

  @media screen and (max-width: 990px) {
    max-width: 100%;
  }
`;

export const Rotate = keyframes`
from{
    transform:rotate(0deg);
}
to{
    transform:rotate(360deg); 
}
`;
export const LoadingBtn = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 4px solid var(--white);
  border-top-color: transparent;
  animation: ${Rotate} 1s linear infinite;
`;

export const Alert = styled.p`
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
  color: var(--red500);
  text-align: center;
  width: 100%;
  max-width: 100%;
  margin: 0 auto 8px;

  span {
    text-align: left;
  }

  @media screen and (max-width: 990px) {
    font-size: 0.85rem;
    text-align: left;
    width: 100%;
    max-width: 256px;
    margin: 0.75rem auto;
    gap: 0.75rem;

    &.modal {
      display: none;
    }
  }
`;

export const ButtonRequest = styled.button`
  flex-grow: 1;
  width: 100%;
  max-width: 212px;
  cursor: pointer;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 700;
  color: var(--yellow600);
  background-color: transparent;
  border: 2px solid var(--yellow600);
  border-radius: 20px;
  padding: 6px 10px;
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.5;
    border-color: var(--gray400);
    color: var(--gray400);
  }

  &:hover {
    background-color: rgba(229, 0, 118, 0.08);
  }

  @media screen and (max-width: 990px) {
    font-size: 0.813rem;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 0.55rem;
  }
`;

export const ButtonAvailable = styled.button`
  flex-grow: 1;
  cursor: pointer;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 700;
  color: var(--white);
  background-color: var(--orange600);
  border-radius: 20px;
  padding: 6px 10px;
  border: 2px solid var(--orange600);
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.5;
    background-color: transparent;
    border-color: var(--gray400);
    color: var(--gray400);
  }

  &:hover {
    background-color: var(--pink500);
    border-color: var(--pink500);
  }

  @media screen and (max-width: 990px) {
    font-size: 0.813rem;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
`;

export const Helpers = styled.p`
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 10px 0;

  span {
    display: inline-block;
    font-weight: 600;
    color: var(--purple600);
    margin-left: 5px;
    cursor: pointer;
  }

  @media screen and (max-width: 990px) {
    text-align: center;
    margin-top: 0;
  }
`;

export const WrapperGroupMobile = styled.div`
  flex-grow: 1;
  display: flex;

  @media screen and (max-width: 990px) {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }
`;
