import { useField } from "@unform/core";
import { Fragment, SyntheticEvent, useEffect, useRef } from "react";
import {
  BoxInputradio,
  ErrorText,
  InputRadio,
  InputText,
  Label,
  LabelText,
  WrapperInputs,
} from "./styles";
import { InputProps } from "./types";

const InputPassenger = ({
  name,
  label,
  id,
  description,
  type,
  options,
  maxLength,
}: InputProps) => {
  const { fieldName, registerField, defaultValue = 1, error } = useField(name);
  const inputTextRef = useRef(null);
  const inputRadioRef = useRef<HTMLInputElement[]>([]);

  const mask_cpf_fix = (input: SyntheticEvent<HTMLInputElement>) => {
    input.currentTarget.value = input.currentTarget.value.replaceAll(
      /[^0-9]/g,
      ""
    );
  };

  useEffect(() => {
    if (type !== "radio") {
      registerField({
        name: fieldName,
        ref: inputTextRef.current,
        path: "value",
      });
    }

    if (type === "radio") {
      registerField({
        name: fieldName,
        ref: inputRadioRef.current,
        getValue: (refs: HTMLInputElement[]) =>
          refs.find((ref) => ref.checked)?.value || "",
        setValue: (refs: HTMLInputElement[], id: string) => {
          const inputRef = refs.find((ref) => ref.id === id);

          if (inputRef) inputRef.checked = true;
        },
        clearValue: (refs: HTMLInputElement[]) => {
          const inputRef = refs.find((ref) => ref.checked === true);
          if (inputRef) inputRef.checked = false;
        },
      });
    }
  }, [fieldName, registerField, type, defaultValue]);

  return (
    <WrapperInputs>
      {type === "text" && (
        <Fragment>
          <LabelText>{label}</LabelText>
          <InputText
            type="text"
            placeholder={description}
            id={id}
            ref={inputTextRef}
            name={name}
            required
            maxLength={maxLength}
            error={!!error}
          />
          {error && <ErrorText>{error}</ErrorText>}
        </Fragment>
      )}

      {type === "cpf" && (
        <Fragment>
          <LabelText>{label}</LabelText>
          <InputText
            type="text"
            placeholder={description}
            id={id}
            ref={inputTextRef}
            name={name}
            required
            maxLength={maxLength}
            onChange={mask_cpf_fix}
            error={!!error}
          />
          {error && <ErrorText>{error}</ErrorText>}
        </Fragment>
      )}

      {type === "date" && (
        <Fragment>
          <LabelText>{label}</LabelText>
          <InputText
            type="date"
            name={name}
            ref={inputTextRef}
            required={true}
            error={!!error}
          />

          {error && <ErrorText>{error}</ErrorText>}
        </Fragment>
      )}

      {type === "radio" && (
        <Fragment>
          {options?.map((option, index) => (
            <BoxInputradio key={option.id}>
              <InputRadio
                type={type}
                id={option.id}
                defaultChecked={defaultValue}
                ref={(ref) => ref && (inputRadioRef.current[index] = ref)}
                name={name}
                value={option.value}
                required
              />
              <Label htmlFor={option.label}>{option.description}</Label>
              {error && <ErrorText>{error}</ErrorText>}
            </BoxInputradio>
          ))}
        </Fragment>
      )}
    </WrapperInputs>
  );
};
export default InputPassenger;
