import {
  // AreaBenefitys,
  AreaContent,
  Container,
  ContentInfos,
  ImageWorks,
  TitleContent,
} from "./styles";

import HowWorksImg from "./assets/img-comofunciona.png";
// import BeneFitys from "../../components/benefitys";
import BannerPagesInst from "../../components/BannerPagesInst";
import { useEffect } from "react";

const HowItsWorks = () => {
  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    handleGoToTop();
  }, []);

  return (
    <Container>
      <BannerPagesInst title="como funciona a coob+" />
      <AreaContent>
        <ImageWorks src={HowWorksImg} alt="como funciona" />
        <ContentInfos>
          <TitleContent>
            Sua Assinatura de Hotéis Coob+ com os melhores preços para você se
            hospedar!
          </TitleContent>
          <p>
            Somos uma assinatura de hotéis que você pode personalizar o número
            de diárias e o número de pessoas para se hospedar de acordo com o
            seu perfil de viajante, economizando até 60% em relação à sites de
            hospedagens! As assinaturas cobrem 100% das diárias com café da
            manhã incluso para duas ou três pessoas.
          </p>
          <p>
            Faça agora mesmo a sua Assinatura Coob+ e hospede-se em um dos + de
            500.000 hotéis, pousadas e resorts no mundo!
          </p>
        </ContentInfos>
      </AreaContent>
    </Container>
  );
};

export default HowItsWorks;
