import { SyntheticEvent } from "react";
import { InputProps } from "../../components/input";

const maskCpfOrCNPJ = (input: SyntheticEvent<HTMLInputElement>) => {
  input.currentTarget.value = input.currentTarget.value
    .trim()
    .replace(/\D/g, "")
    .substring(0, 14); //max numbers
};

export const dataInput: InputProps[] = [
  {
    name: "user",
    label: "Usuário(seu CPF/CNPJ)",
    id: "usuario",
    description: "Mesmo CPF/CNPJ do Portal do Usuário",
    type: "text",
    maxLength: 18,
    onChange: maskCpfOrCNPJ,
  },
  {
    name: "password",
    label: "Senha",
    id: "senha",
    description: "Mesma senha do Portal do Usuário",
    type: "password",
  },
];

export const initialData = {
  user: "",
  password: "",
};

export const regexCpf = RegExp(/(\d{3})(\d{3})(\d{3})(\d{2})/);
export const regexUpperCase = RegExp(/^(?=.*[A-Z]).+$/);
export const regexLowerCase = RegExp(/^(?=.*[a-z]).+$/);
export const regexNumber = RegExp(/^(?=.*[0-9]).+$/);
export const regexSpecialCharacters = RegExp(/[^a-zA-Z 0-9]+/g);
