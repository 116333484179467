import styled from "styled-components";
import { OptionsSelectProps } from "./types";

export const ContainerButton = styled.div`
  position: relative;
`;

export const ButtonSelect = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 156px;
  height: 40px;
  background-color: transparent;
  border: 1px solid var(--gray400);
  border-radius: 25px;
  outline: none;
  padding: 0 1rem;

  span {
    font-size: 1rem;
    color: var(--gray500);

    &.active {
      font-weight: 700;
      color: var(--purple600);
    }
  }

  svg {
    transform: rotate(90deg);
  }
`;

export const OptionsSelect = styled.div<OptionsSelectProps>`
  position: absolute;
  left: 0;
  top: 110%;
  z-index: 9;
  width: 100%;
  max-width: 100%;
  height: 165px;
  max-height: 257px;
  display: ${({ open }) => (open ? "block" : "none")};
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
`;

export const OptionsContent = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  width: calc(100%);
  max-width: calc(100%);
  height: calc(100%);
  max-height: calc(100%);
  border-radius: 8px;
  padding: 0.75rem;
  overflow-y: auto;

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: var(--purple600);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #fff;
  }
`;

export const OptionsSelectLabel = styled.label`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;

  p {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
    margin: 0;
  }

  & + & {
    margin-top: 0.75rem;
  }
`;

export const OptionsSelectInput = styled.input`
  appearance: none;

  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  border: 2px solid var(--gray500);
  border-radius: 50%;
  transition: border-color 0.3s ease;

  &::before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--purple600);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:checked {
    border-color: var(--purple600);

    &::before {
      opacity: 1;
    }
  }
`;
