import { FormProps } from "@unform/core";
import * as Yup from "yup";
import { cpfValidator } from "../../../../utils";
import { RegexPhone, RegexPhoneFix, RegexUf } from "./constants";

export type FormOtherPersonProps = FormProps;

export const validadeFormOtherPerson = async (data: FormProps) => {
  const schema = Yup.object().shape({
    nameUser: Yup.string()
      .required("Campo é obrigatório")
      .min(8, "o nome deve ser completo"),
    ageUser: Yup.number()
      .required("Campo obrigátorio")
      .positive()
      .min(18, "No mínimo 18 anos"),

    cpfUser: Yup.string()
      .required("Campo é obrigatório")
      .test(
        "invalid-cpf",
        "* CPF inválido. Por favor, tente novamente.",
        (cpf) => cpfValidator(cpf)
      ),
    rgUser: Yup.string()
      .required("Campo obrigatório")
      .min(6, "minimo 6 caracteres"),
    addressUser: Yup.string()
      .required("Campo obrigatório")
      .min(10, "Minimo de 10 caracteres"),
    ufUser: Yup.string()
      .required("Campo obrigatório")
      .min(2, "Mínimo 2 caracteres")
      .max(2, "Mínimo 2 caracteres")
      .matches(RegexUf, "UF inválida, use letras máiusculas"),
    emailUser: Yup.string()
      .required("Campo obrigátório")
      .email("Digite um e-mail válido"),
    parentUser: Yup.string()
      .required("campo obrigátorio")
      .equals(
        [
          "Pai/Mãe",
          "Filho(a)",
          "Irmão(ã)",
          "Cônjuge",
          "Sobrinho(a)",
          "Tio(a)",
          "Neto(a)",
          "Primo(a)",
          "Sogro(a)",
          "Cunhado(a)",
          "Avô(ó)",
          "Outro",
          "Não informado",
        ],
        "opção inválida"
      ),
    phoneRes: Yup.string()
      .required("Campo obrigátório")
      .matches(RegexPhoneFix, "Telefone inválido"),
    phoneCel: Yup.string()
      .required("Campo obrigátório")
      .matches(RegexPhone, "Telefone inválido"),
  });

  await schema.validate(data, {
    abortEarly: false,
  });
  return data;
};
