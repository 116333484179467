import { useState } from "react";
// import { ButtonWishlist } from "../buttonWishlist";
import { Options, Splide, SplideSlide } from "@splidejs/react-splide";

import "@splidejs/react-splide/css/core";
import { CarroselProps } from "./types";

import { CountSlide, Wrapper } from "./styles";

import imageDefault from "../../assets/default.jpg";

const optitonsDefault: Options = {
  height: "100%",
  pagination: false,
  perPage: 1,
  breakpoints: {
    990: {
      height: "225px",
    },
  },
};

export const Carrosel = ({
  options = optitonsDefault,
  width = "232px",
  styles,
  images,
}: CarroselProps) => {
  const [count, setCount] = useState(1);

  return (
    <Wrapper width={width} styles={styles}>
      {/* <ButtonWishlist /> */}
      <Splide options={options} onMove={({ index }) => setCount(index + 1)}>
        {images?.map((image, index) => (
          <SplideSlide key={index.toString()}>
            <img src={image} alt={index.toString()} />
          </SplideSlide>
        ))}
        {!images?.length || images.length === 0 ? (
          <SplideSlide>
            <img src={imageDefault} alt={"1"} />
          </SplideSlide>
        ) : (
          ""
        )}
      </Splide>
      <CountSlide options={options}>
        <span className="currentSlide">{count}</span>
        {" / "}
        <span className="total">{images?.length || 1}</span>
      </CountSlide>
    </Wrapper>
  );
};
