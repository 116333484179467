import styled from "styled-components";
import { OptionsSelectProps } from "./types";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  max-height: 100%;
`;

export const ModalHeader = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 0.75rem;
  padding: 0.65rem 1rem;
  border-bottom: 1px solid rgba(218, 218, 218, 0.24);
`;

export const CloseModal = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    g {
      path {
        stroke: var(--purple600);
      }
    }
  }
`;

export const ModalTitle = styled.h3`
  font-size: 1rem;
  font-weight: 700;
  color: var(--purple600);
  margin: 0;
`;

export const ModalBody = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ModalFooter = styled.div`
  border-top: 1px solid var(--gray400);
  padding: 1rem;
`;

export const FooterMessage = styled.p`
  font-size: 1rem;
  text-align: center;
  color: var(--red500);
  margin: 0;
  margin-bottom: 12px;

  .success {
    color: var(--purple600);
    font-weight: 700;
  }
`;

export const ButtonSave = styled.button`
  cursor: pointer;
  display: block;
  background-color: var(--pink600);
  border: none;
  color: var(--white);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  border-radius: 20px;
  width: 100%;
  max-width: 328px;
  min-height: 40px;
  margin: 0 auto;
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.15;

    &:hover {
      background-color: var(--pink600);
    }
  }

  &:hover {
    background-color: var(--pink500);
  }
`;

export const Room = styled.div`
  border-bottom: 1px solid rgba(218, 218, 218, 0.24);
  padding-bottom: 0.75rem;
`;

export const RoomHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 0.75rem;
`;

export const ButtonRemove = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  background: none;
  outline: none;
  border: none;
  color: var(--gray500);

  svg {
    margin-left: 0.35rem;
  }
`;

export const RoomTitle = styled.h3`
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--gray500);
  margin: 0.75rem 0;
`;

export const RoomOptions = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 0.75rem;

  & + & {
    margin-top: 1rem;
  }
`;

export const RoomContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: var(--purple600);
    margin: 0;
  }

  sup {
    font-size: 0.75rem;
    line-height: 1;
    color: var(--purple600);
  }
`;

export const RoomButtonsGroup = styled.div`
  width: 154px;
  display: grid;
  grid-template-columns: 1fr 43px 1fr;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gray400);
  border-radius: 25px;
  padding: 4px;

  button {
    cursor: pointer;
    display: block;
    background: none;
    border: none;
    outline: none;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--gray600);

    &:active,
    &:focus,
    &:hover {
      color: var(--purple600);
    }

    &:disabled {
      color: var(--gray400);
    }
  }

  span {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: var(--purple600);
    text-align: center;
    border-left: 1px solid var(--gray400);
    border-right: 1px solid var(--gray400);
  }
`;

export const MessageHelp = styled.span`
  display: block;
  font-size: 0.875rem;
  line-height: 1;
  color: var(--gray500);
  margin: 0.75rem 0;
`;

export const ButtonSelect = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 156px;
  height: 40px;
  background-color: transparent;
  border: 1px solid var(--gray400);
  border-radius: 25px;
  outline: none;
  padding: 0 1rem;

  span {
    font-size: 1rem;
    color: var(--gray500);

    &.active {
      font-weight: 700;
      color: var(--purple600);
    }
  }

  svg {
    transform: rotate(90deg);
  }
`;

export const OptionsSelect = styled.div<OptionsSelectProps>`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: ${({ open }) => (open ? "block" : "none")};
`;

export const OptionsContent = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  width: calc(100% - 2rem);
  max-width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  max-height: calc(100% - 2rem);
  border-radius: 8px;
  padding: 1rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const OptionsSelectLabel = styled.label`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;

  p {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1;
    margin: 0;
  }

  & + & {
    margin-top: 0.75rem;
  }
`;

export const OptionsSelectInput = styled.input`
  appearance: none;

  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  border: 2px solid var(--gray500);
  border-radius: 50%;
  transition: border-color 0.3s ease;

  &::before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--purple600);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:checked {
    border-color: var(--purple600);

    &::before {
      opacity: 1;
    }
  }
`;

export const ButtonAddRoom = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 175px;
  min-height: 36px;
  border-radius: 25px;
  background-color: transparent;
  border: 1px solid var(--gray400);
  outline: none;
  margin: 1rem 0;
  padding: 0 0.75rem;

  font-size: 0.875rem;
  font-weight: 500;
  color: var(--gray500);
  transition: all 0.3s ease;

  &:hover {
    color: var(--purple600);

    svg {
      path {
        fill: var(--purple600);
      }
    }
  }

  svg {
    margin-right: 0.45rem;
    path {
      fill: var(--gray500);
      transition: all 0.3s ease;
    }
  }
`;
