import { useRef, useState, SyntheticEvent } from "react";
import { FormHandles, FormHelpers } from "@unform/core";
import * as Yup from "yup";
import { api } from "../../services";
import { Errors } from "../../pages/Identify/types";
import Input from "../input";
import { Btn, FormItem, LoadingBtn } from "./styles";
import { DataFormProps, FirstAccessFormComponentProps } from "./types";
import { cpfOrCNPJValidator } from "../../utils";

const FirstAccessForm = ({
  tokenApi,
  onSuccessFirstAccess,
  onClickLogin,
  initialData
}: FirstAccessFormComponentProps) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");

  const maskCpfOrCnpj = (input: SyntheticEvent<HTMLInputElement>) => {
    input.currentTarget.value = input.currentTarget.value
      .trim()
      .replace(/\D/g, "")
      .substring(0, 14); //max numbers
  };

  const handleSubmit = async (data: DataFormProps, { reset }: FormHelpers) => {
    const today = new Date();
    const dataMais18 = new Date(
      today.getUTCFullYear() - 18,
      today.getUTCMonth(),
      today.getUTCDate()
    );

    setLoading(true);
    try {
      setError("");
      const schema = Yup.object().shape({
        userRegister: Yup.string()
          .required("campo é obrigatório")
          .test(
            "invalid-cpf-or-cnpj",
            "* CPF ou CNPJ inválido. Por favor, tente novamente.",
            (cpf) => cpfOrCNPJValidator(cpf)
          ),

        rg: Yup.string()
          .required("campo obrigatório")
          .max(16, "Máximo de 16 caracteres"),

        dataNasc: Yup.date()
          .required("campo obrigatório")
          .max(dataMais18, "Usuário deve ser maior de 18 anos"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      let birthDay = data.dataNasc;
      let newBirthDay = birthDay.replaceAll("-", "/");
      let date = new Date(newBirthDay);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      const payload = {
        token: tokenApi,
        assCpfCnpj: data.userRegister,
        assDtNascimento: `${day}/${month}/${year}`,
        assRg: data.rg,
      };
      api
        .get(`/wsCoobrastur/ConsultaHoteis.asmx/C2_1`, {
          params: { dados: JSON.stringify(payload) },
        })
        .then((resp) => {
          setLoading(false);

          if (resp.data.Table[0].situacao === 0) {
            setError(resp.data.Table[0].Texto);
            return;
          }

          if (
            resp.data.Table[0].Texto ===
            "Você ficou muito tempo sem navegar pelo site, a página será atualizada!?"
          ) {
            setError("Você ficou muito tempo sem navegar, recarregue a página");
            return;
          }

          if (resp.data.Table[0].situacao === 1) {
            setSuccess(
              "Os Dados de acesso foram enviados para seu e-mail cadastrado conosco"
            );
            if (!!onSuccessFirstAccess) onSuccessFirstAccess();
            return;
          }
        })
        .catch((err) => {
          console.error(err);
          setError(err?.mensagem || err.data.message || "ops, algo deu errado");
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errorMessage: Errors = {};
        err.inner.forEach((error) => {
          if (error.path) {
            errorMessage[error.path] = error.message;
          }
          return formRef.current?.setErrors(errorMessage);
        });
        return;
      }
    }

    formRef.current?.setErrors({});
  };
  return (
    <>
      <div
        style={{
          textAlign: "center",
          width: "100%",
          maxWidth: "540px",
          color: "var(--gray600)",
        }}
      >
        Preencha as informações abaixo para receber seu usuário e senha no seu
        e-mail cadastrado conosco:
      </div>
      <FormItem ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
        <Input
          name="userRegister"
          label="CPF/CNPJ"
          id="CPF/CNPJ"
          description="Informe seu CPF/CPNJ"
          type="text"
          maxLength={18}
          onChange={maskCpfOrCnpj}
        />
        <Input
          name="rg"
          label="RG"
          id="RG"
          description="Informe seu RG"
          type="text"
        />
        <Input
          name="dataNasc"
          label=""
          id="data"
          description="data de nascimento"
          type="date"
        />
        {!!onClickLogin && (
          <div style={{ width: "100%" }}>
            <span style={{ color: "var(--purple500)" }}>
              Você já possui conta?
              <strong
                style={{ cursor: "pointer", color: "var(--pink500)" }}
                onClick={onClickLogin}
              >
                {" "}
                Entrar
              </strong>
            </span>
          </div>
        )}

        {error !== "" && <div style={{ color: "var(--red600)" }}>{error}</div>}

        {success !== "" && (
          <div style={{ color: "var(--green600)" }}>{success}</div>
        )}

        <Btn type="submit" disabled={loading || !!success}>
          {loading ? <LoadingBtn /> : "continuar"}
        </Btn>
      </FormItem>
    </>
  );
};

export default FirstAccessForm;
