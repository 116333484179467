import { PrevArrowProps } from "./types";
import ArrowLeft from "./assets/arrowLeft.svg";
import { Container } from "./styles";

function PrevArrow({ onClick }: PrevArrowProps) {
  return (
    <Container onClick={onClick}>
      <img src={ArrowLeft} alt="next" />
    </Container>
  );
}

export default PrevArrow;
