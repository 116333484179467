import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 50px 50px 0px;

    @media screen and (max-width: 800px) {
        padding: 50px 20px 0px;
        gap: 30px;
    }
`

export const Title = styled.h2`
    width: 100%;
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
    margin: 0px;
    text-transform: uppercase;
    color: rgb(236, 103, 38);
    text-align: center;

    @media screen and (max-width: 800px) {
        font-size: 1.5rem;
        line-height: 2rem;
    }
`

export const VStack = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
`

export const HStack = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;

    @media screen and (max-width: 800px) {
        flex-direction: column;
        gap: 20px;
    }
`

export const Text = styled.p`
    font-size: 0.9rem;
    font-weight: normal;
    color: rgb(58, 46, 131);
    margin: 0;
    max-width: 50%;

    @media screen and (max-width: 800px) {
        max-width: 100%;
    }
`

export const BoldText = styled.span`
    font-weight: bold;
`

export const InfoContainer = styled.div`
    padding: 45px;
    border: 2px solid rgb(229, 0, 118);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
`

export const InfoContainerTitle = styled.h3`
    font-size: 1.25rem;
    font-weight: normal;
    color: rgb(58, 46, 131);
    text-align: center;
    text-transform: uppercase;
    margin: 0;
`

export const InfoBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: center;
    align-items: center;
`

export const InfoTitle = styled.p`
    margin: 0;
    font-size: 30px;
    font-weight: bold;
    color: rgb(58, 46, 131);
    line-height: 1;
`

export const InfoSubtitle = styled.p`
    margin: 0;
    font-size: 20px;
    font-weight: normal;
    color: rgb(229, 0, 118);
    line-height: 1;
`

export const InfoSmallText = styled.p`
    margin: 0;
    font-size: 10px;
    font-weight: normal;
    color: rgb(58, 46, 131);
    line-height: 1;
`

export const Button = styled.a`
    width: 300px;
    height: 40px;
    background-color: rgb(58, 46, 131);
    border-radius: 25px;
    font-size: 1rem;
    color: rgb(255, 255, 255);
    font-weight: 700;
    margin-top: 8px;
    text-transform: uppercase;
    text-decoration: none;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
`

