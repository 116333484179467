export const optionSelected = [
  {
    value: "parentesco",
    label: "Parentesco",
    code: -1,
  },
  {
    value: "Pai/Mãe",
    label: "Pai/Mãe",
    code: 1,
  },
  {
    value: "Filho(a)",
    label: "Filho(a)",
    code: 2,
  },
  {
    value: "Irmão(ã)",
    label: "Irmão(ã)",
    code: 3,
  },
  {
    value: "Cônjuge",
    label: "Cônjuge",
    code: 4,
  },
  {
    value: "Sobrinho(a)",
    label: "Sobrinho(a)",
    code: 5,
  },
  {
    value: "Tio(a)",
    label: "Tio(a)",
    code: 6,
  },
  {
    value: "Neto(a)",
    label: "Neto(a)",
    code: 7,
  },
  {
    value: "Primo(a)",
    label: "Primo(a)",
    code: 8,
  },
  {
    value: "Sogro(a)",
    label: "Sogro(a)",
    code: 9,
  },
  {
    value: "Cunhado(a)",
    label: "Cunhado(a)",
    code: 10,
  },
  {
    value: "Avô(ó)",
    label: "Avô(ó)",
    code: 11,
  },
  {
    value: "Outro",
    label: "Outro",
    code: 12,
  },
  {
    value: "Não informado",
    label: "Não informado",
    code: 13,
  },
];
