import Cookies from "js-cookie";
import { BtnAccept, ContainerCookie } from "./styles";
import { PopCookieProps } from "./types";

const PopCookie = ({ click }: PopCookieProps) => {
  const handleAcceptedCookie = async () => {
    await Cookies.set("aceiteCookies", JSON.stringify({ cookieAccept: true }), {
      expires: 30,
    });
    click();
  };
  return (
    <ContainerCookie>
      <p>
        Ao continuar navegando, você concorda com nossa{" "}
        <strong>
          <a
            href="https://www.coobmais.com.br/politica-cookies.html"
            target="_blanck"
          >
            Política de Cookies{" "}
          </a>
        </strong>
        e com a{" "}
        <strong>
          {" "}
          <a
            href="https://www.coobmais.com.br/politica-privacidade.html"
            target="_blanck"
          >
            Política de Privacidade{" "}
          </a>
        </strong>
      </p>

      <BtnAccept onClick={handleAcceptedCookie}>aceitar e continuar</BtnAccept>
    </ContainerCookie>
  );
};

export default PopCookie;
