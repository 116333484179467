import {
  useState,
  useContext,
  SyntheticEvent,
  createContext,
  ReactNode,
  useCallback,
} from "react";

interface FoltersProviderProps {
  children: ReactNode;
}

interface FilterSelect {
  type: string | null;
  value: string;
}

interface FilterContextData {
  filterSelected: FilterSelect[];
  handleSelectFilter: (event: SyntheticEvent<HTMLInputElement>) => void;
  handleRemoverSelectFilter: (event: SyntheticEvent<HTMLInputElement>) => void;
  handleSetFilter: (data: FilterSelect[]) => void;
  clearAllFilter: () => void;
}

const FiltersContext = createContext<FilterContextData>(
  {} as FilterContextData
);

export function FiltersProvider({
  children,
}: FoltersProviderProps): JSX.Element {
  const [filterSelected, setFilterSelected] = useState<FilterSelect[]>([]);

  /**
   * Faz selecao do fitro clicado
   * @param event SyntheticEvent
   * @returns void
   */
  const handleSelectFilter = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      const { currentTarget } = event;

      const value = currentTarget.value;
      const type = currentTarget?.getAttribute("data-type");

      const newData = [...filterSelected, { value, type }];

      setFilterSelected(newData);
    },
    [filterSelected]
  );

  /**
   * Seta o fitros no estado
   * @param data string[]
   */
  const handleSetFilter = useCallback((data: FilterSelect[]) => {
    setFilterSelected(data);
  }, []);

  /**
   * Limpa todos os filtros e mantem so primeiro item no estado
   */
  const clearAllFilter = useCallback(() => {
    const targets: NodeListOf<HTMLInputElement> =
      document.querySelectorAll("input.input-filter");

    targets.length > 0 &&
      targets.forEach((target: HTMLInputElement) => {
        target.checked = false;
      });

    setFilterSelected((_) => {
      const newData: FilterSelect[] = [];

      return newData;
    });
  }, []);

  /**
   * Remove selecao do filtro e tira ele do estado
   * @param event SyntheticEvent
   * @returns void
   */
  const handleRemoverSelectFilter = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      const { currentTarget } = event;

      const value = currentTarget.value;

      setFilterSelected((oldFilterSelected) => {
        const newData: FilterSelect[] = [...oldFilterSelected];

        const filterPosition: number = newData.findIndex(
          (filter) => filter.value === value
        );

        newData.splice(filterPosition, 1);

        return newData;
      });
    },
    []
  );

  return (
    <FiltersContext.Provider
      value={{
        filterSelected,
        handleSelectFilter,
        handleSetFilter,
        handleRemoverSelectFilter,
        clearAllFilter,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
}

export function useFilters(): FilterContextData {
  const context = useContext(FiltersContext);

  return context;
}
