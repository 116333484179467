import {
  RegexAmex,
  RegexDinners,
  RegexElo,
  RegexHyperCard,
  RegexMaster,
  RegexVisa,
} from "./validations";

export const validPatternCard = (data: string) => {
  if (data.match(RegexMaster)) {
    return RegexMaster;
  }

  if (data.match(RegexVisa)) {
    return RegexVisa;
  }

  if (data.match(RegexElo)) {
    return RegexElo;
  }

  if (data.match(RegexAmex)) {
    return RegexAmex;
  }

  if (data.match(RegexHyperCard)) {
    return RegexHyperCard;
  }
  return RegexDinners;
};
