import styled from "styled-components";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  max-height: 100%;
`;

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem 0.65rem 1rem;
`;
export const ContainerButtonCloseModal = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 16px 16px 0 16px;
  width: 100%;
`;
export const ButtonCloseModal = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  outline: 0;
  padding: 0;

  svg {
    display: block;
    width: 24px;
    height: 24px;
  }
`;

export const ModalHeaderTilte = styled.h2`
  font-size: 1rem;
  font-weight: 900;
  color: var(--purple400);
  margin: 0;
`;

export const ModalBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  scroll-behavior: smooth;
  padding: 0 2rem;

  /* Estilização da barra de rolagem */
  ::-webkit-scrollbar {
    width: 10px; /* Largura da barra de rolagem */
  }

  ::-webkit-scrollbar-track {
    background: #f5f5f5; /* Cor do fundo da barra de rolagem */
    border-radius: 10px; /* Pontas arredondadas */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #706f6f; /* Cor da parte clicável da barra de rolagem */
    border-radius: 10px; /* Pontas arredondadas */
    border: 2px solid #f5f5f5; /* Adiciona um contorno ao redor da parte clicável */
  }
`;

export const Text = styled.p`
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin: 0;
  margin-bottom: 12px;
  text-align: center;
`;

export const ModalFooter = styled.div`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  gap: 8px;

  @media screen and (max-width: 580px) {
    flex-direction: column;
  }
`;

export const ButtonConfirmation = styled.button`
  cursor: pointer;
  display: block;
  background-color: var(--white);
  border-color: var(--pink600);
  border-width: 3px;
  color: var(--pink600);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  border-radius: 20px;
  width: 100%;
  max-width: 100%;
  min-height: 40px;
  margin: 0 auto;

  &:disabled {
    opacity: 0.15;

    &:hover {
      background-color: var(--gray300);
    }
  }

  &:hover {
    background-color: var(--pink400);
  }
`;

export const ButtonSelectOtherDate = styled.button`
  cursor: pointer;
  display: block;
  background-color: var(--pink600);
  border: none;
  color: var(--white);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  border-radius: 20px;
  width: 100%;
  max-width: 100%;
  min-height: 40px;
  margin: 0 auto;

  &:disabled {
    opacity: 0.15;

    &:hover {
      background-color: var(--pink600);
    }
  }

  &:hover {
    background-color: var(--pink500);
  }
`;
