import * as Yup from "yup";
import { ValidationError } from "yup";
import { cpfValidator } from "../../../utils";
import { DataFormProps } from "./types";

export type DataFormPassengersProps = DataFormProps;

export function idade(nascimento: Date, hoje: Date): number {
  let diferencaAnos = hoje.getFullYear() - nascimento.getFullYear();
  if (
    new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()) <
    new Date(hoje.getFullYear(), nascimento.getMonth(), nascimento.getDate())
  ) {
    diferencaAnos--;
  }

  return diferencaAnos;
}

const ehMenorDe18 = (nasc: Date, today?: Date): boolean => {
  const now = !today ? new Date() : today;
  return (
    idade(new Date(nasc.getFullYear(), nasc.getMonth(), nasc.getDate()), now) <
    18
  );
};

export function getGender(data: any, room: number, numberOfPassenger: number) {
  if (room === 1) {
    if (numberOfPassenger === 1) {
      return data.gender;
    }
    if (numberOfPassenger === 2) {
      return data.genderH2;
    }
    if (numberOfPassenger === 3) {
      return data.genderH3;
    }
  }
  return data[`genderH${numberOfPassenger}Q${room}`];
}

export const validadeFormPassengers = async (
  data: DataFormProps,
  room?: number
): Promise<DataFormProps> => {
  const today = new Date();

  const schema = Yup.object().shape({
    hospede: Yup.array(
      Yup.object({
        nameUser: Yup.string().required("campo é obrigatório"),
        lastNameUser: Yup.string().required("campo obrigatório"),
        cpfUser: Yup.string()
          .required("campo obrigatório")
          .test(
            "invalid-cpf",
            "* CPF inválido. Por favor, tente novamente.",
            (cpf) => cpfValidator(cpf)
          ),
        nasc: Yup.date()
          .max(today, "Não pode ser uma data futura")
          .required("campo obrigatório")
          .typeError("data inválida"),
      })
    ),
  });

  await schema.validate(data, {
    abortEarly: false,
  });

  if (room) {
    const hospede = data.hospede.map((passenger, i) => ({
      ...passenger,
      gender: getGender(passenger, room, i + 1),
      isChield: ehMenorDe18(new Date(passenger.nasc), today),
    }));

    return { hospede };
  }
  return data;
};

export const validadeHasChildren = async (
  data: DataFormProps,
  numberOfChildrenThisRoom: number,
  ageChildrenThisRoom: number,
  onFailure?: (message: string) => void | Promise<void>
): Promise<DataFormProps> => {
  const today = new Date();

  const oneErrorYup = (i: number, message: string) => {
    return new ValidationError(message, "", `hospede[${i}].nasc`, "matches");
  };
  const throwError = async (message: string, i?: number) => {
    await onFailure?.(message);
    if (!i) {
      const errors: ValidationError[] = data.hospede.map((data, i) =>
        oneErrorYup(i, message)
      );
      throw new ValidationError(errors);
    }
    throw new ValidationError(oneErrorYup(i, message));
  };

  const plural = (quant: number): string => {
    return quant === 1 ? "" : "s";
  };

  const childrenInRoom = data.hospede.filter(({ nasc }) => {
    const idadeHospede = idade(new Date(nasc), today);
    return idadeHospede < 18;
  });
  if (childrenInRoom.length !== numberOfChildrenThisRoom) {
    if (numberOfChildrenThisRoom <= 0) {
      await throwError(`Esse quarto não deveria ter crianças`);
    }
    await throwError(
      `Esse quarto deveria ter ${numberOfChildrenThisRoom} criança${plural(
        numberOfChildrenThisRoom
      )}, mas foram encontradas ${childrenInRoom.length} criança${plural(
        childrenInRoom.length
      )}!`
    );
  }
  if (numberOfChildrenThisRoom > 0) {
    const findChield = data.hospede.find(({ nasc }) => {
      const idadeCrianca = idade(new Date(nasc), today);
      return idadeCrianca === ageChildrenThisRoom;
    });
    //  Para nao continuar o submit
    if (!findChield) {
      await throwError(
        `Esse quarto deveria ter uma criança de ${ageChildrenThisRoom} anos de idade`
      );
    }
  }
  return data;
};
