import styled, { keyframes } from "styled-components";
import { ArrowImageProps, BtnProps, SwitcherProps } from "./types";

const fade = keyframes`
from{
  opacity:0 ;
}
to{
  opacity:1 ;
}
`;

export const WrapperSelectRoom = styled.div`
  width: 60%;
  min-width: 300px;
  height: auto;
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 500px) {
    min-width: 400px;
  }
  @media (max-width: 400px) {
    min-width: 380px;
  }
`;

export const AlertBox = styled.div`
  color: #a94442;
  background-color: #f2dede;
  border: 1px solid #ebccd1;
  font-size: 0.9rem;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 15px;
`;

export const BoxRoom = styled.div`
  width: 100%;
  max-width: 514px;
  height: auto;
  margin: 10px 0px;
  border: 1px solid var(--gray500Opacity);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  @media (max-width: 500px) {
    border: 0;
    border-top: 1px solid var(--gray500Opacity);
    border-radius: 0px;
  }
`;

export const TitleRoom = styled.h4`
  margin: 0;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-size: 1.3rem;
  color: var(--purple600);
  text-transform: uppercase;
  font-weight: bold;
`;

export const WrapperContent = styled.div`
  width: 100%;
  padding: 20px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
`;

export const Subtitle = styled.h6`
  margin: 0;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-size: 20px;
  color: var(--orange600);
  font-weight: bold;
  margin-bottom: 10px;

  @media (max-width: 500px) {
    text-align: center;
  }
`;

export const BoxTypeOfRoom = styled.div`
  width: 100%;
  border: 2px solid var(--purple600);
  border-radius: 12px;
  height: 70px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  margin-bottom: 40px;
  position: relative;

  span {
    font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
      BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", "Helvetica Neue";
    font-weight: 700;
    font-size: 0.9rem;
    color: var(--purple600);
  }
  p {
    width: 100%;
    max-width: 420px;
    margin: 0;
    font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
      BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", "Helvetica Neue";
    color: var(--black);
    font-size: 0.9rem;
    letter-spacing: 0.45px;
  }
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ArrowImage = styled.img<ArrowImageProps>`
  transform: ${({ open }) => (open ? "rotate(90deg)" : "rotate(270deg)")};
  cursor: pointer;
  transition: 0.3s all ease;
`;

export const WrapperRadios = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
`;

export const WrapperRecomended = styled.div`
  width: 100%;
  max-width: 370px;
`;

export const BadgeRecomended = styled.div`
  width: 166px;
  background-color: var(--gray400);
  padding: 6px 8px;
  border-radius: 20px;
  text-align: center;
  font-size: 12px;
  color: var(--purple600);
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
`;

export const AreaTickets = styled.div`
  width: 100%;
  height: auto;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 400px; //calc((56px + 20px) * 5 + 20)
`;

export const PolyticsArea = styled.div`
  width: 100%;
  max-width: 514px;
  height: auto;
  border-radius: 20px;
  border: 1px solid var(--gray500Opacity);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  @media (max-width: 500px) {
    border: 0;
  }
`;

export const WrappertextAndSwitcher = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 500px) {
    padding-left: 8px;
  }

  p {
    margin: 0;
    width: 100%;
    max-width: 400px;
    text-align: left;
    color: var(--purple600);
    font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
      BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", "Helvetica Neue";
    font-size: 14px;
    padding-left: 8px;
  }
  strong {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Switcher = styled.div<SwitcherProps>`
  width: 28px;
  height: 16px;
  background-color: ${({ active }) =>
    active ? "var(--pink400)" : "var(--gray500)"};
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
`;

export const ItemCircle = styled.div<SwitcherProps>`
  width: 16px;
  height: 16px;
  background-color: ${({ active }) =>
    active ? "var(--pink600)" : "var(--white)"};
  border-radius: 50%;
  position: absolute;
  box-shadow: 0px 0px 24px var(--gray300);
  cursor: pointer;
  right: ${({ active }) => (active ? 0 : 15)}px;
  transition: all 0.3s ease-in-out;
`;
export const BtnKeep = styled.button<BtnProps>`
  width: 100%;
  max-width: 348px;
  height: 40px;
  background-color: var(--pink600);
  border-radius: 25px;
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  margin: 12px 0px;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  border: 0;
  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? "trasparent" : "var(--pink400)"};
  }
`;

export const ModalTypeOfRoon = styled.div`
  background-color: #fff;
  width: 100%;
  position: absolute;
  top: 70px;
  left: 0;
  border-radius: 8px;
  overflow-y: auto;
  box-shadow: 0px 0px 24px #00000033;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  animation: ${fade} 0.3s ease-in-out;

  &::-webkit-scrollbar-thumb {
    width: 2px;
    background-color: #dadada;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  &::-webkit-scrollbar {
    width: 2px;
    background-color: #fff;
  }
`;
