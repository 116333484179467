export const content = [
  {
    title: `O que você adquire`,
    paragraph: `Um Plano de Hospedagem que dá direito a no mínimo 7 (sete) diárias para a utilização em uma Rede Credenciada de Hotéis, 
    em apartamento duplo ou triplo, para duas ou mais pessoas, com café da manhã incluso`,
  },
  {
    title: `Como você paga o seu plano de hospedagem`,
    paragraph: `O pagamento poderá ser realizado através de débito em conta corrente ou cartão de crédito.`,
  },
  {
    title: `Como funciona a taxa de adesão`,
    paragraph: `Todos os palnos Coob+ possuem um valor de adesão com flexibilização na forma de pagamento, ou seja, 
    com opção de pagamento à vista ou parcelado em até 2x sem juros. 
    Após concluir o pagamento da adesão e a primeira mensalidade do plano adquirido, 
    as diárias estarão disponíveis para reserva no site Coob+.`,
  },
  {
    title: `O que você recebe quando adquire um plano de hospedagem`,
    paragraph: `Receberá um extrato virtual com a especificação das diárias a serem utilizadas correspondente ao Plano. 
    Cada Plano Coob+ dá direito à hospedagem de 7 (sete) até 49 (quarenta e nove) diárias, 
    distribuídas em blocos, denominados de e-tickets, de 3 (três) e de 2 (duas) diárias, 
    que somados perfarão o montante correspondente ao número de diárias da opção de plano adquirido.
    `,
  },
  {
    title: `Qual é a validade dos seus e-tickets`,
    paragraph: `Cada e-ticket Coob+ poderá ser utilizado até o último dia da sua validade que é de 2 (dois) anos. 
    Caso não for utilizado neste período, 
    a Coob+ lhe dará mais 1 (um) ano de prorrogação, sem nenhum custo adicional.
    `,
  },
  {
    title: `Quem pode utilizar seus e-tickets`,
    paragraph: `A reserva é feita através do Sistema de Reserva Fácil, existente no site Coob+ e para isso basta você seguir os passos corretamente.
     No entanto, deve considerar que a antecedência mínima é de 30 (trinta) dias, a fim de garantir que a reserva seja efetivada. Caso o período que anteceda sua solicitação seja menor do que 30 dias, a reserva só poderá ser realizada mediante solicitação de disponibilidade ao hotel de sua preferência em nosso site. 
    Caberá ao hotel aceitar ou não, sem interferência da Coob+. 
    `,
    paragraphBold: `Se aceito pelo hotel e a sua hospedagem for entre 15/03 à 15/12, com até 16 dias de antecedência do check-in, não haverá cobrança da Taxa por Reserva Fora do Prazo.`,
  },

  {
    title: `Como pagar a sua hospedagem`,
    paragraph: `O pagamento será realizado pelo e-Ticket, 
    através do Sistema de Reserva Fácil, 
    o qual sempre priorizará aqueles com data de vencimento mais imediata.
    `,
  },
  {
    title: `Onde consultar os hotéis credenciados`,
    paragraph: `São diversas as fontes de consulta dos Hotéis Credenciados:
    `,
    itemList1: ` - A Revista de Hotéis, recebida no momento da aquisição do plano;`,
    itemList2: `  - A Revista online, à disposição no site Coob+;`,
    itemLIst3: `- O próprio Sistema de Reserva Fácil.`,
  },
  {
    title: `Como cancelar uma reserva`,
    paragraph: `A reserva poderá ser cancelada acessando no site da Coob+, 
    via o Portal do Usuário através do link Reservas Fácil. Ao identificar a reserva, clique “Cancelar”. 
    Observe a política de cancelamento que informará, se houver, 
    a devolução de valores extras pagos em cartão de crédito, ou cobrança de No-Show.
    `,
  },
  {
    title: `Como você deve proceder caso você queira estender sua permanência no hotel`,
    paragraph: `Reservas podem ser alteradas de acordo com a disponibilidade do hotel até o momento do Check-out no Portal do Usuário, 
    em Minhas Reservas.
    `,
  },
  {
    title: `Como utilizar os tickets para pacotes`,
    paragraph: `Poderá ser realizado através do Sistema de Reserva Fácil, 
    também via contato com nossa Agência ou as agências de viagens parceiras através do e-mail: atendimento@coobmais.com.br.
    `,
  },
  {
    title: `Como é feita a renovação do seu Plano de Hospedagem`,
    paragraph: `O seu Plano de Hospedagem tem validade de 12 (doze) meses a contar da data do pagamento da primeira mensalidade, sendo renovado automaticamente pelo mesmo prazo, 
    sem o pagamento de nenhuma taxa ou adesão adicional, caso não haja manifestação em contrário.
    `,
  },
];
