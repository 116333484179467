import { useState, useEffect, useCallback, SyntheticEvent } from "react";
import Modal from "react-modal";

import { ReactComponent as IconClose } from "../../assets/icones/icons dense (24px)/fechar - white.svg";
import { ReactComponent as IconMapPin } from "../../assets/icones/icons outline (48px)/pin map.svg";
import { ReactComponent as IconCompass } from "../../assets/icones/icons outline (48px)/bussola.svg";
import { ReactComponent as IconPlace } from "../../assets/icones/icons minimal (20px)/place.svg";
import { ReactComponent as IconHotel } from "../../assets/icones/icons minimal (20px)/hotel.svg";

import {
  CloseModal,
  Compass,
  Content,
  InputSearch,
  Loading,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalTitle,
  ResultItem,
  Results,
  ResultSubtitle,
  ResultTtilte,
} from "./styles";

import { City, ModalPlaceProps } from "./types";
import { useDebounce } from "../../hooks/debounceHook";
import { api } from "../../services";
import useCreateToken from "../../hooks/createToken";
import { useSearchStore } from "../../store/useSearch";

const styleModal = {
  content: {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    padding: "0",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden",
    zIndex: 999,
  },
  overlay: {
    zIndex: 999,
    backgroundColor: "rgba(0,0,0,0.48)",
  },
};

export const ModalPlace = ({
  modalSearch,
  setModalSearch,
}: ModalPlaceProps) => {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState<City[]>([]);
  const { tokenApi, createToken } = useCreateToken();
  const { setData, data: searchStore } = useSearchStore();

  const handleChage = (event: SyntheticEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    setQuery(value);
  };

  const loadData = useCallback(() => {
    const serachData = async () => {
      setLoading(true);
      setSearchData([]);

      if (!query.trim()) {
        setLoading(false);
        setSearchData([]);
        return;
      }

      api
        .post(`/wsCoobrastur/ConsultaHoteis.asmx/C20_1`, {
          token: tokenApi,
          consulta: query,
          iata: false,
        })
        .then((response) => {
          setLoading(false);
          setSearchData(response.data);
        })
        .catch((error) => {
          setLoading(false);
          console.error({ component: "modalPlace", erro: error });
        });
    };

    serachData();
  }, [tokenApi, query]);

  useDebounce(query, 500, loadData);

  const save = (data: City) => {
    setData({ ...searchStore, place: data });
    setModalSearch(!modalSearch);
  };

  useEffect(() => {
    createToken();
  }, [createToken]);

  return (
    <Modal isOpen={modalSearch} style={styleModal} ariaHideApp={false}>
      <ModalContent>
        <ModalHeader>
          <CloseModal
            type="button"
            title="fechar"
            onClick={() => setModalSearch(!modalSearch)}
          >
            <IconClose />
          </CloseModal>

          <ModalTitle>Para onde você quer ir?</ModalTitle>

          <IconMapPin />
        </ModalHeader>
        <ModalBody>
          <form>
            <label>
              <InputSearch
                type="search"
                name="buscar"
                placeholder="Insira uma cidade, estado ou hotel"
                onChange={handleChage}
                value={query}
                autoComplete="off"
              />
            </label>
          </form>

          <Content>
            <Compass active={!loading && searchData.length < 1}>
              <IconCompass />
              <span>Inicie sua busca</span>
            </Compass>
            <Loading active={loading}>
              <span></span>
            </Loading>

            <Results>
              {searchData.map((data: any) => {
                const name = data.Descricao.split(",");

                return (
                  <ResultItem key={data.CodigoID} onClick={() => save(data)}>
                    {data.Tipo === "Cidade" ? <IconPlace /> : <IconHotel />}
                    <div>
                      <ResultTtilte>{name[0]}</ResultTtilte>
                      <ResultSubtitle>
                        {name[1]}, {name[2]}
                      </ResultSubtitle>
                    </div>
                  </ResultItem>
                );
              })}
            </Results>
          </Content>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
