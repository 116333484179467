import { NextArrowProps } from "./types";
import ArrowRight from "./assets/arrowRight.svg";
import { Container } from "./styles";

function NextArrow({ onClick }: NextArrowProps) {
  return (
    <Container onClick={onClick}>
      <img src={ArrowRight} alt="next" />
    </Container>
  );
}

export default NextArrow;
