import { Fragment, useState } from "react";
import { api } from "../../services";

import {
  BtnSendEmailNews,
  ErrorSpan,
  InputEmail,
  WhiteSpan,
  WrapperInput,
} from "./styles";
import * as Yup from "yup";
import { ModalAlert } from "../modalAlert";
import { MessageAlertProps } from "../modalAlert/types";

const NewsletterInput = () => {
  const [valueInput, setValueInput] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");
  const [modalAlertMessage, setModalAlertMessage] = useState(
    {} as MessageAlertProps
  );
  const SUCCESS_TITLE = "Sucesso";

  const handleCloseModal = () => {
    if (modalAlertMessage?.title === SUCCESS_TITLE) {
      setValueInput("");
    }
    setModalAlertMessage({} as MessageAlertProps);
  };

  const handleChangeRegisterForNewsLetter = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValueInput(e.target.value);
  };

  const handleRegisterForNewsLetter = () => {
    const schema = Yup.object().shape({
      email: Yup.string()
        .email("Insira um e-mail válido")
        .required("Preencha o e-mail"),
    });
    schema
      .validate({
        email: valueInput,
      })
      .then(() => {
        setErrorMessage("");
        api
          .post(`/wsCoobrastur/Administracao.asmx/CadastrarNewsletter`, {
            email: valueInput,
          })
          .then((resp) => {
            if (resp.status === 200) {
              setModalAlertMessage({
                title: SUCCESS_TITLE,
                body: "Obrigado por se inscrever na nossa newsletter",
              });
              return;
            }
            throw new Error(resp.data);
          })
          .catch((error) => {
            setModalAlertMessage({
              title: "Ops! Algo deu Errado.",
              body: "Não foi possivel efetuar sua solicitação no momento, tente novamente mais tarde",
              htmlBody: error?.message || error,
            });
            console.error({ component: "footer", error: error.message });
          });
      })
      .catch(function (err) {
        setModalAlertMessage({
          title: "Campo Inválido",
          body: err.errors.toString(),
        });
        setErrorMessage(err.errors.toString());
      });
  };

  return (
    <Fragment>
      <WhiteSpan>Assine nossa newsletter e receba novidades</WhiteSpan>
      <WrapperInput>
        <InputEmail
          type="email"
          placeholder="Digite seu e-mail aqui"
          onChange={handleChangeRegisterForNewsLetter}
          value={valueInput}
          hasError={!!errorMessage}
        />
        <BtnSendEmailNews onClick={handleRegisterForNewsLetter}>
          enviar
        </BtnSendEmailNews>
      </WrapperInput>
      {!!errorMessage && (
        <WrapperInput>
          <ErrorSpan>{errorMessage}</ErrorSpan>
        </WrapperInput>
      )}
      <ModalAlert
        isOpen={!!modalAlertMessage?.title}
        onClose={handleCloseModal}
        message={modalAlertMessage}
      />
    </Fragment>
  );
};

export default NewsletterInput;
