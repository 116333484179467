import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const WrapperTextSteps = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  color: #3a2e83;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";

  margin-left: auto;
  margin-right: auto;
  h4 {
    margin: 0;
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 0.51px;
  }
  span {
    font-size: 15px;
    text-align: center;
  }
`;

export const WrapperSteps = styled.div`
  width: 100%;
  height: auto;
  background-color: var(--white);
  border-radius: 0px 0px 32px 32px;
  box-shadow: 0px 0px 12px #0000001f;
  border-bottom: 1px solid #0000001f;
  padding: 10px 10px 0px 10px;
`;

export const SectionPayment = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 10px;
  @media (max-width: 500px) {
    padding: 20px 0px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0px 10px;
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;
