/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useState, useCallback } from "react";
import { Carrosel } from "../carrosel";
import Modal from "react-modal";
import IconeDiamante from "../../assets/planos/Plano de Assinatura Hotel.png";
import { ReactComponent as IconStar } from "../../assets/icones/icons minimal (20px)/star - empty.svg";
import { ReactComponent as IconDistancia } from "../../assets/icones/icons dense (24px)/distancia.svg";
import { ReactComponent as IconPlace } from "../../assets/icones/icons minimal (20px)/place.svg";
import { ReactComponent as IconFechar } from "../../assets/icones/icons dense (24px)/fechar.svg";
import { ReactComponent as IconTelefone } from "../../assets/icones/icons minimal (20px)/phone.svg";

import {
  Body,
  ButtonCloseModal,
  ButtonDetails,
  ButtonReservation,
  ButtonReserve,
  CardBody,
  CardContent,
  ContainerContact,
  Content,
  ContentFooter,
  ContentPlans,
  ContentPlansItem,
  ContentTitle,
  Details,
  DetailsText,
  DetailsTitle,
  GroupLocation,
  GroupLocationMap,
  GroupLocationTitle,
  Hgroup,
  HotelCategory,
  HotelCategoryTitle,
  HotelStars,
  LocationRef,
  LocationRefIcon,
  LocationRefText,
  Locations,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderTilte,
  PlansImage,
  Text,
  TextPhone,
  Title,
  WrapperGroup,
} from "./styles";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { CardHotelInternacionalProps, HotelList } from "./types";
import { ModalRoomSelection } from "../modalRoomSelection";
import { useSearchStore } from "../../store/useSearch";
import useCreateToken from "../../hooks/createToken";
import { formatDate } from "../../utils";
import { api } from "../../services";
import { useNavigate } from "react-router-dom";
import { roomChosenProps } from "../modalRoomSelection/types";
import { ModalFormConfirmation } from "../modalFormConfirmation";
import { MessageProps } from "../modalFormAvailability/types";

export const CardHotelInternacional = ({
  hotel,
}: CardHotelInternacionalProps) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalRooms, setModalRooms] = useState(false);
  const mobile = useMediaQuery("(max-width: 990px)");
  const { data: searchStore } = useSearchStore();
  const { createToken } = useCreateToken();
  const [numAttempts, setNumAttempts] = useState(0);
  const navigate = useNavigate();
  const [selectedsRooms, setSelectedRooms] = useState<roomChosenProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<MessageProps>({} as MessageProps);
  const [modalReload, setModalReload] = useState<boolean>(false);
  const [modalExeption, setModalExeption] = useState<boolean>(false);

  const token = localStorage.getItem("token");
  const handleCloseModalreload = () => {
    setModalReload(false);
    window.location.href = `${process.env.PUBLIC_URL}/`;
  };

  const handleClosereload = () => {
    setModalExeption(false);
  };

  const handleReserve = useCallback(
    (hotel: HotelList) => {
      localStorage.removeItem("planHotel");
      setLoading(true);
      const { Rooms, ...restHotel } = hotel.raw;
      const payloadRooms: any[] = [];
      selectedsRooms.map(({ roomID, groupID }) => {
        const room = Rooms.find(({ Id }: any) => Id === roomID);
        if (!room) throw new Error("erro rooms");
        payloadRooms[groupID] = room;
        return room;
      });
      const hotelPreorder = { ...restHotel, Rooms: payloadRooms };

      const preorderPayload = {
        token: token,
        hotel: [hotelPreorder],
      };
      const insertPesquisaPayload = {
        in_token: token,
        dataInicial: formatDate(searchStore.dateIn),
        dataFinal: formatDate(searchStore.dateOut),
        qtdeQuartos: searchStore.numRoom,
        adultosQ1:
          searchStore?.rooms && searchStore?.rooms[0]
            ? searchStore?.rooms[0]?.adducts
            : 0,
        adultosQ2:
          searchStore?.rooms && searchStore?.rooms[1]
            ? searchStore?.rooms[1]?.adducts
            : 0,
        adultosQ3:
          searchStore?.rooms && searchStore?.rooms[2]
            ? searchStore?.rooms[2]?.adducts
            : 0,
        adultosQ4:
          searchStore?.rooms && searchStore?.rooms[3]
            ? searchStore?.rooms[3]?.adducts
            : 0,
        criancasQ1:
          searchStore.rooms && searchStore?.rooms[0]
            ? searchStore?.rooms[0]?.childrens?.number
            : 0,
        criancasQ2:
          searchStore.rooms && searchStore?.rooms[1]
            ? searchStore?.rooms[1]?.childrens?.number
            : 0,
        criancasQ3:
          searchStore.rooms && searchStore?.rooms[2]
            ? searchStore?.rooms[2]?.childrens?.number
            : 0,
        criancasQ4:
          searchStore.rooms && searchStore?.rooms[3]
            ? searchStore?.rooms[3]?.childrens?.number
            : 0,
        idadeC1:
          searchStore.rooms && searchStore.rooms[0]
            ? searchStore.rooms[0]?.childrens?.children
                .map((child) => child.year.toString() || "")
                .join(",")
            : 0,
        idadeC2:
          searchStore.rooms && searchStore.rooms[1]
            ? searchStore.rooms[1]?.childrens?.children
                .map((child) => child.year.toString() || "")
                .join(",")
            : 0,
        idadeC3:
          searchStore.rooms && searchStore.rooms[2]
            ? searchStore.rooms[2]?.childrens?.children
                .map((child) => child.year.toString() || "")
                .join(",")
            : 0,
        idadeC4:
          searchStore.rooms && searchStore.rooms[3]
            ? searchStore.rooms[3]?.childrens?.children
                .map((child) => child.year.toString() || "")
                .join(",")
            : 0,
        consultaInternacional: 1,
        in_Codigo: searchStore.place?.Codigo,
        hotCodigoSelecionado: hotel.id,
        fotoHotelSelecionado: hotel.images[0] || "",
        enderecoHotelSelecionado: hotel.address,
        nomeHotelSelecionado: hotel.name,
        nomeDestino: searchStore.place?.Descricao,
        usuarioLogin: 0,
        tktCodigo1: 0,
        tktCodigo2: 0,
        tktCodigo3: 0,
        tktCodigo4: 0,
        totalAdultos: searchStore?.rooms
          ?.map((room) => room.adducts)
          ?.reduce((acc, curr) => (acc += curr), 0),
        totalCriancas: searchStore?.rooms
          ?.map((room) => room.childrens.children.length)
          .reduce((acc, curr) => (acc += curr), 0),
      };

      if (!hotelPreorder?.Rooms?.length) {
        throw new Error("erro rooms");
      }
      const formData = new FormData();
      formData.append("hotel", JSON.stringify(preorderPayload));

      api
        .post("/wsCoobrastur/Hotel.asmx/preOrder_1", formData)
        .then((responsePreOrder) => {
          if (responsePreOrder.data.Successful === false) {
            let OtherErro =
              "System.Exception: Rooms with the same number of occupants must have the same room id\r\n   at TravelEngine.Hotel.Api.Cangooroo.CangoorooConnection.Post(String req, String path) in C:\\SourceCode\\EME\\TravelEngine\\Sources\\Hotel\\TravelEngine.Hotel.Api.Cangooroo\\CangoorooConnection.cs:line 72\r\n   at TravelEngine.Hotel.Api.Cangooroo.Availability.AssessFare(Hotel hotel) in C:\\SourceCode\\EME\\TravelEngine\\Sources\\Hotel\\TravelEngine.Hotel.Api.Cangooroo\\Availability.cs:line 92\r\n   at TravelEngine.Hotel.Api.Cangooroo.Sources.CangoorooBookingSource.AssessFare(TimeoutExecutionTask timeOutExecutionTask, Object state) in C:\\SourceCode\\EME\\TravelEngine\\Sources\\Hotel\\TravelEngine.Hotel.Api.Cangooroo\\Sources\\CangoorooBookingSource.cs:line 99";

            let errorMessage = responsePreOrder.data.Errors[0].ErrorMessage;
            if (errorMessage === OtherErro) {
              setModalRooms(false);
              setMessage({
                title: "System.Exception",
                body: OtherErro,
              });
              setModalExeption(true);
              setLoading(false);
              return;
            }

            if (
              errorMessage ===
              "Err: Hotel_CancelationPolicyError - Problem to get the cancellation policies"
            ) {
              setModalRooms(false);
              setMessage({
                title: "System.Exception",
                body: "O quarto selecionado não está mais disponível.<br>Tente selecionar outro quarto.",
              });
              setModalExeption(true);
              setLoading(false);
              return;
            }
          }

          api
            .post("/wsCoobrastur/Hotel.asmx/InsertPesquisa_1", {
              dados: JSON.stringify(insertPesquisaPayload),
            })
            .then((response) => {
              setLoading(false);
              const { Table } = response.data;

              if (Table[0].situacao === 1) {
                navigate(`${process.env.PUBLIC_URL}/identifique-se`);
                setLoading(false);
                return;
              }

              if (Table[0].situacao === 1 && numAttempts < 3) {
                createToken();
                handleReserve(hotel);
                setNumAttempts(numAttempts + 1);
                setLoading(false);
                return;
              }

              if (response.data.Table[0].situacao === 0) {
                setModalRooms(false);
                setMessage({
                  title: "Você ficou muito tempo sem navegar",
                  body: response.data.Table[0].Texto,
                });
                setModalReload(true);
                setLoading(false);
                return;
              }

              setLoading(false);
              return;
            })
            .catch((err) => {
              console.error(err);
              setLoading(false);
            });
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    },
    [createToken, navigate, numAttempts, searchStore, token, selectedsRooms]
  );

  return (
    <Fragment>
      <CardContent>
        <Carrosel images={hotel.images} />

        <Content>
          <CardBody>
            <Hgroup>
              <ContentPlans>
                <ContentPlansItem>
                  <PlansImage src={IconeDiamante} alt="Diamante" />
                </ContentPlansItem>
                <ContentPlansItem>
                  <HotelCategory>
                    <HotelCategoryTitle>categoria</HotelCategoryTitle>
                    <HotelStars className={`star-${hotel.hotelStars}`}>
                      <IconStar />
                      <IconStar />
                      <IconStar />
                      <IconStar />
                      <IconStar />
                    </HotelStars>
                  </HotelCategory>
                </ContentPlansItem>
              </ContentPlans>

              <div>
                <ContentTitle>{hotel.name}</ContentTitle>

                <Locations>
                  <IconPlace />
                  <span>{hotel.address}</span>
                </Locations>
              </div>
            </Hgroup>

            <WrapperGroup>
              <LocationRef>
                <LocationRefIcon>
                  <IconDistancia />
                </LocationRefIcon>
                <div>
                  <LocationRefText>
                    <strong>{hotel.centerDistance.toFixed(2)} km</strong> do
                    centro
                  </LocationRefText>
                  <LocationRefText>
                    <strong>{hotel.AirportDistance.toFixed(2)} km</strong> do
                    aeroporto
                  </LocationRefText>
                </div>
              </LocationRef>
              <Details>
                <DetailsTitle>Descrição do Hotel</DetailsTitle>
                <DetailsText>{hotel.description}</DetailsText>
                {mobile && (
                  <ButtonDetails onClick={() => setIsOpen(!modalIsOpen)}>
                    ver detalhes do hotel
                  </ButtonDetails>
                )}
              </Details>
            </WrapperGroup>
          </CardBody>

          <ContentFooter>
            <ButtonReservation onClick={() => setModalRooms(!modalRooms)}>
              RESERVAR
            </ButtonReservation>
            {!mobile && (
              <ButtonDetails onClick={() => setIsOpen(!modalIsOpen)}>
                ver detalhes do hotel
              </ButtonDetails>
            )}
          </ContentFooter>
        </Content>
      </CardContent>

      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        style={{
          content: {
            width: "95%",
            maxWidth: "815px",
            height: mobile ? "92vh" : "80vh",
            padding: "0",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            overflow: "hidden",
            zIndex: 999,
            borderRadius: "20px",
          },
          overlay: {
            zIndex: 999,
            backgroundColor: "rgba(0,0,0,0.48)",
          },
        }}
      >
        <ModalContent>
          <ModalHeader>
            <ButtonCloseModal
              type="button"
              title="fechar"
              onClick={() => setIsOpen(!modalIsOpen)}
            >
              <IconFechar />
            </ButtonCloseModal>
            <ModalHeaderTilte>{hotel.name}</ModalHeaderTilte>
          </ModalHeader>

          <ModalBody>
            <Carrosel
              options={{
                perPage: 2,
                height: "265px",
                gap: "1rem",
                pagination: false,
                breakpoints: {
                  990: {
                    perPage: 1,
                    height: "224px",
                    gap: 0,
                  },
                },
              }}
              width={"100%"}
              styles={{ width: "100%", borderRadius: "8px" }}
              images={hotel.images}
            />

            <ContainerContact>
              <div>
                {hotel.contacts.map((contact) => (
                  <TextPhone>
                    <IconTelefone />
                    <span>{contact.description}</span>
                  </TextPhone>
                ))}
              </div>
            </ContainerContact>

            <GroupLocation>
              <GroupLocationTitle>Localização</GroupLocationTitle>
              <GroupLocationMap>
                <iframe
                  src={encodeURI(
                    `https://maps.google.com/maps?q=${hotel.address}&t=&z=15&ie=UTF8&iwloc=&output=embed`
                  )}
                  title="Localização"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </GroupLocationMap>
            </GroupLocation>

            <Body>
              <Title>Descrição do Hotel</Title>
              <Text>{hotel.description}</Text>
            </Body>
          </ModalBody>

          <ModalFooter>
            <ButtonReserve
              onClick={() => {
                setIsOpen(false);
                setModalRooms(true);
              }}
            >
              RESERVAR
            </ButtonReserve>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ModalRoomSelection
        setIsOpen={setModalRooms}
        modalIsOpen={modalRooms}
        hotel={hotel}
        handleReserve={handleReserve}
        handleSelectRoom={setSelectedRooms}
        isCreatingReservation={loading}
      />
      <ModalFormConfirmation
        modalIsOpen={modalReload}
        message={message}
        setIsOpen={handleCloseModalreload}
      />
      <ModalFormConfirmation
        modalIsOpen={modalExeption}
        message={message}
        setIsOpen={handleClosereload}
      />
    </Fragment>
  );
};
