import { useState, useEffect } from "react";

export function useDebounce(
  value: string,
  timeout: number,
  callback: Function
) {
  const [timer, setTimer] = useState<number | null>(null);

  const clearTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    clearTimer();

    if (value && callback) {
      const newTimer: number = setTimeout(callback, timeout);
      setTimer(newTimer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
}
