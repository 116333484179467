import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { InputSelectProps } from "../types";
import {
  ErrorText,
  WrapperInputs,
  Select,
  OptionSelect,
  LabelText,
} from "../styles";

export default function InputSelect({
  name,
  options,
  classNameWrapper,
  label,
  ...props
}: InputSelectProps) {
  const inputRefs = useRef<HTMLOptionElement[]>([]);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLOptionElement[]) => {
        return refs.find((ref) => ref.selected)?.value || "";
      },
      setValue: (refs: HTMLOptionElement[], value: string) => {
        const option = refs.find((ref) => ref.value === value);

        if (option) option.selected = true;
      },
      clearValue: (refs: HTMLOptionElement[]) => {
        refs.forEach((ref) => (ref.selected = false));
      },
    });
  }, [fieldName, registerField]);

  return (
    <WrapperInputs className={classNameWrapper} id={name}>
      <LabelText>
        {label}
        {props?.required ? "*" : ""}
      </LabelText>
      <Select
        name={fieldName}
        defaultValue={defaultValue}
        {...props}
        errorText={error}
      >
        {options.map(({ value, ...optionsProps }, index) => (
          <OptionSelect
            key={value}
            value={value}
            ref={(elRef) => {
              if (!!elRef) inputRefs.current[index] = elRef;
            }}
            {...optionsProps}
          />
        ))}
      </Select>
      {error && <ErrorText>{error}</ErrorText>}
    </WrapperInputs>
  );
}
