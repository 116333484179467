import { useState } from "react";
import Modal from "react-modal";
import { ReactComponent as IconFechar } from "../../assets/icones/icons dense (24px)/fechar.svg";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import {
  ButtonCloseModal,
  ButtonSelectDate,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderTilte,
  Text,
  Title,
} from "./styles";

export const ModalCancellationPolicy = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const mobile = useMediaQuery("(max-width: 990px)");

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      style={{
        content: {
          width: "95%",
          maxWidth: "592px",
          height: mobile ? "571px" : "485px",
          padding: "0",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "hidden",
          zIndex: 999,
          borderRadius: "20px",
        },
        overlay: {
          zIndex: 999,
          backgroundColor: "rgba(0,0,0,0.48)",
        },
      }}
    >
      <ModalContent>
        <ModalHeader>
          <ButtonCloseModal
            type="button"
            title="fechar"
            onClick={() => setIsOpen(!modalIsOpen)}
          >
            <IconFechar />
          </ButtonCloseModal>
          <ModalHeaderTilte>FECHAR</ModalHeaderTilte>
        </ModalHeader>

        <ModalBody>
          <Title>Política de Cancelamento</Title>
          <Text>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu
            volutpat odio facilisis mauris sit. Adipiscing elit ut aliquam purus
            sit. In hendrerit gravida rutrum quisque non tellus orci. Nunc vel
            risus commodo viverra maecenas accumsan. Leo integer malesuada nunc
            vel risus commodo viverra. Interdum velit euismod in pellentesque
            massa placerat duis. Eget sit amet tellus cras adipiscing enim eu
            turpis. Viverra nam libero justo laoreet sit amet cursus sit amet.
          </Text>
          <Text>
            Blandit turpis cursus in hac habitasse platea dictumst. Consectetur
            lorem donec massa sapien faucibus et molestie ac. Tellus elementum
            sagittis vitae et leo duis ut. Neque viverra justo nec ultrices dui
            sapien. Faucibus a pellentesque sit amet porttitor eget. Porttitor
            leo a diam sollicitudin tempor id eu nisl nunc. Fermentum et
            sollicitudin ac orci phasellus egestas tellus. Sit amet nisl
            suscipit adipiscing bibendum est ultricies integer quis. Egestas
            erat imperdiet sed euismod nisi porta lorem. Sollicitudin aliquam
            ultrices sagittis orci a scelerisque purus semper eget. Ut consequat
            semper viverra nam libero justo laoreet. Pellentesque habitant morbi
            tristique senectus et netus. Fames ac turpis egestas integer eget
            aliquet nibh praesent. Phasellus vestibulum lorem sed risus
            ultricies tristique nulla.
          </Text>
          <Text>
            Pellentesque sit amet porttitor eget dolor morbi non arcu risus.
            Lobortis mattis aliquam faucibus purus in massa tempor. Massa sapien
            faucibus et molestie ac. A diam sollicitudin tempor id. Viverra
            justo nec ultrices dui sapien eget. At ultrices mi tempus imperdiet
            nulla malesuada pellentesque. Sagittis orci a scelerisque purus
            semper. Dui accumsan sit amet nulla. Mi sit amet mauris commodo quis
            imperdiet massa tincidunt nunc. Sed cras ornare arcu dui vivamus
            arcu. Fringilla urna porttitor rhoncus dolor purus non enim praesent
            elementum. Dolor sed viverra ipsum nunc. Aliquet lectus proin nibh
            nisl condimentum id. Amet consectetur adipiscing elit ut aliquam
            purus sit amet luctus. Fusce id velit ut tortor pretium viverra
            suspendisse potenti nullam. Euismod in pellentesque massa placerat
            duis ultricies. Eu feugiat pretium nibh ipsum. Tortor posuere ac ut
            consequat semper viverra nam libero justo. Urna duis convallis
            convallis tellus id interdum velit laoreet id. Libero volutpat sed
            cras ornare arcu dui vivamus arcu.
          </Text>
          <Text>
            Morbi blandit cursus risus at ultrices. Condimentum vitae sapien
            pellentesque habitant morbi tristique senectus. Aliquet bibendum
            enim facilisis gravida neque convallis a cras. Varius sit amet
            mattis vulputate enim nulla aliquet porttitor lacus. Egestas sed
            tempus urna et pharetra pharetra massa massa. Nunc sed velit
            dignissim sodales. Sed egestas egestas fringilla phasellus faucibus
            scelerisque eleifend donec pretium. Commodo elit at imperdiet dui
            accumsan. Nisi est sit amet facilisis magna etiam tempor. Est
            placerat in egestas erat. In ante metus dictum at tempor. Tempus
            egestas sed sed risus pretium quam vulputate. Viverra accumsan in
            nisl nisi scelerisque eu ultrices vitae. Aliquam vestibulum morbi
            blandit cursus risus at ultrices. Malesuada pellentesque elit eget
            gravida cum sociis natoque penatibus et. Facilisi morbi tempus
            iaculis urna. Habitasse platea dictumst vestibulum rhoncus est
            pellentesque elit ullamcorper. Penatibus et magnis dis parturient
            montes nascetur ridiculus mus. Integer feugiat scelerisque varius
            morbi enim nunc faucibus a. Facilisis mauris sit amet massa vitae
            tortor. Dolor sit amet consectetur adipiscing elit pellentesque.
          </Text>
        </ModalBody>

        <ModalFooter>
          <ButtonSelectDate onClick={() => setIsOpen(!modalIsOpen)}>
            FECHAR
          </ButtonSelectDate>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
