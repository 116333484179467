import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

interface UrlParamsProps {
  tipoUsuario: string;
}

interface UrlParamsContextData {
  urlParams: UrlParamsProps;
  setUrlParams: (params: UrlParamsProps) => void;
}

interface UrlParamsProviderProps {
  children: ReactNode;
}

const UrlParamsContext = createContext<UrlParamsContextData>(
  {} as UrlParamsContextData
);

export function UrlParamsProvider({ children }: UrlParamsProviderProps) {
  const location = useLocation();
  const [urlParams, setUrlParams] = useState<UrlParamsProps>({
    tipoUsuario: "",
  });

  function updatePageUrl(queryParams: URLSearchParams) {
    const urlWithoutSearchParams = window.location.href.split("?")[0];
    const newUrl = `${urlWithoutSearchParams}?${queryParams.toString()}`;
    window.history.pushState(null, "", newUrl);
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tipoUsuario = queryParams.get("tipoUsuario");

    if (urlParams.tipoUsuario && !tipoUsuario) {
      queryParams.set("tipoUsuario", urlParams.tipoUsuario);
      updatePageUrl(queryParams);
      return;
    }

    if (tipoUsuario && tipoUsuario === "C0ob123Intern0") {
      if (tipoUsuario !== urlParams.tipoUsuario) {
        setUrlParams({ tipoUsuario });
        updatePageUrl(queryParams);
        return;
      }

      return;
    }
  }, [urlParams, location]);

  return (
    <UrlParamsContext.Provider value={{ urlParams, setUrlParams }}>
      {children}
    </UrlParamsContext.Provider>
  );
}

export function useUrlParams() {
  return useContext(UrlParamsContext);
}
