import { useField } from "@unform/core";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { optionSelected } from "./constants";
import { InputItem, Select } from "./styles";
import { InputProps } from "./types";

const Input = ({ name, placeholder, id, type }: InputProps) => {
  const { fieldName, registerField, error, defaultValue } = useField(name);
  const [selectedOption] = useState("parentesco");
  const inputRef = useRef(null);

  const mask_phone = (input: SyntheticEvent<HTMLInputElement>) => {
    let value = input.currentTarget.value;

    let result = value.replace(/\D/g, "");
    result = result.replace(/^0/, "");

    result = result.replace(/(\d{2})(\d{1})/, "($1) $2");
    result = result.replace(/(\d{5})(\d{1})/, "$1-$2");

    input.currentTarget.value = result;
  };

  const mask_phoneFix = (input: SyntheticEvent<HTMLInputElement>) => {
    let value = input.currentTarget.value;

    let result = value.replace(/\D/g, "");
    result = result.replace(/^0/, "");

    result = result.replace(/(\d{2})(\d)/, "($1) $2");
    result = result.replace(/(\d)(\d{4})$/, "$1-$2");
    input.currentTarget.value = result;
  };

  const mask_cpf_fix = (input: SyntheticEvent<HTMLInputElement>) => {
    input.currentTarget.value = input.currentTarget.value.replaceAll(
      /[^0-9]/g,
      ""
    );
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 2,
        marginTop: 2,
      }}
    >
      {name !== "parentUser" &&
        name !== "phoneRes" &&
        name !== "phoneCel" &&
        name !== "cpfUser" && (
          <InputItem
            type={type}
            ref={inputRef}
            id={id}
            required
            defaultValue={defaultValue}
            placeholder={placeholder}
            name={name}
            error={error}
          />
        )}
      {name === "cpfUser" && (
        <InputItem
          type={type}
          ref={inputRef}
          id={id}
          required
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={mask_cpf_fix}
          maxLength={11}
          name={name}
          error={error}
        />
      )}

      {name === "phoneRes" && (
        <InputItem
          type={type}
          ref={inputRef}
          id={id}
          required
          defaultValue={defaultValue}
          placeholder={placeholder}
          name={name}
          onChange={mask_phoneFix}
          maxLength={14}
          error={error}
        />
      )}

      {name === "phoneCel" && (
        <InputItem
          type={type}
          ref={inputRef}
          id={id}
          required
          defaultValue={defaultValue}
          placeholder={placeholder}
          name={name}
          onChange={mask_phone}
          maxLength={15}
          error={error}
        />
      )}

      {name === "parentUser" && (
        <Select
          name={name}
          id={id}
          ref={inputRef}
          defaultValue={selectedOption}
          error={error}
        >
          {optionSelected.map((option) => (
            <option key={option.value}>{option.label}</option>
          ))}
        </Select>
      )}

      {error && (
        <span style={{ paddingLeft: 10, fontSize: 12, color: "red" }}>
          {error}
        </span>
      )}
    </div>
  );
};

export default Input;
