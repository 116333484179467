import styled, { keyframes }  from "styled-components";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  max-height: 100%;
`;

export const ModalHeader = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
  gap: 1rem;
  padding: 0.65rem 1rem;
  background-color: #f6f6f6;
  border-bottom: 2px solid var(--gray600);

  @media screen and (max-width: 990px) {
    gap: 0.35rem;
    border-bottom: 1px solid var(--gray400);
  }
`;

export const ButtonCloseModal = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: 0;
  padding: 0;

  svg {
    display: block;
    width: 24px;
    height: 24px;
  }
`;

export const ReservationInfo = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid var(--gray200);

  @media screen and (max-width: 990px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

export const InfoContent = styled.div`
  flex-grow: 1;
  max-width: 100px;

  sub {
    font-size: 0.75rem;
    font-weight: 500;
    color: var(--gray500);
  }

  p {
    font-size: 1rem;
    font-weight: 700;
    color: var(--purple600);
    margin: 0;
  }

  &:nth-of-type(2) {
    margin: 10px 25px;
    padding: 0 15px;
    border-left: 1px solid var(--gray200);
    border-right: 1px solid var(--gray200);
    box-sizing: content-box;
  }

  @media screen and (max-width: 990px) {
    margin: 0;
  }
`;

export const ModalHeaderTilte = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--purple600);
  margin: 0;
`;

export const ModalBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SelectAccommodation = styled.div`
  padding: 0.45rem 3.6rem;
  border-bottom: 2px solid var(--gray600);

  @media screen and (max-width: 990px) {
    padding: 0.65rem 1rem;
    border-bottom: 1px solid var(--gray400);

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const SelectAccommodationTitle = styled.h3`
  font-size: 1rem;
  font-weight: 700;
  color: var(--purple600);
  margin: 0;
  margin-bottom: 0.25rem;

  @media screen and (max-width: 990px) {
    text-align: center;
  }
`;

export const ButtonSelect = styled.button`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  min-height: 40px;
  padding: 6px 12px;
  border-radius: 25px;

  font-size: 1rem;
  line-height: 1;
  font-weight: 900;
  color: var(--gray500);
  border: 2px solid var(--gray400);
  background-color: transparent;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(218, 218, 218, 0.25);
  }

  &.select {
    color: var(--pink600);
    border-color: var(--pink600);

    &:hover {
      color: var(--pink600);
      border-color: var(--pink600);
      background-color: transparent;
    }
  }

  &.complet {
    color: var(--white);
    background-color: var(--green500);
    border-color: var(--green500);

    &:hover {
      opacity: 0.75;
    }
  }

  & + & {
    margin-left: 15px;
  }
`;

export const LabelsContainer = styled.div`
  padding: 1rem;
`;

export const Label = styled.label`
  cursor: pointer;
  position: relative;
  display: flex;
  border-radius: 12px;
  background-color: transparent;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(75, 72, 183, 0.04);
  }

  & + & {
    margin-top: 1rem;
  }
`;

export const Input = styled.input`
  appearance: none;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0.65rem;
  top: 0.65rem;

  &::before {
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
  }

  &::after {
    position: absolute;
    content: "";
    display: block;
    width: 50%;
    height: 50%;
    background-color: var(--purple600);
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s ease;
  }

  &:checked {
    &::before {
      border-color: var(--purple600);
    }

    &::after {
      opacity: 1;
    }
  }
`;

export const LabelContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  border: 2px solid var(--gray400);
  border-radius: 12px;
  padding: 0.65rem;

  @media screen and (max-width: 990px) {
    flex-direction: column;
  }
`;

export const LabelContent = styled.div`
  position: relative;

  &:nth-of-type(1) {
    flex-grow: 1;
    max-width: 300px;

    p {
      color: var(--purple600);
    }
  }

  &:nth-of-type(2) {
    border-left: 1px solid var(--gray400);
    border-right: 1px solid var(--gray400);
    margin: 0 15px;
    padding: 0 15px;
  }

  @media screen and (max-width: 990px) {
    &:nth-of-type(1) {
      border-bottom: 1px solid var(--gray400);
    }

    &:nth-of-type(2) {
      margin: 0;
      padding: 0;
      border: none;
    }
  }
`;

export const LabelTitle = styled.sub`
  font-size: 0.875rem;
`;

export const LabelText = styled.p`
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--black);
  margin: 0;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
`;

export const Helpers = styled.p`
  font-size: 1rem;
  color: var(--red500);
  text-align: center;
  margin: 0;
  margin-bottom: 0.35rem;

  span.success {
    color: var(--purple600);
  }
`;

export const ButtonSelectRooms = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--pink600);
  border: none;
  color: var(--white);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  border-radius: 20px;
  width: 100%;
  max-width: 400px;
  min-height: 40px;
  margin: 0 auto;

  &:disabled {
    opacity: 0.15;

    &:hover {
      background-color: var(--pink600);
    }
  }

  &:hover {
    background-color: var(--pink500);
  }
`;


export const Rotate = keyframes`
from{
    transform:rotate(0deg);
}
to{
    transform:rotate(360deg);
}
`;

export const LoadingBtn = styled.div`
  width: 30px;
  height: 30px;
  background-color: var(--pink600);
  border-radius: 50%;
  border: 4px solid var(--white);
  border-top-color: var(--pink600);
  animation: ${Rotate} 1s linear infinite;
`;