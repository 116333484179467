import {
  BodyContent,
  BtnClose,
  Container,
  FooterModal,
  HeaderBottom,
  HeaderModal,
  HeaderTop,
  IconClose,
  OverLay,
} from "./styles";
import IconCLose from "../../assets/icones/icons dense (24px)/fechar.svg";

interface ModalProps {
  close: () => void;
  title: string;
  content: string;
}

const Modal = ({ close, title, content }: ModalProps) => {
  return (
    <OverLay>
      <Container>
        <HeaderModal>
          <HeaderTop>
            <IconClose onClick={close} src={IconCLose} />
            <span>FECHAR</span>
          </HeaderTop>
          <HeaderBottom>{title}</HeaderBottom>
        </HeaderModal>
        <BodyContent>
          <div style={{ width: "100%", maxWidth: "430px" }}>{content}</div>
        </BodyContent>
        <FooterModal>
          <BtnClose onClick={close}>Fechar</BtnClose>
        </FooterModal>
      </Container>
    </OverLay>
  );
};

export default Modal;
