import axios from "axios";

export const api = axios.create({
  baseURL: "https://www.coobmais.com.br",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  },
});

export const apiGetTestimonial = axios.create({
  baseURL: "https://apiprod.coobrastur.com.br/ApiDotz/api",

  headers: {
    "Content-Type": "applicatio/json",
  },
});

export const apiAdministracao = axios.create({
  baseURL: "https://www.coobmais.com.br/wsCoobrastur/Administracao.asmx",
});

export const apiCrateTokenAuthPix = axios.create({
  baseURL: "https://apiprod.coobrastur.com.br/auth/api",
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});
