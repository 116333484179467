import * as Yup from "yup";
import { validPatternCard } from "../../../utils/validationPatternCreditCard";
import { regexNumber } from "../../Identify/constants";
import { DataPaymentProps as DataPaymentPropsFromTypes, Month } from "./types";

export type DataPaymentProps = DataPaymentPropsFromTypes;

export const validateFormChoosePaymentsInternational = async (
  data: DataPaymentProps,
  totalPayment: number
): Promise<DataPaymentProps> => {
  let numerOffSpaces = data.numeroDoCartao.replace(/\s/g, "");
  let newData = {};
  let dataOffFormat: DataPaymentProps = Object.assign(newData, {
    numeroDoCartao: numerOffSpaces,
    cvv: data.cvv,
    mes: data.mes,
    parcelas: data.parcelas,
    ano: data.ano,
    nome: data.nome,
  });

  const total = totalPayment.toLocaleString("pt-Br", {
    style: "currency",
    currency: "BRL",
  });
  const total2x = totalPayment / 2;
  const total3x = totalPayment / 3;
  const total4x = totalPayment / 4;
  const total5x = totalPayment / 5;
  const total6x = totalPayment / 6;

  const schema = Yup.object().shape({
    parcelas: Yup.string()
      .required("*Campo é obrigatório")
      .equals(
        [
          `1x ${total} (sem juros)`,
          `2x ${total2x.toLocaleString("pt-Br", {
            style: "currency",
            currency: "BRL",
          })} (sem juros)`,
          `3x ${total3x.toLocaleString("pt-Br", {
            style: "currency",
            currency: "BRL",
          })} (sem juros)`,
          `4x ${total4x.toLocaleString("pt-Br", {
            style: "currency",
            currency: "BRL",
          })} (sem juros)`,
          `5x ${total5x.toLocaleString("pt-Br", {
            style: "currency",
            currency: "BRL",
          })} (sem juros)`,
          `6x ${total6x.toLocaleString("pt-Br", {
            style: "currency",
            currency: "BRL",
          })} (sem juros)`,
        ],
        "*Opção inválida, escolha sua opção de parcelamento."
      ),
    numeroDoCartao: Yup.string()
      .required("*Campo obrigatório")
      .matches(
        validPatternCard(numerOffSpaces),
        "*Digite um numero de cartão válido"
      ),
    mes: Yup.string()
      .required("Campo obrigatório")
      .equals(
        [
          Month.Jan,
          Month.Feb,
          Month.Mar,
          Month.Apr,
          Month.May,
          Month.Jun,
          Month.Jul,
          Month.Aug,
          Month.Sep,
          Month.Oct,
          Month.Nov,
          Month.Dez,
        ],
        "Mês inválido; ex:01"
      ),
    ano: Yup.string()
      .required("*Campo obrigatório")
      .min(2, " Mínimo 2 caracteres")
      .matches(regexNumber, "*Apenas números"),
    cvv: Yup.string()
      .required("*Campo obrigatório")
      .min(3, " Mínimo 3 caracteres")
      .matches(regexNumber, "*Apenas números"),
    nome: Yup.string().required("*Campo obrigatório"),
  });

  await schema.validate(dataOffFormat, {
    abortEarly: false,
  });
  return dataOffFormat;
};
