import styled from "styled-components";

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.48);
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 900px) {
    align-items: flex-end;
  }
`;

export const Wrapperbanner = styled.div`
  position: relative;
  max-width: 600px;

  // background-color: var(--purple600);
  padding: 0;
  border-radius: 20px;

  h2 {
    color: white;

    @media screen and (max-width: 900px) {
      font-size: 20px;
    }
  }

  p {
    color: white;

    @media screen and (max-width: 900px) {
      font-size: 14px;
    }
  }

  img,
  a > img {
    border-radius: 20px;
  }

  @media screen and (max-width: 500px) {
    padding: 0;
    width: 95vw;
    transform: translateY(-80px);
    max-height: 70%;
    overflow: auto;
  }
`;

export const Link = styled.a`
  img {
    border-radius: 20px;
  }
`;

export const WrapperClose = styled.div`
  position: absolute;
  z-index: 2;
  top: 10px;
  right: 10px;
  width: 35px;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.28);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media screen and (max-width: 500px) {
    width: 20px;
    height: 20px;
  }
`;
