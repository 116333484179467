import styled from "styled-components";
interface WrapperProps {
  border?: string;
  haveBorder: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: ${({ border, haveBorder }) => (border && haveBorder ? border : 0)};
  padding: 10px;
  border-radius: 8px;
`;

interface BoxProps {
  direction: string;
  justifyContent: string;
}

export const Box = styled.div<BoxProps>`
  display: flex;
  width: 100%;
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justifyContent }) => justifyContent};
  padding-right: 10px;
`;

export const Input = styled.input`
  margin: 0;
  appearance: none;
  border: 2px solid var(--gray600);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.8s;

  &:before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--purple300);
    opacity: 0;
  }

  &:checked:before {
    opacity: 1;
    transition: all 0.5s;
  }

  &:checked {
    opacity: 1;
    transition: all 0.5s;
    border: 2px solid var(--purple300);
  }
  &:checked ~ label {
    color: var(--purple600);
  }
`;
interface LabelProps {
  paddingLeft: string;
  fontSize: string;
}
export const Label = styled.label<LabelProps>`
  color: var(--gray600);
  font-size: ${({ fontSize }) => fontSize};
  font-weight: bold;
  padding-left: ${({ paddingLeft }) => paddingLeft};
  cursor: pointer;
`;

export const WrapperDescription = styled.div`
  width: 100%;
  margin: 0;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  color: var(--black);
  font-size: 0.9rem;
  letter-spacing: 0.45px;
  padding-right: 20px;
`;
