import styled from "styled-components";

import { ErrorMessageProps, ErrorProps } from "./types";

export const WrapperInput = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  padding: 10px 0px;
`;

export const InputItem = styled.input<ErrorProps>`
  width: 100%;
  border: 0;
  border-bottom: 3px solid var(--black);
  padding: 4px 4px 0px 0px;
  outline: none;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-size: 1rem;
  background-color: transparent;

  transition: 0.3s all ease-in-out;
  &:hover {
    border-bottom: 3px solid var(--purple300);
  }
  &:hover ~ label {
    color: var(--purple300);
  }

  :focus {
    border-bottom: 3px solid
      ${({ errorText }) =>
        errorText !== undefined ? "var(--red500)" : "var(--pink600)"};
    color: ${({ errorText }) =>
      errorText !== undefined ? "var(--red500)" : "var(--pink600)"};
  }
  :valid {
    border-bottom: 3px solid
      ${({ errorText }) =>
        errorText !== undefined ? "var(--red500)" : "var(--pink600)"};
    color: ${({ errorText }) =>
      errorText !== undefined ? "var(--red500)" : "var(--pink600)"};
  }
  :focus ~ label {
    color: ${({ errorText }) =>
      errorText !== undefined ? "var(--red500)" : "var(--pink600)"};
    transform: translateY(-25px);
    font-size: 0.8rem;
  }
  :valid ~ label {
    color: ${({ errorText }) =>
      errorText !== undefined ? "var(--red500)" : "var(--pink600)"};
    transform: translateY(-25px);
    font-size: 0.8rem;
  }
`;

export const LabelInput = styled.label`
  width: 100%;
  color: var(--gray600);
  font-weight: bold;
  font-size: 1rem;
  position: absolute;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  padding: 4px 0px;
  left: 0;
  bottom: 30px;
  cursor: text;
  transition: 0.3s all ease-in-out;
`;

export const Message = styled.span<ErrorMessageProps>`
  width: 100%;
  font-size: 12px;
  color: ${({ errorText }) =>
    errorText !== undefined ? "var(--red500)" : "var(--gray500)"};
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  padding-top: 4px;
  font-weight: ${({ errorText }) => (errorText !== undefined ? "bold" : "400")};
  font-weight: 600;
`;
