import { Fragment, useEffect, useState } from "react";

import { ReactComponent as IconFechar } from "../../assets/icones/icons dense (24px)/fechar.svg";
import { ReactComponent as IconTelefone } from "../../assets/icones/icons minimal (20px)/phone.svg";
// import { ReactComponent as IconInfo } from "../../assets/icones/icons dense (24px)/informacao.svg";
import { ReactComponent as IconEmail } from "../../assets/icones/icons minimal (20px)/email.svg";
import { ReactComponent as IconWeb } from "../../assets/icones/icons minimal (20px)/website.svg";
import { ReactComponent as IconAlert } from "../../assets/icones/icons dense (24px)/aviso - red.svg";

import Modal from "react-modal";
import { Carrosel } from "../carrosel";

import {
  Alert,
  Body,
  ButtonCloseModal,
  ButtonReserve,
  ContainerContact,
  ContentAPI,
  Group,
  GroupBenefitsList,
  GroupBenefitsListItem,
  GroupLocation,
  GroupLocationMap,
  GroupLocationTitle,
  GroupTitle,
  ModalBody,
  ModalButtonAvailable,
  ModalButtonRequest,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderTilte,
  ModalWrapperGroup,
  Separator,
  Subtitle,
  Text,
  TextLink,
  TextPhone,
  Title,
} from "./styles";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { ModalDetailsProps } from "./types";
import { useCommodityRoom } from "../../store/useCommoditysRoom";
import { api } from "../../services";
import useCreateToken from "../../hooks/createToken";

export const ModalDetails = ({
  modalIsOpen,
  setIsOpen,
  openModalDateAvailable,
  openModalFormAvailability,
  hotel,
  images,
  handleReserve,
}: ModalDetailsProps) => {
  const mobile = useMediaQuery("(max-width: 990px)");
  const { data: commodityStore } = useCommodityRoom();
  const [description, setDescription] = useState("");
  const { tokenApi, createToken } = useCreateToken();

  useEffect(() => {
    createToken();
  }, [createToken]);

  useEffect(() => {
    if (modalIsOpen) {
      api
        .get("/wsCoobrastur/ConsultaHoteis.asmx/C25_1", {
          params: {
            hotCodigo: hotel.hotCodigo,
            token: tokenApi,
          },
        })
        .then((res) => {
          const { descricao } = res.data[0];

          console.log([descricao]);

          setDescription(descricao);
        })
        .catch((err) => {
          console.log(`error modalDetails: ${err}`);
        });
    }
  }, [tokenApi, hotel, modalIsOpen]);

  return (
    <Modal
      isOpen={modalIsOpen}
      style={{
        content: {
          width: "95%",
          maxWidth: "815px",
          height: mobile ? "90vh" : "80vh",
          padding: "0",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "hidden",
          zIndex: 999,
          borderRadius: "20px",
        },
        overlay: {
          zIndex: 999,
          backgroundColor: "rgba(0,0,0,0.48)",
        },
      }}
      ariaHideApp={false}
    >
      <ModalContent>
        <ModalHeader>
          <ButtonCloseModal
            type="button"
            title="fechar"
            onClick={() => setIsOpen(!modalIsOpen)}
          >
            <IconFechar />
          </ButtonCloseModal>
          <ModalHeaderTilte>{hotel.name}</ModalHeaderTilte>
        </ModalHeader>

        <ModalBody>
          <Carrosel
            options={{
              perPage: 2,
              height: "265px",
              gap: "1rem",
              pagination: false,
              breakpoints: {
                990: {
                  perPage: 1,
                  height: "224px",
                  gap: 0,
                },
              },
            }}
            width={"100%"}
            styles={{ width: "100%", borderRadius: "8px" }}
            images={images}
          />

          <ContainerContact>
            <div>
              <TextPhone>
                <IconTelefone />
                <span>{hotel.phone}</span>
              </TextPhone>
            </div>
            <div>
              <TextLink
                href={`mailto:${hotel.email}`}
                target="_blank"
                rel="noreferrer"
              >
                <IconEmail />
                <span>{hotel.email}</span>
              </TextLink>
              <TextLink href={`//${hotel.website}`} target="_blank">
                <IconWeb />
                <span>{hotel.website}</span>
              </TextLink>
            </div>
          </ContainerContact>

          <Body>
            <Separator />

            <Title>
              <IconAlert /> Importante
            </Title>

            <Text
              className="textWarnning"
              dangerouslySetInnerHTML={{ __html: hotel.obs }}
            />
          </Body>

          <Group>
            <div>
              <GroupTitle>Comodidades da Hospedagem</GroupTitle>

              <GroupBenefitsList>
                {hotel.features_apto
                  .split(",")
                  .filter((feature) => !!feature.trim())
                  .map((feature) => (
                    <GroupBenefitsListItem key={feature}>
                      {commodityStore?.commodities
                        ?.filter((commodity) => commodity.id === feature)
                        .map((commodity) => (
                          <Fragment key={commodity.id}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              width="20"
                              height="20"
                            >
                              <defs>
                                <filter id="colorMask1">
                                  <feFlood
                                    floodColor="#3a2e83"
                                    result="flood"
                                  />
                                  <feComposite
                                    in="SourceGraphic"
                                    in2="flood"
                                    operator="arithmetic"
                                    k1="1"
                                    k2="0"
                                    k3="0"
                                    k4="0"
                                  />
                                </filter>
                              </defs>
                              <image
                                width="100%"
                                height="100%"
                                xlinkHref={`https://www.coobmais.com.br/images/car_apto/${feature}.png`}
                                filter="url(#colorMask1)"
                              />
                            </svg>
                            <span>{commodity.name}</span>
                          </Fragment>
                        ))}
                    </GroupBenefitsListItem>
                  ))}
              </GroupBenefitsList>
            </div>

            <div>
              <GroupLocation>
                <GroupLocationTitle>Localização</GroupLocationTitle>
                <GroupLocationMap>
                  <iframe
                    src={encodeURI(
                      `https://maps.google.com/maps?q=${hotel.address}&t=&z=15&ie=UTF8&iwloc=&output=embed`
                    )}
                    title="Localização"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </GroupLocationMap>
              </GroupLocation>
            </div>
          </Group>

          <Body>
            <Separator />

            <Subtitle>Informações Adicionais</Subtitle>

            <Text>{hotel.description}</Text>

            <Separator />

            <ContentAPI dangerouslySetInnerHTML={{ __html: description }} />
          </Body>
        </ModalBody>

        <ModalFooter>
          {hotel.btnReserve === 1 ? (
            <ButtonReserve onClick={() => handleReserve(hotel)}>
              RESERVAR
            </ButtonReserve>
          ) : (
            <Fragment>
              <Alert className="modal">
                <IconAlert />
                Não há disponibilidade para as datas selecionadas.
              </Alert>
              <ModalWrapperGroup>
                {hotel.availability === 1 && hotel.btnReserve === 0 && (
                  <ModalButtonRequest
                    onClick={() => openModalFormAvailability(true)}
                  >
                    Solicite aqui sua disponibilidade
                  </ModalButtonRequest>
                )}
                {((hotel.btnReserve === 0 && hotel.dates === 1) ||
                  (hotel.dates === 0 &&
                    hotel.availability === 0 &&
                    hotel.btnReserve === 0)) && (
                  <ModalButtonAvailable
                    onClick={() => openModalDateAvailable(true)}
                  >
                    ver datas disponíveis
                  </ModalButtonAvailable>
                )}
              </ModalWrapperGroup>
            </Fragment>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
