import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.png";
import ArrowIcon from "../../assets/icones/icons minimal (20px)/seta_direita.svg";
import AvatarDefault from "./assets/avatar.png";
import IconMenuMobile from "../../assets/mobileMenu.svg";
import { dataInputHotelier, listMenu } from "./constants";
import IconCLoseDArk from "../../assets/icones/icons dense (24px)/fechar.svg";
import IconClose from "../../assets/icones/icons dense (24px)/fechar - white.svg";
import IconProfile from "../../assets/icones/icons dense (24px)/perfil.svg";
import IconLogout from "../../assets/icones/icons dense (24px)/logout.svg";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import {
  Avatar,
  BodyModal,
  BoxContentHeader,
  BtnAssociated,
  BtnAssociatedMobile,
  BtnLogin,
  BtnLoginMobile,
  BtnLoginUser,
  ContainerModal,
  ContainerOptionsLogin,
  ContainerSubMenu,
  Dividir,
  FormItem,
  Header as ContainerHeader,
  HeaderAreaLogin,
  HeaderAreaLogo,
  HeaderAreaMenu,
  HeaderAreaMenuMobile,
  HeaderLogo,
  HeaderMenuMobile,
  HeaderMobile,
  HeaderModal,
  HotelOption,
  ItemMenu,
  Name,
  NameMobile,
  OverLay,
  SubMenuItemBottom,
  SubMenuItemHeader,
  SubMenuItemList,
  UserOption,
  WrapperIconClose,
  WrapperIconSubmenu,
  WrapperListItemMenuMobile,
  WrapperLoggedUser,
  WrapperLoggedUserMobile,
} from "./styles";
import { api } from "../../services";
import { FormHandles, FormHelpers } from "@unform/core";
import Input from "../input";
import { Errors } from "../../pages/Identify/types";
import useCreateToken from "../../hooks/createToken";
import { FormLoginProps } from "./types";
import { FormaNameUser } from "../../utils/FormatText";
import axios from "axios";
import Cookie from "js-cookie";
import ModalFirstAccess from "../FirstAccess";
import LoginForm from "../LoginForm";
import ForgotPasswordForm from "../ForgotPasswordForm";
import { useUrlParams } from "../../hooks/useUrlParams";

interface initialDataLogin {
  user: string;
  password: string;
}

export const Header = () => {
  const { urlParams } = useUrlParams();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [modalLogin, setModalLogin] = useState<boolean>(false);
  const [modalLoginHotelier, setModalLoginHotelier] = useState<boolean>(false);
  const [subMenuOpened, setSUbMenuOpened] = useState<boolean>(false);
  const formRef = useRef<FormHandles>(null);
  const { createToken, tokenApi } = useCreateToken();
  const [nameUser, setNameUser] = useState<string>("");
  const [avatar, setAvatar] = useState<string>("");
  const idUser = localStorage.getItem("assnic");
  const idUserHotelier = localStorage.getItem("IdUserHotelier");
  const [openOptionsLogin, setOpenOptionsLogin] = useState<boolean>();
  const [errorLogin, setErroLogin] = useState<string>("");
  const navigate = useNavigate();
  const [ipUser, setIpUser] = useState<string>("");
  const [forgotPassOpen, setForGottPassOpen] = useState<boolean>(false);
  const [openFirstAccess, setOpenFirstAccess] = useState<boolean>(false);
  const [initialDataLogin, setInitialDataLogin] = useState<initialDataLogin>({
    user: "",
    password: "",
  });
  const [initialDataLoginHotelier, setInitialDataLoginHotelier] =
    useState<initialDataLogin>({
      user: "",
      password: "",
    });
  const { pathname } = useLocation();
  const [isCheckedHotelier, setIsCheckedHotelier] = useState<boolean>(false);

  const handleToggleMenu = useCallback(() => {
    setOpenMenu((old) => !old);
  }, []);

  const handleNavigate = () => {
    window.open("https://www.coobmais.com.br/lp/planos/", "_blanck");
  };

  const handleToggleModallogin = () => {
    setModalLogin(!modalLogin);
    setOpenOptionsLogin(false);
    setErroLogin("");
    setForGottPassOpen(false);
    setOpenFirstAccess(false);
  };

  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleToggleOptionsLogin = () => {
    setOpenOptionsLogin(!openOptionsLogin);
  };

  const handleLogout = useCallback(() => {
    handleToggleMenu();
    localStorage.removeItem("tokenPortalAssociado");
    localStorage.removeItem("assnic");
    localStorage.removeItem("usuarioLogin");
    localStorage.removeItem("nameUser");
    Cookie.remove("usuario_coob");
    window.location.href = `${process.env.PUBLIC_URL}/`;
    setIsLogged(false);
    setSUbMenuOpened(false);
  }, [handleToggleMenu]);

  const handleLogoutHotelier = () => {
    localStorage.removeItem("nameUserHotelier");
    localStorage.removeItem("IdUserHotelier");
    localStorage.removeItem("codigoHotelPH");
    Cookie.remove("login_PH");
    window.location.href = `${process.env.PUBLIC_URL}`;
    setIsLogged(false);
    setSUbMenuOpened(false);
  };

  const handleOpenModalHotelier = () => {
    setModalLoginHotelier(!modalLoginHotelier);
    setOpenOptionsLogin(false);
    setErroLogin("");
  };
  const setCookies = (
    token: string,
    userName: string,
    id: string,
    level: number
  ) => {
    const data = {
      currentUser: {
        token: token,
        nomePessoa: userName,
        assnic: id,
        nivelUser: level,
      },
    };
    Cookie.set("usuario_coob", JSON.stringify(data), { expires: 1 });
  };

  const handleForGotPassword = () => {
    setForGottPassOpen(true);
  };

  const handleFirstAccess = () => {
    setOpenFirstAccess(true);
    setModalLogin(false);
  };

  const handleCloseFirstAccess = () => {
    setModalLogin(false);
    setOpenFirstAccess(false);
  };

  const handleRememBerHotelier = () => {
    setIsCheckedHotelier(!isCheckedHotelier);
  };

  const setCookiesHotelier = (
    code: string,
    nameHotel: string,
    userPartnerHotel: string,
    nameUser: string,
    idUserPartnerHotel: string,
    typeUser: string,
    idContract: string,
    optionsPH: string,
    redePH: string,
    idRedePH: string,
    nameRedePh: string,
    sitPH: string
  ) => {
    const data = {
      currentUser: {
        hotCodigoPH: code,
        nomeHotelPH: nameHotel,
        usuarioPH: userPartnerHotel,
        nomeUsuarioPH: nameUser,
        idUsuarioPH: idUserPartnerHotel,
        tipoUsuarioPH: typeUser,
        idContrato: idContract,
        opcoesPermissaoPH: optionsPH,
        redePH: redePH,
        idRedePH: idRedePH,
        nomeRedePH: nameRedePh,
        situacaoPH: sitPH,
      },
    };
    Cookie.set("login_PH", JSON.stringify(data), { expires: 1 });
  };

  const handlePU1 = useCallback(
    async (assNic: string, inToken: string) => {
      try {
        const resp = await api.post(
          `/wsCoobrastur/ConsultaHoteis.asmx/PU_1_2`,
          {
            nic: assNic,
            in_token: inToken,
          }
        );
        if (resp.status === 200) {
          setNameUser(resp.data[0].assnome_razaosocial);
          setAvatar(
            !resp.data?.[0]?.imaImagem
              ? AvatarDefault
              : `${process.env.PUBLIC_URL}/images/perfil/${resp.data[0].imaImagem}`
          );
          setCookies(inToken, resp.data[0].assnome_razaosocial, assNic, 1);
          setIsLogged(true);
          return;
        }
        // console.error("PU_1_2 resp", resp.data);
      } catch (e: any) {
        // console.error("PU_1_2 catch", resp.data);
        if (
          typeof e?.response?.data === "string" &&
          e?.response?.data?.toLowerCase()?.includes("seu login expirou")
        ) {
          handleLogout();
        }
      }
    },
    [handleLogout] //remove tokenApi
  );
  const handleIsLogged = useCallback(
    async (assNic: string, inToken: string) => {
      setIsLogged(true);
      try {
        await handlePU1(assNic, inToken);
      } catch (err) {
        console.error(err);
      }
    },
    [handlePU1]
  );
  const handleSuccessLogin = async (
    { assNic }: { assNic: string },
    inToken: string
  ) => {
    await handlePU1(assNic, inToken);
    setIsLogged(true);
    handleToggleModallogin();
    debugger;
    window.location.href = `https://www.coobmais.com.br/portal_associado.html#/minhasReservas`;
  };

  const handleLoginHotelier = (data: FormLoginProps) => {
    api
      .get(`/wsCoobrastur/ConsultaHoteis.asmx/PH115`, {
        params: {
          dados: Object.values({
            t: 115,
            in_usuario: data.user,
            in_senha: data.password,
            in_ip: ipUser,
          }).join(";"),
        },
      })
      .then((resp) => {
        if (resp.data.mensagem === "Usuário inválido") {
          setErroLogin(resp.data.mensagem);
          return;
        }

        if (!isCheckedHotelier) {
          localStorage.removeItem("rememberIputHotelier");
          localStorage.removeItem("rememberLoginHotelier");
        } else {
          localStorage.setItem("rememberIputHotelier", JSON.stringify(true));
          localStorage.setItem(
            "rememberLoginHotelier",
            JSON.stringify({ user: data.user, password: data.password })
          );
        }

        setNameUser(resp.data[0].nomeUsuarioPH);
        setErroLogin("");
        setModalLoginHotelier(false);
        localStorage.setItem("nameUserHotelier", resp.data[0].nomeUsuarioPH);
        localStorage.setItem("IdUserHotelier", resp.data[0].codigoUsuarioPH);
        localStorage.setItem("codigoHotelPH", resp.data[0].codigoHotelPH);
        setCookiesHotelier(
          resp.data[0].codigoHotelPH,
          resp.data[0].nomeHotelPH,
          resp.data[0].usuarioPH,
          resp.data[0].nomeUsuarioPH,
          resp.data[0].codigoUsuarioPH,
          resp.data[0].tipoUsuarioPH,
          resp.data[0].idContrato,
          resp.data[0].opcoesPH,
          resp.data[0].redePH,
          resp.data[0].idRedePH,
          resp.data[0].nomeRedePH,
          resp.data[0].situacaoPH
        );
        setIsLogged(true);
        window.location.href =
          "https://www.coobmais.com.br/portal_hoteleiros.html#/home";
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.mensagem === "Usuário inválido") {
          setErroLogin("Usuário inválido");
          return;
        }
      });
  };

  const handleAncorItem = (item: string) => {
    navigate(`${process.env.PUBLIC_URL}/`);
    setTimeout(() => {
      document.querySelector(`#${item}`)?.scrollIntoView();
    }, 300);
    return;
  };

  const handleSubmitHotelier = async (
    data: FormLoginProps,
    { reset }: FormHelpers
  ) => {
    try {
      const schema = Yup.object().shape({
        user: Yup.string().required("campo é obrigatório"),
        password: Yup.string().required("a senha é obrigatória"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      handleLoginHotelier(data);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessage: Errors = {};
        err.inner.forEach((error) => {
          if (error.path) {
            errorMessage[error.path] = error.message;
          }
          return formRef.current?.setErrors(errorMessage);
        });
        return;
      }
    }

    formRef.current?.setErrors({});
  };

  useEffect(() => {
    createToken();
  }, [createToken]);

  useEffect(() => {
    const inToken = localStorage.getItem("tokenPortalAssociado");
    if (idUser !== null && inToken !== null) {
      handleIsLogged(idUser, inToken);
    }

    if (idUserHotelier !== null) {
      const nameUseHotelier = localStorage.getItem("nameUserHotelier");
      if (nameUseHotelier) {
        setNameUser(nameUseHotelier);
        setIsLogged(true);
        return;
      }
    }
  }, [idUser, idUserHotelier, handleIsLogged]);

  useEffect(() => {
    axios
      .get(`https://api.ipify.org/`)
      .then((resp) => {
        if (resp.status === 200) {
          setIpUser(resp.data);
          return;
        }
      })
      .catch((err) => console.error({ component: "header", error: err }));
  }, []);

  const rememberdataLogin = localStorage.getItem("rememberLogin");
  const rememberdataLoginHotelier = localStorage.getItem(
    "rememberLoginHotelier"
  );
  const rememberInputHotelier = localStorage.getItem("rememberIputHotelier");

  useEffect(() => {
    if (rememberdataLogin) {
      const dataLogin: initialDataLogin = JSON.parse(rememberdataLogin);
      setInitialDataLogin({
        user: dataLogin.user,
        password: dataLogin.password,
      });
    }
  }, [rememberdataLogin]);

  useEffect(() => {
    if (rememberdataLoginHotelier) {
      const dataLogin: initialDataLogin = JSON.parse(rememberdataLoginHotelier);
      setInitialDataLoginHotelier({
        user: dataLogin.user,
        password: dataLogin.password,
      });
    }
  }, [rememberdataLoginHotelier]);

  useEffect(() => {
    if (rememberInputHotelier) {
      const remember = JSON.parse(rememberInputHotelier);

      setIsCheckedHotelier(remember);
    }
  }, [rememberInputHotelier]);

  return (
    <Fragment>
      {modalLogin && (
        <OverLay>
          <ContainerModal>
            <HeaderModal>
              <span>
                {!forgotPassOpen ? "acessar sua conta" : "redefinir senha"}
              </span>
              <img
                src={IconCLoseDArk}
                alt="fechar-modal"
                onClick={handleToggleModallogin}
              />
            </HeaderModal>
            {!forgotPassOpen && (
              <BodyModal>
                <LoginForm
                  onSuccessLogin={handleSuccessLogin}
                  tokenApi={tokenApi}
                  initialData={initialDataLogin}
                  onClickFirstAccess={handleFirstAccess}
                  onClickForgotPassword={handleForGotPassword}
                />
              </BodyModal>
            )}

            {forgotPassOpen && (
              <BodyModal>
                <ForgotPasswordForm
                  tokenApi={tokenApi}
                  onClickLogin={() => setForGottPassOpen(false)}
                />
              </BodyModal>
            )}
          </ContainerModal>
        </OverLay>
      )}
      {openFirstAccess && <ModalFirstAccess onClose={handleCloseFirstAccess} />}
      {modalLoginHotelier && (
        <OverLay>
          <ContainerModal>
            <HeaderModal>
              <span>acesar sua conta</span>
              <img
                src={IconCLoseDArk}
                alt="fechar-modal"
                onClick={handleOpenModalHotelier}
              />
            </HeaderModal>
            <BodyModal>
              <FormItem
                ref={formRef}
                onSubmit={handleSubmitHotelier}
                initialData={initialDataLoginHotelier}
              >
                {dataInputHotelier.map((item) => (
                  <Input
                    name={item.name}
                    label={item.label}
                    key={item.id}
                    id={item.id}
                    description={item.description}
                    type={item.type}
                    // maxLength={item?.maxLength}
                    // onChange={item?.onChange}
                  />
                ))}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <input
                    type="checkbox"
                    id="remember"
                    name="remember"
                    style={{ cursor: "pointer" }}
                    onChange={handleRememBerHotelier}
                    checked={isCheckedHotelier}
                  />
                  <label
                    htmlFor="remember"
                    style={{
                      cursor: "pointer",
                      color: "var(--purple600)",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    lembrar-me
                  </label>
                </div>
                {errorLogin !== "" && (
                  <span style={{ color: "var(--red600)" }}>{errorLogin}</span>
                )}

                <div style={{ width: "100%" }}>Esqueceu a senha?</div>
                <div>
                  Solicite a nova senha enviando uma mensagem para
                  <strong> suportehoteis@coobmais.com.br</strong>
                </div>

                <BtnLoginUser type="submit">acessar conta</BtnLoginUser>
                <p
                  style={{
                    margin: 0,
                    color: "var(--gray600)",
                    fontSize: "0.9rem",
                  }}
                >
                  Seja um Hotel Credenciado Coobrastur
                </p>
                <a
                  href="https://www.coobmais.com.br/info.html#/hotel-cadastro"
                  target="_blanck"
                  style={{
                    color: "var(--pink500)",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  Saiba mais
                </a>
              </FormItem>
            </BodyModal>
          </ContainerModal>
        </OverLay>
      )}
      <ContainerHeader>
        <BoxContentHeader>
          {pathname === `${process.env.PUBLIC_URL}/` && (
            <HeaderAreaLogo>
              <a href={`${process.env.PUBLIC_URL}/`}>
                <HeaderLogo
                  src={Logo}
                  alt="logo-coob+"
                  onClick={handleGoToTop}
                  title={urlParams?.tipoUsuario}
                />
              </a>
            </HeaderAreaLogo>
          )}
          {pathname !== `${process.env.PUBLIC_URL}/` && (
            <HeaderAreaLogo>
              <a href={`${process.env.PUBLIC_URL}/`}>
                <HeaderLogo
                  src={Logo}
                  alt="logo-coob+"
                  title={urlParams?.tipoUsuario}
                />
              </a>
            </HeaderAreaLogo>
          )}
          <HeaderAreaMenu>
            {listMenu.map((element) => (
              <ItemMenu key={element?.item}>
                {element?.typeLink === "link" && (
                  <Link to={`${process.env.PUBLIC_URL}/${element?.link}`}>
                    {element?.item}
                  </Link>
                )}
                {element?.typeLink === "a" && (
                  <a href={`${element.link}`} target="_blanck">
                    {element.item}
                  </a>
                )}
                {element?.typeLink === "span" && (
                  <span onClick={() => handleAncorItem(element!.item)}>
                    {element?.item}
                  </span>
                )}
              </ItemMenu>
            ))}
          </HeaderAreaMenu>

          <HeaderAreaLogin>
            {!isLogged && (
              <Fragment>
                <BtnAssociated onClick={handleNavigate}>Assine</BtnAssociated>
                <BtnLogin onClick={handleToggleOptionsLogin}>
                  Acessar conta
                </BtnLogin>
                {openOptionsLogin && (
                  <ContainerOptionsLogin>
                    <UserOption onClick={handleToggleModallogin}>
                      Usuário
                    </UserOption>
                    <HotelOption onClick={handleOpenModalHotelier}>
                      Hoteleiro
                    </HotelOption>
                  </ContainerOptionsLogin>
                )}
              </Fragment>
            )}
            {isLogged && (
              <WrapperLoggedUser>
                <WrapperIconSubmenu opened={subMenuOpened}>
                  <img
                    src={ArrowIcon}
                    alt="submenu"
                    onClick={() => setSUbMenuOpened(!subMenuOpened)}
                  />
                </WrapperIconSubmenu>

                <Dividir />
                {/* <IconHeart /> */}
                <Avatar
                  src={avatar !== "" ? avatar : AvatarDefault}
                  alt="avatar-usuario"
                />
                {nameUser !== "" && <Name>{FormaNameUser(nameUser)}</Name>}
                {subMenuOpened && (
                  <ContainerSubMenu>
                    {idUser !== null && (
                      <Fragment>
                        <SubMenuItemHeader>
                          <img src={IconProfile} alt="perfil" />

                          <a
                            href="https://www.coobmais.com.br/portal_associado.html#/perfil"
                            target="_blanck"
                          >
                            Meus dados
                          </a>
                        </SubMenuItemHeader>
                        <SubMenuItemList
                          href="https://www.coobmais.com.br/portal_associado.html#/minhasReservas"
                          target="_blanck"
                        >
                          Consulta de Reservas
                        </SubMenuItemList>
                        <SubMenuItemList
                          href="https://www.coobmais.com.br/portal_associado.html#/extrato"
                          target="_blanck"
                        >
                          Consulta de Extrato
                        </SubMenuItemList>
                        <SubMenuItemList
                          href="https://www.coobmais.com.br/portal_associado.html#/boleto"
                          target="_blanck"
                        >
                          Boleto
                        </SubMenuItemList>
                        <SubMenuItemList
                          href="https://www.coobmais.com.br/portal_associado.html#/indiquemais"
                          target="_blanck"
                        >
                          Indicação premiada
                        </SubMenuItemList>
                        <SubMenuItemList
                          href="https://www.coobmais.com.br/portal_associado.html#/favoritos"
                          target="_blanck"
                        >
                          Favoritos
                        </SubMenuItemList>
                        <SubMenuItemBottom>
                          <img
                            src={IconLogout}
                            onClick={handleLogout}
                            alt="sair"
                          />
                          <span onClick={handleLogout}>Sair</span>
                        </SubMenuItemBottom>
                      </Fragment>
                    )}
                    {idUserHotelier !== null && (
                      <Fragment>
                        <SubMenuItemHeader>
                          {/* <img src={IconProfile} alt="perfil" /> */}

                          <a
                            href="https://www.coobmais.com.br/portal_Hoteleiros.html#/home"
                            target="_blanck"
                          >
                            Home
                          </a>
                        </SubMenuItemHeader>
                        <SubMenuItemList
                          href="https://www.coobmais.com.br/portal_Hoteleiros.html#/comprovantes"
                          target="_blanck"
                        >
                          Financeiro
                        </SubMenuItemList>
                        <SubMenuItemList
                          href="https://www.coobmais.com.br/portal_Hoteleiros.html#/relatorio-financeiro"
                          target="_blanck"
                        >
                          Relatórios
                        </SubMenuItemList>
                        <SubMenuItemList
                          href="https://www.coobmais.com.br/portal_Hoteleiros.html#/aumento-bloqueio"
                          target="_blanck"
                        >
                          Informações Contratuais
                        </SubMenuItemList>
                        <SubMenuItemList
                          href="https://www.coobmais.com.br/portal_Hoteleiros.html#/aumento-bloqueio"
                          target="_blanck"
                        >
                          Perguntas frequentes
                        </SubMenuItemList>
                        <SubMenuItemBottom>
                          <img
                            src={IconLogout}
                            onClick={handleLogoutHotelier}
                            alt="sair"
                          />
                          <span onClick={handleLogoutHotelier}>Sair</span>
                        </SubMenuItemBottom>
                      </Fragment>
                    )}
                  </ContainerSubMenu>
                )}
              </WrapperLoggedUser>
            )}
          </HeaderAreaLogin>
        </BoxContentHeader>
      </ContainerHeader>
      <HeaderMobile>
        <a href={`${process.env.PUBLIC_URL}/`}>
          <HeaderLogo
            src={Logo}
            alt="logo-coob+"
            onClick={handleGoToTop}
            title={urlParams?.tipoUsuario}
          />
        </a>
        <HeaderAreaMenuMobile>
          <img
            src={IconMenuMobile}
            alt="menu-mobile"
            onClick={handleToggleMenu}
          />
        </HeaderAreaMenuMobile>
        {openMenu && (
          <HeaderMenuMobile>
            <WrapperIconClose>
              <img
                src={IconClose}
                alt="fechar menu"
                onClick={handleToggleMenu}
              />
            </WrapperIconClose>
            <WrapperListItemMenuMobile>
              {listMenu.map((element) => (
                <ItemMenu key={element?.item} onClick={handleToggleMenu}>
                  {element?.typeLink === "link" && (
                    <Link to={`${process.env.PUBLIC_URL}/${element.link}`}>
                      {element.item}
                    </Link>
                  )}
                  {element?.typeLink === "a" && (
                    <a href={`${element.link}`} target="_blanck">
                      {element.item}
                    </a>
                  )}
                  {element?.typeLink === "span" && (
                    <span onClick={() => handleAncorItem(element!.item)}>
                      {element?.item}
                    </span>
                  )}
                </ItemMenu>
              ))}
              {!isLogged && (
                <Fragment>
                  <BtnAssociatedMobile onClick={handleNavigate}>
                    Assine
                  </BtnAssociatedMobile>
                  <BtnLoginMobile onClick={handleToggleOptionsLogin}>
                    Acessar conta
                  </BtnLoginMobile>
                  {openOptionsLogin && (
                    <ContainerOptionsLogin>
                      <UserOption onClick={handleToggleModallogin}>
                        Usuário
                      </UserOption>
                      <HotelOption onClick={handleOpenModalHotelier}>
                        Hoteleiro
                      </HotelOption>
                    </ContainerOptionsLogin>
                  )}
                </Fragment>
              )}

              {isLogged && (
                <WrapperLoggedUserMobile>
                  <Avatar
                    src={avatar !== "" ? avatar : AvatarDefault}
                    alt="avatar-usuario"
                  />
                  <NameMobile>{nameUser}</NameMobile>
                  <a
                    href="https://www.coobmais.com.br/portal_associado.html#/perfil"
                    target="_blanck"
                  >
                    Meus dados
                  </a>
                  <a
                    href="https://www.coobmais.com.br/portal_associado.html#/minhasReservas"
                    target="_blanck"
                  >
                    Consulta de reservas
                  </a>
                  <a
                    href="https://www.coobmais.com.br/portal_associado.html#/extrato"
                    target="_blanck"
                  >
                    Consulta de extrato
                  </a>
                  <a
                    href="https://www.coobmais.com.br/portal_associado.html#/boleto"
                    target="_blanck"
                  >
                    Boleto
                  </a>
                  <a
                    href="https://www.coobmais.com.br/portal_associado.html#/indiquemais"
                    target="_blanck"
                  >
                    Indique Mais
                  </a>

                  <a
                    href="https://www.coobmais.com.br/portal_associado.html#/favoritos"
                    target="_blanck"
                  >
                    Favoritos
                  </a>

                  <span onClick={handleLogout}>Sair</span>
                </WrapperLoggedUserMobile>
              )}
            </WrapperListItemMenuMobile>
          </HeaderMenuMobile>
        )}
      </HeaderMobile>
    </Fragment>
  );
};

export default memo(Header);
