import styled from "styled-components";
import { CountSlideProps, WrapperProps } from "./types";

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  width: ${({ styles }) => styles?.width};
  max-height: 100%;

  & > div:first-child {
    height: 100%;
  }

  .splide {
    height: 100%;
  }

  .splide__slide {
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: ${({ styles }) => styles?.borderRadius};
    }
  }

  .splide__track {
    height: 100%;
  }

  .splide__arrow {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--purple400);
    border: 0;
    opacity: 0.5;

    &:hover {
      opacity: 1;

      &:disabled {
        opacity: 0.5;
      }
    }

    svg {
      path {
        fill: var(--white);
      }
    }

    &.splide__arrow--prev {
      left: 5px;

      svg {
        transform: rotate(180deg);
      }
    }

    &.splide__arrow--next {
      right: 5px;
    }

    @media screen and (max-width: 990px) {
      display: none;
    }
  }
`;

export const CountSlide = styled.div<CountSlideProps>`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  z-index: 2;
  border-radius: 4px;
  background-color: var(--black);
  color: var(--white);
  padding: 2px 10px;

  font-size: 0.75rem;
  font-weight: 700;

  ${({ options }) =>
    options?.perPage &&
    options?.perPage > 1 && {
      left: "8px",
      transform: "none",
    }};
`;
