import { InputProps } from "../../input";

export const listMenu: {
  item: string;
  link: string;
  typeLink: "link" | "a" | "span";
}[] = [
  {
    item: "Como funciona",
    link: "comofunciona",
    typeLink: "link",
  },
  {
    item: "Promoções",
    link: "Promoções",
    typeLink: "span",
  },
  {
    item: "Indique+",
    link: "https://www.coobmais.com.br/lp/indiquemais/",
    typeLink: "a",
  },
  {
    item: "Revista de Hotéis",
    link: "https://www.coobmais.com.br/revista_hoteis/index.html",
    typeLink: "a",
  },
  {
    item: "Agência de viagem",
    link: "https://www.coobmais.com.br/lp/agencia-de-viagens/",
    typeLink: "a",
  },
  {
    item: "Ajuda",
    link: "ajuda",
    typeLink: "span",
  },
];

export const dataInputHotelier: InputProps[] = [
  {
    name: "user",
    label: "Usuário",
    id: "usuario",
    description: "Usuário do portal",
    type: "text",
  },
  {
    name: "password",
    label: "Senha",
    id: "senha",
    description: "Mesma senha do Portal do Usuário",
    type: "password",
  },
];

export const dataInputForgottPass: InputProps[] = [
  {
    name: "email",
    label: "email",
    id: "email",
    description: "Insira seu-email",
    type: "email",
  },
];
