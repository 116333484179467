import { contentSectionBenefits } from "./constants";
import { WrapperContentInfos, Container } from "./styles";

const BeneFitys = () => {
  return (
    <Container>
      {contentSectionBenefits.map(({ id, icon, description }) => (
        <WrapperContentInfos key={id}>
          <img src={icon} alt="exclusividade de seguro" width={80} />
          <p>{description}</p>
        </WrapperContentInfos>
      ))}
    </Container>
  );
};

export default BeneFitys;
