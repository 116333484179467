import { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { RadioProps } from "../types";
import {
  ErrorText,
  LabelText,
  WrapperInputs,
  InputRadio,
  LabelRadio,
  BoxInputradio,
  WrapperRadiosOptions,
} from "../styles";

export default function Radio({
  name,
  label,
  options,
  classNameWrapper,
  required,
}: RadioProps) {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = "", error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue(refs) {
        const checked = refs.find((ref: HTMLInputElement) => ref.checked);

        return checked ? checked.value : null;
      },
      setValue(refs, value) {
        const item = refs.find((ref: HTMLInputElement) => ref.value === value);

        if (item) {
          item.checked = true;
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <WrapperInputs className={classNameWrapper} id={name}>
      <LabelText>
        {label}
        {required ? "*" : ""}
      </LabelText>
      <WrapperRadiosOptions>
        {options.map((option, index) => (
          <BoxInputradio key={option.value}>
            <InputRadio
              ref={(elRef) => {
                if (!!elRef) inputRefs.current[index] = elRef;
              }}
              type="radio"
              name={fieldName}
              id={String(option.value)}
              value={option.value}
              defaultChecked={defaultValue === option.value}
            />
            <LabelRadio htmlFor={String(option.value)}>
              {option.label}
            </LabelRadio>
          </BoxInputradio>
        ))}
      </WrapperRadiosOptions>
      {error && <ErrorText>{error}</ErrorText>}
    </WrapperInputs>
  );
}
