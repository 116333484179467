import { Form } from "@unform/web";
import styled from "styled-components";

export const ContainerForm = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: flex-start;
`;

export const Paragraph = styled.p`
  width: 100%;
  max-width: 514px;
  margin: 0;
  font-size: 0.8rem;
  text-align: center;

  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";

  @media (max-width: 768px) {
    max-width: 360px;
  }
`;

export const FormItem = styled(Form)`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 514px;
`;

export const WrapperInputs = styled.div`
  width: 100%;
  padding-right: 35px;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 500px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

export const WrapperBtnReserve = styled.div`
  width: 100%;
  max-width: 514px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
