import { FormHelpers } from "@unform/core";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import ReCaptcha from "react-google-recaptcha";
import { Subtitle } from "../styles";
import {
  optionsPaymentcard,
  optionsPayments,
  optionsPaymentcard2,
  optionsPaymentcard3,
  optionsPaymentcard4,
  optionsPaymentcard5,
  optionsPaymentcard6,
} from "./constants";
import { regexNumber } from "../../../Identify/constants";
import FieldsPaymentCard from "./FieldsPaymentcard";
import { Errors } from "../../../Identify/types";
import { ChoosePaymentsProps, DataPaymentProps, Month } from "./types";
import {
  AreaInfoPayment,
  BoxPayment,
  FormCard,
  LinkKnowMore,
  ParagraphInfoPayment,
  TitlePayment,
  TotalvalueArea,
  Paragraph,
  WrapperRecaptcha,
} from "./styles";
import { validPatternCard } from "../../../../utils/validationPatternCreditCard";
import { useChoosePayment } from "../../../../store/useChoosePayment";
import { api } from "../../../../services";
import { removeHtmlInString } from "../../../../utils/FormatText";
import { useNavigate } from "react-router-dom";
import { ModalAlert } from "../../../../components/modalAlert";
import { MessageAlertProps } from "../../../../components/modalAlert/types";
import Loading from "../../../../components/loading";
import InputRadioPayments from "../../../../components/InputRadioPayments";
import { FieldPaymentPropsWithoutTotal } from "./FieldsPaymentcard/types";
import { useUrlParams } from "../../../../hooks/useUrlParams";

const initialData = {
  parcelas: "Número de parcelas",
};

const ChoosePayments = ({
  formRef,
  value,
  confirmationTickets,
  showTotalValue,
  resetRadio = false,
  setResetRadio = () => {},
  onFinish = () => {},
  onErrorInResLocalizador = () => {},
}: ChoosePaymentsProps) => {
  const [loading, setLoading] = useState(false);
  const [openPaymentCard, setOpenPaymentcard] = useState<boolean>(false);
  // const [nameCart, setNamecart] = useState<string>("");
  const setName = useChoosePayment((state) => state.setName);
  const [email, setEmail] = useState<string>("");
  const [phoneUser, setPhoneUser] = useState<string>("");
  const token = localStorage.getItem("token");
  // const nameUser = localStorage.getItem("nameUser");
  const userId = localStorage.getItem("assnic");
  const [errorPayment, setErrorPayment] = useState<string>("");
  const [messageModalError, setMessageModalError] = useState<MessageAlertProps>(
    {} as MessageAlertProps
  );
  const navigate = useNavigate();

  const handleCloseModalError = () => {
    setMessageModalError({} as MessageAlertProps);
  };

  const [captchaBlock, setCaptchaBlock] = useState<boolean>(true);
  const { urlParams } = useUrlParams();

  const handleRecaptcha = (value: string | null) => {
    if (value !== null) {
      setCaptchaBlock(false);
      return;
    }
  };

  const handleOpenAreaPaymentcard = async (item: any) => {
    setLoading(true);
    await confirmationTickets();
    setLoading(false);

    setName(item.name);

    if (item.name === "cartao") {
      setOpenPaymentcard(true);
      return;
    }
    setOpenPaymentcard(false);
  };

  const toDoPaymentWithcard = (
    numberOfCard: string,
    month: string,
    year: string,
    portion: string,
    cvc: string,
    titular: string,
    reset?: (data?: Record<string, any>) => void
  ) => {
    const ddd = phoneUser.substring(0, 2);
    const numberOfportion = portion.substring(0, 1);
    const total = value.toFixed(2).toString();
    const phoneWIthouDDD = phoneUser.substring(2, phoneUser.length);
    const data = {
      EmailPessoal: email,
      resTelefone: phoneWIthouDDD,
      resTelefoneDDD: ddd,
      disCodigo: null,
    };

    const dataPayment = {
      TipoPgto: 1,
      Titular: titular,
      Numero: numberOfCard,
      fmCodigo: 2,
      Mes: month,
      Ano: `20${year}`,
      CVC: cvc,
      Parcelas: numberOfportion,
      total: total,
    };

    // Pagamento caso tenha valor a ser pago no cartão
    api
      .get("/wsCoobrastur/Hotel.asmx/Pagamento_S_Hotel", {
        params: {
          dados: Object.values({
            t: "Pagamento_S_Hotel",
            in_token: token,
            reservaTempJson: JSON.stringify([data]),
            terceirosTempJson: JSON.stringify([]),
            acompanhantesTempJson: JSON.stringify([]),
            pagamentoJson: JSON.stringify([dataPayment]),
          }).join(";"),
        },
      })
      .then((resp) => {
        if (
          resp.data ===
          "Pagamento não autorizado.<br>Contate sua operadora.<br>Reserva não efetuada."
        ) {
          setErrorPayment(
            "Pagamento não autorizado. Contate sua operadora. Reserva não efetuada."
          );
          setMessageModalError({
            title: "Pagamento não autorizado.",
            body: "Contate sua operadora. Reserva não efetuada",
          });
          onFinish();
          return;
        }
        if (resp.data.situacao === 1 || resp.data.situacao === "1") {
          if (!resp?.data?.resLocalizador) {
            onErrorInResLocalizador();
            onFinish();
            return;
          }
          localStorage.setItem("resLocale", resp.data.resLocalizador);
          const resLocalizador = resp?.data?.resLocalizador || "";
          navigate(`${process.env.PUBLIC_URL}/reserva/${resLocalizador}`, {
            state: {
              resLocalizador: String(resp.data.resLocalizador),
            },
          });
          reset?.();
          onFinish();
          return;
        }
        setMessageModalError({
          title: "Ops! Algo deu Errado.",
          htmlBody: !resp?.data?.mensagem ? resp.data : resp.data.mensagem,
        });
        onFinish();
      })
      .catch((e: any) => {
        const messageError =
          typeof e?.response?.data === "string"
            ? e?.response?.data
            : e?.message || "Algo deu Errado, tente mais tarde";

        if (messageError?.includes("Pagamento não autorizado")) {
          setErrorPayment(
            "Pagamento não autorizado. Contate sua operadora. Reserva não efetuada."
          );
          setMessageModalError({
            title: "Pagamento não autorizado.",
            body: "Contate sua operadora. Reserva não efetuada",
          });
          onFinish();
          return;
        }

        setErrorPayment("Algo deu errado, tente mais tarde");
        setMessageModalError({
          title: "Ops! Algo deu Errado.",
          htmlBody: messageError,
        });
        console.error({
          component: "choosePaymentCredCard",
          messageError,
          error: e,
        });
        onFinish();
      });
  };

  const handleSubmit = async (
    data: DataPaymentProps,
    { reset }: FormHelpers
  ) => {
    setErrorPayment("");
    if (captchaBlock && !urlParams?.tipoUsuario) {
      setErrorPayment("Por favor, complete o reCaptcha!");
      onFinish();
      return;
    }
    let numberOfcard = data.numeroDoCartao;
    let numerOffSpaces = numberOfcard.replace(/\s/g, "");
    let newData = {};
    let dataOffFormat = Object.assign(newData, {
      numeroDoCartao: numerOffSpaces,
      cvv: data.cvv,
      mes: data.mes,
      parcelas: data.parcelas,
      ano: data.ano,
      nome: data.nome,
    });

    const total = value.toLocaleString("pt-Br", {
      style: "currency",
      currency: "BRL",
    });
    const total2x = value / 2;
    const total3x = value / 3;
    const total4x = value / 4;
    const total5x = value / 5;
    const total6x = value / 6;

    try {
      const schema = Yup.object().shape({
        parcelas: Yup.string()
          .required("*Campo é obrigatório")

          .equals(
            [
              `1x ${total} (sem juros)`,
              `2x ${total2x.toLocaleString("pt-Br", {
                style: "currency",
                currency: "BRL",
              })} (sem juros)`,
              `3x ${total3x.toLocaleString("pt-Br", {
                style: "currency",
                currency: "BRL",
              })} (sem juros)`,
              `4x ${total4x.toLocaleString("pt-Br", {
                style: "currency",
                currency: "BRL",
              })} (sem juros)`,
              `5x ${total5x.toLocaleString("pt-Br", {
                style: "currency",
                currency: "BRL",
              })} (sem juros)`,
              `6x ${total6x.toLocaleString("pt-Br", {
                style: "currency",
                currency: "BRL",
              })} (sem juros)`,
            ],
            "*Opção inválida, escolha sua opção de parcelamento."
          ),
        numeroDoCartao: Yup.string()
          .required("*Campo obrigatório")
          .matches(
            validPatternCard(numerOffSpaces),
            "*Digite um numero de cartão válido"
          ),
        mes: Yup.string()
          .required("Campo obrigatório")
          .equals(
            [
              Month.Jan,
              Month.Feb,
              Month.Mar,
              Month.Apr,
              Month.May,
              Month.Jun,
              Month.Jul,
              Month.Aug,
              Month.Sep,
              Month.Oct,
              Month.Nov,
              Month.Dez,
            ],
            "Mês inválido; ex:01"
          ),
        nome: Yup.string().required("*Campo obrigatório"),
        ano: Yup.string()
          .required("*Campo obrigatório")
          .min(2, " Mínimo 2 caracteres")
          .matches(regexNumber, "*Apenas números"),
        cvv: Yup.string()
          .required("*Campo obrigatório")
          .min(3, " Mínimo 3 caracteres")
          .matches(regexNumber, "*Apenas números"),
      });

      await schema.validate(dataOffFormat, {
        abortEarly: false,
      });
      toDoPaymentWithcard(
        numerOffSpaces,
        data.mes,
        data.ano,
        data.parcelas,
        data.cvv,
        data.nome,
        reset
      );
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessage: Errors = {};

        err.inner.forEach((error) => {
          if (error.path) {
            errorMessage[error.path] = error.message;
          }
          return formRef.current?.setErrors(errorMessage);
        });
        onFinish();
        return;
      }
      onFinish();
    }
    formRef.current?.setErrors({});
  };

  const numberOfPortion = (): FieldPaymentPropsWithoutTotal[] => {
    if (value < 100) {
      return optionsPaymentcard;
    }

    if (value >= 100 && value < 150) {
      return optionsPaymentcard2;
    }

    if (value >= 150 && value < 200) {
      return optionsPaymentcard3;
    }

    if (value >= 200 && value < 250) {
      return optionsPaymentcard4;
    }

    if (value >= 250 && value < 300) {
      return optionsPaymentcard5;
    }

    return optionsPaymentcard6;
  };

  useEffect(() => {
    const inToken = localStorage.getItem("tokenPortalAssociado");
    if (!!userId && !!inToken) {
      api
        .get(`/wsCoobrastur/ConsultaHoteis.asmx/PU_1_2`, {
          params: { nic: userId, in_token: inToken },
        })
        .then((resp) => {
          if (resp.status === 200) {
            setEmail(resp.data[0].assEmailPessoal);
            setPhoneUser(
              `${resp.data[0].assNumCelularDDD}${resp.data[0].assNumCelular}`
            );
            const dataUser = {
              ddd: resp.data[0].assNumCelularDDD,
              cel: resp.data[0].assNumCelular,
              birthday: resp.data[0].assDTNascimento,
            };
            localStorage.setItem("completeForm", JSON.stringify(dataUser));
            return;
          }
          alert("algo deu errado, recarregue á pagina");
        })
        .catch((e) => console.error({ component: "Choosepayment", error: e }));
    }
  }, [userId]);

  useEffect(() => {
    if (showTotalValue === false) {
      setName("");
      setOpenPaymentcard(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTotalValue]);

  return (
    <BoxPayment>
      <TitlePayment>PAGAMENTO</TitlePayment>
      <AreaInfoPayment>
        <ParagraphInfoPayment>
          O valor adicional é cobrado por extras como camas adicionais, períodos
          fetivos, denominadas Pacotes, refeições extras em hotéis com regimes
          de All Inclusive ou Meia pensão ou pensão completa.
        </ParagraphInfoPayment>
        <LinkKnowMore href={`${process.env.PUBLIC_URL}/Ajuda`} target="_blank">
          Saiba mais
        </LinkKnowMore>

        {loading && <Loading />}

        {showTotalValue && (
          <TotalvalueArea>
            <span>Valor total a pagar:</span>
            <strong>
              {value.toLocaleString("pt-Br", {
                style: "currency",
                currency: "BRL",
              })}
            </strong>
          </TotalvalueArea>
        )}

        <Subtitle>Escolha uma forma de pagamento</Subtitle>
        <InputRadioPayments
          fontSize="1rem"
          direction="row"
          justifyContent="flex-start"
          paddingLeft="18px"
          options={optionsPayments}
          handleSelected={handleOpenAreaPaymentcard}
          resetRadio={resetRadio}
          setResetRadio={setResetRadio}
        />
        <FormCard
          onSubmit={handleSubmit}
          ref={formRef}
          initialData={initialData}
          style={{ display: openPaymentCard ? "flex" : "none" }}
        >
          {numberOfPortion().map((option: FieldPaymentPropsWithoutTotal) => (
            <FieldsPaymentCard
              key={option?.id}
              id={option?.id}
              name={option?.name}
              placeholder={option?.placeholder}
              type={option?.type}
              options={option?.options}
              total={value}
              autoComplete={option?.autoComplete}
            />
          ))}

          {!urlParams?.tipoUsuario && (
            <>
              <Paragraph>
                Para garantir sua segurança, complete o reCaptcha abaixo
              </Paragraph>
              <WrapperRecaptcha>
                <ReCaptcha
                  sitekey="6LcO4ZQiAAAAAJ89a0mxNgk4hkrU1PUOmvWITgCy"
                  onChange={handleRecaptcha}
                />
              </WrapperRecaptcha>
            </>
          )}
        </FormCard>
        {errorPayment !== "" && (
          <span
            style={{
              color: "var(--red600)",
              width: "100%",
              textAlign: "center",
              fontSize: "16px",
            }}
          >
            {removeHtmlInString(errorPayment)}
          </span>
        )}
      </AreaInfoPayment>
      <ModalAlert
        isOpen={!!messageModalError?.htmlBody || !!messageModalError?.body}
        message={messageModalError}
        onClose={handleCloseModalError}
      />
    </BoxPayment>
  );
};

export default ChoosePayments;
