import { Fragment, useCallback, useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useLocation, useParams } from "react-router-dom";
import { BtnKeep, Container, Content, Paragraph, Title } from "./styles";
import animationData from "./assets/check-success.json";
import Loading from "../../components/loading";
import { api } from "../../services";
import { CompleteFormProps, DataFormProps, pageProps } from "./types";

const ReserveConcluded = () => {
  const [loading, setLoading] = useState<boolean>(true);
  let { resLocalizador } = useParams();
  const { state }: pageProps = useLocation();
  // const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const choiceParent = (item: string) => {
    if (item === "Pai/Mãe") {
      return 1;
    }
    if (item === "Filho(a)") {
      return 2;
    }
    if (item === "Irmão(ã)") {
      return 3;
    }
    if (item === "Cônjuge") {
      return 4;
    }
    if (item === "Sobrinho(a)") {
      return 5;
    }
    if (item === "Tio(a)") {
      return 6;
    }
    if (item === "Neto(a)") {
      return 7;
    }
    if (item === "Primo(a)") {
      return 8;
    }
    if (item === "Sogro(a)") {
      return 9;
    }
    if (item === "Cunhado(a)") {
      return 10;
    }
    if (item === "Avô(ó)") {
      return 11;
    }
    if (item === "Outro") {
      return 12;
    }
    return 13;
  };
  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    handleGoToTop();
    const data = localStorage.getItem("formOtherPerson");
    const dataCOmplete = localStorage.getItem("completeForm");

    if (data && dataCOmplete && (resLocalizador || state?.resLocalizador)) {
      const dataObj: DataFormProps = JSON.parse(data);
      const dataCompleteObj: CompleteFormProps = JSON.parse(dataCOmplete);

      api
        .get("/wsCoobrastur/Hotel.asmx/insertAutorTerceiros", {
          params: {
            dados: Object.values({
              t: "insertAutorTerceiros",
              utiterNome: dataObj.nameUser,
              utiterCPF: parseInt(dataObj.cpfUser),
              utiterRG: parseInt(dataObj.rgUser),
              utiterEndereco: dataObj.addressUser,
              utiterCidade: dataObj.cityUser,
              UFCodigo: dataObj.ufUser,
              utiterDDDRes: parseInt(dataCompleteObj.ddd),
              utiterFoneRes: parseInt(dataCompleteObj.cel),
              utiterDDDComl: 0,
              utiterFoneComl: 0,
              utiterDDDCelular: parseInt(dataCompleteObj.ddd),
              utiterFoneCelular: parseInt(dataCompleteObj.cel),
              utiterIdade: parseInt(dataObj.ageUser),
              utiterEmail: dataObj.emailUser,
              resLocalizador: resLocalizador || state?.resLocalizador,
              grauparCodigo: choiceParent(dataObj.parentUser),
              utiterDataNascimento:
                dataObj?.birthday || dataCompleteObj.birthday,
            }).join(";"),
          },
        })
        .then((resp) => {
          if (resp.data.Table[0].situacao === 0) {
            setLoading(false);
            localStorage.removeItem("formOtherPerson");
            return;
          }
        })
        .catch((erro) => console.log(erro));
    }

    setLoading(false);
  }, [state?.resLocalizador, resLocalizador]);

  const handleClickGoToReserve = useCallback(() => {
    if (!state?.resLocalizador && !resLocalizador) {
      window.location.href = `https://www.coobmais.com.br/portal_associado.html#/minhasReservas`;
      return;
    }
    if (state?.internationalReserveToken) {
      window.location.href = `https://www.coobmais.com.br/portal_associado.html#/dadosReservaInternacional/${
        resLocalizador || state?.resLocalizador
      }/${state.internationalReserveToken}`;
      return;
    }
    window.location.href = `https://www.coobmais.com.br/portal_associado.html#/dadosReserva/${
      resLocalizador || state?.resLocalizador
    }`;
  }, [state?.internationalReserveToken, state?.resLocalizador, resLocalizador]);

  if (loading) {
    return (
      <Container>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      </Container>
    );
  }
  return (
    <Fragment>
      {!loading &&
        (!state?.resLocalizador && !resLocalizador ? (
          <Container>
            <Content>
              <Title>Ops! Parece que encontramos um problema!</Title>
              <Paragraph>
                Por favor entre em contato com a nossa central no telefone (51)
                3018-6900.
                <br />
                Não refaça sua reserva!
              </Paragraph>
              <BtnKeep onClick={handleClickGoToReserve}>
                Ir Para Minhas Reservas
              </BtnKeep>
            </Content>
          </Container>
        ) : (
          <Container>
            <Content>
              <Lottie options={defaultOptions} width={250} />
              <Title>Sua solicitação reserva foi concluída com sucesso!</Title>
              <Paragraph>
                Você pode conferir os detalhes em sua área logada na sessão
                "Consulta de Reservas".
              </Paragraph>
            </Content>
            <Title>{`Reserva: ${
              resLocalizador || state?.resLocalizador
            }`}</Title>
            <BtnKeep onClick={handleClickGoToReserve}>
              Ir Para a Reserva
            </BtnKeep>
          </Container>
        ))}
    </Fragment>
  );
};

export default ReserveConcluded;
