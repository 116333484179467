import HelpPlansImage from "./assets/helpPlansSignature.png";
import HelpPaymentImage from "./assets/helpPayment.png";
import HelpCancelImage from "./assets/helpCancel1.png";
import HelpCancelImage2 from "./assets/helpCancel2.png";
import Table from "./assets/table.png";
export const content = [
  {
    title: `1. Quanto tempo de antecedência é necessário para fazer minha reserva?`,
    paragraph: `Para reservar as suas diárias no nosso site, é preciso fazê-las com a <strong>antecedência mínima de 30 dias</strong> e máxima de 01 ano da data de check-in, de acordo com a disponibilidade de cada hotel.`,
    paragraph2: `Se houver necessidade de fazer uma reserva fora do prazo mencionado, ou seja,
    com menos de 30 dias de antecedência do início da hospedagem, 
    procure disponibilidade de hotel diretamente pelo Reserva Fácil, no nosso site. 
    Caberá ao hotel aceitar ou não a sua solicitação, sem interferência da Coob+. 
    Se aceito pelo hotel e caso a sua hospedagem for entre 15/03 à 15/12, <strong>com até 16 dias de antecedência do check-in</strong>, não haverá cobrança da Taxa por Reserva Fora do Prazo.`,
  },

  {
    title: `2. Como funciona o reembolso fora da rede credenciada?`,
    paragraph: `Consulte a disponibilidade de vagas nos hotéis credenciados via Clara, nossa assistente virtual. 
    Caso ela não encontrar hotéis com disponibilidade, você será direcionado para o “reembolso fora da rede credenciada” e 
    receberá um e-mail com o código de liberação para sua solicitação.`,
    paragraph2: `Para pedir reembolso, a solicitação de reserva deverá ser feita com mais de <strong>30 dias de antecedência</strong> da sua viagem. Se estiver dentro do prazo, você poderá solicitar reembolso de até 85% do valor dos e-Tickets. Para isso, você deverá ter e-Tickets disponíveis para bloqueio.`,
    paragraph3: `Após seu check-out, <strong>você terá 5 dias úteis</strong> para enviar á Clara a nota fiscal eletrônicada sua hospedagem. A NF precisa ser emitida pelo hotel, ou seja, não poderão ser feitas reservas por agências, sites ou outras OTAS.`,
    paragraph4: `Para enviar sua NF, basta anexá-la no chat com a Clara, disponível no nosso site e aguardar o reembolso em até 5 dias úteis.`,
    paragraph5: `A agência credenciada não receberá e-Tickets para fins de hospedagens, exceto em casos de pacote turístico completo e mediante a comprovação.`,
    paragraph6: `Nossa central de atendimento não procede com reembolso fora da rede, a solicitação deverá ser feita exclusivamente via Clara.`,
  },
  {
    title: `3. O que a Coob+ comercializa?`,
    paragraph: `Diárias de hotéis por assinatura, a partir de 2 até 50 diárias por ano, em apartamento duplo ou triplo para duas ou três pessoas e com café da manhã incluso. São + de 1.900 hotéis credenciados em todo o Brasil.`,
  },
  {
    title: `4. O que você recebe quando adquire diárias de hotéis por assinatura?`,
    paragraph: `Ao adquirir diárias Coob+, você está fazendo uma Assinatura de Hotéis. A cada ano será disponibilizado o número de diárias hoteleiras de acordo com a assinatura e quantidade que você escolher, podendo ser utilizado nos diversos hotéis credenciados em todo o Brasil.`,
    paragraph2: `As assinaturas Coob+ são: Go Vip, Go Master, Vip, Master, Gold Vip, Gold Master e Diamante, a partir de 02 até 50 diárias anuais. Todas distribuídas em blocos, denominados de e-Tickets, de 3 (três) e de 2 (duas) diárias, que, somados, ajustarão a quantia correspondente ao número total de diárias da opção da assinatura adquirida.`,
    paragraph3: `Todas as diárias nacionais incluem café da manhã sem custo adicional.`,
    image: HelpPlansImage,
  },
  {
    title: `5. Quais são as assinaturas Coob+ e quais suas características?`,
    paragraph: `As assinaturas Coob+ oferecem hospedagem em quartos duplos, que acomodam até 2 pessoas, e em quartos triplos, 
    que acomodam até 3 pessoas, denominado plano família. 
    As denominações “Vip” e “Master” diferenciam o período de utilização das hospedagens. 
    `,
    paragraph2: `Conforme a rede de hotéis parceiros, são oferecidas 4 categorias de assinaturas: Go, Vip/Master, Gold e Diamante`,
    listStyle: "disc",
    list1: `Assinatura: Go Vip - Utilização o ano todo – rede de hotéis executivos - quartos com capacidade para até 2 pessoas.`,
    list2: `Assinatura: Go Master - Utilização 15/03 a 15/12 – rede de hotéis executivos - quartos com capacidade para até 2 pessoas.`,
    list3: `Assinatura: Vip - Utilização o ano todo – rede de hotéis executivos - quartos duplos e triplos`,
    list4: `Assinatura: Master - Utilização 15/03 a 15/12 – rede de hotéis executivos - quartos duplos e triplos`,
    list5: `Assinatura: Gold Vip - Utilização o ano todo – rede de hotéis superior - quartos duplos e triplos`,
    list6: `Assinatura: Gold Master - Utilização 15/03 a 15/12 – rede de hotéis superior - quartos duplos e triplos`,
    list7: `Assinatura: Diamante - Utilização o ano todo – rede de hotéis super luxo e resort - quartos duplos e triplos.`,
  },

  {
    title: `6. Qual a quantidade de diárias por assinatura?`,
    paragraph: `Todas as assinaturas são a partir de 02 até 50 diárias, com 3 anos de validade. Cada cliente pode adquirir quantas assinaturas achar necessário. Não há limite de quantidade de assinaturas por CPF.`,
  },
  {
    title: `7. Quais são as formas de pagamento das assinaturas Coob+?`,
    paragraph: `O pagamento da assinatura se dá por meio de mensalidades. <strong>Após o pagamento da taxa de adesão e primeira mensalidade</strong>, é disponibilizado o número de diárias contratadas na assinatura, que serão pagas em 12 meses.`,
    paragraph2: `A cada 12 mensalidades pagas, completa-se um período do plano (1 ano) e inicia-se o próximo, 
    quando um novo lote de diárias serão disponibilizadas.`,
    paragraph3: `As mensalidades podem ser pagas por meio de cartão de crédito, nos principais bancos.`,
    paragraph4: `Os valores são descontados mês a mês, e não comprometem o limite do cartão.`,
  },
  {
    title: `8. Qual o procedimento a ser adotado se o cliente não tiver conta corrente em banco ou cartão de crédito?`,
    paragraph: `A cobrança da assinatura poderá ser feita na conta corrente ou cartão de crédito de um terceiro. Este procedimento requer o preenchimento e assinatura de autorização de débito em conta de terceiros.`,
  },
  {
    title: `9. O que é um e-Ticket Coob+?`,
    paragraph: `É a representação virtual das diárias da sua assinatura. Os e-Tickets possuem validade de 03 anos sendo apresentados de duas formas: e-Ticket de 3 (três) e de 2 (duas) diárias.`,
    paragraph2: `Não há e-Tickets de apenas 1 (uma) diária. Apresentam-se com um código de segurança para validarem a reserva e emissão do voucher de hospedagem.`,
    paragraph3: `Quando o Viajante Coob+ realizar a aquisição da assinatura, 
    receberá um extrato por e-mail contendo os e-Tickets (que se apresentam como códigos) com as 
    respectivas diárias de acordo com o tipo de assinaturaescolhida e 
    terá os e-Tickets disponibilizados em sua área logada no site da Coob+.`,
    paragraph4: `Anualmente, no aniversário da assinatura, será enviado o extrato on-line com os novos e-Tickets gerados, 
    correspondentes ao novo período iniciado. O viajante`,
    paragraph5: `Coob+ também pode, a qualquer tempo, fazer a consulta logando em sua conta no nosso site.`,
  },
  {
    title: `10. Qual é a validade das diárias da assinatura Coob+?`,
    paragraph: `A validade de todos e-Tickets é de 3 (três) anos. 
    As diárias não utilizadas dentro deste período, 
    não poderão ser mais utilizadas.`,
  },
  {
    title: `11. Posso ceder meus e-Tickets para terceiros?`,
    paragraph: `Sim. O e-Ticket Coob+ pode ser utilizado por outras pessoas que não o titular. Mas, para a utilização, o terceiro deverá ter em mãos uma autorização por escrito com a rubrica do titular da assinatura reconhecida em cartório, autorizando o uso do e-Ticket referido, a qual deverá ser entregue ao hotel no momento da hospedagem.`,
    paragraph2: `O formulário de Autorização fica disponível na plataforma “Reserva Fácil”, na área logada do viajante em nosso site.`,
  },
  {
    title: `12. Posso fracionar o e-Ticket?`,
    paragraph: `Os e-Tickets são fracionados em 2 e 3 diárias, 
    não sendo possível o fracionamento em apenas 1 diária. Ao reservar apenas 1 diária, não poderá reaver a outra diária do e-Ticket.`,
  },
  {
    title: `13. A partir de que data posso utilizar minhas diárias após a aquisição da assinatura Coob+?`,
    paragraph: `A validade inicia após o pagamento da 1.ª mensalidade. 
    Ex: 1.ª mensalidade em débito em conta no dia 10 do mês, a validade do e-Ticket será a partir do dia 10.`,
    paragraph2: `Em caso da aquisição da assinatura ocorrer em novembro e dezembro, haverá a <strong>aplicação da Tabela Restritiva.</strong>`,
    paragraph3: `Para assinaturas adquiridas durante o período de tabela restritiva, 
    haverá uma carência de 3 meses para utilização: para assinaturas adquiridas em novembro a 
    utilização poderá ocorrer a partir de fevereiro e para assinaturas adquiridas em dezembro a utilização poderá ocorrer a partir de março.`,
  },
  {
    title: `14. As diárias precisam ser utilizadas todas de uma única vez?`,
    paragraph: `Não, elas podem ser utilizadas separadamente. A quantidade de diárias da assinatura é disponibilizada em e-Tickets de 2 ou de 3 diárias.`,
    paragraph2: `Na assinatura de 7 diárias, por exemplo, é disponibilizado 1 e-Ticket de 3 diárias e 2 e-Tickets de 2 diárias, que podem ser utilizados de uma única vez no mesmo hotel, de uma única vez em diferentes hotéis ou em viagens diferentes, sempre mantendo o mínimo de 2 diárias no hotel.`,
  },
  {
    title: `15. Posso utilizar os e-Tickets em outros serviços de turismo?`,
    paragraph: `Sim. Os e-Tickets podem ser utilizados para aquisição de serviços turísticos por meio da Agência Parceira Credenciada Coob+, a Nacional Turismo, que aceita os e-Tickets como forma de pagamento. Esse benefício é exclusivo para o titular da assinatura, cônjuges e terceiros, sendo para parentesco apenas de primeiro grau (pais e filhos). No dia 8 março de 2021, esta regra passou por atualização, na qual ficou estabelecido que o uso dos e-Tickets deverá respeitar o limite de diárias disponíveis por assinatura e por ano nas agências parceiras, considerando todos os serviços/produtos oferecidos de forma unificada. Cada assinante poderá utilizar, por ano, 1 (um) módulo para compra de produtos/serviços na Agência Credenciada.`,
    paragraph2: `- Entende-se por ano, o período compreendido entre 01/01 a 31/12;`,
    paragraph3: `- Entende-se por módulo os e-Tickets referentes ao período vigente, de acordo com o número de diárias da(s) assinaturas(s) adquirida(s);`,
    paragraph4: `- Não será mais permitido o efeito cumulativo de uma única assinatura para utilização. Desta forma, os clientes que possuem assinaturas com base de diárias inferior ou superior a 07, fica estabelecido o limite máximo de diárias da assinatura.`,
    paragraph5: `Exemplos:`,
    paragraph6: `- O viajante que possui somente 01 assinatura à base de 07 diárias, utiliza 02 diárias em hospedagens durante o exercício de 2023 e ainda pretende adquirir o serviço de passagens aéreas durante esse mesmo período, poderá utilizar as 05 diárias restantes na Agência Credenciada;`,
    paragraph7: `- O viajante que possui somente 01 assinatura à base de 07 diárias, não utilizou sua assinatura no ano anterior, 
    gerando um acúmulo de 14 diárias, poderá utilizar 07 diárias em serviços na Agência Credenciada, 
    ficando as outras 7 para utilização em hospedagens pelo Reserva Fácil.`,
    paragraph8: `- Caso o viajante tenha uma assinatura de 11 diárias, 
    o limite de diárias que poderão ser utilizadas por ano para conversão na Agência Credenciada será 11, 
    sempre respeitando o número de diárias vigentes disponíveis por assinatura.`,
    paragraph9: `- Caso o viajante tenha duas assinaturas, 
    o limite de diárias que poderão ser utilizadas por ano para conversão na Agência Credenciada será de 13 diárias.`,
    paragraph10: `- Desta forma, o antigo limite de utilização de 07 diárias para contratação de aéreo não está mais vigente, e passa-se a respeitar a quantidade de diárias vigentes de cada assinatura. Para compra de pacotes, não está mais válido o uso de diárias acumuladas, podendo ser utilizadas apenas as diárias vigentes de cada assinatura.`,
    paragraph11: `- O viajante com 01 assinatura de 04 diárias, dentro de 01/01 a 31/12 do ano e utiliza essas 04 diárias em uma hospedagem NÃO poderá utilizar o aproveitamento de e-Tickets até o encerramento do ano.`,
  },
  {
    title: `16. Pode haver saldo de diárias quando a permanência da hospedagem for menor que a informada no e-Ticket?`,
    paragraph: `Não haverá restituição de diárias e/ou valores caso a quantidade de diárias utilizadas for menor que a informada no e-Ticket, mas a utilização parcial de um e-Ticket é de livre opção do viajante Coob+`,
  },
  {
    title: `17. Qual a cobertura e-Ticket Coob+?`,
    paragraph: `É de uso exclusivo para pagamento de hospedagem, em apartamento duplo ou triplo, para duas ou três pessoas, com café da manhã incluso, 
    nos hotéis Credenciados da rede no Brasil.`,
    paragraph2: `Demais despesas extras, tais como: cama-extra, meia-pensão, pensão completa, all inclusive, e frigobar são de <strong>inteira responsabilidade do viajante</strong>, devendo ser pagas diretamente ao hotel. No caso da rede credenciada internacional, os hotéis podem adotar políticas diferentes daquelas utilizadas na rede de hotéis credenciada nacional, <strong>cabendo ao viajante verificar estas políticas</strong> no momento da reserva.`,
  },
  {
    title: `18. A rede hoteleira credenciada Coob+ é diferenciada por modalidade de assinatura?`,
    paragraph: `Sim. A rede de hotéis credenciados de cada assinatura é confeccionada de acordo com as características e oferta de serviços de cada hotel parceiro. Há uma rede credenciada para cada assinatura Coob+`,
    listStyle: "disc",
    list1: `Assinaturas Go Vip e Go Master - rede de hotéis executivos - quartos com capacidade para até 2 pessoas.`,
    list2: `Assinaturas Vip e Master - rede de hotéis executiva - quartos duplos e triplos.`,
    list3: `Assinaturas Gold Vip e Gold Master - rede de hotéis superior - quartos duplos e triplos.`,
    list4: `Assinatura Diamante – rede de hotéis super luxo e resort - quartos duplos e triplos`,
  },
  {
    title: `19. Assinantes Go Vip e Go Master podem utilizar a rede de hotéis Vip, Master, Gold Vip, Gold Master e Diamante?`,
    paragraph: `Sim. Assinantes Go poderão utilizar hotéis da rede Vip, Master, Gold Vip, Gold Master e Diamante, desde que complementem a diferença de valores por diária.`,
  },
  {
    title: `20. Assinantes Vip e Master podem utilizar a rede de hotéis Gold e Diamante?`,
    paragraph: `Sim. Assinantes Vip e Master poderão utilizar hotéis da rede Gold Vip, Gold Master e Diamante, desde que complementem a diferença de valores por diária.`,
  },
  {
    title: `21. Assinantes Gold Vip e Gold Master podem utilizar a rede de hotéis Diamante?`,
    paragraph: `Sim. Assinantes Gold poderão utilizar a rede de hotéis Diamante, desde que complementem a diferença de valores.`,
  },
  {
    title: `22. Assinantes Gold Vip e Gold Master poderão utilizar a rede de hotéis Vip/Master?`,
    paragraph: `Sim. Assinantes Gold poderão utilizar a rede de hotéis Vip/Master, sem qualquer desembolso, mas sem obter nenhum crédito de valores.`,
  },
  {
    title: `23. Assinantes DIAMANTE podem utilizar a rede de hotéis Go!, Vip, Master, Gold Vip e Gold Master?`,
    paragraph: `Sim. Assinantes Diamante poderão utilizar toda a rede de hotéis credenciados Coob+, sem qualquer desembolso, mas também sem obter nenhum crédito de valores.`,
  },
  {
    title: `24. Como faço uma reserva?`,
    paragraph: `A reserva é feita pelo próprio viajante, utilizando o Sistema de Reserva Fácil em nosso site. O viajante precisa acessar o site coobmais.com.br, clicar em Reserva Fácil e seguir os seguintes passos:`,

    list1: `1. Selecione o hotel desejado ou, se preferir, a cidade onde deseja se hospedar, para fazer a busca por região.`,
    list2: `2. Selecione as datas desejadas.`,
    list3: `3. Selecione o número de quartos e a quantidade de pessoas que irão se hospedar`,
    list4: `4. Insira o login e a senha (os mesmos de acesso à conta do assinante)`,
    list5: `5. Defina os e-Tickets que serão utilizados na reserva;`,
    list6: `6. Caso haja valores adicionais, como, por exemplo, pacote extra, cama adicional e entre outros, inserir as informações de pagamento;`,
    list7: `7. Confirme a reserva;`,
    list8: `8. Pronto! A reserva é confirmada com o voucher no seu e-mail.`,
    list9: `9. Após realizar o pagamento via e-Ticket e/ou cartão, você receberá um e-mail com a confirmação da reserva.`,
    list10: `10. Você tem total autonomia para escolher o destino e o hotel desejado, sendo importante ficar atento ao período de uso, a rede de hotéis estabelecidos pela sua assinatura e sempre respeitando a antecedência mínima de 30 dias antes da entrada no hotel.`,
    list11: `11. Caso o período que antecede a solicitação seja menor que 30 dias, a reserva só poderá ser realizada mediante solicitação de disponibilidade ao hotel da sua preferência, no nosso site. Se aceito pelo hotel e caso a sua hospedagem for entre 15/03 à 15/12, com até 16 dias de antecedência do check-in, não haverá cobrança da Taxa por Reserva Fora do Prazo.`,
    list12: `12. LEMBRE-SE: no dia do check-in no hotel, apresente o voucher (confirmação da reserva) e um documento com foto.`,
    imageAfterList: HelpPaymentImage,
    paragraphAfterImage: `Para utilização de terceiros: O titular deverá acessar o 'Formulário de utilização de Terceiros' depois que finalizar a reserva, reconhecer firma em cartório desse documento e entregar as pessoas que irão viajar utilizando suas diárias. O documento está disponível na área logada do viajante no nosso site.`,
  },
  {
    title: `25. Como cancelar uma reserva?`,
    paragraph: `Para alterar ou cancelar uma reserva, basta fazer a solicitação acessando sua conta no nosso site.`,
    image: HelpCancelImage,
    paragraphAfterImage: `Você precisa acessar o Portal do Usuário no nosso site e localizar as suas reservas.`,
    imageAfterList2: HelpCancelImage2,
    paragraphAfterImage2: `Ao identificar a reserva desejada, clique em “Cancelar”. Observe sempre a política de cancelamento de cada hotel descrito no voucher de reserva, pois nela será informado, se houver, cobrança de multas por cancelamento fora do prazo de isenção e No-Show, bem como trará as informações sobre a devolução de valores extras pagos em cartão de crédito, débito ou boleto bancário e a taxa administrativa.`,
  },
  {
    title: `26. O que é taxa administrativa cobrada na minha reserva?`,
    paragraph: `A taxa administrativa é uma cobrança em cima dos valores extras de uma reserva, pela operação de seu cancelamento ou alteração, 
    independente do prazo de antecedência. 
    O valor por cancelamento é de 10% sobre o valor dos extras pagos e será cobrado no momento da restituição dos valores.`,
    paragraph2: `Para alterações de reserva, 
    se houver alguma devolução de valores como, por exemplo, uma cama extra retirada,
     é cobrado 4% dos extras pagos.`,
  },
  {
    title: `27. Cancelei uma reserva e tenho valores para receber. Em quanto tempo receberei?`,
    paragraph: `Se o pagamento dos extras da sua reserva foi efetuado em cartão de crédito, 
    você receberá a restituição dos valores em até 90 dias, como crédito na sua fatura.`,
    paragraph2: `Se o pagamento foi realizado por débito em conta ou boleto bancário, 
    a restituição ocorrerá em até 30 dias e você receberá um contato da Coob+ 
    para verificar os dados de conta que deseja receber os valores a restituir.`,
  },
  {
    title: `28. O que é o Indique+?`,
    paragraph: `O Indique+ é o nosso programa de benefícios em que a cada indicação sua que se converter em uma assinatura, você escolhe ganhar R$200 na sua conta, abater R$200 nas mensalidades da sua assinatura ou 10.000 Dotz*. Optando por R$200 na conta ou em abatimento, o valor será creditado 30 dias após o pagamento da 3ª mensalidade do seu indicado. Se você optar por Dotz, após 15 dias úteis do pagamento da 3º mensalidade do seu indicado, os 10.000 Dotz já estarão disponíveis.`,
    paragraph2: `*Até 7 diárias, premiação proporcional.`,
  },
  {
    title: `29. O que acontece se o viajante não comparecer à reserva?`,
    paragraph: `No caso de o viajante Coob+ não comparecer no hotel reservado ou não comunicar o cancelamento da reserva no prazo especificado pelo estabelecimento, haverá cobrança de no-show, observadas as regras de cada estabelecimento de hospedagem.`,
    paragraph2: `As regras, multas e prazos de cancelamento de reserva serão de acordo com as políticas de cada hotel, 
    conforme informado no momento da reserva e no voucher da reserva.`,
  },
  {
    title: `30. O que é Solicitação de Disponibilidade Extra?`,
    paragraph: `A solicitação de disponibilidade extra irá aparecer para o viajante quando o hotel não possuir mais apartamentos disponíveis pelo bloqueio da Coob+.`,
    paragraph2: `Essa solicitação será enviada diretamente ao hotel para verificar se pode aceitar mais uma reserva pela assinatura da Coob+.`,
  },
  {
    title: `31. Como solicitar uma disponibilidade extra ao Hotel?`,
    paragraph: `A solicitação deverá ser realizada por meio do site Coob+, na busca de Hotéis Credenciados.`,
    paragraph2: `Após consultar o hotel desejado, clicar no link "Solicitar Disponibilidade". 
    Após a solicitação efetuada, é necessário aguardar o retorno do hotel no prazo máximo de 3 (três) dias.`,
    paragraph3: `Ficará sempre a critério do Hotel aceitar ou negar esta solicitação.`,
  },
  {
    title: `32. Qual a Agência de Viagens Parceira da Coob+?`,
    paragraph: `A agência parceira credenciada Coob+ é a Nacional Turismo, atende os viajantes de todo o Brasil.`,
  },
  {
    title: `33. Quais os contatos da Agência de Viagens Parceira Credenciada da Coob+?`,
    paragraph: `(54) 3278-9880 | (81) 3202 1440 | reservas@nacionalturismo.com`,
  },
  {
    title: `34. O e-Ticket pode ser utilizado como forma de pagamento no pacote?`,
    paragraph: `Sim. Desde que adquiridos na nossa agência parceira credenciada e respeitando as regras de utilização.`,
  },
  {
    title: `35. Onde consultar os hotéis credenciados?`,
    paragraph: `Todos os hotéis credenciados Coob+, estão disponíveis para visualização no site www.coobmais.com.br`,
    paragraph2: `No campo de pesquisa você pode filtrar por região, nome de hotel e cidade. Por meio da plataforma ‘Reserva Fácil’ você pode fazer as reservas com autonomia e totalmente online.`,
  },
  {
    title: `36. Como é feita a renovação da assinatura Coob+?`,
    paragraph: `A sua assinatura Coob+ tem validade indeterminada.`,
    paragraph2: `A cada 12 (doze) meses, a contar da data do pagamento da primeira mensalidade, é concluído um ciclo.`,
    paragraph3: `Automaticamente é iniciado o próximo, gerando novo lote de e-Tickets, sem o pagamento de nenhuma taxa ou adesão adicional, caso não haja manifestação em contrário.`,
  },
  {
    title: `37. Posso cancelar minha assinatura a qualquer momento?`,
    paragraph: `Para realizar o cancelamento das assinaturas Coob+ é sempre bom se atentar às premissas de 30 (trinta) dias antes do próximo débito.`,
    paragraph2: `A assinatura não pode estar com inadimplência de mensalidade ou e-Tickets utilizados. O cancelamento deverá ser feito por meio do contato com a Central de Atendimento, pelos telefones (11) 3192-2020 | (31) 2116-5770 | (61) 3770-0400 | (81) 3787-7660 | (51) 3018-6900.`,
  },
  {
    title: `38. Qual o valor de uma cama adicional?`,
    paragraph: `O valor da cama extra é variável de acordo com cada hotel e período. Quando você for efetuar sua reserva e optar por incluir uma cama adicional, aparecerá o valor antes de finalizar sua reserva.`,
  },
  {
    title: `39. Quando recebo meu extrato com informações dos meus e-Tickets?`,
    paragraph: `Você receberá por e-mail, em até 24 horas após o cadastramento da assinatura Coob+ adquirida.`,
    paragraph2: `Para assinaturas adquiridas por meio do site, o cadastramento acontece no momento do preenchimento dos dados.`,
    paragraph3: `Para assinaturas adquiridas por meio de um vendedor, utilizando o formulário de aquisição impresso/físico, 
    deve-se considerar o prazo até o cadastro.`,
  },
  {
    title: `40. Quantas pessoas podem ir comigo na viagem?`,
    paragraph: `Se a sua assinatura for para duas pessoas, você poderá incluir uma cama adicional, podendo ficar com 3 pessoas em um apartamento.`,
    paragraph2: `Se sua assinatura for família, essa cama adicional já está inclusa no valor da sua mensalidade e você poderá se hospedar com até 3 
    pessoas sem se preocupar em pagar o valor de cama adicional.`,
  },
  {
    title: `41. O e-Ticket pode ser utilizado por outra pessoa que não o titular da assinatura?`,
    paragraph: `Sim. Para a utilização, o terceiro deverá ter em mãos uma autorização por escrito, 
    com rubrica do titular da assinatura reconhecida em cartório, 
    autorizando o uso do e-Ticket referido, a qual deverá ser entregue ao hotel no momento da hospedagem.`,
  },
  {
    title: `42. O que acontece se o assinante não comparece na reserva?`,
    paragraph: `Caso a reserva não tenha sido cancelada previamente, de acordo com o prazo estipulado por cada hotel, 
    fica facultado ao Hotel a cobrança de uma multa de "no-show”, ou seja, pelo não comparecimento na reserva ou cancelamento fora do prazo.`,
  },
  {
    title: `43. Como posso usar mais diárias do que as que possuo na assinatura?`,
    paragraph: `O viajante poderá adquirir as diárias avulsas excedentes no momento da reserva, pagando com seu cartão de crédito, débito ou boleto bancário.`,
  },
  {
    title: `44. Qualquer pessoa pode adquirir as diárias de hotéis Coob+?`,
    paragraph: `Sim. Qualquer pessoa, desde que, maior de 18 anos, portador de CPF e detenha conta no sistema bancário, 
    ou possua titularidade de cartão de crédito.`,
  },
  {
    title: `45. A cobrança das mensalidades pode ser feita em conta poupança?`,
    paragraph: `Sim. Exclusivamente na conta poupança da Caixa Econômica Federal.`,
  },
  {
    title: `46. Quais são os Bancos conveniados para débito em conta das mensalidades?`,
    paragraph: `Os bancos conveniados são: Banco do Brasil, Sicredi, Banrisul, Santander, Itaú, Bradesco e Caixa Econômica Federal.

    Cartões: Visa, Mastercard, Hipercard, American Express, Elo, Credicard`,
  },
  {
    title: `47. Quais os cartões de crédito são aceitos para pagamento das mensalidades da assinatura?`,
    paragraph: `Aceitamos todas as bandeiras de cartões de crédito.`,
  },
  {
    title: `48. Como faço para ser um viajante Coob+?`,
    paragraph: `Para ser um viajante Coob+ acesse nosso site e adquira as nossas assinaturas, ou se preferir, preencha o formulário www.coobmais.com.br/associa-se e um dos nossos consultores entrará em contato com você.`,
  },
  {
    title: `49. Posso contratar uma assinatura para uma viagem solo?`,
    paragraph: `As nossas assinaturas são para 2 ou 3 pessoas, mas, você pode viajar sozinho.`,
  },
  {
    title: `50. Posso contratar uma assinatura para até quantos membros?`,
    paragraph: `As assinaturas da Coob+ são para duas ou três pessoas. As assinaturas para 3 pessoas são chamadas assinaturas família. Não é possível contratar assinaturas para mais de 3 pessoas.`,
    paragraph2: `Para casos de famílias maiores, indicamos a aquisição de mais assinaturas em que será possível reservar mais apartamentos no 
    estabelecimento hoteleiro.`,
  },
  {
    title: `51. Posso dividir a assinatura com terceiros?`,
    paragraph: `Você poderá levar o acompanhante que desejar, sem necessidade de cadastrá-lo junto a Coob+. 
    Como a assinatura está vinculado ao seu CPF, as reservas sairão sempre em seu nome e poderá levar quem desejar.`,
  },
  {
    title: `52. É possível incluir crianças na assinatura?`,
    paragraph: `A assinatura família inclui a terceira pessoa, independentemente da idade. 
    Mas, é importante sempre observar como funciona a política de gratuidade de crianças em determinados hotéis.`,
  },
  {
    title: `53. O valor para as crianças é o mesmo que para os adultos?`,
    paragraph: `O valor da cama adicional é variável. Cada hotel possui um valor de acordo com o período da reserva.`,
  },
  {
    title: `54. Veja todos os assuntos que a Clara, nossa assistente virtual pode te ajudar e agilizar o seu atendimento. 
    Para acioná-la basta acessar o site coobmais.com.br e clicar em cima da foto no canto inferior da tela.`,
    list1: `1. Fazer novas reservas.`,
    list2: `2. Pedir isenção de No show para reservas não realizadas ou canceladas fora do prazo.`,
    list3: `3. Consultar suas diárias: validade, últimas atualizações ou a quantidade disponível.`,
    list4: `4. Inserir ou excluir uma cama adicional da sua reserva.`,
    list5: `5. Alteração e cancelamento de reservas.`,
    list6: `6. Troca de modalidade de assinatura.`,
    list7: `7. Indique+: fazer ou consultar indicações e ver as regras.`,
    list8: `8. Negociações: reemissão de boleto, débito junto aos bancos, negociação de mensalidade em aberto.`,
    list9: `9. Reenvio de voucher.`,
    list10: `10. Formulário de autorização de terceiros.`,
    list11: `11. Dúvidas sobre e-Tickets utilizados.`,
    list12: `12. Informações sobre pacotes para períodos festivos.`,
  },
  {
    title: `55. O que ganho por indicar uma assinatura Coob+`,
    paragraph: `A cada indicação sua que se converter em uma assinatura, você pode escolher entre ganhar R$200,00 na sua conta bancária, abater R$200,00 nas suas mensalidades ou 10.000 Dotz. Lembre-se, se você optar por Dotz, receberá em até 10 dias úteis após o pagamento da primeira mensalidade do seu indicado. Caso opte por R$200,00 na conta ou abatimento das mensalidades, o valor será creditado após o pagamento da terceira mensalidade do seu indicado.`,
    image: Table,
  },
];
