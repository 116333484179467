import {
  Dayli,
  Divider,
  Input,
  Label,
  Loading,
  TicketContainer,
  TopInfo,
  Validate,
  WrapperInfo,
  WrapperLabel,
} from "./styles";
import IconTicket from "./assets/ticket.svg";
import { TicketsProps } from "../types";
import { Fragment, useEffect, useState } from "react";

interface TicketsCompProps {
  tickets?: TicketsProps[];
  update: (tickets: TicketsProps[], room: string) => void;
  room: string;
  loading?: boolean;
}

const TicketsInternational = ({
  tickets,
  room,
  update,
  loading,
}: TicketsCompProps) => {
  const [ticketList, setTicketList] = useState<TicketsProps[] | undefined>([]);
  const [descriptionList, setDescriptionList] = useState<TicketsProps[]>([]);

  const handleChange = async (option: TicketsProps) => {
    if (ticketList) {
      // console.log(option);
      const cloneList = [...ticketList];
      const newList = cloneList.map((element) => {
        if (option.tktCodigo === element.tktCodigo) {
          if (!option.checked) {
            return { ...element, checked: true };
          }
          if (option.checked) {
            return { ...element, checked: false };
          }
        }
        return { ...element };
      });
      update(newList, room);

      //  update(newList, room, option);
    }
  };

  const FormatNameTicket = (item: string) => {
    const firstSeparator = item.split("(");

    return firstSeparator[0];
  };

  useEffect(() => {
    const ticketsFiltered = tickets?.filter(
      (element) => element.plano !== null
    );
    const description = tickets?.filter((element) => element.plano === null);

    setTicketList(ticketsFiltered);
    if (description && description?.length >= 0) {
      setDescriptionList(description);
    }
  }, [tickets]);

  if (loading) {
    return (
      <Loading active={loading}>
        <span></span>
      </Loading>
    );
  }

  if (!ticketList || ticketList?.length <= 0) {
    return (
      <div
        style={{
          textAlign: "center",
          color: "var(--pink600)",
          fontWeight: "bold",
        }}
      >
        você não tem possui e-tickets disponiveis para selecionar esta reserva
        mas você pode pagar por esta reserva sem usar e-tickets
      </div>
    );
  }
  return (
    <Fragment>
      {ticketList?.map((option) => (
        <TicketContainer key={option.tktCodigo}>
          <Input
            type="checkbox"
            id={option.plano}
            name={option.Texto}
            onChange={() => handleChange(option)}
            checked={option.checked}
            isUsed={option.Editar}
            disabled={option.Editar === 0 ? true : false}
          />
          <Label sugestion={0} isUsed={option.Editar}>
            <TopInfo>
              <WrapperLabel>
                <div style={{ marginTop: "-3px" }}>
                  <img src={IconTicket} alt="icon-ticket" />
                </div>
                <Divider />
                <WrapperInfo>{FormatNameTicket(option.plano)}</WrapperInfo>
              </WrapperLabel>
              <Dayli> {option.tktQtdeDiarias_tkt} diárias</Dayli>
            </TopInfo>
            <Validate>
              <span>validade {option.validade}</span>
              <span>{option.tktCodigo}</span>
            </Validate>
          </Label>
        </TicketContainer>
      ))}
      {descriptionList.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "var(--white)",
          }}
        >
          {descriptionList.map((item) => (
            <span
              key={item.Descricao}
              style={{
                width: "100%",
                padding: 4,
                textAlign: "center",
                marginTop: 10,
                backgroundColor: "var(--green600)",
                fontSize: "0.9rem",
                fontWeight: "bold",
                borderRadius: "8px",
              }}
            >
              {item.Descricao}
            </span>
          ))}
        </div>
      )}
      <div
        style={{
          width: "100%",
          maxWidth: "420px",
          backgroundColor: "var(--red600)",
          marginBottom: 4,
          borderRadius: 8,
          color: "var(--white)",
          fontWeight: "bold",
          padding: 4,
          margin: 8,
          fontSize: "0.9rem",
          textAlign: "center",
        }}
      >
        Você pagará diárias excedentes mesmo tendo e-ticket(s) válido(s). Se
        tiver ciente, desconsidere esta mensagem.
      </div>
    </Fragment>
  );
};

export default TicketsInternational;
