import { ReactComponent as IconArrow } from "../../assets/icones/icons dense (24px)/seta direita.svg";

import {
  ContainerButton,
  ButtonSelect,
  OptionsContent,
  OptionsSelect,
  OptionsSelectInput,
  OptionsSelectLabel,
} from "./styles";

import { useState, useCallback, SyntheticEvent } from "react";
import { CustomButtonSelectProps } from "./types";

export const CustomButtonSelect = ({
  children,
  handleInput,
  indexRoom,
  indexChildren,
}: CustomButtonSelectProps) => {
  const [open, setOpen] = useState(false);

  const selectInput = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      handleInput(event)(indexRoom)(indexChildren);
      setOpen(!open);
    },
    [handleInput, indexChildren, indexRoom, open]
  );

  return (
    <ContainerButton>
      <ButtonSelect type="button" onClick={() => setOpen(!open)}>
        {children.year === -1 && <span>Idade</span>}
        {children.year === 0 && <span className="active">Até 1 ano</span>}
        {children.year > 0 && (
          <span className="active">{children.year} anos</span>
        )}

        <IconArrow />
      </ButtonSelect>

      <OptionsSelect open={open}>
        <OptionsContent>
          <OptionsSelectLabel>
            <p>Até 1 ano</p>
            <OptionsSelectInput
              type="radio"
              name="year-children1"
              value={0}
              onInput={selectInput}
            />
          </OptionsSelectLabel>
          <OptionsSelectLabel>
            <p>1 ano</p>
            <OptionsSelectInput
              type="radio"
              name="year-children1"
              value={1}
              onInput={selectInput}
            />
          </OptionsSelectLabel>
          <OptionsSelectLabel>
            <p>2 ano</p>
            <OptionsSelectInput
              type="radio"
              name="year-children1"
              value={2}
              onInput={selectInput}
            />
          </OptionsSelectLabel>
          <OptionsSelectLabel>
            <p>3 ano</p>
            <OptionsSelectInput
              type="radio"
              name="year-children1"
              value={3}
              onInput={selectInput}
            />
          </OptionsSelectLabel>
          <OptionsSelectLabel>
            <p>4 ano</p>
            <OptionsSelectInput
              type="radio"
              name="year-children1"
              value={4}
              onInput={selectInput}
            />
          </OptionsSelectLabel>
          <OptionsSelectLabel>
            <p>5 ano</p>
            <OptionsSelectInput
              type="radio"
              name="year-children1"
              value={5}
              onInput={selectInput}
            />
          </OptionsSelectLabel>
          <OptionsSelectLabel>
            <p>6 ano</p>
            <OptionsSelectInput
              type="radio"
              name="year-children1"
              value={6}
              onInput={selectInput}
            />
          </OptionsSelectLabel>
          <OptionsSelectLabel>
            <p>7 ano</p>
            <OptionsSelectInput
              type="radio"
              name="year-children1"
              value={7}
              onInput={selectInput}
            />
          </OptionsSelectLabel>
          <OptionsSelectLabel>
            <p>8 ano</p>
            <OptionsSelectInput
              type="radio"
              name="year-children1"
              value={8}
              onInput={selectInput}
            />
          </OptionsSelectLabel>
          <OptionsSelectLabel>
            <p>9 ano</p>
            <OptionsSelectInput
              type="radio"
              name="year-children1"
              value={9}
              onInput={selectInput}
            />
          </OptionsSelectLabel>
          <OptionsSelectLabel>
            <p>10 ano</p>
            <OptionsSelectInput
              type="radio"
              name="year-children1"
              value={10}
              onInput={selectInput}
            />
          </OptionsSelectLabel>
          <OptionsSelectLabel>
            <p>11 ano</p>
            <OptionsSelectInput
              type="radio"
              name="year-children1"
              value={11}
              onInput={selectInput}
            />
          </OptionsSelectLabel>
          <OptionsSelectLabel>
            <p>12 ano</p>
            <OptionsSelectInput
              type="radio"
              name="year-children1"
              value={12}
              onInput={selectInput}
            />
          </OptionsSelectLabel>
          <OptionsSelectLabel>
            <p>13 ano</p>
            <OptionsSelectInput
              type="radio"
              name="year-children1"
              value={13}
              onInput={selectInput}
            />
          </OptionsSelectLabel>
          <OptionsSelectLabel>
            <p>14 ano</p>
            <OptionsSelectInput
              type="radio"
              name="year-children1"
              value={14}
              onInput={selectInput}
            />
          </OptionsSelectLabel>
          <OptionsSelectLabel>
            <p>15 ano</p>
            <OptionsSelectInput
              type="radio"
              name="year-children1"
              value={15}
              onInput={selectInput}
            />
          </OptionsSelectLabel>
          <OptionsSelectLabel>
            <p>16 ano</p>
            <OptionsSelectInput
              type="radio"
              name="year-children1"
              value={16}
              onInput={selectInput}
            />
          </OptionsSelectLabel>
          <OptionsSelectLabel>
            <p>17 ano</p>
            <OptionsSelectInput
              type="radio"
              name="year-children1"
              value={17}
              onInput={selectInput}
            />
          </OptionsSelectLabel>
        </OptionsContent>
      </OptionsSelect>
    </ContainerButton>
  );
};
