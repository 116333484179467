import create from "zustand";

type DataProps = {
  name: string;
  setName: (item: string) => void;
};

export const useChoosePayment = create<DataProps>((set) => ({
  name: "",
  setName: (item) => set((state) => ({ name: (state.name = item) })),
}));
