import { useState, useEffect, Fragment, SyntheticEvent, useRef } from "react";
import { Card } from "../../components/card";
import { FilterAccordion } from "../../components/filtersAccordion";
import { ModalCancellationPolicy } from "../../components/modalCancellationPolicy";

import { Search } from "../../components/search";
import { Steps } from "../../components/steps";
import { ReactComponent as IconFilter } from "../../assets/icones/icons dense (24px)/filter.svg";
import { ReactComponent as IconFechar } from "../../assets/icones/icons dense (24px)/fechar.svg";
import { ReactComponent as IconBussola } from "../../assets/icones/icons outline (48px)/bussola.svg";

import {
  BtnKeep,
  BtnResearch,
  ButtonClear,
  ButtonClearFilters,
  ButtonFilter,
  ButtonSubmit,
  Container,
  ContainerPlans,
  ContentAside,
  ContentAsideBody,
  ContentAsideFooter,
  ContentAsideHeader,
  ContentBody,
  ContentGrid,
  ContentHeader,
  Header,
  HeaderSearch,
  Hgroup,
  Loading,
  Pagintaion,
  PlanItem,
  PlansList,
  Separator,
  SubtitleAside,
  Text,
  TilteContent,
  Title,
  TitleAside,
  WrapperResearch,
} from "./styles";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useLocation } from "react-router-dom";
import { api } from "../../services";
import useCreateToken from "../../hooks/createToken";
import { formatDate } from "../../utils";
import {
  Filter,
  Hotels,
  LocationProps,
  ResponseData,
  SearchProps,
} from "./types";
import { useCommodity } from "../../store/useComodidades";
import { useFilters } from "../../hooks/useFilters";
import { SearchDesktop } from "../../components/searchDesktop";
import { useCommodityRoom } from "../../store/useCommoditysRoom";
import { ModalFormConfirmation } from "../../components/modalFormConfirmation";
import { MessageProps } from "../../components/modalFormAvailability/types";
import { ModalAlert } from "../../components/modalAlert";

const PLANS_ID = "PLANS_ID";
const INITIAL_PAGE = 1;
const INITIAL_FILTER_NEIGHBORHOOD = "";
const INITIAL_FILTER_SERVICES = "";
const INITIAL_PLAN_SELECT = -1;
const TIME_WAIT_TO_MODAL_DELAY_SEARCH = 15000; //milisseconds

export const Accommodation = () => {
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [showModalDelaySearch, setShowModalDelaySearch] = useState(false);
  const [hotels, setHotels] = useState<Hotels[]>([]);
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE);
  const [totalPages, setTotalPages] = useState(0);
  const [totalHotels, setTotalHotels] = useState(0);
  const mobile = useMediaQuery("(max-width: 990px)");
  const { tokenApi, createToken } = useCreateToken();
  const { state }: LocationProps = useLocation();
  const [loading, setLoading] = useState(false);
  const { setData } = useCommodity();
  const [filterNeighborhood, setFilterNeighborhood] = useState(
    INITIAL_FILTER_NEIGHBORHOOD
  );
  const [neighborhood, setNeighborhood] = useState<Filter[]>([]);
  const [services, setServices] = useState<Filter[]>([]);
  const [filterServices, setFilterServices] = useState(INITIAL_FILTER_SERVICES);
  const [message, setMessage] = useState<MessageProps>({} as MessageProps);
  const { clearAllFilter, filterSelected } = useFilters();
  const plans = useRef<HTMLUListElement>(null);
  const { setData: setStoreRoom } = useCommodityRoom();
  const [planSelect, setPlanSelect] = useState(INITIAL_PLAN_SELECT);
  const [modalUnderstand, setModalUnderstand] = useState(false);
  const handleToggleModalUnderstand = () => {
    setModalUnderstand(!modalUnderstand);
  };
  // const navigate = useNavigate();
  // const { urlParams } = useUrlParams();

  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleGoToPLANS_ID = () => {
    const element = document.querySelector(`#${PLANS_ID}`);
    element?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  useEffect(() => {
    if (mobile) {
      handleGoToPLANS_ID();
    } else {
      handleGoToTop();
    }
  }, [hotels, mobile]);

  const handleCloseModal = () => {
    setModal(false);
    setShowModalDelaySearch(false);
    window.location.href = `${process.env.PUBLIC_URL}/`;
  };

  const handleTotop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const selectPlan = (event: SyntheticEvent<HTMLButtonElement>) => {
    const { currentTarget } = event;

    const value = Number(currentTarget.value);

    if (plans.current) {
      const items = plans.current.querySelectorAll("li");
      if (items) items.forEach((item) => item.classList.remove("active"));
    }

    const parent = currentTarget.parentElement;

    if (parent) parent.classList.add("active");

    setPlanSelect(value);
  };

  const _prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const _nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    createToken();
  }, [createToken]);

  function BeforeSearch(search: SearchProps) {
    api.get(`/wsCoobrastur/ConsultaHoteis.asmx/C199_1`, {
      params: {
        dados: JSON.stringify({
          token: search.token,
          in_Codigo: search.place.Codigo,
          dataInicial: formatDate(search?.dateIn) || "",
          dataFinal: formatDate(search?.dateOut) || "",
          qtdeQuartos: search.rooms.length || 1,
          qtdeAdultos: search.rooms.reduce(
            (accumulator, obj) => accumulator + obj.adducts,
            0
          ),
          totalCriancas: search.rooms.reduce(
            (accumulator, obj) => accumulator + obj.childrens.number,
            0
          ),
          idadeC1: search.rooms
            .map((room) => {
              if (
                room.childrens.number === 0 ||
                !room.childrens.children.length
              ) {
                return "-1";
              }
              return room.childrens.children.map((child) => child.year).join();
            })
            .join(","),
          hotCodigo: search?.hotCodigoSelecionado || 0,
          adultosQ1: search.rooms?.[0]?.adducts || 0,
          adultosQ2: search.rooms?.[1]?.adducts || 0,
          adultosQ3: search.rooms?.[2]?.adducts || 0,
          adultosQ4: search.rooms?.[3]?.adducts || 0,
          criancasQ1: search.rooms?.[0]?.childrens?.number || 0,
          criancasQ2: search.rooms?.[1]?.childrens?.number || 0,
          criancasQ3: search.rooms?.[2]?.childrens?.number || 0,
          criancasQ4: search.rooms?.[3]?.childrens?.number || 0,
          tirCodigo: 1,
          internacional: search.place.pais === "BR" ? 0 : 1,
        }),
      },
    });
    api.post(`/wsCoobrastur/Hotel.asmx/InsertPesquisa_1`, {
      dados: JSON.stringify({
        in_token: search.token,
        in_Codigo: search.place.Codigo,
        dataInicial: formatDate(search?.dateIn) || "",
        dataFinal: formatDate(search?.dateOut) || "",
        qtdeQuartos: search.rooms.length || 1,
        adultosQ1: search.rooms?.[0]?.adducts || 0,
        adultosQ2: search.rooms?.[1]?.adducts || 0,
        adultosQ3: search.rooms?.[2]?.adducts || 0,
        adultosQ4: search.rooms?.[3]?.adducts || 0,
        criancasQ1: search.rooms?.[0]?.childrens?.number || 0,
        criancasQ2: search.rooms?.[1]?.childrens?.number || 0,
        criancasQ3: search.rooms?.[2]?.childrens?.number || 0,
        criancasQ4: search.rooms?.[3]?.childrens?.number || 0,
        idadeC1: search.rooms?.[0]?.childrens?.children?.[0]?.year || "-1",
        idadeC2: search.rooms?.[1]?.childrens?.children?.[0]?.year || "-1",
        idadeC3: search.rooms?.[2]?.childrens?.children?.[0]?.year || "-1",
        idadeC4: search.rooms?.[3]?.childrens?.children?.[0]?.year || "-1",
        consultaInternacional: search.place.pais === "BR" ? 0 : 1,
        hotCodigoSelecionado: search?.hotCodigoSelecionado || 0,
        fotoHotelSelecionado: search?.fotoHotelSelecionado || "",
        enderecoHotelSelecionado: search?.enderecoHotelSelecionado || "",
        nomeHotelSelecionado: search?.nomeHotelSelecionado || "",
        nomeDestino: search.place.Descricao,
        usuarioLogin: 0,
        tktCodigo1: 0,
        tktCodigo2: 0,
        tktCodigo3: 0,
        tktCodigo4: 0,
        totalAdultos: search.rooms.reduce(
          (accumulator, obj) => accumulator + obj.adducts,
          0
        ),
        totalCriancas: search.rooms.reduce(
          (accumulator, obj) => accumulator + obj.childrens.number,
          0
        ),
      }),
    });
    return {
      in_token: search.token,
      in_Codigo: search.place.Codigo,
      in_Periodo_Inicial: formatDate(search?.dateIn) || "",
      in_Periodo_Final: formatDate(search?.dateOut) || "",
      in_Qtde_quartos: search.rooms.length || 1,
      in_carCodigos: search?.filtroServicosCodigos || INITIAL_FILTER_SERVICES,
      in_hotBairro: search?.filtroBairros || INITIAL_FILTER_NEIGHBORHOOD,
      in_adultos: search.rooms
        ?.map((room) => room.adducts.toString())
        .join(","),
      in_Qtde_criancas: search.rooms
        ?.map((room) => room.childrens.children.length)
        .reduce((acc, curr) => (acc += curr), 0),
      in_idade_criancas: search.rooms
        .map((room) => {
          if (room.childrens.number === 0 || !room.childrens.children.length) {
            return "-1";
          }
          return room.childrens.children.map((child) => child.year).join();
        })
        .join(","),
      in_internacional: search.place.pais === "BR" ? 0 : 1,
      in_pagina: search?.pagina || INITIAL_PAGE,
      in_TipoPlano: search?.tipoPlano || INITIAL_PLAN_SELECT,
    };
  }

  // Filter or paginate + First
  useEffect(() => {
    const token = localStorage.getItem("token") || tokenApi;
    const params: boolean =
      currentPage !== undefined &&
      filterNeighborhood !== undefined &&
      filterServices !== undefined &&
      planSelect !== undefined &&
      neighborhood !== undefined;

    if (state?.data?.place && token && params) {
      setShowModalDelaySearch(false);
      setLoading(true);
      setHotels([]);

      const timeoutId = setTimeout(() => {
        setShowModalDelaySearch(true); // muda o estado para mostrar o modal de atraso
      }, TIME_WAIT_TO_MODAL_DELAY_SEARCH);

      const childList = state?.data?.rooms?.map((room) => {
        return room.childrens.children.map((child) => child.year).join();
      });
      const yearList = childList?.map((item) => {
        if (item !== "") {
          return item;
        }
        return "-1";
      });

      const data = {
        in_token: token,
        in_Codigo: state.data.place?.Codigo,
        in_Periodo_Inicial: formatDate(state?.data.dateIn) || "",
        in_Periodo_Final: formatDate(state?.data.dateOut) || "",
        in_Qtde_quartos: state?.data?.rooms?.length || 1,
        in_carCodigos: filterServices,
        in_hotBairro: filterNeighborhood,
        in_adultos: state?.data?.rooms
          ?.map((room) => room.adducts.toString())
          .join(","),
        in_Qtde_criancas: state?.data?.rooms
          ?.map((room) => room.childrens.children.length)
          .reduce((acc, curr) => (acc += curr), 0),
        in_idade_criancas: yearList?.join(),
        in_internacional: 0,
        in_pagina: currentPage > 0 ? currentPage : 1,
        in_TipoPlano: planSelect || -1,
      };

      if (!neighborhood?.length) {
        BeforeSearch({
          token,
          dateIn: state?.data?.dateIn,
          dateOut: state?.data?.dateOut,
          place: {
            Codigo: state.data.place.Codigo,
            CodigoID: state.data.place.CodigoID,
            Descricao: state.data.place.Descricao,
            Tipo: state.data.place.Tipo,
            pais: state.data.place.pais,
            UF: state.data.place.UF,
          },
          rooms: state.data?.rooms || [
            { adducts: 2, childrens: { number: 0, children: [] } },
          ],
        });
      }

      api
        .post(`/wsCoobrastur/Hotel.asmx/hoteis_C_1`, {
          dados: JSON.stringify(data),
        })
        .then((response) => {
          const { Table1, Table }: ResponseData = response.data;

          if (response.data?.Table?.[0]?.Texto1) {
            setModal(true);
            setMessage({
              title: "Você ficou muito tempo sem navegar",
              body: `${response.data.Table[0].Texto1}`,
            });

            return;
          }

          if (Table1[0]?.situacao === 0) {
            localStorage.setItem("token", tokenApi);
            return;
          }

          if (!Table1) {
            throw new Error("No response!");
          }

          const newData = Table1.map((hotel) => {
            return {
              id: hotel.id,
              name: hotel.hotnomefantasia,
              description: hotel.adiDescricaoParaHomePage,
              address: hotel.Endereco,
              neighborhood: hotel.hotBairro,
              obs: hotel.adiObs_HomePage,
              email: hotel.resEmail,
              phone: hotel.resFone,
              website: hotel.hotHomePage,
              hotCodigo: hotel.hotCodigo,
              link_disponibilidade: hotel.link_disponibilidade,
              features_apto: hotel.Caracteristicas_apto,
              features_hotel: hotel.caracteristicas,
              warning: hotel.texto,
              dates: hotel.link_datas,
              availability: hotel.link_disponibilidade,
              plan: hotel.Rede,
              originalData: hotel,
              btnReserve: hotel.botao_reservar,
              Promotion: hotel.Promocao,
            };
          });

          const totalPages = Table1[0].qtde_pag;
          const totalHotels = Table1[0].qtde_hoteis;

          if (!neighborhood?.length) {
            const neighborhoods = Table.map(({ bairros }) => ({
              name: bairros.toLocaleLowerCase(),
              value: bairros,
            })).sort();

            setNeighborhood(neighborhoods);
          }

          setHotels(newData);
          setTotalPages(totalPages);
          setTotalHotels(totalHotels);
          setLoading(false);
          clearTimeout(timeoutId);
        })
        .catch((erro) => {
          console.log(erro);
          setLoading(false);
          clearTimeout(timeoutId);
        });
    }
  }, [
    state,
    tokenApi,
    currentPage,
    filterNeighborhood,
    filterServices,
    planSelect,
    neighborhood,
  ]);

  useEffect(() => {
    api
      .get("/wsCoobrastur/ConsultaHoteis.asmx/C19_1")
      .then((response) => {
        const { data } = response;

        const newData = data
          .map((item: any) => {
            return {
              id: item.carCodigo,
              name: item.carDescricao.toLocaleLowerCase(),
              value: item.carCodigo,
            };
          })
          .sort((a: any, b: any) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          .map((val: any) => {
            return JSON.stringify(val);
          })
          .filter(
            (value: any, ind: any, arr: any) => arr.indexOf(value) === ind
          )
          .map((val: any) => {
            return JSON.parse(val);
          });

        setServices(newData);
        setData({
          commodities: newData,
        });
      })
      .catch(console.error);
  }, [setData]);

  useEffect(() => {
    api
      .get("/wsCoobrastur/ConsultaHoteis.asmx/C18_1")
      .then((response) => {
        const { data } = response;

        const newData = data.map((item: any) => {
          return {
            id: item.aptoCodigo,
            name: item.aptoDescricao.toLocaleLowerCase(),
          };
        });

        setStoreRoom({
          commodities: newData,
        });
      })
      .catch(console.error);
  }, [setStoreRoom]);

  useEffect(() => {
    const neighborhood: string[] = [];
    const services: string[] = [];

    filterSelected.forEach((filter) => {
      if (filter.type === "neighborhood") {
        neighborhood.push(filter.value);
      }

      if (filter.type === "services") {
        services.push(filter.value);
      }
    });

    setCurrentPage(1);
    setTotalPages(0);
    setTotalHotels(0);
    setFilterNeighborhood(neighborhood.join(","));
    setFilterServices(services.join(","));
    setPlanSelect(planSelect);
  }, [filterSelected, planSelect]);

  return (
    <Fragment>
      {modalUnderstand && (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.48)",
            position: "fixed",
            zIndex: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              maxWidth: "500px",
              height: "auto",
              padding: "16px",
              backgroundColor: "var(--white)",
              borderRadius: "20px",
              margin: 12,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <h5 style={{ color: "var(--purple600)" }}>
              ESTE HOTEL NÃO POSSUI VAGA PARA O PERÍODO CONSULTADO!
            </h5>
            <div>
              <p>
                Você pode enviar uma solicitação diretamente para o hotel
                verificar se aceita sua reserva para este período.
              </p>
              <p>
                Os quartos reservados para a Coob+ estão esgotados e é uma
                decisão do próprio hotel aceitar ou não sua solicitação.
              </p>
              <p>A Coob+ não pode interferir nessa solicitação.</p>
            </div>
            <BtnKeep onClick={handleToggleModalUnderstand}>Ok Entendi</BtnKeep>
          </div>
        </div>
      )}
      <Container>
        <Header>
          <ContentHeader>
            <HeaderSearch>
              {mobile ? (
                <Search resetFilterPlan={setPlanSelect} />
              ) : (
                <SearchDesktop
                  resetFilterPlan={setPlanSelect}
                  initialQuery={state?.data?.place?.Descricao}
                />
              )}
              <Hgroup>
                <Title>Etapas para concluir sua reserva</Title>
                <Text>
                  Você está primeira etapa. Escolha a sua hospedagem dentro os
                  resultados abaixo para prosseguir.
                </Text>
              </Hgroup>
            </HeaderSearch>
            <Steps
              stepOne={"active"}
              stepTwo={"disabled"}
              stepThree={"disabled"}
            />
          </ContentHeader>
        </Header>

        <ContainerPlans id={PLANS_ID}>
          <PlansList ref={plans}>
            <PlanItem className="active">
              <button
                type="button"
                value={-1}
                onClick={selectPlan}
                id="initFilterPlans"
              >
                Todos os Planos
              </button>
            </PlanItem>
            <PlanItem>
              <button type="button" value={1} onClick={selectPlan}>
                Vip
              </button>
            </PlanItem>
            <PlanItem>
              <button type="button" value={1} onClick={selectPlan}>
                Master
              </button>
            </PlanItem>
            <PlanItem>
              <button type="button" value={2} onClick={selectPlan}>
                Gold Vip
              </button>
            </PlanItem>
            <PlanItem>
              <button type="button" value={2} onClick={selectPlan}>
                Gold Master
              </button>
            </PlanItem>
            <PlanItem>
              <button type="button" value={3} onClick={selectPlan}>
                Diamante
              </button>
            </PlanItem>
            <PlanItem>
              <button type="button" value={4} onClick={selectPlan}>
                GO Vip
              </button>
            </PlanItem>
            <PlanItem>
              <button type="button" value={4} onClick={selectPlan}>
                GO Master
              </button>
            </PlanItem>
          </PlansList>
        </ContainerPlans>

        <Separator />

        <ContentGrid>
          <ButtonFilter type="button" onClick={() => setOpen(!open)}>
            <span>
              {filterSelected.length > 0 ? (
                <span>{filterSelected.length}</span>
              ) : (
                <IconFilter />
              )}
            </span>
            Refinar busca
          </ButtonFilter>

          <ContentAside className={open ? "active" : ""}>
            <ContentAsideHeader>
              <TitleAside>
                {mobile && <IconFechar onClick={() => setOpen(!open)} />}
                <span>Filtrar Resultados</span>
                {mobile && <IconBussola />}
              </TitleAside>
            </ContentAsideHeader>

            <ContentAsideBody>
              {!mobile && <Separator />}

              {filterSelected.length > 0 && (
                <ButtonClearFilters className="acitve" onClick={clearAllFilter}>
                  Limpar
                </ButtonClearFilters>
              )}

              <SubtitleAside>Serviços</SubtitleAside>

              <FilterAccordion filters={services} type={"services"} />

              <Separator />

              <SubtitleAside>Bairros</SubtitleAside>

              <FilterAccordion filters={neighborhood} type={"neighborhood"} />
            </ContentAsideBody>
            <ContentAsideFooter>
              <ButtonClear
                type="button"
                onClick={() => {
                  clearAllFilter();
                  setOpen(!open);
                }}
              >
                LIMPAR
              </ButtonClear>
              <ButtonSubmit type="button" onClick={() => setOpen(!open)}>
                Filtrar
              </ButtonSubmit>
            </ContentAsideFooter>
          </ContentAside>

          <ContentBody>
            {loading ? (
              <Loading active={loading}>
                <span></span>
              </Loading>
            ) : (
              <Fragment>
                <TilteContent>
                  Foram encontrados{" "}
                  <strong>
                    {totalHotels} {totalHotels > 1 ? "hotéis" : "hotel"}
                  </strong>
                </TilteContent>

                {hotels.length <= 0 && (
                  <WrapperResearch>
                    <TilteContent>
                      <strong> Remarque os filtros ou </strong>
                    </TilteContent>
                    <BtnResearch onClick={handleTotop}>
                      Refazer busca
                    </BtnResearch>
                  </WrapperResearch>
                )}
                <Pagintaion>
                  {hotels.map((hotel) => (
                    <Fragment key={hotel.hotCodigo}>
                      <Card hotel={hotel} click={handleToggleModalUnderstand} />
                    </Fragment>
                  ))}

                  {totalPages > 1 && (
                    <Fragment>
                      <ul className="paginationBtns">
                        <li
                          className={`previousBtn ${
                            currentPage > 1 ? "" : "paginationDisabled"
                          }`}
                          onClick={_prevPage}
                        >
                          <span
                            role="button"
                            aria-disabled="true"
                            aria-label="Previous page"
                          ></span>
                        </li>
                        {Array(totalPages)
                          .fill(null)
                          .map((_, index) => (
                            <li
                              key={index.toString()}
                              aria-label={`page ${index}`}
                              className={`${
                                currentPage === index + 1
                                  ? "paginationActive"
                                  : ""
                              }`}
                              onClick={() => setCurrentPage(index + 1)}
                            >
                              <span>{index + 1}</span>
                            </li>
                          ))}
                        <li
                          className={`nextBtn ${
                            currentPage >= totalPages
                              ? "paginationDisabled"
                              : ""
                          }`}
                          onClick={_nextPage}
                        >
                          <span
                            role="button"
                            aria-disabled="false"
                            aria-label="Next page"
                          ></span>
                        </li>
                      </ul>
                    </Fragment>
                  )}
                </Pagintaion>
              </Fragment>
            )}

            {/* <ModalPolicy /> */}

            <ModalCancellationPolicy />
            <ModalFormConfirmation
              modalIsOpen={modal}
              setIsOpen={handleCloseModal}
              message={message}
            />
            <ModalAlert
              isOpen={showModalDelaySearch && loading}
              onClose={() => setShowModalDelaySearch(false)}
              message={{
                title: "Sua Pesquisa está demorando!",
                body: "Isso está levando mais tempo do que o esperado. \n Deseja voltar a tela inicial e refazer sua pesquisa? ",
              }}
              textButtonFooter="Voltar a Tela inicial"
              onClickButtonFooter={() => {
                handleCloseModal();
              }}
            />
          </ContentBody>
        </ContentGrid>
      </Container>
    </Fragment>
  );
};

export default Accommodation;
