import styled from "styled-components";

export const WrapperDetails = styled.div`
  width: 40%;
  min-width: 359px;
  height: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 500px) {
    min-width: 410px;
  }
`;

export const BoxDetails = styled.div`
  width: 100%;
  border: 1px solid var(--gray500Opacity);

  border-radius: 20px;
  height: 100%;
  max-height: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px 10px;
  @media (max-width: 500px) {
    border: 0;
  }
`;

export const Typography = styled.h4`
  margin: 0;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-weight: bold;
  color: var(--orange600);
  font-size: 20px;
`;

export const DetailsHotel = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: 10px 0px;
  justify-content: space-evenly;

  box-sizing: border-box;
`;

export const DetailsConfig = styled.div`
  width: 100%;
  height: 150px;
  border: 1px solid var(--gray500Opacity);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;

  box-sizing: border-box;
`;

export const DetaisHotelImage = styled.img`
  width: 120px;
  height: 150px;
  border-radius: 10px;
  object-fit: cover;
`;

export const DetaisHotelInfo = styled.div`
  width: 210px;
  display: flex;
  min-height: 150px;
  flex-direction: column;
`;

export const TitleHotel = styled.h6`
  margin: 0;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-weight: bold;
  font-size: 16px;
`;

export const WrapperLocation = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  img {
    margin-top: 2px;
    margin-right: 4px;
  }
`;

export const AdressHotel = styled.p`
  width: 100%;
  margin: 0;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-size: 14px;
  font-weight: 500;
  text-align: left;
`;

export const WrapperPlan = styled.div`
  display: flex;
  width: 100%;
  max-width: 160px;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
`;

export const WrapperDates = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
`;

export const BoxCalendary = styled.div`
  width: 200px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const BoxDate = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;

export const Typrography = styled.span`
  color: var(--gray500);
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-size: 13px;
`;

export const Dates = styled.span`
  color: var(--purple600);
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-size: 13px;
  font-weight: bold;
`;

export const WrapperRooms = styled.div`
  width: 100%;
  display: flex;
  padding: 8px;
  img {
    width: 25px;
  }
  span {
    margin-left: 10px;
    color: var(--purple600);
    font-weight: bold;
    font-size: 14px;
    font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
      BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", "Helvetica Neue";
    letter-spacing: 0.58px;
  }
`;

export const WrapperDays = styled.div`
  width: 100%;
  max-width: 280px;
  display: flex;
`;

export const DurationText = styled.span`
  color: var(--gray500);
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-size: 0.9rem;
  font-weight: 500;
  strong {
    color: var(--purple600);
  }
`;

export const ErrorText = styled.span`
  color: var(--red500);
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-size: 0.9rem;
  font-weight: 500;
  strong {
    color: var(--red500);
  }
`;
