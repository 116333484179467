import styled from "styled-components";
import review from "../../assets/icones/moreReview.svg";
import ArrowLeft from "../../components/prevArrow/assets/arrowLeft.svg";
import ArrowRight from "../../components/nextArrow/assets/arrowRight.svg";
import { ImageCardPromotionProps } from "./types";

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 80px;
`;

export const WrapperCarousel = styled.div`
  width: 100%;
  height: auto;

  .slick-dots {
    bottom: -22px;

    li {
      margin: 0 2px;

      &.slick-active {
        button {
          &::before {
            background-color: var(--purple600);
          }
        }
      }

      button {
        &::before {
          content: "";
          width: 8px;
          height: 8px;
          background-color: transparent;
          border: 1px solid var(--purple600);
          border-radius: 50%;
          opacity: 1;
          transition: all 0.3s ease;
        }

        &:hover {
          &::before {
            background-color: var(--purple600);
          }
        }
      }
    }
  }
  @media (max-width: 990px) {
    background-color: var(--purple600);
    transform: translate(0, 1px);
  }

  @media (max-width: 500px) {
    div.slick-slide,
    img {
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const ImgItem = styled.img`
  display: block;
  width: 100%;
  object-fit: cover;
  height: auto;

  @media screen and (max-width: 500px) {
    display: none !important;
  }
`;

export const ImgItemMobile = styled.img`
  display: none !important;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: fill;

  @media screen and (max-width: 500px) {
    display: block !important;
  }
`;

export const ImageCardPromotion = styled.div<ImageCardPromotionProps>`
  width: 100%;
  height: 280px;
  background-image: url(${({ img }) => img});
  background-size: cover;
  background-repeat: no-repeat;
  border-top-right-radius: 100px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 10px;
  box-sizing: border-box;
`;

export const Section = styled.section`
  width: 100%;
  box-sizing: border-box;
  padding: 0px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
  scroll-margin-top: 60px;

  @media (max-width: 1100px) {
    padding: 0px;
  }
`;

export const TitleSection = styled.h3`
  width: 100%;
  max-width: 482px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
  margin: 0;
  text-transform: uppercase;
  color: #ec6726;
  text-align: center;

  @media (max-width: 500px) {
    max-width: 350px;
    font-size: 1.4rem;
  }
`;

export const WrapperCarouselBenefits = styled.div`
  width: 100%;
  max-width: 1100px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;

  @media (max-width: 1200px) {
    padding-left: 30px;
  }

  @media (max-width: 1100px) {
    padding-left: 50px;
    max-width: 800px;
    margin-left: 100px;
  }
  @media (max-width: 900px) {
    padding-left: 50px;
    max-width: 700px;
    margin-left: auto;
  }
  @media (max-width: 780px) {
    max-width: 600px;
    margin-left: auto;
  }
  @media (max-width: 450px) {
    margin-left: 0px;
  }
  @media (max-width: 400px) {
    margin-left: -30px;
  }
`;

export const WrapperItensSection = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 1100px;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

export const SectionCarouselPromo = styled.section`
  box-sizing: border-box;
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-direction: row;
  @media (max-width: 1024px) {
    padding-left: 30px;
  }
  @media (max-width: 500px) {
    padding-left: 0px;
  }
`;

export const WrapperPhotoSection = styled.div`
  width: 30%;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const ImgCouple1 = styled.img`
  max-width: 100%;
`;

export const CardPromotion = styled.div`
  width: 100%;
  max-width: 300px;
  height: 500px;
  padding: 10px;
  box-sizing: border-box;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;

  @media (max-width: 500px) {
    margin-left: 50px;
  }

  @media (max-width: 450px) {
    margin-left: 20px;
  }
`;

export const BadgeCard = styled.div`
  margin: 0;
  box-sizing: border-box;
  background-color: #dadada;
  padding: 8px 12px;
  border-radius: 25px;
  min-width: 114px;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
  color: #3a2e83;
  text-transform: uppercase;
  transform: translateY(-28px);
`;

export const TitleCard = styled.h4`
  margin: 0;
  margin-bottom: 0.45rem;
  color: #3a2e83;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.8rem;
  transform: translateY(-1rem);
  text-transform: capitalize;
  text-align: center;
`;

export const DescriptioncCard = styled.span`
  transform: translateY(-1rem);
  font-size: 0.875rem;
  font-weight: 700;
  color: #3a2e83;
  text-transform: uppercase;
`;

export const BtnSeeOffers = styled.button`
  width: 300px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #e50076;
  outline: none;
  border-radius: 25px;
  font-size: 1rem;
  color: #e50076;
  font-weight: 700;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    background-color: #e50076;
    color: #fff;
    border: 0;
  }
  @media (max-width: 780px) {
    width: 100%;
    max-width: 250px;
  }
`;

export const BtnSubscription = styled.a`
  width: 300px;
  height: 40px;
  background-color: #e50076;
  border-radius: 25px;
  font-size: 1rem;
  color: #fff;
  font-weight: 700;
  margin-top: 8px;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: #f489ba;
  }

  @media (max-width: 780px) {
    width: 100%;
    max-width: 250px;
  }
`;

export const WrapperContent = styled.div`
  width: 100%;
  max-width: 1100px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;

  @media (max-width: 1100px) {
    justify-content: center;
  }

  @media (max-width: 768px) {
    height: auto;
    flex-direction: column;
  }
`;

export const TitleContent = styled.h4`
  width: 100%;
  max-width: 300px;
  font-size: 1.8rem;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
  color: #ec6726;
  text-align: center;
  @media (max-width: 768px) {
    max-width: 400px;
  }

  @media (max-width: 500px) {
    max-width: 350px;
    font-size: 1.4rem;
  }
`;

export const ParagraphContent = styled.p`
  margin: 0;
  width: 300px;
  color: #ec6726;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-weight: 500;
  font-size: 1.2rem;
  text-align: center;
`;

export const WrapperItensContent1 = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    display: none;
  }
`;
export const WrapperItensContent1Mobile = styled.div`
  display: none;
  padding-top: 10px;
  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const ColumnCentered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

export const WrapperItensContent2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    height: 200px;
  }
`;

export const SectionReviews = styled.div`
  width: 100%;
  max-width: 600px;
  height: auto;
  display: flex;
  padding-top: 30px;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
`;
export const WrapperSlides = styled.div`
  padding-left: 25px;
  padding-right: 15px;
  display: flex !important;
  max-width: 100vw;

  @media (max-width: 590px) {
    width: calc(100vw - 70px);
  }
`;
export const WrapperReviews = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 45px;
  border: 2px solid #e50076;
  border-top-left-radius: 50px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 50px;
  align-items: center;
  justify-content: space-evenly;
  margin: 45px 15px 45px 25px;

  &::before {
    content: "";
    background-image: url("${review}");
    background-size: cover;
    display: block;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    right: 60px;
    transform: translate(-50%, -50%);
  }

  &::after {
    content: "";
    background-image: url("${review}");
    background-size: cover;
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
  }
`;

export const ParagraphReview = styled.div`
  width: 100%;
  text-align: center;
  color: #3a2e83;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin: 0;
  margin-bottom: 15px;

  &::before {
    content: "";
    background-image: url("${review}");
    background-size: cover;
    display: block;
    width: 42px;
    height: 42px;
    position: absolute;
    top: 0;
    right: 15px;
    transform: translate(-50%, -50%);
  }

  &::after {
    content: "";
    background-image: url("${review}");
    background-size: cover;
    display: block;
    width: 42px;
    height: 42px;
    position: absolute;
    top: 100%;
    right: 15px;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 500px) {
    font-size: 1.1rem;
  }
`;

export const NameUserReview = styled.span`
  width: 100%;

  text-align: center;

  color: #3a2e83;
  font-size: 1.25rem;
  font-weight: 700;
`;

// export const CityUserReview = styled.span`
//   width: 100%;
//   position: absolute;
//   margin-left: 50%;
//   margin-right: 50%;
//   text-align: center;
//   top: 72%;
//   color: #3a2e83;
//   font-family: "Poppins", "Open-sans", sans-serif, -apple-system,
//     BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
//     "Fira Sans", "Droid Sans", "Helvetica Neue";
//   font-size: 0.9rem;
//   @media (max-width: 500px) {
//     top: 78%;
//   }
// `;

export const ImgCouple2 = styled.img``;

export const WrapperLastContent = styled.div`
  width: 100%;
  max-width: 590px;
  box-sizing: border-box;
  padding-top: 50px;
  padding-bottom: 65px;
  padding-left: 0;
  display: flex;
  justify-content: center;
`;

export const WrapperLastSlider = styled.div`
  width: 100%;
  max-width: 590px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  .slick-dots {
    li {
      margin: 0 2px;

      &.slick-active {
        button {
          &::before {
            background-color: var(--purple600);
          }
        }
      }

      button {
        &::before {
          content: "";
          width: 8px;
          height: 8px;
          background-color: transparent;
          border: 1px solid var(--purple600);
          border-radius: 50%;
          opacity: 1;
          transition: all 0.3s ease;
        }

        &:hover {
          &::before {
            background-color: var(--purple600);
          }
        }
      }
    }
  }

  @media (max-width: 590px) {
    width: calc(100vw - 70px);
  }

  @media (max-width: 330px) {
    margin-left: 20px;
    width: calc(100vw - 30px);
  }
`;

export const PrevArrowDiv = styled.div`
  background: url(${ArrowLeft}) no-repeat 50% 50% #4b48b7;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  opacity: 1;
  z-index: 2;
  cursor: pointer;
  border: 5px solid white;

  :before {
    content: none;
  }
  :hover {
    background: url(${ArrowLeft}) no-repeat 50% 50% #4b48b7;
  }
`;

export const NextArrowDiv = styled.div`
  background: url(${ArrowRight}) no-repeat 50% 50% #4b48b7;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  opacity: 1;
  z-index: 2;
  cursor: pointer;
  border: 5px solid white;

  :before {
    content: none;
  }
  :hover {
    background: url(${ArrowRight}) no-repeat 50% 50% #4b48b7;
  }
`;

export const CardImage = styled.div`
  width: 300px !important;
  height: 150px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  box-sizing: border-box !important;
  margin-left: 10px;
  margin-right: 10px;
  @media (max-width: 1024px) {
    width: 250px !important;
  }

  @media (max-width: 500px) {
    width: 250px !important ;
  }

  @media (max-width: 430px) {
    margin-top: 0px;
    margin-left: -20px;
  }

  a img {
    @media (max-width: 420px) {
      width: 170px;
      margin-left: -10px;
    }
    @media (max-width: 400px) {
      width: 170px;
      margin-left: -30px;
    }
  }
`;

export const WrapperPartnersContent = styled.div`
  max-width: 1200px;
  display: flex;
  justify-content: center;
`;

export const WrapperPartnersCards = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: start;
  box-sizing: border-box;
  padding-top: 50px;

  max-width: 1200px;
  width: 1200px;

  @media (max-width: 1350px) {
    //padding 50px each side
    width: 1000px;
  }

  @media (max-width: 1100px) {
    width: 800px;
  }

  @media (max-width: 850px) {
    width: 600px;
  }

  @media (max-width: 650px) {
    width: 450px;
  }
  @media (max-width: 500px) {
    width: 300px;
  }

  @media (max-width: 310px) {
    width: 150px;
  }
`;

export const CardPartnerImage = styled.div`
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  @media (max-width: 1350px) {
    width: 200px;
  }

  @media (max-width: 1100px) {
    width: 200px;
  }

  @media (max-width: 850px) {
    width: 200px;
  }

  @media (max-width: 650px) {
    width: 150px;
  }

  @media (max-width: 500px) {
    width: 150px;
  }

  @media (max-width: 310px) {
    width: 150px;
  }

  > img,
  > a > img {
    object-fit: contain;

    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);

    :hover,
    :focus,
    :active {
      filter: none;
      -webkit-filter: none;
    }

    width: 200px;
    height: 150px;

    @media (max-width: 1350px) {
      width: 200px;
      height: 150px;
    }

    @media (max-width: 1100px) {
      width: 200px;
      height: 150px;
    }

    @media (max-width: 850px) {
      width: 200px;
      height: 150px;
    }

    @media (max-width: 650px) {
      width: 150px;
      height: 100px;
    }

    @media (max-width: 500px) {
      width: 150px;
      height: 100px;
    }

    @media (max-width: 310px) {
      width: 150px;
      height: 100px;
    }
  }
`;
export const ImagePartner = styled.img``;

export const TitleCoob = styled.h1`
  position: absolute;
  z-index: 4;
  opacity: 0;
  left: 0;
`;
