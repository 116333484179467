export const regexCpf = RegExp(/(\d{3})(\d{3})(\d{3})(\d{2})/);
export const RegexCNPJ = new RegExp(
  /^(\d{3})\.?(\d{3})\.?(\d{3})-?(\d{2}$)$|^(\d{2})\.?(\d{3})\.?(\d{3})\/?([0-1]{4})-?(\d{2})$/
);
export const regexUpperCase = RegExp(/^(?=.*[A-Z]).+$/);
export const regexLowerCase = RegExp(/^(?=.*[a-z]).+$/);
export const regexNumber = RegExp(/^(?=.*[0-9]).+$/);
export const regexSpecialCharacters = RegExp(/[^a-zA-Z 0-9]+/g);
export const RegexVisa = new RegExp(/^4[0-9]{12}(?:[0-9]{3})/);
export const RegexMaster = new RegExp(
  /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{14}$/
);

export const RegexElo = new RegExp(
  /^(4011(78|79)|43(1274|8935)|45(1416|7393|763(1|2))|50(4175|6699|67[0-7][0-9]|9000)|50(9[0-9][0-9][0-9])|627780|63(6297|6368)|650(03([^4])|04([0-9])|05(0|1)|05([7-9])|06([0-9])|07([0-9])|08([0-9])|4([0-3][0-9]|8[5-9]|9[0-9])|5([0-9][0-9]|3[0-8])|9([0-6][0-9]|7[0-8])|7([0-2][0-9])|541|700|720|727|901)|65165([2-9])|6516([6-7][0-9])|65500([0-9])|6550([0-5][0-9])|655021|65505([6-7])|6516([8-9][0-9])|65170([0-4]))/
);

export const RegexAmex = new RegExp(/^3[47][0-9]{13}/);
export const RegexDinners = new RegExp(/^3(?:0[0-5]|[68][0-9])[0-9]{11}/);
export const RegexHyperCard = new RegExp(
  /^(606282\d{10}(\d{3})?)|(3841\d{15})$/
);
