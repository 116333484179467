import InputRadio from "../../../components/InputRadio";

import {
  AreaInfoPayment,
  BoxPayment,
  FormCard,
  LinkKnowMore,
  ParagraphInfoPayment,
  Subtitle,
  TitlePayment,
  TotalvalueArea,
} from "./styles";
import { useEffect, useState } from "react";
import {
  optionsPaymentcard,
  optionsPaymentcard2,
  optionsPaymentcard3,
  optionsPaymentcard4,
  optionsPaymentcard5,
  optionsPaymentcard6,
  optionsPayments,
} from "./constants";
import * as Yup from "yup";
import { Errors } from "../../Identify/types";
import { ChoosePaymentsProps, DataPaymentProps } from "./types";
import { FormHelpers } from "@unform/core";
import { api } from "../../../services";
import FieldsPaymentCard from "../../Payments/Roons/ChoosePayments/FieldsPaymentcard";
import { removeHtmlInString } from "../../../utils/FormatText";
import { useChoosePayment } from "../../../store/useChoosePayment";
import { validateFormChoosePaymentsInternational as validateForm } from "./validateForm";
import { FieldPaymentPropsWithoutTotal } from "../../Payments/Roons/ChoosePayments/FieldsPaymentcard/types";

const initialData = {
  parcelas: "Número de parcelas",
};

const ChoosePaymentsInternational = ({
  formRef,
  value,
  errorPayment,
  id,
}: ChoosePaymentsProps) => {
  const [openPaymentCard, setOpenPaymentcard] = useState<boolean>(false);
  // const [phoneUser, setPhoneUser] = useState<string>("");
  // const [email, setEmail] = useState<string>("");
  const inToken = localStorage.getItem("tokenPortalAssociado");
  // const nameUser = localStorage.getItem("nameUser");
  const userId = localStorage.getItem("assnic");
  const setName = useChoosePayment((state) => state.setName);
  //const formRef = useRef<FormHandles>(null);

  const handleOpenAreaPaymentcard = (item: any) => {
    setName(item.name);

    if (item.name === "cartao") {
      setOpenPaymentcard(true);
      return;
    }
    setOpenPaymentcard(false);
  };

  // const toDoPaymentWithcard = (
  //   numberOfCard: string,
  //   month: string,
  //   year: string,
  //   portion: string,
  //   cvc: string,
  //   nome:string,
  // ) => {
  //   const ddd = phoneUser.substring(0, 2);
  //   const numberOfportion = portion.substring(0, 1);
  //   const total = value.toFixed(2).toString();
  //   const phoneWIthouDDD = phoneUser.substring(2, phoneUser.length);
  //   const data = {
  //     EmailPessoal: email,
  //     resTelefone: phoneWIthouDDD,
  //     resTelefoneDDD: ddd,
  //     disCodigo: null,
  //   };

  //   const dataPayment = {
  //     TipoPgto: 1,
  //     Titular: nome,
  //     Numero: numberOfCard,
  //     fmCodigo: 2,
  //     Mes: month,
  //     Ano: `20${year}`,
  //     CVC: cvc,
  //     Parcelas: numberOfportion,
  //     total: total,
  //   };
  //   return {
  //      api
  //      .get("/wsCoobrastur/Hotel.asmx/Pagamento_S_Hotel", {
  //      params: {
  //        dados: Object.values({
  //          t: "Pagamento_S_Hotel",
  //          in_token: token,
  //          reservaTempJson: JSON.stringify([data]),
  //          terceirosTempJson: JSON.stringify([]),
  //          acompanhantesTempJson: JSON.stringify([]),
  //          pagamentoJson: JSON.stringify([dataPayment]),
  //        }).join(";"),
  //      },
  //    })
  //     //   .then((resp) => {
  //     //     if (
  //     //       resp.data ===
  //     //       "Pagamento não autorizado.<br>Contate sua operadora.<br>Reserva não efetuada."
  //     //     ) {
  //     //       setErrorPayment(resp.data);
  //     //       return;
  //     //     }
  //     //     localStorage.setItem("resLocale", resp.data.resLocalizador);
  //     //     navigate(`${process.env.PUBLIC_URL}/reserva/${resp.data.resLocalizador || ""}`);
  //     //   })
  //     //   .catch((e) => {
  //     //     setErrorPayment("Algo deu errado, tente mais tarde");
  //     //     console.error({ componenet: "choosePayment", error: e });
  //     //   });
  //   };
  // };

  const handleSubmit = async (
    data: DataPaymentProps,
    { reset }: FormHelpers
  ) => {
    try {
      // console.log('fex-->',data)
      await validateForm(data, value);
      // toDoPaymentWithcard(
      //   resp.numeroDoCartao,
      //   data.mes,
      //   data.ano,
      //   data.parcelas,
      //   data.cvv,
      //   data.nome
      // );
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        console.error({
          path: err.path,
          message: err.message,
          value: err.value,
          type: err.type,
          inner: err.inner,
        });
        const errorMessage: Errors = {};

        err.inner.forEach((error) => {
          if (error.path) {
            errorMessage[error.path] = error.message;
          }
          return formRef.current?.setErrors(errorMessage);
        });
        return;
      } else {
        console.error(err);
      }
    }
    formRef.current?.setErrors({});
  };

  const numberOfPortion = (): FieldPaymentPropsWithoutTotal[] => {
    if (value < 100) {
      return optionsPaymentcard;
    }

    if (value >= 100 && value < 150) {
      return optionsPaymentcard2;
    }

    if (value >= 150 && value < 200) {
      return optionsPaymentcard3;
    }

    if (value >= 200 && value < 250) {
      return optionsPaymentcard4;
    }

    if (value >= 250 && value < 300) {
      return optionsPaymentcard5;
    }

    return optionsPaymentcard6;
  };

  useEffect(() => {
    if (userId && inToken)
      api
        .get(`/wsCoobrastur/ConsultaHoteis.asmx/PU_1_2`, {
          params: { nic: userId, in_token: inToken },
        })
        .then((resp) => {
          if (resp.status === 200) {
            // setEmail(resp.data[0].assEmailPessoal);
            // setPhoneUser(
            //   `${resp.data[0].assNumCelularDDD}${resp.data[0].assNumCelular}`
            // );
            const dataUser = {
              ddd: resp.data[0].assNumCelularDDD,
              cel: resp.data[0].assNumCelular,
              birthday: resp.data[0].assDTNascimento,
            };
            localStorage.setItem("completeForm", JSON.stringify(dataUser));
            return;
          }
          alert("algo deu errado, recarregue á pagina");
        })
        .catch((e) => console.error({ component: "Choosepayment", error: e }));
  }, [userId, inToken]);

  return (
    <BoxPayment id={id}>
      <TitlePayment>PAGAMENTO</TitlePayment>
      <AreaInfoPayment> pagamentos</AreaInfoPayment>
      <ParagraphInfoPayment>
        O valor adicional é cobrado por extras como camas adicionais, períodos
        fetivos, denominadas Pacotes, refeições extras em hotéis com regimes de
        All Inclusive ou Meia pensão ou pensão completa.
      </ParagraphInfoPayment>
      <LinkKnowMore href={`${process.env.PUBLIC_URL}/Ajuda`} target="_blank">
        Saiba mais
      </LinkKnowMore>
      <TotalvalueArea>
        <span>Valor total a pagar:</span>
        <strong>
          {value.toLocaleString("pt-Br", {
            style: "currency",
            currency: "BRL",
          })}
        </strong>
      </TotalvalueArea>
      <Subtitle>Forma de pagamento</Subtitle>
      <InputRadio
        fontSize="1rem"
        direction="row"
        justifyContent="flex-start"
        paddingLeft="18px"
        options={optionsPayments}
        handleSelected={handleOpenAreaPaymentcard}
      />

      <FormCard
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={initialData}
        style={{ display: openPaymentCard ? "flex" : "none" }}
      >
        {numberOfPortion().map((option: FieldPaymentPropsWithoutTotal) => (
          <FieldsPaymentCard
            key={option?.id}
            id={option?.id}
            name={option?.name}
            placeholder={option?.placeholder}
            type={option?.type}
            options={option?.options}
            total={value}
            autoComplete={option?.autoComplete}
          />
        ))}
      </FormCard>

      {errorPayment && (
        <div
          style={{
            color: "var(--red600)",
            width: "100%",
            textAlign: "center",
            fontSize: "16px",
          }}
        >
          {removeHtmlInString(errorPayment)}
        </div>
      )}
    </BoxPayment>
  );
};

export default ChoosePaymentsInternational;
