import { useEffect } from "react";
import BannerPagesInst from "../../components/BannerPagesInst";
import { content } from "./constants";
import { Container, ContentArea, ItemParagraph } from "./styles";

const Security = () => {
  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    handleGoToTop();
  }, []);
  return (
    <Container>
      <BannerPagesInst title="CONFIANÇA E SEGURANÇA" />
      <ContentArea>
        {content.map((item) => (
          <ItemParagraph key={item.id}>{item.paragraph}</ItemParagraph>
        ))}
      </ContentArea>
    </Container>
  );
};

export default Security;
