export const optionsRadioInputQ1 = [
  {
    id: "sou hóspede",
    label: "Eu sou o hóspede principal.",
    value: "sou hospede",
    name: "quarto 1",
    checked: true,
  },
  {
    id: "outra pessoa",
    label: "Estou reservando para outra pessoa.",
    value: "outra pessoa",
    name: "quarto 1",
    checked: false,
  },
];

export const inputPassengerdata = [
  {
    name: "nameUser",
    label: "nome",
    id: "nome",
    description: "digite seu nome",
    type: "text",
  },
  {
    name: "lastNameUser",
    label: "sobrenome",
    id: "sobrenome",
    description: "digite seu sobrenome",
    type: "text",
  },
  {
    name: "cpfUser",
    label: "cpf",
    id: "cpf",
    description: "digite seu cpf",
    maxLength: 11,
    type: "cpf",
  },
  {
    name: "nasc",
    label: "data de nascimento",
    id: "nascimento",
    description: "",
    type: "date",
  },
  {
    name: "gender",
    label: "masc",
    id: "gender",
    description: "",
    type: "radio",
    options: [
      {
        label: "feminino",
        id: "feminino",
        value: 0,
        description: "feminino",
      },
      {
        label: "masculino",
        id: "masculino",
        value: 1,
        description: "masculino",
      },
    ],
  },
];

export const inputPassengerdataH2 = [
  {
    name: "nameUser",
    label: "nome",
    id: "nome",
    description: "digite seu nome",
    type: "text",
  },
  {
    name: "lastNameUser",
    label: "sobrenome",
    id: "sobrenome",
    description: "digite seu sobrenome",
    type: "text",
  },
  {
    name: "cpfUser",
    label: "cpf",
    id: "cpf",
    description: "digite seu cpf",
    maxLength: 11,
    type: "cpf",
  },
  {
    name: "nasc",
    label: "data de nascimento",
    id: "nascimento",
    description: "",
    type: "date",
  },
  {
    name: "genderH2",
    label: "masc",
    id: "gender",
    description: "",
    type: "radio",
    options: [
      {
        name: "gender",
        label: "feminino2",
        id: "feminino2",
        value: 0,
        description: "feminino",
      },
      {
        name: "gender",
        label: "masculino2",
        id: "masculino2",
        value: 1,
        description: "masculino",
      },
    ],
  },
];

export const inputPassengerdataH3 = [
  {
    name: "nameUser",
    label: "nome",
    id: "nome",
    description: "digite seu nome",
    type: "text",
  },
  {
    name: "lastNameUser",
    label: "sobrenome",
    id: "sobrenome",
    description: "digite seu sobrenome",
    type: "text",
  },
  {
    name: "cpfUser",
    label: "cpf",
    id: "cpf",
    description: "digite seu cpf",
    maxLength: 11,
    type: "cpf",
  },
  {
    name: "nasc",
    label: "data de nascimento",
    id: "nascimento",
    description: "",
    type: "date",
  },
  {
    name: "genderH3",
    label: "masc",
    id: "gender",
    description: "",
    type: "radio",
    options: [
      {
        name: "gender",
        label: "feminino3",
        id: "feminino3",
        value: 0,
        description: "feminino",
      },
      {
        name: "gender",
        label: "masculino3",
        id: "masculino3",
        value: 1,
        description: "masculino",
      },
    ],
  },
];

export const inputPassengerdataQ2H1 = [
  {
    name: "nameUser",
    label: "nome",
    id: "nome",
    description: "digite seu nome",
    type: "text",
  },
  {
    name: "lastNameUser",
    label: "sobrenome",
    id: "sobrenome",
    description: "digite seu sobrenome",
    type: "text",
  },
  {
    name: "cpfUser",
    label: "cpf",
    id: "cpf",
    description: "digite seu cpf",
    maxLength: 11,
    type: "cpf",
  },
  {
    name: "nasc",
    label: "data de nascimento",
    id: "nascimento",
    description: "",
    type: "date",
  },
  {
    name: "genderH1Q2",
    label: "masc",
    id: "gender",
    description: "",
    type: "radio",
    options: [
      {
        label: "femininoH1Q2",
        id: "femininoH1Q2",
        value: 0,
        description: "feminino",
      },
      {
        label: "masculinoH1Q2",
        id: "masculinoH1Q2",
        value: 1,
        description: "masculino",
      },
    ],
  },
];

export const inputPassengerdataQ2H2 = [
  {
    name: "nameUser",
    label: "nome",
    id: "nome",
    description: "digite seu nome",
    type: "text",
  },
  {
    name: "lastNameUser",
    label: "sobrenome",
    id: "sobrenome",
    description: "digite seu sobrenome",
    type: "text",
  },
  {
    name: "cpfUser",
    label: "cpf",
    id: "cpf",
    description: "digite seu cpf",
    maxLength: 11,
    type: "cpf",
  },
  {
    name: "nasc",
    label: "data de nascimento",
    id: "nascimento",
    description: "",
    type: "date",
  },
  {
    name: "genderH2Q2",
    label: "masc",
    id: "gender",
    description: "",
    type: "radio",
    options: [
      {
        label: "femininoH2Q2",
        id: "femininoH2Q2",
        value: 0,
        description: "feminino",
      },
      {
        label: "masculinoH2Q2",
        id: "masculinoH2Q2",
        value: 1,
        description: "masculino",
      },
    ],
  },
];

export const inputPassengerdataQ2H3 = [
  {
    name: "nameUser",
    label: "nome",
    id: "nome",
    description: "digite seu nome",
    type: "text",
  },
  {
    name: "lastNameUser",
    label: "sobrenome",
    id: "sobrenome",
    description: "digite seu sobrenome",
    type: "text",
  },
  {
    name: "cpfUser",
    label: "cpf",
    id: "cpf",
    description: "digite seu cpf",
    maxLength: 11,
    type: "cpf",
  },
  {
    name: "nasc",
    label: "data de nascimento",
    id: "nascimento",
    description: "",
    type: "date",
  },
  {
    name: "genderH3Q2",
    label: "masc",
    id: "gender",
    description: "",
    type: "radio",
    options: [
      {
        label: "femininoH3Q2",
        id: "femininoH3Q2",
        value: 0,
        description: "feminino",
      },
      {
        label: "masculinoH3Q2",
        id: "masculinoH3Q2",
        value: 1,
        description: "masculino",
      },
    ],
  },
];

export const inputPassengerdataQ3H1 = [
  {
    name: "nameUser",
    label: "nome",
    id: "nome",
    description: "digite seu nome",
    type: "text",
  },
  {
    name: "lastNameUser",
    label: "sobrenome",
    id: "sobrenome",
    description: "digite seu sobrenome",
    type: "text",
  },
  {
    name: "cpfUser",
    label: "cpf",
    id: "cpf",
    description: "digite seu cpf",
    maxLength: 11,
    type: "cpf",
  },
  {
    name: "nasc",
    label: "data de nascimento",
    id: "nascimento",
    description: "",
    type: "date",
  },
  {
    name: "genderH1Q3",
    label: "masc",
    id: "gender",
    description: "",
    type: "radio",
    options: [
      {
        label: "femininoH1Q3",
        id: "femininoH1Q3",
        value: 0,
        description: "feminino",
      },
      {
        label: "masculinoH1Q3",
        id: "masculinoH1Q3",
        value: 1,
        description: "masculino",
      },
    ],
  },
];

export const inputPassengerdataQ3H2 = [
  {
    name: "nameUser",
    label: "nome",
    id: "nome",
    description: "digite seu nome",
    type: "text",
  },
  {
    name: "lastNameUser",
    label: "sobrenome",
    id: "sobrenome",
    description: "digite seu sobrenome",
    type: "text",
  },
  {
    name: "cpfUser",
    label: "cpf",
    id: "cpf",
    description: "digite seu cpf",
    maxLength: 11,
    type: "cpf",
  },
  {
    name: "nasc",
    label: "data de nascimento",
    id: "nascimento",
    description: "",
    type: "date",
  },
  {
    name: "genderH2Q3",
    label: "masc",
    id: "gender",
    description: "",
    type: "radio",
    options: [
      {
        label: "femininoH2Q3",
        id: "femininoH2Q3",
        value: 0,
        description: "feminino",
      },
      {
        label: "masculinoH2Q3",
        id: "masculinoH2Q3",
        value: 1,
        description: "masculino",
      },
    ],
  },
];

export const inputPassengerdataQ3H3 = [
  {
    name: "nameUser",
    label: "nome",
    id: "nome",
    description: "digite seu nome",
    type: "text",
  },
  {
    name: "lastNameUser",
    label: "sobrenome",
    id: "sobrenome",
    description: "digite seu sobrenome",
    type: "text",
  },
  {
    name: "cpfUser",
    label: "cpf",
    id: "cpf",
    description: "digite seu cpf",
    maxLength: 11,
    type: "cpf",
  },
  {
    name: "nasc",
    label: "data de nascimento",
    id: "nascimento",
    description: "",
    type: "date",
  },
  {
    name: "genderH3Q3",
    label: "masc",
    id: "gender",
    description: "",
    type: "radio",
    options: [
      {
        label: "femininoH3Q3",
        id: "femininoH3Q3",
        value: 0,
        description: "feminino",
      },
      {
        label: "masculinoH3Q3",
        id: "masculinoH3Q3",
        value: 1,
        description: "masculino",
      },
    ],
  },
];

export const inputPassengerdataQ4H1 = [
  {
    name: "nameUser",
    label: "nome",
    id: "nome",
    description: "digite seu nome",
    type: "text",
  },
  {
    name: "lastNameUser",
    label: "sobrenome",
    id: "sobrenome",
    description: "digite seu sobrenome",
    type: "text",
  },
  {
    name: "cpfUser",
    label: "cpf",
    id: "cpf",
    description: "digite seu cpf",
    maxLength: 11,
    type: "cpf",
  },
  {
    name: "nasc",
    label: "data de nascimento",
    id: "nascimento",
    description: "",
    type: "date",
  },
  {
    name: "genderH1Q4",
    label: "masc",
    id: "gender",
    description: "",
    type: "radio",
    options: [
      {
        label: "femininoH1Q4",
        id: "femininoH1Q4",
        value: 0,
        description: "feminino",
      },
      {
        label: "masculinoH1Q4",
        id: "masculinoH1Q4",
        value: 1,
        description: "masculino",
      },
    ],
  },
];

export const inputPassengerdataQ4H2 = [
  {
    name: "nameUser",
    label: "nome",
    id: "nome",
    description: "digite seu nome",
    type: "text",
  },
  {
    name: "lastNameUser",
    label: "sobrenome",
    id: "sobrenome",
    description: "digite seu sobrenome",
    type: "text",
  },
  {
    name: "cpfUser",
    label: "cpf",
    id: "cpf",
    description: "digite seu cpf",
    maxLength: 11,
    type: "cpf",
  },
  {
    name: "nasc",
    label: "data de nascimento",
    id: "nascimento",
    description: "",
    type: "date",
  },
  {
    name: "genderH2Q4",
    label: "masc",
    id: "gender",
    description: "",
    type: "radio",
    options: [
      {
        label: "femininoH2Q4",
        id: "femininoH2Q4",
        value: 0,
        description: "feminino",
      },
      {
        label: "masculinoH2Q4",
        id: "masculinoH2Q4",
        value: 1,
        description: "masculino",
      },
    ],
  },
];

export const inputPassengerdataQ4H3 = [
  {
    name: "nameUser",
    label: "nome",
    id: "nome",
    description: "digite seu nome",
    type: "text",
  },
  {
    name: "lastNameUser",
    label: "sobrenome",
    id: "sobrenome",
    description: "digite seu sobrenome",
    type: "text",
  },
  {
    name: "cpfUser",
    label: "cpf",
    id: "cpf",
    description: "digite seu cpf",
    maxLength: 11,
    type: "cpf",
  },
  {
    name: "nasc",
    label: "data de nascimento",
    id: "nascimento",
    description: "",
    type: "date",
  },
  {
    name: "genderH3Q4",
    label: "masc",
    id: "gender",
    description: "",
    type: "radio",
    options: [
      {
        label: "femininoH3Q4",
        id: "femininoH3Q4",
        value: 0,
        description: "feminino",
      },
      {
        label: "masculinoH3Q4",
        id: "masculinoH3Q4",
        value: 1,
        description: "masculino",
      },
    ],
  },
];

export const oneRoonItem = [
  {
    id: 1,
    room: "quarto 1",
    formPassengerRef: null,
    formPassenger: [],
    tickets: [],
    numberPassenger: 0,
  },
];

export const twoRoonItem = [
  {
    id: 1,
    room: "quarto 1",
    formPassenger: [],
    formPassengerRef: null,
    tickets: [],
    numberPassenger: 0,
  },
  {
    id: 2,
    room: "quarto 2",
    formPassenger: [],
    formPassengerRef: null,
    tickets: [],
    numberPassenger: 0,
  },
];

export const threeRoonItem = [
  {
    id: 1,
    room: "quarto 1",
    formPassenger: [],
    tickets: [],
    formPassengerRef: null,
    numberPassenger: 0,
  },
  {
    id: 2,
    room: "quarto 2",
    formPassenger: [],
    tickets: [],
    formPassengerRef: null,
    numberPassenger: 0,
  },
  {
    id: 3,
    room: "quarto 3",
    formPassenger: [],
    tickets: [],
    formPassengerRef: null,
    numberPassenger: 0,
  },
];

export const fourRoonItem = [
  {
    id: 1,
    room: "quarto 1",
    formPassenger: [],
    tickets: [],
    formPassengerRef: null,
    numberPassenger: 0,
  },
  {
    id: 2,
    room: "quarto 2",
    formPassenger: [],
    tickets: [],
    formPassengerRef: null,
    numberPassenger: 0,
  },
  {
    id: 3,
    room: "quarto 3",
    formPassenger: [],
    tickets: [],
    formPassengerRef: null,
    numberPassenger: 0,
  },
  {
    id: 4,
    room: "quarto 4",
    formPassenger: [],
    formPassengerRef: null,
    numberPassenger: 0,
    tickets: [],
  },
];

export const dataFormOtherPerson = [
  {
    name: "nameUser",
    placeholder: "Nome completo",
    id: "nomeUsuario",
    type: "text",
  },
  {
    name: "ageUser",
    placeholder: "Idade",
    id: "idadeUsuario",
    type: "number",
  },
  {
    name: "cpfUser",
    placeholder: "CPF",
    id: "cpfUsuario",
    type: "text",
  },
  {
    name: "rgUser",
    placeholder: "RG",
    id: "rgUsuario",
    type: "text",
  },
  {
    name: "addressUser",
    placeholder: "Endereço completo",
    id: "endUsuario",
    type: "text",
  },
  {
    name: "cityUser",
    placeholder: "Cidade",
    id: "cidadeUsuario",
    type: "text",
  },

  {
    name: "ufUser",
    placeholder: "UF",
    id: "ufUsuario",
    type: "text",
  },
  {
    name: "emailUser",
    placeholder: "E-mail",
    id: "emailUsuario",
    type: "email",
  },
  {
    name: "parentUser",
    placeholder: "Parentesco",
    id: "paranteUsuario",
    type: "select",
  },
  {
    name: "phoneRes",
    placeholder: "Telefone Residencial",
    id: "phoneRes",
    type: "tel",
  },
  {
    name: "phoneCel",
    placeholder: "Telefone Celular",
    id: "phoneCel",
    type: "tel",
  },
];
