import { useField } from "@unform/core";
import { useEffect, useRef, InputHTMLAttributes } from "react";
import { InputItem, LabelInput, Message, WrapperInput } from "./styles";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  id: string;
  description: string;
  type: string;
}
export const Input = ({
  name,
  label,
  id,
  description,
  type,
  ...rest
}: InputProps) => {
  const { fieldName, registerField, error, defaultValue } = useField(name);
  const inputRef = useRef(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);
  return (
    <WrapperInput>
      <InputItem
        type={type}
        ref={inputRef}
        id={id}
        required
        defaultValue={defaultValue}
        errorText={error}
        maxLength={name === "user" ? 11 : undefined}
        {...rest}
      />
      <LabelInput htmlFor={id}>{label}</LabelInput>
      <Message errorText={error}>{!error ? description : error}</Message>
    </WrapperInput>
  );
};

export default Input;
