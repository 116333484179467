import styled from "styled-components";
import { FieldSetProps, InputProps, InputSelectProps } from "./types";

export const InputSelect = styled.select<InputSelectProps>`
  width: 100%;
  max-width: 419px;
  height: 40px;
  padding: 8px;
  border-radius: 12px;
  outline: none;
  border: 2px solid
    ${({ errorMessage }) =>
      errorMessage !== undefined ? "var(--red500)" : "var(--gray500Opacity)"};
  font-size: 16px;
  // font-weight: 500;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  color: ${({ valueSelect }) =>
    valueSelect !== "Número de parcelas" ? "var(--black)" : "var(--gray600)"};
  opacity: 1;
  background-color: var(--white);
`;

export const OptionSelect = styled.option`
  background-color: buttonface;
  color: var(--black);
  box-shadow: 0px 1px 3px var(--gray500Opacity);
`;

export const Input = styled.input<InputProps>`
  width: 100%;
  max-width: ${({ fieldName }) =>
    fieldName === "mes" || fieldName === "ano" || fieldName === "cvv"
      ? 128
      : 419}px;
  height: 40px;
  padding: 8px;
  border-radius: 12px;
  border: 2px solid
    ${({ errorMessage }) =>
      errorMessage !== undefined ? "var(--red500)" : "var(--gray500Opacity)"};
  outline: none;
  // font-weight: 500;
  font-size: 16px;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  margin: ${({ fieldName }) =>
    fieldName === "numeroDoCartao" || fieldName === "cvv" ? "10px 0px" : "0px"};
  padding-left: ${({ fieldName }) =>
    fieldName === "numeroDoCartao" ? 50 : 12}px;
  margin-left: ${({ fieldName }) => (fieldName === "ano" ? "12px" : "0px")};
  opacity: 1;
`;

export const FieldSet = styled.fieldset<FieldSetProps>`
  margin: 0;
  padding: 0;
  position: relative;
  width: ${({ name }) =>
    name !== "nome"
      ? name.includes("parcelas" || "numeroDoCartao") && "100%"
      : "100%"};
  flex: ${({ name }) => name.includes("numeroDoCartao" || "cvv") && 1};
  outline: none;
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: end;
`;

export const WrapperIcon = styled.div`
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--gray600);
  height: 20px;
  position: absolute;
  left: 10px;
  top: 20px;
  opacity: 0.48;
`;

export const IconCarditem = styled.img`
  width: 20px;
  height: 20px;
`;

export const CardValidateText = styled.span`
  width: 100%;
  padding-left: 10px;
  margin-top: 10px;
  font-size: 14px;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-weight: bold;
  color: var(--gray500);
`;

export const TextCvv = styled.span`
  font-size: 12px;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-weight: 500;
  color: var(--gray500);
  padding-left: 16px;
`;

export const ErroMessage = styled.span`
  color: var(--red500);
  font-size: 12px;
  font-weight: 600;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
`;
