import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background: #4b48b7;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  opacity: 1;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30%;
  left: 20px;
  z-index: 2;
  cursor: pointer;
  border: 5px solid white;

  @media (max-width: 500px) {
    left: 90px;
  }

  @media (max-width: 430px) {
    left: 70px;
  }
`;
