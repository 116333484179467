import styled from "styled-components";

export const Container = styled.div`
  flex-grow: 1;
  max-width: 645px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 0.45rem;
  align-content: center;
  padding-left: 1rem;

  @media (max-width: 990px) {
    grid-template-columns: 1fr;
    padding: 0;
  }
`;

export const WrapperContentInfos = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
    margin-left: 8px;
    font-size: 1rem;
    color: var(--purple600);
  }
`;
