export const content = [
  {
    id: "1",
    paragraph: `A Coob+ Viagens e Turismo Ltda. se compromete a não divulgar a terceiros quaisquer informações ou dados fornecidos pelos usuários do website, 
    para fins de cadastro ou utilização do sistema de reserva nos hotéis credenciados,
     a menos que seja obrigado a fazê-lo em função dos seguintes casos:`,
  },
  {
    id: "2",
    paragraph: `* Cumprimento de qualquer ordem judicial ou cumprimento de ordem proferida por órgão regulatório competente, 
    ou outra autoridade legalmente constituída`,
  },
  {
    id: "3",
    paragraph: `* Cumprimento de disposição constante na legislação brasileira atual ou que venha a vigorar`,
  },
  {
    id: "4",
    paragraph: `* Quando em juízo, proteger os interesses da empresa Coob+, 
    de suas coligadas, ou de terceiros e/ou responder reclamações de que algum conteúdo fornecido viole direitos de terceiros`,
  },
  {
    id: "5",
    paragraph: `A Coob+ declara, por fim, que fará uso das proteções viáveis para garantir a privacidade dos dados fornecidos pelos clientes e 
    usuários do site, para fins de cadastro ou de utilização do sistema de reservas online.
    `,
  },
  {
    id: "6",
    paragraph: `Não obstante tal fato e, em função de que não é possível colocar sob guarda ou vigilância as linhas telefônicas e de transmissão de dados,
    a Coob+ não pode se responsabilizar e efetivamente não se responsabiliza por tentativas de invasão de privacidade que possam vir a ocorrer no tráfego de
     informações por essas linhas ou, ainda, pelo acesso que outras pessoas possam ter aos computadores dos usuários ou às senhas pessoais destes, 
     não se podendo garantir que os serviços serão prestados livres de vírus ou qualquer 
     tipo de rotina de programação ou códigos criados ou utilizados para corromper, 
    destruir ou alterar software, hardware ou equipamentos de telecomunicação.`,
  },
];
