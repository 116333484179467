export const optionsRadioInputQ1 = [
  {
    id: "sou hóspede",
    label: "Eu sou o hóspede principal.",
    value: "sou hospede",
    name: "quarto 1",
    checked: true,
  },
  {
    id: "outra pessoa",
    label: "Estou reservando para outra pessoa.",
    value: "outra pessoa",
    name: "quarto 1",
    checked: false,
  },
];

const optionsRadioInputQ2 = [
  {
    id: "sou hóspede2",
    label: "Eu sou o hóspede principal.",
    value: "sou hospede",
    name: "quarto 2",
    checked: true,
  },
  {
    id: "outra pessoa2",
    label: "Estou reservando para outra pessoa.",
    value: "outra pessoa",
    name: "quarto 2",
    checked: false,
  },
];

const optionsRadioInputQ3 = [
  {
    id: "sou hóspede3",
    label: "Eu sou o hóspede principal.",
    value: "sou hospede",
    name: "quarto 3",
    checked: true,
  },
  {
    id: "outra pessoa3",
    label: "Estou reservando para outra pessoa.",
    value: "outra pessoa",
    name: "quarto 3",
    checked: false,
  },
];

const optionsCheckboxInputQ1 = [
  {
    id: "diamante1",
    value: "diamante",
    label: "Assinatura diamante",
    name: "quartoq1",
  },
  {
    id: "diamante2",
    value: "master",
    label: "Assinatura master",
    name: "quartoq1",
  },
  {
    id: "diamante3",
    value: "go",
    label: "Assinatura Go!",
    name: "quartoq1",
  },
];

const optionsTypeOfRoon = [
  {
    id: "single",
    label: "single",
    value: "single",
    name: "quarto 1",
    description: "uma cama de solteiro",
    checked: false,
    código: 1,
  },
  {
    id: "duplo",
    label: "duplo",
    value: "duplo",
    name: "quarto 1",
    description: "uma cama de casal",
    checked: true,
    código: 2,
  },
  {
    id: "duplo twin",
    label: "duplo twin",
    value: "duplo twin",
    name: "quarto 1",
    description: "duas camas de solteiro",
    checked: false,
    código: 3,
  },
];

const optionsTypeOfRoonQ2 = [
  {
    id: "single 2",
    label: "single",
    value: "single",
    name: "quarto 2",
    description: "uma cama de solteiro",
    checked: false,
    código: 1,
  },
  {
    id: "duplo 2",
    label: "duplo",
    value: "duplo",
    name: "quarto 2",
    description: "uma cama de casal",
    checked: true,
    código: 2,
  },

  {
    id: "duplo twin 2",
    label: "duplo twin",
    value: "duplo twin",
    name: "quarto 2",
    description: "duas camas de solteiro",
    checked: false,
    código: 3,
  },
];

const optionsTypeOfRoonQ3 = [
  {
    id: "single 3",
    label: "single",
    value: "single",
    name: "quarto 3",
    description: "uma cama de solteiro",
    checked: false,
    código: 1,
  },
  {
    id: "duplo 3",
    label: "duplo",
    value: "duplo",
    name: "quarto 3",
    description: "uma cama de casal",
    checked: true,
    código: 2,
  },
  {
    id: "duplo twin 3",
    label: "duplo twin",
    value: "duplo twin",
    name: "quarto 3",
    description: "duas camas de solteiro",
    checked: false,
    código: 3,
  },
];
export const dataInputSelect = [
  {
    name: "nameUser",
    placeholder: "Nome completo",
    id: "nomeUsuario",
    type: "text",
  },
  {
    name: "ageUser",
    placeholder: "Idade",
    id: "idadeUsuario",
    type: "number",
  },
  {
    name: "cpfUser",
    placeholder: "CPF",
    id: "cpfUsuario",
    type: "text",
  },
  {
    name: "rgUser",
    placeholder: "RG",
    id: "rgUsuario",
    type: "text",
  },
  {
    name: "addressUser",
    placeholder: "Endereço completo",
    id: "endUsuario",
    type: "text",
  },
  {
    name: "cityUser",
    placeholder: "Cidade",
    id: "cidadeUsuario",
    type: "text",
  },

  {
    name: "ufUser",
    placeholder: "UF",
    id: "ufUsuario",
    type: "text",
  },
  {
    name: "emailUser",
    placeholder: "E-mail",
    id: "emailUsuario",
    type: "email",
  },
  {
    name: "parentUser",
    placeholder: "Parentesco",
    id: "paranteUsuario",
    type: "select",
  },
  {
    name: "phoneRes",
    placeholder: "Telefone Residencial",
    id: "phoneRes",
    type: "tel",
  },
  {
    name: "phoneCel",
    placeholder: "Telefone Celular",
    id: "phoneCel",
    type: "tel",
  },
  {
    name: "birthday",
    placeholder: "Data de Nascimento",
    id: "birthday",
    type: "date",
  },
];

export const dataInputSelectQ2 = [
  {
    name: "nameUser",
    placeholder: "Nome completo",
    id: "nomeUsuarioQ2",
    type: "text",
  },
  {
    name: "ageUser",
    placeholder: "Idade",
    id: "idadeUsuarioQ2",
    type: "number",
  },
  {
    name: "cpfUser",
    placeholder: "CPF",
    id: "cpfUsuarioQ2",
    type: "text",
  },
  {
    name: "rgUser",
    placeholder: "RG",
    id: "rgUsuarioQ2",
    type: "text",
  },
  {
    name: "addressUser",
    placeholder: "Endereço completo",
    id: "endUsuarioQ2",
    type: "text",
  },
  {
    name: "cityUser",
    placeholder: "Cidade",
    id: "cidadeUsuarioQ2",
    type: "text",
  },

  {
    name: "ufUser",
    placeholder: "UF",
    id: "ufUsuarioQ2",
    type: "text",
  },
  {
    name: "emailUser",
    placeholder: "E-mail",
    id: "emailUsuarioQ2",
    type: "email",
  },
  {
    name: "parentUser",
    placeholder: "Parentesco",
    id: "paranteUsuarioQ2",
    type: "select",
  },
];

export const dataInputSelectQ3 = [
  {
    name: "nameUser",
    placeholder: "Nome completo",
    id: "nomeUsuarioQ3",
    type: "text",
  },
  {
    name: "ageUser",
    placeholder: "Idade",
    id: "idadeUsuarioQ3",
    type: "number",
  },
  {
    name: "cpfUser",
    placeholder: "CPF",
    id: "cpfUsuarioQ3",
    type: "text",
  },
  {
    name: "rgUser",
    placeholder: "RG",
    id: "rgUsuarioQ3",
    type: "text",
  },
  {
    name: "addressUser",
    placeholder: "Endereço completo",
    id: "endUsuarioQ3",
    type: "text",
  },
  {
    name: "cityUser",
    placeholder: "Cidade",
    id: "cidadeUsuarioQ3",
    type: "text",
  },

  {
    name: "ufUser",
    placeholder: "UF",
    id: "ufUsuarioQ3",
    type: "text",
  },
  {
    name: "emailUser",
    placeholder: "E-mail",
    id: "emailUsuarioQ3",
    type: "email",
  },
  {
    name: "parentUser",
    placeholder: "Parentesco",
    id: "paranteUsuarioQ3",
    type: "select",
  },
];

export const oneRoonItem = [
  {
    id: 1,
    room: "quarto 1",
    optionsRadio: optionsRadioInputQ1,
    optionsCheckBox: optionsCheckboxInputQ1,
    optionsTypeOfRoon: optionsTypeOfRoon,
    opened: false,
    typeOfRoom: optionsTypeOfRoon,
    formOpenOtherPerson: false,
    optionsFormOtherPerson: dataInputSelect,
    formRefOtherPerson: null,
    tickets: [],
  },
];

export const twoRoonItem = [
  {
    id: 1,
    room: "quarto 1",
    optionsRadio: optionsRadioInputQ1,
    optionsCheckBox: optionsCheckboxInputQ1,
    optionsTypeOfRoon: optionsTypeOfRoon,
    opened: false,
    typeOfRoom: optionsTypeOfRoon,
    formOpenOtherPerson: false,
    optionsFormOtherPerson: dataInputSelect,
    formRefOtherPerson: null,
    tickets: [],
  },
  {
    id: 2,
    room: "quarto 2",
    optionsRadio: optionsRadioInputQ2,
    optionsCheckBox: optionsCheckboxInputQ1,
    optionsTypeOfRoon: optionsTypeOfRoonQ2,
    opened: false,
    typeOfRoom: optionsTypeOfRoonQ2,
    formOpenOtherPerson: false,
    optionsFormOtherPerson: dataInputSelectQ2,
    formRefOtherPerson: null,
    tickets: [],
  },
];

export const threeRoonItem = [
  {
    id: 1,
    room: "quarto 1",
    optionsRadio: optionsRadioInputQ1,
    optionsCheckBox: optionsCheckboxInputQ1,
    optionsTypeOfRoon: optionsTypeOfRoon,
    opened: false,
    typeOfRoom: optionsTypeOfRoon,
    formOpenOtherPerson: false,
    optionsFormOtherPerson: dataInputSelect,
    formRefOtherPerson: null,
    tickets: [],
  },
  {
    id: 2,
    room: "quarto 2",
    optionsRadio: optionsRadioInputQ2,
    optionsCheckBox: optionsCheckboxInputQ1,
    optionsTypeOfRoon: optionsTypeOfRoonQ2,
    opened: false,
    typeOfRoom: optionsTypeOfRoonQ2,
    formOpenOtherPerson: false,
    optionsFormOtherPerson: dataInputSelectQ2,
    formRefOtherPerson: null,
    tickets: [],
  },
  {
    id: 3,
    room: "quarto 3",
    optionsRadio: optionsRadioInputQ3,
    optionsCheckBox: optionsCheckboxInputQ1,
    optionsTypeOfRoon: optionsTypeOfRoonQ3,
    opened: false,
    typeOfRoom: optionsTypeOfRoonQ3,
    formOpenOtherPerson: false,
    optionsFormOtherPerson: dataInputSelectQ3,
    formRefOtherPerson: null,
    tickets: [],
  },
];

export const fourRoonItem = [
  {
    id: 1,
    room: "quarto 1",
    optionsRadio: optionsRadioInputQ1,
    optionsCheckBox: optionsCheckboxInputQ1,
    optionsTypeOfRoon: optionsTypeOfRoon,
    opened: false,
    typeOfRoom: optionsTypeOfRoon,
    formOpenOtherPerson: false,
    optionsFormOtherPerson: dataInputSelect,
    formRefOtherPerson: null,
    tickets: [],
  },
  {
    id: 2,
    room: "quarto 2",
    optionsRadio: optionsRadioInputQ2,
    optionsCheckBox: optionsCheckboxInputQ1,
    optionsTypeOfRoon: optionsTypeOfRoonQ2,
    opened: false,
    typeOfRoom: optionsTypeOfRoonQ2,
    formOpenOtherPerson: false,
    optionsFormOtherPerson: dataInputSelectQ2,
    formRefOtherPerson: null,
    tickets: [],
  },
  {
    id: 3,
    room: "quarto 3",
    optionsRadio: optionsRadioInputQ3,
    optionsCheckBox: optionsCheckboxInputQ1,
    optionsTypeOfRoon: optionsTypeOfRoonQ3,
    opened: false,
    typeOfRoom: optionsTypeOfRoonQ3,
    formOpenOtherPerson: false,
    optionsFormOtherPerson: dataInputSelectQ3,
    formRefOtherPerson: null,
    tickets: [],
  },
  {
    id: 4,
    room: "quarto 4",
    optionsRadio: optionsRadioInputQ3,
    optionsCheckBox: optionsCheckboxInputQ1,
    optionsTypeOfRoon: optionsTypeOfRoonQ3,
    opened: false,
    typeOfRoom: optionsTypeOfRoonQ3,
    formOpenOtherPerson: false,
    optionsFormOtherPerson: dataInputSelectQ3,
    formRefOtherPerson: null,
    tickets: [],
  },
];
