import { FormHandles } from "@unform/core";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Input, Radio, Select } from "./Inputs";
import { OptionsSelectProps } from "./Inputs/types";
import { Button, FormItem, GridContent, ContainerLoading } from "./styles";
import {
  CEPResponse,
  FinalizeAssociateRegistrationData,
  FinalizeAssociateRegistrationPayload,
  FormFinalizeAssociateRegistrationProps,
} from "./types";
import ModalForm from "./modal";
import { apiAdministracao } from "../../services";

interface Errors {
  [key: string]: string;
}

export const FormFinalizeAssociateRegistration = ({
  initialData,
  onSuccess = () => {},
  onFailure = () => {},
}: FormFinalizeAssociateRegistrationProps) => {
  const [loading, setLoading] = useState(false);
  const [loadingCivCodigoOptions, setLoadingCivCodigoOptions] = useState(false);
  const [loadingUFOptions, setLoadingUFOptions] = useState(false);
  const [loadingCEP, setLoadingCEP] = useState(false);

  const isLoading = (): boolean => {
    return loading || loadingCivCodigoOptions || loadingUFOptions || loadingCEP;
  };
  const formRef = useRef<FormHandles>(null);

  const handleFinalizarCadastroAssociado = async (
    data: FinalizeAssociateRegistrationPayload
  ) => {
    return apiAdministracao.get("FinalizarCadastroAssociado", {
      params: { dados: JSON.stringify(data) },
    });
  };

  const handleSubmit = async (data: FinalizeAssociateRegistrationData) => {
    setLoading(true);
    try {
      formRef?.current?.setErrors({});
      const assNic = localStorage.getItem("assnic");
      const today = new Date();
      const schema = Yup.object().shape({
        assRG: Yup.string().required("Campo obrigatório"),
        assOrgExpRG: Yup.string().required("Campo obrigatório"),
        assDTNascimento: Yup.date()
          .max(today, "Não pode ser uma data futura")
          .required("Campo obrigatório")
          .typeError("data inválida"),
        assSexo: Yup.string()
          .required("Campo obrigatório")
          .typeError("Campo obrigatório"),
        civCodigo: Yup.number()
          .required("Campo obrigatório")
          .notOneOf([-1], "Campo obrigatório"),
        assNomeMae: Yup.string().required("Campo obrigatório"),
        CEPRes: Yup.string().required("Campo obrigatório"),
        logradouroRes: Yup.string().required("Campo obrigatório"),
        numeroRes: Yup.string().required("Campo obrigatório"),
        complementoRes: Yup.string(),
        bairroRes: Yup.string().required("Campo obrigatório"),
        cidadeRes: Yup.string().required("Campo obrigatório"),
        UFRes: Yup.string()
          .required("Campo obrigatório")
          .notOneOf(["-1"], "Campo obrigatório"),
        assNumCelular: Yup.string().matches(
          /(\([1-9]{2}\)\s\d{5}-\d{4})|(\([1-9]{2}\)\s\d{4}-\d{4}|^$)/,
          "Telefone inválido"
        ), // |^$ for optional field
        assFoneRes: Yup.string().matches(
          /(\([1-9]{2}\)\s\d{5}-\d{4})|(\([1-9]{2}\)\s\d{4}-\d{4}|^$)/,
          "Telefone inválido"
        ), // |^$ for optional field
        assFoneComl: Yup.string().matches(
          /(\([1-9]{2}\)\s\d{5}-\d{4})|(\([1-9]{2}\)\s\d{4}-\d{4}|^$)/,
          "Telefone inválido"
        ), // |^$ for optional field
        assNic: Yup.string().required("Código do Cliente"),
      });
      await schema.validate(
        { assNic, ...data },
        {
          abortEarly: false,
        }
      );
      if (!assNic) {
        throw Error(
          "Código do Cliente Faltando, por favor recarregue a página"
        );
      }
      const {
        assNumCelular,
        assFoneRes,
        assFoneComl,
        assDTNascimento,
        ...restData
      } = data;
      const dateString = assDTNascimento.split("-");
      const payload = {
        ...restData,
        assNic,
        assDTNascimento: `${dateString[2]}/${dateString[1]}/${dateString[0]}`,
        assNumCelular: !assNumCelular
          ? undefined
          : assNumCelular.replace(/\D/g, "").substring(2),
        assNumCelularDDD: !assNumCelular
          ? undefined
          : assNumCelular.replace(/\D/g, "").substring(0, 2),
        assFoneRes: !assFoneRes
          ? undefined
          : assFoneRes.replace(/\D/g, "").substring(2),
        assFoneResDDD: !assFoneRes
          ? undefined
          : assFoneRes.replace(/\D/g, "").substring(0, 2),
        assFoneComl: !assFoneComl
          ? undefined
          : assFoneComl.replace(/\D/g, "").substring(2),
        assFoneComlDDD: !assFoneComl
          ? undefined
          : assFoneComl.replace(/\D/g, "").substring(0, 2),
      };
      await handleFinalizarCadastroAssociado(payload);
      formRef?.current?.setErrors({});
      setLoading(false);
      onSuccess(payload);
    } catch (err) {
      console.error(err);
      if (err instanceof Yup.ValidationError) {
        const errorMessage: Errors = {};

        err.inner.forEach((error) => {
          if (error.path) {
            errorMessage[error.path] = error.message;
          }
          if (error.path === "assNic") {
            alert("Código do Cliente Faltando, por favor recarregue a página");
            window.location.reload();
          }
          return formRef?.current?.setErrors(errorMessage);
        });
        if (err.inner[0].path) {
          const nameInput = formRef?.current?.getFieldRef(err.inner[0].path);
          if (!nameInput?.focus) {
            const element = document.querySelector(`#${err.inner[0].path}`);
            element?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          } else {
            nameInput.focus();
          }
        }
      }
      setLoading(false);
      onFailure();
    }
    setLoading(false);
  };

  const [civCodigoOptions, setCivCodigoOptions] = useState<
    OptionsSelectProps[]
  >([]);
  const getCivCodigoOptions = async () => {
    const dados = { tpCodigo: -1 };
    setLoadingCivCodigoOptions(true);
    await apiAdministracao
      .get(`Estado_Civil_DropDownList`, {
        params: { dados: JSON.stringify(dados) },
      })
      .then((resp) => {
        if (!!resp.data?.Table?.length) {
          setCivCodigoOptions(
            resp.data?.Table.map((resp: any) => ({
              value: resp.civCodigo,
              label: resp.civDescricao,
              hidden: resp.civCodigo === -1,
            }))
          );
        }
        setLoadingCivCodigoOptions(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingCivCodigoOptions(false);
      });
  };
  const [UFOptions, setUFOptions] = useState<OptionsSelectProps[]>([]);
  const getUFOptions = async () => {
    const dados = { tpCodigo: "-1" };
    setLoadingUFOptions(true);
    await apiAdministracao
      .get(`Get_UF_DropDownList2_1`, {
        params: { dados: JSON.stringify(dados) },
      })
      .then((resp) => {
        if (!!resp.data?.Table?.length) {
          setUFOptions(
            resp.data?.Table.map((resp: any) => ({
              value: resp.UFCodigo,
              label: resp.UFDescricao,
              hidden: resp.UFCodigo === "-1",
            }))
          );
        }
        setLoadingUFOptions(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingUFOptions(false);
      });
  };

  const maskPhone = (input: string): string => {
    let value = input;

    let result = value.trim().replace(/\D/g, "");
    result = result.replace(/^0/, "");

    result = result.replace(/(\d{2})(\d{1})/, "($1) $2");
    if (result.length > 13) {
      result = result.replace(/(\d{5})(\d{4})/, "$1-$2");
    } else {
      result = result.replace(/(\d{4})(\d{1})/, "$1-$2");
    }
    return result;
  };

  useEffect(() => {
    if (!civCodigoOptions.length) getCivCodigoOptions();
    if (!UFOptions.length) getUFOptions();
    if (initialData) {
      const { assNumCelular, assFoneRes, assFoneComl, ...rest } = initialData;
      formRef?.current?.setData({
        assNumCelular: maskPhone(assNumCelular || ""),
        assFoneRes: maskPhone(assFoneRes || ""),
        assFoneComl: maskPhone(assFoneComl || ""),
        ...rest,
      });
    }
  }, [initialData, UFOptions, civCodigoOptions]);

  const handleChangePhone = (input: SyntheticEvent<HTMLInputElement>) => {
    input.currentTarget.value = maskPhone(input.currentTarget.value);
  };

  const handleChangePhoneFix = (input: SyntheticEvent<HTMLInputElement>) => {
    let value = input.currentTarget.value;

    let result = value.replace(/\D/g, "");
    result = result.replace(/^0/, "");

    result = result.replace(/(\d{2})(\d)/, "($1) $2");
    result = result.replace(/(\d)(\d{4})$/, "$1-$2");
    input.currentTarget.value = result;
  };

  const handleSearchCep = async (cep: string) => {
    try {
      setLoadingCEP(true);
      const cepNumber = cep.replace(/\D/g, "").trim();
      const resp = await apiAdministracao.get(
        `ConsultaEnderecoPorCep?cepNumero=${cepNumber}`
      );
      if (resp?.data?.Table?.[0]) {
        const location = resp.data.Table[0] as CEPResponse;
        formRef.current?.setFieldValue("logradouroRes", location.cepLogradouro);
        formRef.current?.setFieldValue("bairroRes", location.cepBairro);
        formRef.current?.setFieldValue("cidadeRes", location.cepCidade);
        formRef.current?.setFieldValue("UFRes", location.cepUF);
        const nameInput = formRef.current?.getFieldRef("numeroRes");
        nameInput?.focus?.();
      }
      setLoadingCEP(false);
    } catch (err) {
      console.error(err);
      setLoadingCEP(false);
    }
  };

  const handleChangeCEP = (input: SyntheticEvent<HTMLInputElement>) => {
    let value = input.currentTarget.value;
    let result = value.trim().replace(/\D/g, "");
    if (result.length > 8) result = result.substring(0, 8);
    input.currentTarget.value = result;
    if (result.length === 8) {
      handleSearchCep(result);
    }
  };
  const handleBlurCEP = (input: SyntheticEvent<HTMLInputElement>) => {
    handleSearchCep(input.currentTarget.value);
  };

  return (
    <FormItem onSubmit={handleSubmit} ref={formRef}>
      <ContainerLoading loading={isLoading()}>
        <span className="spining"></span>
      </ContainerLoading>
      <GridContent>
        <Input type="text" name="assRG" required label="RG" />
        <Input
          type="text"
          name="assOrgExpRG"
          required
          label="Orgão Expeditor"
        />
        <Input
          type="date"
          name="assDTNascimento"
          required
          label="Data de Nascimento"
          autoComplete="bday"
        />
        <Radio
          options={[
            { value: "M", label: "Masculino" },
            { value: "F", label: "Feminino" },
          ]}
          name="assSexo"
          label="Sexo"
          required
        />
        <Select
          name="civCodigo"
          required
          label="Estado Civil"
          options={civCodigoOptions}
        />
        <Input
          type="text"
          name="assNomeMae"
          required
          label="Nome da Mãe"
          classNameWrapper="doubleWidth"
        />
      </GridContent>
      <GridContent>
        <Input
          type="text"
          name="CEPRes"
          required
          label="CEP"
          onChange={handleChangeCEP}
          onBlur={handleBlurCEP}
          maxLength={9}
          autoComplete="postal-code"
        />
        <Input
          type="text"
          name="logradouroRes"
          required
          label="Endereço"
          classNameWrapper="doubleWidth"
          autoComplete="address-line1"
        />
        <Input
          type="text"
          name="numeroRes"
          required
          label="Número"
          autoComplete="address-line2"
        />
        <Input
          type="text"
          name="complementoRes"
          label="Complemento"
          autoComplete="address-line3"
        />
        <Input
          type="text"
          name="bairroRes"
          required
          label="Bairro"
          autoComplete="address-level3"
        />
        <Input
          type="text"
          name="cidadeRes"
          required
          label="Cidade"
          autoComplete="address-level2"
        />
        <Select
          name="UFRes"
          required
          label="UF"
          options={UFOptions}
          autoComplete="address-level1"
        />
      </GridContent>
      <GridContent>
        <Input
          type="tel"
          name="assNumCelular"
          label="Telefone Celular"
          onChange={handleChangePhone}
          maxLength={15}
        />
        <Input
          type="tel"
          name="assFoneRes"
          label="Telefone Fixo"
          onChange={handleChangePhoneFix}
          maxLength={14}
        />
        <Input
          type="tel"
          name="assFoneComl"
          label="Telefone Comercial"
          onChange={handleChangePhone}
          maxLength={15}
        />
      </GridContent>
      <Button type="submit" disabled={isLoading()}>
        Finalizar
      </Button>
    </FormItem>
  );
};

export const ModalFormFinalizeAssociateRegistration = ModalForm;
export default FormFinalizeAssociateRegistration;
