import { Form } from "@unform/web";
import styled, { keyframes } from "styled-components";
import { InputWithError } from "./types";

export const FormItem = styled(Form)`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
`;
export const GridContent = styled.div`
  max-width: 100%;
  margin: 0 auto 0 0;
  display: grid;
  gap: 1rem;
  // grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-columns: 1fr;
  padding-bottom: 1rem;

  @media screen and (min-width: 685px) {
    grid-template-columns: repeat(2, 1fr);

    .doubleWidth {
      grid-column-end: auto;
      grid-column-start: span 2;
    }
  }
  @media (min-width: 1020px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1300px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const InputText = styled.input<InputWithError>`
  width: 100%;
  //max-width: 450px;
  min-width: 285px;
  flex: 1;
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  border-radius: 12px;
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid
    ${({ error }) => (!!error ? "var(--red500)" : "var(--gray500Opacity)")};
  margin: 4px;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  outline: none;
  color: var(--black);
  font-weight: 500;

  ::placeholder {
    color: var(--black);
    font-size: 14px;
    font-weight: 500;
  }
`;

export const BoxInputradio = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 10px 0px;
  padding-left: 10px;
`;

export const InputRadio = styled.input`
  margin: 0;
  appearance: none;
  border: 2px solid var(--gray600);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.8s;

  &:before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--purple300);
    opacity: 0;
  }

  &:checked:before {
    opacity: 1;
    transition: all 0.5s;
  }

  &:checked {
    opacity: 1;
    transition: all 0.5s;
    border: 2px solid var(--purple300);
  }
  &:checked ~ label {
    color: var(--purple600);
  }
`;

export const Label = styled.label`
  color: var(--gray600);
  font-size: 0.9rem;
  font-weight: bold;
  padding-left: 25px;
  cursor: pointer;
`;

export const Button = styled.button`
  cursor: pointer;
  display: block;
  background-color: var(--pink600);
  border: none;
  color: var(--white);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  border-radius: 20px;
  width: 100%;
  max-width: 400px;
  min-height: 40px;
  margin: 0 auto;

  &:disabled {
    opacity: 0.15;

    &:hover {
      background-color: var(--pink600);
    }
  }

  &:hover {
    background-color: var(--pink500);
  }
`;

interface LoadingProps {
  loading?: boolean;
}
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
export const Loading = styled.div<LoadingProps>`
  ::before {
    content: <span class= "spining" / >;
  }
  display: ${({ loading }) => (loading ? "flex" : "none")};
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  border-radius: 60%;
  border: 6px solid #c9c8ea;
  border-top-color: #3a2e83;
  animation: ${rotate} 1s linear infinite;
`;

export const ContainerLoading = styled.div<LoadingProps>`
  display: ${({ loading }) => (loading ? "flex" : "none")};
  position: absolute;
  top: 0;
  background-color: rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 100%;
  span.spining {
    display: ${({ loading }) => (loading ? "flex" : "none")};
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    width: calc(30px * 2);
    height: calc(30px * 2);
    border-radius: 60%;
    border: 6px solid #c9c8ea;
    border-top-color: #3a2e83;
    animation: ${rotate} 1s linear infinite;
  }
`;
