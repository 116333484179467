import { Link } from "react-router-dom";
import styled from "styled-components";

const heightFooter = "530px";
const heightFooterSmallScreen = "580px";
export const FooterContainer = styled.footer`
  width: 100%;
  height: ${heightFooter};
  margin-top: 80px;
  position: relative;
  color: #fff;

  @media (max-width: 1100px) {
    height: ${heightFooterSmallScreen};
  }
  @media (max-width: 800px) {
    display: none;
  }
`;

export const FooterContainerMobile = styled.div`
  display: none;
  @media (max-width: 800px) {
    width: 100%;
    min-height: 850px;
    padding: 30px;
    padding-bottom: 80px;
    margin-top: 80px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    background-color: #44389e;
    box-sizing: border-box;
  }

  @media (max-width: 500px) {
    width: 100%;
    min-height: 850px;
    padding: 0px 10px;
    padding-bottom: 80px;
  }
`;

export const AreaLeftFooter = styled.div`
  width: 40%;
  min-width: 700px;
  height: ${heightFooter};
  background-color: #44389e;
  border-top-right-radius: 270px;
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1350px) {
    min-width: 600px;
  }
  @media (max-width: 1250px) {
    min-width: 580px;
  }

  @media (max-width: 1100px) {
    min-width: 500px;
    height: ${heightFooterSmallScreen};
  }
  @media (max-width: 900px) {
    min-width: 450px;
  }
`;

export const AreaRightFooter = styled.div`
  width: 70%;
  height: ${heightFooter};
  background-color: #4b48b7;
  border-top-left-radius: 300px;
  position: absolute;
  z-index: 1;
  right: 0;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 0px;
  @media (max-width: 1600px) {
    padding-left: 200px;
  }

  @media (max-width: 1440px) {
    padding-left: 250px;
  }

  @media (max-width: 1330px) {
    padding-left: 250px;
  }
  @media (max-width: 1100px) {
    height: ${heightFooterSmallScreen};
  }
`;

export const AreaBottomFooter = styled.div`
  width: 100%;
  height: 65px;
  background-color: #3a2e83;
  position: absolute;
  bottom: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextBottomFooter = styled.span`
  font-size: 1rem;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  h5 {
    font-weight: bold;
    font-size: 14px;
    margin: 0px 4px;
  }
`;

export const WrapperIconSocial = styled.div`
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  transform: translate(60px, 0px);
  a {
    margin: 0;
  }
  @media (max-width: 800px) {
    max-width: 400px;
    transform: translate(0px, 0px);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContentLeftFooter = styled.div`
  width: 400px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;

  img {
    width: 300px;
  }
  span {
    font-size: 0.9rem;
    font-weight: bold;
    color: #fff;
  }

  @media (max-width: 800px) {
    transform: translate(0px, 0px);
    height: 100px;
  }
  @media (max-width: 500px) {
    width: 100%;
    max-width: 350px;

    span {
      width: 350px;
      text-align: center;
    }
  }
`;

export const BtnSendEmailNews = styled.button`
  width: 120px;
  height: 50px;
  background-color: #e50076;
  outline: none;
  border: 0;
  border-radius: 25px;
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  text-transform: uppercase;
  right: 0px;

  z-index: 1;
  &:hover {
    background-color: #f489ba;
  }
`;

export const IconSocialItem = styled.img`
  margin: 0px 4px;
  margin-top: 10px;
`;

export const WrapperContentInfosLeft = styled.div`
  width: 100%;

  @media (max-width: 800px) {
    height: 400px;
  }

  @media (max-width: 500px) {
    height: auto;
  }
`;

export const WrapperInfosLeft = styled.div`
  width: 250px;
  height: calc(100% - 40px);
  padding: 10px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  div {
    color: #fff;
    font-size: 0.9rem;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 200px;
    h5 {
      margin-left: auto;
      margin-right: auto;
    }
    p {
      color: #fff;
    }
  }
  @media (max-width: 500px) {
    height: auto;

    a {
      text-align: center;
    }
  }
`;

export const WrapperInfosRight = styled.div`
  width: 250px;
  height: calc(100% - 40px);
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media (max-width: 800px) {
    height: auto;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    p {
      color: #fff;
      margin: 0;
    }
  }
`;

export const WrapperInfosMobile = styled.div`
  width: 250px;
  height: calc(100% - 40px);
  padding: 10px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const TitleInfo = styled.span`
  margin: 0;
  font-size: 1.1rem;
  color: #fff;
  font-weight: 600;
  @media (max-width: 800px) {
    text-align: center;
  }

  @media (max-width: 500px) {
    font-weight: 600;
    font-size: 14px;
  }
`;

export const TitleInfoPaddingTop = styled(TitleInfo)`
  padding-top: 5px;
`;
export const LinkNoUnderline = styled.a`
  text-decoration: none;
  color: #fff;

  &:hover {
    text-decoration: underline;
  }
`;

export const ItensMenuFooter = styled(Link)`
  color: #fff;
  font-size: 0.9rem;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 500px) {
    font-size: 0.8rem;
  }
`;

export const ItensMenuFooterOther = styled.a`
  color: #fff;
  font-size: 0.9rem;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 500px) {
    font-size: 0.8rem;
  }
`;

export const InfosMenuFooter = styled.span`
  color: #fff;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  @media (max-width: 300px) {
    font-size: 0.8rem;
    white-space: nowrap;
  }
`;

export const ParagraphItemFooter = styled.p`
  margin: 0;
  font-family: Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.5px;

  @media (max-width: 500px) {
    text-align: center;
    text-align: -moz-center;
    text-align: -webkit-center;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.38px;

    font-weight: 600;
  }
`;
export const ParagraphItemFooterCenter = styled(ParagraphItemFooter)`
  text-align: center;
`;
export const WrapperPhone = styled.div`
  display: flex;
  align-items: center;
`;

export const WrapperAtendance = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-evenly;
  height: 150px;
  //background-color: pink;

  @media (max-width: 500px) {
    justify-content: center;
    #attendenceCenter {
      width: 100%;
      max-width: 180px;
    }

    #attendencTitle {
      font-size: 14px;
      margin-top: -12px;
    }
  }
`;

export const WrapperInfoRightUserMobile = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;

  div {
    color: #fff;
    font-size: 0.9rem;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 500px) {
    margin-top: 28px;
  }
`;
