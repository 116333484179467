import { Fragment, useEffect } from "react";
import { useLocation, useNavigate, Location, To } from "react-router-dom";
import { handleRedirectDefault } from "./functions";

export interface RedirectPageParams {
  functionRedirect?: (location: Location) => To;
}

export const RedirectPage = ({
  functionRedirect = handleRedirectDefault,
}: RedirectPageParams) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate(`${process.env.PUBLIC_URL}${functionRedirect(location)}`);
  }, [functionRedirect, location, navigate]);

  return (
    <Fragment>
      {/* <div style={{ paddingTop: "100px" }}></div>
      <button
        onClick={() => {
          console.log(location);
          console.log(functionRedirect(location));
        }}
      >
        click
      </button> */}
    </Fragment>
  );
};

export default RedirectPage;
