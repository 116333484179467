/* eslint-disable react-hooks/exhaustive-deps */
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import InputRadio from "../../../components/InputRadio";
import ArrowIcon from "../../../assets/icones/icons minimal (20px)/seta_esquerda.svg";
import {
  dataInputSelect,
  oneRoonItem,
  twoRoonItem,
  threeRoonItem,
  fourRoonItem,
  optionsRadioInputQ1,
} from "./constants";
import {
  AlertBox,
  ArrowImage,
  BadgeRecomended,
  Box,
  BoxRoom,
  BoxTypeOfRoom,
  BtnKeep,
  ItemCircle,
  ModalTypeOfRoon,
  PolyticsArea,
  Subtitle,
  Switcher,
  TitleRoom,
  WrapperContent,
  WrapperRadios,
  WrapperRecomended,
  WrapperSelectRoom,
  WrappertextAndSwitcher,
} from "./styles";

import TicketsList from "./Tickets";
import { returnQuartosList, TypeOfRoons } from "./types";
import { Options } from "../../../components/InputRadio/types";
import FormOtherPerson from "./FormOtherPerson";
import { WrapperBtnReserve } from "./FormOtherPerson/styles";
import ChoosePayments from "./ChoosePayments";
import { FormHandles } from "@unform/core";
import { useChoosePayment } from "../../../store/useChoosePayment";
import { useNavigate } from "react-router-dom";
import { api } from "../../../services";
import { HotelDetailsProps } from "../types";
import { TesteProps } from "./Tickets/types";
// import * as Yup from "yup";
// import { fileURLToPath } from "url";
// import { useFormOtherPerson } from "../../../store/useFormOtherPerson";
import { MessageProps } from "../../../components/modalFormAvailability/types";
import { ModalFormConfirmation } from "../../../components/modalFormConfirmation";
import {
  validadeFormOtherPerson,
  FormOtherPersonProps,
} from "./FormOtherPerson/validateForm";
import { ModalAlert } from "../../../components/modalAlert";
import { MessageAlertProps } from "../../../components/modalAlert/types";
import { LoadingBtn } from "../../PaymentsInternational/styles";
import { Loading } from "./Tickets/styles";

interface RoonsProps {
  openModal: () => void;
  openModalPolitics: () => void;
}
const Roons = ({ openModal }: RoonsProps) => {
  // const [activeSwitcher, setActiveSwitcher] = useState<boolean>(false);
  const [activeSwitcherPayment, setActiveSwitcherPayment] =
    useState<boolean>(false);
  const [roons, setRoons] = useState<TypeOfRoons[]>([]);
  const formRef = useRef<FormHandles>(null);
  const formOtherPersonRef = useRef<FormHandles>(null);
  const namePayment = useChoosePayment((state) => state.name);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [totalValue, setTotalValue] = useState<number>(0);
  const [totalValueOfRoom, setTotalValueOfRoom] = useState<number>(0);
  const [showTotalValue, setShowTotalValue] = useState(false);
  const [resetRadio, setResetRadio] = useState(false);
  const [message, setMessage] = useState<MessageProps>({} as MessageProps);
  const [, setHotelDetails] = useState<HotelDetailsProps>();
  const [modalReload, setModalReload] = useState<boolean>(false);
  //const [teste, setTeste] = useState<boolean>(false);
  const [openFormOtherPerson, setOpenFormOtherPerson] =
    useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [phoneUser, setPhoneUser] = useState<string>("");
  let dataInitial = localStorage.getItem("dataInicial");
  let dataFinal = localStorage.getItem("dataFinal");
  const userId = localStorage.getItem("assnic");
  const [loadingReservation, setLoadingReservation] = useState(false);
  const [loadingTickets, setLoadingTickets] = useState(false);

  useEffect(() => {
    api
      .post(`wsCoobrastur/Hotel.asmx/returnPesquisa_1`, { token: token })
      .then((resp) => {
        if (resp.status === 200) {
          setHotelDetails(resp.data.Table[0]);

          dataInitial = resp.data.Table[0].dataInicial;
          dataFinal = resp.data.Table[0].dataFinal;
          localStorage.setItem("dataInicial", dataInitial!);
          localStorage.setItem("dataFinal", dataFinal!);

          return;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [token]);

  const [messageModalError, setMessageModalError] = useState<MessageAlertProps>(
    {} as MessageAlertProps
  );

  const handleCloseModalError = () => {
    setMessageModalError({} as MessageProps);
  };

  //const teste = localStorage.getItem("erroForm");
  const totalPayments = totalValue;

  // const errorList = useFormOtherPerson((state) => state.error);

  // const handleToggleSwitcher = () => {
  //   setActiveSwitcher(!activeSwitcher);
  // };
  const handleToggleSwitcherPayment = () => {
    setActiveSwitcherPayment(!activeSwitcherPayment);
  };

  const handleCloseModalreload = () => {
    setActiveSwitcherPayment(false);
    setModalReload(false);
    window.location.href = `${process.env.PUBLIC_URL}/`;
  };

  const returnTicketsFunction = async (data: {
    t: string;
    in_token: string | null;
    quarto: number;
    tktCodigos1: string;
    tktCodigos2: string;
    tktCodigos3: string;
    tktCodigos4: string;
    tpQuarto: string | number | undefined;
    resLocalizador: string;
    dataInicial: string | null;
    dataFim: string | null;
  }) => {
    return api.get("/wsCoobrastur/Hotel.asmx/returnTickets_C", {
      params: {
        dados: Object.values(data).join(";"),
      },
    });
  };
  const returnTickets = {
    get: returnTicketsFunction,
  };
  const updateTickets = async (
    tickets: TesteProps[],
    nameRoom: string,
    newRooms?: TypeOfRoons[]
  ) => {
    setShowTotalValue(false);
    setResetRadio(true);

    let ticketsQ1: TesteProps[] = [];
    let ticketsQ2: TesteProps[] = [];
    let ticketsQ3: TesteProps[] = [];
    let ticketsQ4: TesteProps[] = [];
    let valuePayQ1: number = 0;
    let valuePayQ2: number = 0;
    let valuePayQ3: number = 0;
    let valuePayQ4: number = 0;

    const roomsUpdate = newRooms || roons;
    // dados quarto 1
    const ticketsQ1Payload = roomsUpdate[0].tickets
      ?.filter((ticket) => ticket.checked === true)
      .map((ticket) => ticket.tktCodigo);
    const typeOfRoomQ1 = roomsUpdate[0].optionsTypeOfRoon
      .filter((type) => type.checked === true)
      .map((type) => type.código);

    // dados quarto 2
    let ticketsQ2Payload = roomsUpdate[1]?.tickets
      ?.filter((ticket) => ticket.checked === true)
      .map((ticket) => ticket.tktCodigo);
    const typeOfRoomQ2 = roomsUpdate[1]?.optionsTypeOfRoon
      .filter((type) => type.checked === true)
      .map((type) => type.código);

    // dados quarto 3
    const ticketsQ3Payload = roomsUpdate[2]?.tickets
      ?.filter((ticket) => ticket.checked === true)
      .map((ticket) => ticket.tktCodigo);
    const typeOfRoomQ3 = roomsUpdate[2]?.optionsTypeOfRoon
      .filter((type) => type.checked === true)
      .map((type) => type.código);

    // dados quarto 4
    const ticketsQ4Payload = roomsUpdate[3]?.tickets
      ?.filter((ticket) => ticket.checked === true)
      .map((ticket) => ticket.tktCodigo);
    const typeOfRoomQ4 = roomsUpdate[3]?.optionsTypeOfRoon
      .filter((type) => type.checked === true)
      .map((type) => type.código);

    if (nameRoom === "quarto 1") {
      const codeTktList = tickets.filter((item) => item.checked === true);
      let tktCode = codeTktList.map((item) => item.tktCodigo);
      let messageQ1 = "";
      let messageQ2 = "";
      let messageQ3 = "";
      let messageQ4 = "";

      setLoadingTickets(true);
      await returnTickets
        .get({
          t: "returnTickets_C",
          in_token: token,
          quarto: 1,
          tktCodigos1: tktCode.join() ?? "",
          tktCodigos2: ticketsQ2Payload?.length ? ticketsQ2Payload.join() : "",
          tktCodigos3: ticketsQ3Payload?.length ? `${ticketsQ3Payload}` : "",
          tktCodigos4: ticketsQ4Payload?.length ? `${ticketsQ4Payload}` : "",
          tpQuarto: typeOfRoomQ1[0],
          resLocalizador: "null",
          dataInicial: dataInitial ?? "",
          dataFim: dataFinal ?? "",
        })
        .then(async (resp) => {
          if (resp.status === 200) {
            valuePayQ1 = resp.data.Table1[0].out_valor_Total;
            ticketsQ1 = resp.data.Table.map((ticket: TesteProps) => {
              if (
                tktCode.includes(ticket.tktCodigo) &&
                ticket.Selecionado === 1
              ) {
                return { ...ticket, checked: true };
              }
              return { ...ticket, checked: false };
            });

            const hasTicket = ticketsQ1.filter(
              (ticket) => ticket.checked === true
            ).length;

            if (hasTicket === 0) {
              tktCode = [];
            }

            messageQ1 = resp.data.Table1[0].out_descricao || "";

            if (roomsUpdate.length > 1) {
              await returnTickets
                .get({
                  t: "returnTickets_C",
                  in_token: token,
                  quarto: 2,
                  tktCodigos1: tktCode?.length ? tktCode.join() : "",
                  tktCodigos2: ticketsQ2Payload?.length
                    ? `${ticketsQ2Payload.join()}`
                    : "",
                  tktCodigos3: "",
                  tktCodigos4: "",
                  tpQuarto: typeOfRoomQ2[0] ?? 2,
                  resLocalizador: "null",
                  dataInicial: dataInitial ?? "",
                  dataFim: dataFinal ?? "",
                })
                .then((resp) => {
                  valuePayQ2 = resp.data.Table1[0].out_valor_Total;
                  ticketsQ2 = resp.data.Table.map((ticket: TesteProps) => {
                    if (
                      ticketsQ2Payload?.includes(ticket.tktCodigo) &&
                      ticket.Selecionado === 1
                    ) {
                      return { ...ticket, checked: true };
                    }
                    return { ...ticket, checked: false };
                  });

                  messageQ2 = resp.data.Table1[0].out_descricao || "";
                });
            }

            if (roomsUpdate.length > 2) {
              await returnTickets
                .get({
                  t: "returnTickets_C",
                  in_token: token,
                  quarto: 3,
                  tktCodigos1: tktCode?.length ? tktCode.join() : "",
                  tktCodigos2: ticketsQ2Payload?.length
                    ? `${ticketsQ2Payload.join()}`
                    : "",
                  tktCodigos3: ticketsQ3Payload?.length
                    ? `${ticketsQ3Payload.join()}`
                    : "",
                  tktCodigos4: "",
                  tpQuarto: typeOfRoomQ3[0] ?? 2,
                  resLocalizador: "null",
                  dataInicial: dataInitial ?? "",
                  dataFim: dataFinal ?? "",
                })
                .then((resp) => {
                  valuePayQ3 = resp.data.Table1[0].out_valor_Total;
                  ticketsQ3 = resp.data.Table.map((ticket: TesteProps) => {
                    if (
                      ticketsQ3Payload?.includes(ticket.tktCodigo) &&
                      ticket.Selecionado === 1
                    ) {
                      return { ...ticket, checked: true };
                    }
                    return { ...ticket, checked: false };
                  });

                  messageQ3 = resp.data.Table1[0].out_descricao || "";
                });
            }

            if (roomsUpdate.length > 3) {
              await returnTickets
                .get({
                  t: "returnTickets_C",
                  in_token: token,
                  quarto: 4,
                  tktCodigos1: tktCode?.length ? tktCode.join() : "",
                  tktCodigos2: ticketsQ2Payload?.length
                    ? `${ticketsQ2Payload.join()}`
                    : "",
                  tktCodigos3: ticketsQ3Payload?.length
                    ? `${ticketsQ3Payload.join()}`
                    : "",
                  tktCodigos4: ticketsQ4Payload?.length
                    ? `${ticketsQ4Payload.join()}`
                    : "",
                  tpQuarto: typeOfRoomQ4[0] ?? 2,
                  resLocalizador: "null",
                  dataInicial: dataInitial ?? "",
                  dataFim: dataFinal ?? "",
                })
                .then((resp) => {
                  valuePayQ4 = resp.data.Table1[0].out_valor_Total;
                  ticketsQ4 = resp.data.Table.map((ticket: TesteProps) => {
                    if (
                      ticketsQ4Payload?.includes(ticket.tktCodigo) &&
                      ticket.Selecionado === 1
                    ) {
                      return { ...ticket, checked: true };
                    }
                    return { ...ticket, checked: false };
                  });

                  messageQ4 = resp.data.Table1[0].out_descricao || "";
                });
            }

            const roonClone = [...roomsUpdate];

            const newRoon = roonClone.map((item) => {
              if (item.room === "quarto 1") {
                return {
                  ...item,
                  tickets: ticketsQ1,
                  message: messageQ1,
                };
              }

              if (item.room === "quarto 2") {
                return {
                  ...item,
                  tickets: ticketsQ2,
                  message: messageQ2,
                };
              }

              if (item.room === "quarto 3") {
                return {
                  ...item,
                  tickets: ticketsQ3,
                  message: messageQ3,
                };
              }

              if (item.room === "quarto 4") {
                return {
                  ...item,
                  tickets: ticketsQ4,
                  message: messageQ4,
                };
              }

              return item;
            });

            setRoons(newRoon);
            setTotalValueOfRoom(
              valuePayQ2 + valuePayQ1 + valuePayQ3 + valuePayQ4
            );
            setTotalValue(valuePayQ2 + valuePayQ1 + valuePayQ3 + valuePayQ4);

            return;
          }

          // alert("recarrege a pagina");
        });
      setLoadingTickets(false);
    }

    if (nameRoom === "quarto 2") {
      const codeTktList = tickets.filter((item) => item.checked === true);
      let tktCode2 = codeTktList.map((item) => item.tktCodigo);
      let messageQ1 = "";
      let messageQ2 = "";
      let messageQ3 = "";
      let messageQ4 = "";

      setLoadingTickets(true);

      await returnTickets
        .get({
          t: "returnTickets_C",
          in_token: token,
          quarto: 1,
          tktCodigos1: ticketsQ1Payload?.length ? `${ticketsQ1Payload}` : "",
          tktCodigos2: tktCode2.join(),
          tktCodigos3: ticketsQ3Payload?.length ? `${ticketsQ3Payload}` : "",
          tktCodigos4: ticketsQ4Payload?.length ? `${ticketsQ4Payload}` : "",
          tpQuarto: typeOfRoomQ1[0],
          resLocalizador: "null",
          dataInicial: dataInitial ?? "",
          dataFim: dataFinal ?? "",
        })
        .then(async (resp) => {
          if (resp.status === 200) {
            valuePayQ1 = resp.data.Table1[0].out_valor_Total;
            ticketsQ1 = resp.data.Table.map((ticket: TesteProps) => {
              if (
                ticketsQ1Payload?.includes(ticket.tktCodigo) &&
                ticket.Selecionado === 1
              ) {
                return { ...ticket, checked: true };
              }
              return { ...ticket, checked: false };
            });

            messageQ1 = resp.data.Table1[0].out_descricao || "";

            await returnTickets
              .get({
                t: "returnTickets_C",
                in_token: token,
                quarto: 2,
                tktCodigos1: ticketsQ1Payload?.length
                  ? `${ticketsQ1Payload}`
                  : "",
                tktCodigos2: tktCode2.join(),
                tktCodigos3: ticketsQ3Payload?.length
                  ? `${ticketsQ3Payload}`
                  : "",
                tktCodigos4: ticketsQ4Payload?.length
                  ? `${ticketsQ4Payload}`
                  : "",
                tpQuarto: typeOfRoomQ2[0],
                resLocalizador: "null",
                dataInicial: dataInitial ?? "",
                dataFim: dataFinal ?? "",
              })
              .then(async (resp) => {
                valuePayQ2 = resp.data.Table1[0].out_valor_Total;
                ticketsQ2 = resp.data.Table.map((ticket: TesteProps) => {
                  if (
                    tktCode2.includes(ticket.tktCodigo) &&
                    ticket.Selecionado === 1
                  ) {
                    return { ...ticket, checked: true };
                  }
                  return { ...ticket, checked: false };
                });

                messageQ2 = resp.data.Table1[0].out_descricao || "";

                const hasTicket = ticketsQ2.filter(
                  (ticket) => ticket.checked === true
                ).length;

                if (hasTicket === 0) {
                  tktCode2 = [];
                }

                if (roomsUpdate.length > 2) {
                  await returnTickets
                    .get({
                      t: "returnTickets_C",
                      in_token: token,
                      quarto: 3,
                      tktCodigos1: ticketsQ1Payload?.length
                        ? `${ticketsQ1Payload.join()}`
                        : "",
                      tktCodigos2: tktCode2.join(),
                      tktCodigos3: ticketsQ3Payload?.length
                        ? `${ticketsQ3Payload}`
                        : "",
                      tktCodigos4: ticketsQ4Payload?.length
                        ? `${ticketsQ4Payload}`
                        : "",
                      tpQuarto: typeOfRoomQ3[0] ?? 2,
                      resLocalizador: "null",
                      dataInicial: dataInitial ?? "",
                      dataFim: dataFinal ?? "",
                    })
                    .then((resp) => {
                      valuePayQ3 = resp.data.Table1[0].out_valor_Total;
                      ticketsQ3 = resp.data.Table.map((ticket: TesteProps) => {
                        if (
                          ticketsQ3Payload?.includes(ticket.tktCodigo) &&
                          ticket.Selecionado === 1
                        ) {
                          return { ...ticket, checked: true };
                        }
                        return { ...ticket, checked: false };
                      });

                      messageQ3 = resp.data.Table1[0].out_descricao || "";
                    });
                }

                if (roomsUpdate.length > 3) {
                  await returnTickets
                    .get({
                      t: "returnTickets_C",
                      in_token: token,
                      quarto: 4,
                      tktCodigos1: ticketsQ1Payload?.length
                        ? `${ticketsQ1Payload.join()}`
                        : "",
                      tktCodigos2: tktCode2.join(),
                      tktCodigos3: ticketsQ3Payload?.length
                        ? `${ticketsQ3Payload}`
                        : "",
                      tktCodigos4: ticketsQ4Payload?.length
                        ? `${ticketsQ4Payload}`
                        : "",
                      tpQuarto: typeOfRoomQ4[0] ?? 2,
                      resLocalizador: "null",
                      dataInicial: dataInitial ?? "",
                      dataFim: dataFinal ?? "",
                    })
                    .then((resp) => {
                      valuePayQ4 = resp.data.Table1[0].out_valor_Total;
                      ticketsQ4 = resp.data.Table.map((ticket: TesteProps) => {
                        if (
                          ticketsQ4Payload?.includes(ticket.tktCodigo) &&
                          ticket.Selecionado === 1
                        ) {
                          return { ...ticket, checked: true };
                        }
                        return { ...ticket, checked: false };
                      });

                      messageQ4 = resp.data.Table1[0].out_descricao || "";
                    });
                }
              });

            const roonClone = [...roomsUpdate];

            const newRoon = roonClone.map((item) => {
              if (item.room === "quarto 1") {
                return {
                  ...item,
                  tickets: ticketsQ1,
                  message: messageQ1,
                };
              }

              if (item.room === "quarto 2") {
                return {
                  ...item,
                  tickets: ticketsQ2,
                  message: messageQ2,
                };
              }

              if (item.room === "quarto 3") {
                return {
                  ...item,
                  tickets: ticketsQ3,
                  message: messageQ3,
                };
              }

              if (item.room === "quarto 4") {
                return {
                  ...item,
                  tickets: ticketsQ4,
                  message: messageQ4,
                };
              }
              return item;
            });

            setRoons(newRoon);
            setTotalValueOfRoom(
              valuePayQ2 + valuePayQ1 + valuePayQ3 + valuePayQ4
            );
            setTotalValue(valuePayQ2 + valuePayQ1 + valuePayQ3 + valuePayQ4);

            return;
          }
        });
      setLoadingTickets(false);
    }

    if (nameRoom === "quarto 3") {
      const codeTktList = tickets.filter((item) => item.checked === true);
      const tktCode3 = codeTktList.map((item) => item.tktCodigo);
      let messageQ1 = "";
      let messageQ2 = "";
      let messageQ3 = "";
      let messageQ4 = "";
      setLoadingTickets(true);
      await returnTickets
        .get({
          t: "returnTickets_C",
          in_token: token,
          quarto: 1,
          tktCodigos1: ticketsQ1Payload?.length ? `${ticketsQ1Payload}` : "",
          tktCodigos2: ticketsQ2Payload?.length ? `${ticketsQ2Payload}` : "",
          tktCodigos3: tktCode3.join(),
          tktCodigos4: ticketsQ4Payload?.length ? `${ticketsQ4Payload}` : "",
          tpQuarto: typeOfRoomQ1[0],
          resLocalizador: "null",
          dataInicial: dataInitial ?? "",
          dataFim: dataFinal ?? "",
        })
        .then(async (resp) => {
          if (resp.status === 200) {
            valuePayQ1 = resp.data.Table1[0].out_valor_Total;
            ticketsQ1 = resp.data.Table.map((ticket: TesteProps) => {
              if (
                ticketsQ1Payload?.includes(ticket.tktCodigo) &&
                ticket.Selecionado === 1
              ) {
                return { ...ticket, checked: true };
              }
              return { ...ticket, checked: false };
            });

            messageQ1 = resp.data.Table1[0].out_descricao || "";

            await returnTickets
              .get({
                t: "returnTickets_C",
                in_token: token,
                quarto: 2,
                tktCodigos1: ticketsQ1Payload?.length
                  ? `${ticketsQ1Payload}`
                  : "",
                tktCodigos2: ticketsQ2Payload?.length
                  ? `${ticketsQ2Payload}`
                  : "",
                tktCodigos3: tktCode3.join(),
                tktCodigos4: ticketsQ4Payload?.length
                  ? `${ticketsQ4Payload}`
                  : "",
                tpQuarto: typeOfRoomQ2[0],
                resLocalizador: "null",
                dataInicial: dataInitial ?? "",
                dataFim: dataFinal ?? "",
              })
              .then(async (resp) => {
                valuePayQ2 = resp.data.Table1[0].out_valor_Total;
                ticketsQ2 = resp.data.Table.map((ticket: TesteProps) => {
                  if (
                    ticketsQ2Payload?.includes(ticket.tktCodigo) &&
                    ticket.Selecionado === 1
                  ) {
                    return { ...ticket, checked: true };
                  }
                  return { ...ticket, checked: false };
                });

                messageQ2 = resp.data.Table1[0].out_descricao || "";

                if (roomsUpdate.length > 2) {
                  await returnTickets
                    .get({
                      t: "returnTickets_C",
                      in_token: token,
                      quarto: 3,
                      tktCodigos1: ticketsQ1Payload?.length
                        ? `${ticketsQ1Payload}`
                        : "",
                      tktCodigos2: ticketsQ2Payload?.length
                        ? `${ticketsQ2Payload}`
                        : "",
                      tktCodigos3: tktCode3.join(),
                      tktCodigos4: ticketsQ4Payload?.length
                        ? `${ticketsQ4Payload}`
                        : "",
                      tpQuarto: typeOfRoomQ3[0],
                      resLocalizador: "null",
                      dataInicial: dataInitial ?? "",
                      dataFim: dataFinal ?? "",
                    })
                    .then((resp) => {
                      valuePayQ3 = resp.data.Table1[0].out_valor_Total;
                      ticketsQ3 = resp.data.Table.map((ticket: TesteProps) => {
                        if (
                          tktCode3.includes(ticket.tktCodigo) &&
                          ticket.Selecionado === 1
                        ) {
                          return { ...ticket, checked: true };
                        }
                        return { ...ticket, checked: false };
                      });

                      messageQ3 = resp.data.Table1[0].out_descricao || "";

                      const hasTicket = ticketsQ3.filter(
                        (ticket) => ticket.checked === true
                      ).length;

                      if (hasTicket === 0) {
                        ticketsQ3 = [];
                      }
                    });
                }

                if (roomsUpdate.length > 3) {
                  await returnTickets
                    .get({
                      t: "returnTickets_C",
                      in_token: token,
                      quarto: 4,
                      tktCodigos1: ticketsQ1Payload?.length
                        ? `${ticketsQ1Payload}`
                        : "",
                      tktCodigos2: ticketsQ2Payload?.length
                        ? `${ticketsQ2Payload}`
                        : "",
                      tktCodigos3: tktCode3.join(),
                      tktCodigos4: ticketsQ4Payload?.length
                        ? `${ticketsQ4Payload}`
                        : "",
                      tpQuarto: typeOfRoomQ4[0],
                      resLocalizador: "null",
                      dataInicial: dataInitial ?? "",
                      dataFim: dataFinal ?? "",
                    })
                    .then((resp) => {
                      valuePayQ4 = resp.data.Table1[0].out_valor_Total;
                      ticketsQ4 = resp.data.Table.map((ticket: TesteProps) => {
                        if (
                          ticketsQ4Payload?.includes(ticket.tktCodigo) &&
                          ticket.Selecionado === 1
                        ) {
                          return { ...ticket, checked: true };
                        }
                        return { ...ticket, checked: false };
                      });

                      messageQ4 = resp.data.Table1[0].out_descricao || "";
                    });
                }
              });

            const roonClone = [...roomsUpdate];

            const newRoon = roonClone.map((item) => {
              if (item.room === "quarto 1") {
                return {
                  ...item,
                  tickets: ticketsQ1,
                  message: messageQ1,
                };
              }

              if (item.room === "quarto 2") {
                return {
                  ...item,
                  tickets: ticketsQ2,
                  message: messageQ2,
                };
              }

              if (item.room === "quarto 3") {
                return {
                  ...item,
                  tickets: ticketsQ3,
                  message: messageQ3,
                };
              }

              if (item.room === "quarto 4") {
                return {
                  ...item,
                  tickets: ticketsQ4,
                  message: messageQ4,
                };
              }
              return item;
            });

            setRoons(newRoon);
            setTotalValueOfRoom(
              valuePayQ2 + valuePayQ1 + valuePayQ3 + valuePayQ4
            );
            setTotalValue(valuePayQ2 + valuePayQ1 + valuePayQ3 + valuePayQ4);

            return;
          }
        });
      setLoadingTickets(false);
    }

    if (nameRoom === "quarto 4") {
      const codeTktList = tickets.filter((item) => item.checked === true);
      const tktCode4 = codeTktList.map((item) => item.tktCodigo);
      let messageQ1 = "";
      let messageQ2 = "";
      let messageQ3 = "";
      let messageQ4 = "";

      setLoadingTickets(true);

      await returnTickets
        .get({
          t: "returnTickets_C",
          in_token: token,
          quarto: 1,
          tktCodigos1: ticketsQ1Payload?.length ? `${ticketsQ1Payload}` : "",
          tktCodigos2: ticketsQ2Payload?.length ? `${ticketsQ2Payload}` : "",
          tktCodigos3: ticketsQ3Payload?.length ? `${ticketsQ3Payload}` : "",
          tktCodigos4: tktCode4.join(),
          tpQuarto: typeOfRoomQ1[0],
          resLocalizador: "null",
          dataInicial: dataInitial ?? "",
          dataFim: dataFinal ?? "",
        })
        .then(async (resp) => {
          if (resp.status === 200) {
            valuePayQ1 = resp.data.Table1[0].out_valor_Total;
            ticketsQ1 = resp.data.Table.map((ticket: TesteProps) => {
              if (
                ticketsQ1Payload?.includes(ticket.tktCodigo) &&
                ticket.Selecionado === 1
              ) {
                return { ...ticket, checked: true };
              }
              return { ...ticket, checked: false };
            });

            messageQ1 = resp.data.Table1[0].out_descricao || "";

            await returnTickets
              .get({
                t: "returnTickets_C",
                in_token: token,
                quarto: 2,
                tktCodigos1: ticketsQ1Payload?.length
                  ? `${ticketsQ1Payload}`
                  : "",
                tktCodigos2: ticketsQ2Payload?.length
                  ? `${ticketsQ2Payload}`
                  : "",
                tktCodigos3: ticketsQ3Payload?.length
                  ? `${ticketsQ3Payload}`
                  : "",
                tktCodigos4: tktCode4.join(),
                tpQuarto: typeOfRoomQ2[0],
                resLocalizador: "null",
                dataInicial: dataInitial ?? "",
                dataFim: dataFinal ?? "",
              })
              .then(async (resp) => {
                valuePayQ2 = resp.data.Table1[0].out_valor_Total;
                ticketsQ2 = resp.data.Table.map((ticket: TesteProps) => {
                  if (
                    ticketsQ2Payload?.includes(ticket.tktCodigo) &&
                    ticket.Selecionado === 1
                  ) {
                    return { ...ticket, checked: true };
                  }
                  return { ...ticket, checked: false };
                });

                messageQ2 = resp.data.Table1[0].out_descricao || "";

                if (roomsUpdate.length > 2) {
                  await returnTickets
                    .get({
                      t: "returnTickets_C",
                      in_token: token,
                      quarto: 3,
                      tktCodigos1: ticketsQ1Payload?.length
                        ? `${ticketsQ1Payload}`
                        : "",
                      tktCodigos2: ticketsQ2Payload?.length
                        ? `${ticketsQ2Payload}`
                        : "",
                      tktCodigos3: ticketsQ3Payload?.length
                        ? `${ticketsQ3Payload}`
                        : "",
                      tktCodigos4: tktCode4.join(),
                      tpQuarto: typeOfRoomQ3[0],
                      resLocalizador: "null",
                      dataInicial: dataInitial ?? "",
                      dataFim: dataFinal ?? "",
                    })
                    .then(async (resp) => {
                      valuePayQ3 = resp.data.Table1[0].out_valor_Total;
                      ticketsQ3 = resp.data.Table.map((ticket: TesteProps) => {
                        if (
                          ticketsQ3Payload?.includes(ticket.tktCodigo) &&
                          ticket.Selecionado === 1
                        ) {
                          return { ...ticket, checked: true };
                        }
                        return { ...ticket, checked: false };
                      });

                      messageQ3 = resp.data.Table1[0].out_descricao || "";

                      if (roomsUpdate.length > 3) {
                        await returnTickets
                          .get({
                            t: "returnTickets_C",
                            in_token: token,
                            quarto: 4,
                            tktCodigos1: ticketsQ1Payload?.length
                              ? `${ticketsQ1Payload}`
                              : "",
                            tktCodigos2: ticketsQ2Payload?.length
                              ? `${ticketsQ2Payload}`
                              : "",
                            tktCodigos3: ticketsQ3Payload?.length
                              ? `${ticketsQ3Payload}`
                              : "",
                            tktCodigos4: tktCode4.join(),
                            tpQuarto: typeOfRoomQ4[0],
                            resLocalizador: "null",
                            dataInicial: dataInitial ?? "",
                            dataFim: dataFinal ?? "",
                          })
                          .then(async (resp) => {
                            valuePayQ4 = resp.data.Table1[0].out_valor_Total;
                            ticketsQ4 = resp.data.Table.map(
                              (ticket: TesteProps) => {
                                if (
                                  tktCode4?.includes(ticket.tktCodigo) &&
                                  ticket.Selecionado === 1
                                ) {
                                  return { ...ticket, checked: true };
                                }
                                return { ...ticket, checked: false };
                              }
                            );

                            const hasTicket = ticketsQ4.filter(
                              (ticket) => ticket.checked === true
                            ).length;

                            if (hasTicket === 0) {
                              ticketsQ4 = [];
                            }

                            messageQ4 = resp.data.Table1[0].out_descricao || "";
                          });
                      }
                    });
                }
              });

            const roonClone = [...roomsUpdate];

            const newRoon = roonClone.map((item) => {
              if (item.room === "quarto 1") {
                return {
                  ...item,
                  tickets: ticketsQ1,
                  message: messageQ1,
                };
              }

              if (item.room === "quarto 2") {
                return {
                  ...item,
                  tickets: ticketsQ2,
                  message: messageQ2,
                };
              }

              if (item.room === "quarto 3") {
                return {
                  ...item,
                  tickets: ticketsQ3,
                  message: messageQ3,
                };
              }

              if (item.room === "quarto 4") {
                return {
                  ...item,
                  tickets: ticketsQ4,
                  message: messageQ4,
                };
              }
              return item;
            });

            setRoons(newRoon);
            setTotalValueOfRoom(
              valuePayQ2 + valuePayQ1 + valuePayQ3 + valuePayQ4
            );
            setTotalValue(valuePayQ2 + valuePayQ1 + valuePayQ3 + valuePayQ4);

            return;
          }
        });
      setLoadingTickets(false);
    }
  };

  const getTickets = useCallback(
    async (
      numberRoom: number,
      quantityRoons: number,
      typeOfRoom?: (number | undefined)[]
    ) => {
      const token = localStorage.getItem("token");
      const dataInitial = localStorage.getItem("dataInicial");
      const dataFinal = localStorage.getItem("dataFinal");
      let q1: TesteProps[] = [];
      let q2: TesteProps[] = [];
      let q3: TesteProps[] = [];
      let q4: TesteProps[] = [];
      let valueQ1 = 0;
      let valueQ2 = 0;
      let valueQ3 = 0;
      let valueQ4 = 0;
      let messageQ1 = "";
      let messageQ2 = "";
      let messageQ3 = "";
      let messageQ4 = "";

      if (quantityRoons === 1) {
        setLoadingTickets(true);

        await returnTickets
          .get({
            t: "returnTickets_C",
            in_token: token,
            quarto: numberRoom,
            tktCodigos1: "null",
            tktCodigos2: "",
            tktCodigos3: "",
            tktCodigos4: "",
            tpQuarto: typeOfRoom ? typeOfRoom[0] : "1",
            resLocalizador: "null",
            dataInicial: dataInitial,
            dataFim: dataFinal,
          })
          .then((resp) => {
            if (resp.data.Table[0].situacao === 0) {
              q1 = [];
              alert(
                resp.data.Table[0]?.Texto ||
                  "Você ficou muito tempo sem navegar, refaça sua busca"
              );
              window.location.href = `${process.env.PUBLIC_URL}/`;
              return;
            }
            valueQ1 = resp.data.Table1[0].out_valor_Total;

            q1 = resp.data.Table.map((ticket: any) => ({
              ...ticket,
              checked: false,
            }));

            messageQ1 = resp.data.Table1[0].out_descricao || "";

            setTotalValue(valueQ1);
          });
        setLoadingTickets(false);

        return {
          q1: q1,
          totalPay: valueQ1,
          messageQ1,
          messageQ2,
          messageQ3,
          messageQ4,
        };
      }

      if (quantityRoons === 2) {
        setLoadingTickets(true);

        await returnTickets
          .get({
            t: "returnTickets_C",
            in_token: token,
            quarto: 1,
            tktCodigos1: "null",
            tktCodigos2: "null",
            tktCodigos3: "",
            tktCodigos4: "",
            tpQuarto: typeOfRoom ? typeOfRoom[0] : "1",
            resLocalizador: "null",
            dataInicial: dataInitial,
            dataFim: dataFinal,
          })
          .then(async (resp) => {
            if (resp.data.Table[0].situacao === 0) {
              q1 = [];
              alert(
                resp.data.Table[0]?.Texto ||
                  "Você ficou muito tempo sem navegar, refaça sua busca"
              );
              window.location.href = `${process.env.PUBLIC_URL}/`;
              return;
            }

            valueQ1 = resp.data.Table1[0].out_valor_Total;
            q1 = resp.data.Table.map((ticket: any) => ({
              ...ticket,
              checked: false,
            }));

            let payload = resp.data.Table.filter(
              (ticket: any) => ticket.sugestao === 1
            );
            let payloadString = payload
              .map((ticket: any) => ticket.tktCodigo)
              .join();

            messageQ1 = resp.data.Table1[0].out_descricao || "";

            await returnTickets
              .get({
                t: "returnTickets_C",
                in_token: token,
                quarto: 2,
                tktCodigos1: payload !== "" ? `${payloadString}` : "null",
                tktCodigos2: "null",
                tktCodigos3: "",
                tktCodigos4: "",
                tpQuarto: typeOfRoom ? typeOfRoom[1] : "1",
                resLocalizador: "null",
                dataInicial: dataInitial,
                dataFim: dataFinal,
              })
              .then((resp) => {
                if (resp.data.Table[0].situacao === 0) {
                  q2 = [];
                  return;
                }

                valueQ2 = resp.data.Table1[0].out_valor_Total;
                q2 = resp.data.Table.map((ticket: any) => ({
                  ...ticket,
                  checked: false,
                }));

                messageQ2 = resp.data.Table1[0].out_descricao || "";

                setTotalValue(valueQ1 + valueQ2);
              });
          });
        setLoadingTickets(false);

        return {
          q1: q1,
          q2: q2,
          totalPay: valueQ2 + valueQ1,
          messageQ1,
          messageQ2,
          messageQ3,
          messageQ4,
        };
      }

      if (quantityRoons === 3) {
        setLoadingTickets(true);

        await returnTickets
          .get({
            t: "returnTickets_C",
            in_token: token,
            quarto: 1,
            tktCodigos1: "null",
            tktCodigos2: "null",
            tktCodigos3: "null",
            tktCodigos4: "",
            tpQuarto: typeOfRoom ? typeOfRoom[0] : "1",
            resLocalizador: "null",
            dataInicial: dataInitial,
            dataFim: dataFinal,
          })
          .then(async (resp) => {
            if (resp.data.Table[0].situacao === 0) {
              q1 = [];
              alert(
                resp.data.Table[0]?.Texto ||
                  "Você ficou muito tempo sem navegar, refaça sua busca"
              );
              window.location.href = `${process.env.PUBLIC_URL}/`;
              return;
            }

            messageQ1 = resp.data.Table1[0].out_descricao || "";

            valueQ1 = resp.data.Table1[0].out_valor_Total;
            q1 = resp.data.Table.map((ticket: any) => ({
              ...ticket,
              checked: false,
            }));

            let payload = resp.data.Table.filter(
              (ticket: any) => ticket.sugestao === 1
            );
            let payloadString = payload
              .map((ticket: any) => ticket.tktCodigo)
              .join();

            await returnTickets
              .get({
                t: "returnTickets_C",
                in_token: token,
                quarto: 2,
                tktCodigos1: payload !== "" ? `${payloadString}` : "null",
                tktCodigos2: "null",
                tktCodigos3: "null",
                tktCodigos4: "",
                tpQuarto: typeOfRoom ? typeOfRoom[1] : "1",
                resLocalizador: "null",
                dataInicial: dataInitial,
                dataFim: dataFinal,
              })
              .then(async (resp) => {
                if (resp.data.Table[0].situacao === 0) {
                  q2 = [];
                  return;
                }

                valueQ2 = resp.data.Table1[0].out_valor_Total;
                q2 = resp.data.Table.map((ticket: any) => ({
                  ...ticket,
                  checked: false,
                }));

                messageQ2 = resp.data.Table1[0].out_descricao || "";

                let payload2 = resp.data.Table.filter(
                  (ticket: any) => ticket.sugestao === 1
                );
                let payloadString2 = payload2
                  .map((ticket: any) => ticket.tktCodigo)
                  .join();

                await returnTickets
                  .get({
                    t: "returnTickets_C",
                    in_token: token,
                    quarto: 3,
                    tktCodigos1: payload !== "" ? `${payloadString}` : "null",
                    tktCodigos2: payload2 !== "" ? `${payloadString2}` : "null",
                    tktCodigos3: "null",
                    tktCodigos4: "",
                    tpQuarto: typeOfRoom ? typeOfRoom[2] : "1",
                    resLocalizador: "null",
                    dataInicial: dataInitial,
                    dataFim: dataFinal,
                  })
                  .then((resp) => {
                    if (resp.data.Table[0].situacao === 0) {
                      q3 = [];
                      return;
                    }

                    valueQ3 = resp.data.Table1[0].out_valor_Total;
                    q3 = resp.data.Table.map((ticket: any) => ({
                      ...ticket,
                      checked: false,
                    }));

                    messageQ3 = resp.data.Table1[0].out_descricao || "";
                  });

                setTotalValue(valueQ1 + valueQ2 + valueQ3);
              });
          });
        setLoadingTickets(false);

        return {
          q1: q1,
          q2: q2,
          q3: q3,
          q4: q4,
          totalPay: valueQ2 + valueQ1 + valueQ3 + valueQ4,
          messageQ1,
          messageQ2,
          messageQ3,
          messageQ4,
        };
      }

      if (quantityRoons === 4) {
        setLoadingTickets(true);

        await returnTickets
          .get({
            t: "returnTickets_C",
            in_token: token,
            quarto: 1,
            tktCodigos1: "null",
            tktCodigos2: "null",
            tktCodigos3: "null",
            tktCodigos4: "null",
            tpQuarto: typeOfRoom ? typeOfRoom[0] : "1",
            resLocalizador: "null",
            dataInicial: dataInitial,
            dataFim: dataFinal,
          })
          .then(async (resp) => {
            if (resp.data.Table[0].situacao === 0) {
              q1 = [];
              alert(
                resp.data.Table[0]?.Texto ||
                  "Você ficou muito tempo sem navegar, refaça sua busca"
              );
              window.location.href = `${process.env.PUBLIC_URL}/`;
              return;
            }

            valueQ1 = resp.data.Table1[0].out_valor_Total;
            q1 = resp.data.Table.map((ticket: any) => ({
              ...ticket,
              checked: false,
            }));
            messageQ1 = resp.data.Table1[0].out_descricao || "";

            let payload = resp.data.Table.filter(
              (ticket: any) => ticket.sugestao === 1
            );
            let payloadString = payload
              .map((ticket: any) => ticket.tktCodigo)
              .join();

            await returnTickets
              .get({
                t: "returnTickets_C",
                in_token: token,
                quarto: 2,
                tktCodigos1: payload !== "" ? `${payloadString}` : "null",
                tktCodigos2: "null",
                tktCodigos3: "null",
                tktCodigos4: "null",
                tpQuarto: typeOfRoom ? typeOfRoom[1] : "1",
                resLocalizador: "null",
                dataInicial: dataInitial,
                dataFim: dataFinal,
              })
              .then(async (resp) => {
                if (resp.data.Table[0].situacao === 0) {
                  q2 = [];
                  return;
                }

                valueQ2 = resp.data.Table1[0].out_valor_Total;
                q2 = resp.data.Table.map((ticket: any) => ({
                  ...ticket,
                  checked: false,
                }));
                messageQ2 = resp.data.Table1[0].out_descricao || "";

                let payloadQ2 = resp.data.Table.filter(
                  (ticket: any) => ticket.sugestao === 1
                );
                let payloadStringQ2 = payloadQ2
                  .map((ticket: any) => ticket.tktCodigo)
                  .join();

                await returnTickets
                  .get({
                    t: "returnTickets_C",
                    in_token: token,
                    quarto: 3,
                    tktCodigos1: payload !== "" ? `${payloadString}` : "null",
                    tktCodigos2:
                      payloadQ2 !== "" ? `${payloadStringQ2}` : "null",
                    tktCodigos3: "null",
                    tktCodigos4: "null",
                    tpQuarto: typeOfRoom ? typeOfRoom[2] : "1",
                    resLocalizador: "null",
                    dataInicial: dataInitial,
                    dataFim: dataFinal,
                  })
                  .then(async (resp) => {
                    if (resp.data.Table[0].situacao === 0) {
                      q3 = [];
                      return;
                    }

                    valueQ3 = resp.data.Table1[0].out_valor_Total;
                    q3 = resp.data.Table.map((ticket: any) => ({
                      ...ticket,
                      checked: false,
                    }));
                    messageQ3 = resp.data.Table1[0].out_descricao || "";

                    let payloadQ3 = resp.data.Table.filter(
                      (ticket: any) => ticket.sugestao === 1
                    );
                    let payloadStringQ3 = payloadQ3
                      .map((ticket: any) => ticket.tktCodigo)
                      .join();

                    await returnTickets
                      .get({
                        t: "returnTickets_C",
                        in_token: token,
                        quarto: 4,
                        tktCodigos1:
                          payload !== "" ? `${payloadString}` : "null",
                        tktCodigos2:
                          payloadQ2 !== "" ? `${payloadStringQ2}` : "null",
                        tktCodigos3:
                          payloadQ3 !== "" ? `${payloadStringQ3}` : "null",
                        tktCodigos4: "null",
                        tpQuarto: typeOfRoom ? typeOfRoom[3] : "1",
                        resLocalizador: "null",
                        dataInicial: dataInitial,
                        dataFim: dataFinal,
                      })
                      .then((resp) => {
                        if (resp.data.Table[0].situacao === 0) {
                          q4 = [];
                          return;
                        }

                        valueQ4 = resp.data.Table1[0].out_valor_Total;
                        q4 = resp.data.Table.map((ticket: any) => ({
                          ...ticket,
                          checked: false,
                        }));
                        messageQ4 = resp.data.Table1[0].out_descricao || "";

                        setTotalValue(valueQ2 + valueQ1 + valueQ3 + valueQ4);
                      });
                  });
              });
          });
        setLoadingTickets(false);

        return {
          q1: q1,
          q2: q2,
          q3: q3,
          q4: q4,
          totalPay: valueQ2 + valueQ1 + valueQ3 + valueQ4,
          messageQ1,
          messageQ2,
          messageQ3,
          messageQ4,
        };
      }
    },
    []
  );

  type returnPequisaProps = {
    rooms?: TypeOfRoons[];
  };

  const returnPequisa = useCallback(
    ({ rooms = [] }: returnPequisaProps) => {
      api
        .post(`wsCoobrastur/Hotel.asmx/returnPesquisa_1`, {
          token: token,
        })
        .then((resp) => {
          if (resp.data.Table.length > 0) {
            resp.data.Table.map(async (element: HotelDetailsProps) => {
              /* Get optionsTypeOfRoon */

              const {
                data: { Table: optionsTypesAvailable },
              } = await api.get(
                `wsCoobrastur/PortalUsuario.asmx/returnQuartosList?hotCodigo=${element.hotCodigoSelecionado}`
              );
              // console.log(optionsTypesAvailable);

              const optionsTypeOfRoonQ1: Options[] = optionsTypesAvailable.map(
                ({ codigo, descricao }: returnQuartosList, i: number) => ({
                  id: descricao,
                  label: descricao,
                  value: descricao,
                  name: "quarto 1",
                  código: codigo,
                  checked: i === 0,
                })
              );
              const optionsTypeOfRoonQ2: Options[] = optionsTypesAvailable.map(
                ({ codigo, descricao }: returnQuartosList, i: number) => ({
                  id: descricao,
                  label: descricao,
                  value: descricao,
                  name: "quarto 2",
                  código: codigo,
                  checked: i === 0,
                })
              );
              const optionsTypeOfRoonQ3: Options[] = optionsTypesAvailable.map(
                ({ codigo, descricao }: returnQuartosList, i: number) => ({
                  id: descricao,
                  label: descricao,
                  value: descricao,
                  name: "quarto 3",
                  código: codigo,
                  checked: i === 0,
                })
              );
              const optionsTypeOfRoonQ4: Options[] = optionsTypesAvailable.map(
                ({ codigo, descricao }: returnQuartosList, i: number) => ({
                  id: descricao,
                  label: descricao,
                  value: descricao,
                  name: "quarto 4",
                  código: codigo,
                  checked: i === 0,
                })
              );

              if (element.qtdeQuartos === 1) {
                let typeRoom: (number | undefined)[];

                if (rooms.length) {
                  const roomTypes = rooms.map((room) => room.optionsTypeOfRoon);
                  const typeSelect = roomTypes.map((room) =>
                    room.filter((type) => type.checked)
                  );
                  const typesRooms = typeSelect.map((room) => room[0].código);

                  typeRoom = typesRooms;
                } else {
                  const [typeSelectQ1] = optionsTypeOfRoonQ1.filter(
                    (type) => type.checked
                  );

                  typeRoom = [typeSelectQ1.código];
                }

                const resp = await getTickets(1, 1, typeRoom);

                let roon = oneRoonItem.map((item) => {
                  return {
                    ...item,
                    tickets: resp?.q1,
                    formRefOtherPerson: formOtherPersonRef,
                    typeOfRoom:
                      rooms[0]?.optionsTypeOfRoon || optionsTypeOfRoonQ1,
                    optionsTypeOfRoon:
                      rooms[0]?.optionsTypeOfRoon || optionsTypeOfRoonQ1,
                    message: resp?.messageQ1 || "",
                  };
                });

                const newRoon = roon.map((item) => {
                  const tickets = item.tickets?.map((ticket) => {
                    return { ...ticket, checked: false };
                  });

                  return { ...item, tickets: tickets };
                });

                setRoons(newRoon);

                let total = resp?.totalPay;

                if (total !== undefined) {
                  setTotalValueOfRoom(total);
                }

                return;
              }

              if (element.qtdeQuartos === 2) {
                let typeRoom: (number | undefined)[];

                if (rooms.length) {
                  const roomTypes = rooms.map((room) => room.optionsTypeOfRoon);
                  const typeSelect = roomTypes.map((room) =>
                    room.filter((type) => type.checked)
                  );
                  const typesRooms = typeSelect.map((room) => room[0].código);

                  typeRoom = typesRooms;
                } else {
                  const [typeSelectQ1] = optionsTypeOfRoonQ1.filter(
                    (type) => type.checked
                  );
                  const [typeSelectQ2] = optionsTypeOfRoonQ2.filter(
                    (type) => type.checked
                  );

                  typeRoom = [typeSelectQ1.código, typeSelectQ2.código];
                }

                const resp = await getTickets(2, 2, typeRoom);

                let roon = twoRoonItem.map((item) => {
                  if (item.room === "quarto 1") {
                    return {
                      ...item,
                      tickets: resp?.q1,
                      typeOfRoom:
                        rooms[0]?.optionsTypeOfRoon || optionsTypeOfRoonQ1,
                      optionsTypeOfRoon:
                        rooms[0]?.optionsTypeOfRoon || optionsTypeOfRoonQ1,
                      message: resp?.messageQ1 || "",
                    };
                  }
                  if (item.room === "quarto 2") {
                    return {
                      ...item,
                      tickets: resp?.q2,
                      typeOfRoom:
                        rooms[1]?.optionsTypeOfRoon || optionsTypeOfRoonQ2,
                      optionsTypeOfRoon:
                        rooms[1]?.optionsTypeOfRoon || optionsTypeOfRoonQ2,
                      message: resp?.messageQ2 || "",
                    };
                  }
                  return { ...item };
                });

                const newRoom2 = roon.map((item) => {
                  const tickets = item.tickets?.map((ticket) => {
                    return { ...ticket, checked: false };
                  });

                  return { ...item, tickets: tickets };
                });

                setRoons(newRoom2);

                let total = resp?.totalPay;

                if (total !== undefined) {
                  setTotalValueOfRoom(total);
                }

                return;
              }

              if (element.qtdeQuartos === 3) {
                let typeRoom: (number | undefined)[];

                if (rooms.length) {
                  const roomTypes = rooms.map((room) => room.optionsTypeOfRoon);
                  const typeSelect = roomTypes.map((room) =>
                    room.filter((type) => type.checked)
                  );
                  const typesRooms = typeSelect.map((room) => room[0].código);

                  typeRoom = typesRooms;
                } else {
                  const [typeSelectQ1] = optionsTypeOfRoonQ1.filter(
                    (type) => type.checked
                  );
                  const [typeSelectQ2] = optionsTypeOfRoonQ2.filter(
                    (type) => type.checked
                  );
                  const [typeSelectQ3] = optionsTypeOfRoonQ3.filter(
                    (type) => type.checked
                  );

                  typeRoom = [
                    typeSelectQ1.código,
                    typeSelectQ2.código,
                    typeSelectQ3.código,
                  ];
                }

                const resp = await getTickets(3, 3, typeRoom);

                let roon = threeRoonItem.map((item) => {
                  if (item.room === "quarto 1") {
                    return {
                      ...item,
                      tickets: resp?.q1,
                      typeOfRoom:
                        rooms[0]?.optionsTypeOfRoon || optionsTypeOfRoonQ1,
                      optionsTypeOfRoon:
                        rooms[0]?.optionsTypeOfRoon || optionsTypeOfRoonQ1,
                      message: resp?.messageQ1 || "",
                    };
                  }

                  if (item.room === "quarto 2") {
                    return {
                      ...item,
                      tickets: resp?.q2,
                      typeOfRoom:
                        rooms[1]?.optionsTypeOfRoon || optionsTypeOfRoonQ2,
                      optionsTypeOfRoon:
                        rooms[1]?.optionsTypeOfRoon || optionsTypeOfRoonQ2,
                      message: resp?.messageQ2 || "",
                    };
                  }

                  if (item.room === "quarto 3") {
                    return {
                      ...item,
                      tickets: resp?.q3,
                      typeOfRoom:
                        rooms[2]?.optionsTypeOfRoon || optionsTypeOfRoonQ3,
                      optionsTypeOfRoon:
                        rooms[2]?.optionsTypeOfRoon || optionsTypeOfRoonQ3,
                      message: resp?.messageQ3 || "",
                    };
                  }

                  return { ...item };
                });

                const newRoom3 = roon.map((item) => {
                  const tickets = item.tickets?.map((ticket) => {
                    return { ...ticket, checked: false };
                  });

                  return { ...item, tickets: tickets };
                });

                setRoons(newRoom3);

                let total = resp?.totalPay;

                if (total !== undefined) {
                  setTotalValueOfRoom(total);
                }

                return;
              }

              if (element.qtdeQuartos === 4) {
                let typeRoom: (number | undefined)[];

                if (rooms.length) {
                  const roomTypes = rooms.map((room) => room.optionsTypeOfRoon);
                  const typeSelect = roomTypes.map((room) =>
                    room.filter((type) => type.checked)
                  );
                  const typesRooms = typeSelect.map((room) => room[0].código);

                  typeRoom = typesRooms;
                } else {
                  const [typeSelectQ1] = optionsTypeOfRoonQ1.filter(
                    (type) => type.checked
                  );
                  const [typeSelectQ2] = optionsTypeOfRoonQ2.filter(
                    (type) => type.checked
                  );
                  const [typeSelectQ3] = optionsTypeOfRoonQ3.filter(
                    (type) => type.checked
                  );
                  const [typeSelectQ4] = optionsTypeOfRoonQ4.filter(
                    (type) => type.checked
                  );

                  typeRoom = [
                    typeSelectQ1.código,
                    typeSelectQ2.código,
                    typeSelectQ3.código,
                    typeSelectQ4.código,
                  ];
                }

                const resp = await getTickets(4, 4, typeRoom);

                let roon = fourRoonItem.map((item) => {
                  if (item.room === "quarto 1") {
                    return {
                      ...item,
                      tickets: resp?.q1,
                      typeOfRoom:
                        rooms[0]?.optionsTypeOfRoon || optionsTypeOfRoonQ1,
                      optionsTypeOfRoon:
                        rooms[0]?.optionsTypeOfRoon || optionsTypeOfRoonQ1,
                      message: resp?.messageQ1 || "",
                    };
                  }

                  if (item.room === "quarto 2") {
                    return {
                      ...item,
                      tickets: resp?.q2,
                      typeOfRoom:
                        rooms[1]?.optionsTypeOfRoon || optionsTypeOfRoonQ2,
                      optionsTypeOfRoon:
                        rooms[1]?.optionsTypeOfRoon || optionsTypeOfRoonQ2,
                      message: resp?.messageQ2 || "",
                    };
                  }

                  if (item.room === "quarto 3") {
                    return {
                      ...item,
                      tickets: resp?.q3,
                      typeOfRoom:
                        rooms[2]?.optionsTypeOfRoon || optionsTypeOfRoonQ3,
                      optionsTypeOfRoon:
                        rooms[2]?.optionsTypeOfRoon || optionsTypeOfRoonQ3,
                      message: resp?.messageQ3 || "",
                    };
                  }

                  if (item.room === "quarto 4") {
                    return {
                      ...item,
                      tickets: resp?.q4,
                      typeOfRoom:
                        rooms[3]?.optionsTypeOfRoon || optionsTypeOfRoonQ4,
                      optionsTypeOfRoon:
                        rooms[3]?.optionsTypeOfRoon || optionsTypeOfRoonQ4,
                      message: resp?.messageQ4 || "",
                    };
                  }

                  return { ...item };
                });

                const newRoom4 = roon.map((item) => {
                  const tickets = item.tickets?.map((ticket) => {
                    return { ...ticket, checked: false };
                  });

                  return { ...item, tickets: tickets };
                });

                setRoons(newRoom4);

                let total = resp?.totalPay;

                if (total !== undefined) {
                  setTotalValueOfRoom(total);
                }

                return;
              }
            });
          }

          if (resp.data.Table[0].situacao === 0) {
            setMessage({
              title: "você ficou muito tempo sem navegar",
              body: "você ficou muito tempo sem navegar, refaça sua busca",
            });
            setModalReload(true);

            return;
          }
        })
        .catch((e) => {
          console.log(e);
          setLoadingTickets(false);
        });
    },
    [getTickets, token]
  );

  const indexRoom = (nameRoom: string): number => {
    switch (nameRoom) {
      case "quarto 4":
        return 3;
      case "quarto 3":
        return 2;
      case "quarto 2":
        return 1;
      case "quarto 1":
      default:
        return 0;
    }
  };

  const handleSelectedRoom = (item: Options) => {
    const copyArray = [...roons];
    setShowTotalValue(false);
    setResetRadio(true);

    const newRooms = copyArray.map((room) => {
      if (room.room === item.name) {
        const typeOfRoom = room.optionsTypeOfRoon.map((typeOfRoom) => {
          if (typeOfRoom.id === item.id) {
            return { ...typeOfRoom, checked: true };
          }

          return { ...typeOfRoom, checked: false };
        });

        return {
          ...room,
          optionsTypeOfRoon: typeOfRoom,
          typeOfRoom,
          opened: false,
        };
      }

      return { ...room, opened: false };
    });
    const ticketsRoom = newRooms[indexRoom(item.name)].tickets || [];
    updateTickets(ticketsRoom, item.name, newRooms);
  };

  const handleToggleModalTypeOfroon = useCallback(
    (roon: TypeOfRoons) => {
      const copyRoon = [...roons];

      const newRoons = copyRoon.map((element) => {
        if (roon.id === element.id) {
          if (element.opened) {
            return { ...element, opened: false };
          }

          return { ...element, opened: true };
        }

        return { ...element, opened: false };
      });

      setRoons(newRoons);
    },
    [roons]
  );

  const setNameRoon = (item: Options[]) => {
    const itemClone = [...item];
    const element = itemClone.find((element) => element.checked === true);

    return element?.label;
  };

  const setDescriptionRoon = (item: Options[]) => {
    const itemClone = [...item];
    const element = itemClone.find((element) => element.checked === true);

    return element?.description;
  };

  const handleOpenFormOtherPerson = (item: Options) => {
    const validId = ["outra pessoa", "outra pessoa2", "outra pessoa3"].includes(
      item.id
    );

    if (validId) {
      setOpenFormOtherPerson(true);
    }

    if (
      item.id === "sou hóspede" ||
      item.id === "sou hóspede2" ||
      item.id === "sou hóspede3"
    ) {
      setOpenFormOtherPerson(false);
    }
  };

  const confirmationTickets = useCallback(
    async (NoSetValue?: boolean) => {
      if (!NoSetValue) {
        setShowTotalValue(false);
      }

      const ticketsRooms = roons.map((room) =>
        room.tickets
          ?.filter((tickets) => tickets.checked)
          .map((tickets) => tickets.tktCodigo)
      );
      const typesRooms = roons.map((room) =>
        room.optionsTypeOfRoon
          .filter((type) => type.checked)
          .map((type) => type.código)
      );

      let q1: TesteProps[] = [];
      let q2: TesteProps[] = [];
      let q3: TesteProps[] = [];
      let q4: TesteProps[] = [];
      let valueQ1 = 0;
      let valueQ2 = 0;
      let valueQ3 = 0;
      let valueQ4 = 0;
      let messageQ1 = "";
      let messageQ2 = "";
      let messageQ3 = "";
      let messageQ4 = "";

      const checkedTicket = (ticket: TesteProps) => {
        if (ticket.Selecionado === 1) {
          return { ...ticket, checked: true };
        }

        return { ...ticket, checked: false };
      };

      if (ticketsRooms.length === 1) {
        await returnTickets
          .get({
            t: "returnTickets_C",
            in_token: token,
            quarto: 1,
            tktCodigos1: ticketsRooms[0]?.join(",") || "",
            tktCodigos2: ticketsRooms[1]?.join(",") || "",
            tktCodigos3: ticketsRooms[2]?.join(",") || "",
            tktCodigos4: ticketsRooms[3]?.join(",") || "",
            tpQuarto: typesRooms ? typesRooms[0][0] : "1",
            resLocalizador: "null",
            dataInicial: dataInitial,
            dataFim: dataFinal,
          })
          .then((resp) => {
            if (resp.data.Table[0].situacao === 0) {
              q1 = [];
              alert(
                resp.data.Table[0]?.Texto ||
                  "Você ficou muito tempo sem navegar, refaça sua busca"
              );
              window.location.href = `${process.env.PUBLIC_URL}/`;
              return;
            }
            valueQ1 = resp.data.Table1[0].out_valor_Total;

            q1 = resp.data.Table.map((ticket: any) => ({
              ...ticket,
              checked: false,
            }));

            messageQ1 = resp.data.Table1[0].out_descricao || "";

            setTotalValue(valueQ1);
          });

        if (valueQ1) {
          setTotalValueOfRoom(valueQ1);
        }

        const ticketsQ1 = q1.map(checkedTicket);

        const newRooms = roons.map((room, index) => {
          if (index === 0) {
            return {
              ...room,
              message: messageQ1,
              tickets: ticketsQ1,
            };
          }

          return room;
        });

        setRoons(newRooms);
      }

      if (ticketsRooms.length === 2) {
        await returnTickets
          .get({
            t: "returnTickets_C",
            in_token: token,
            quarto: 1,
            tktCodigos1: ticketsRooms[0]?.join(",") || "",
            tktCodigos2: ticketsRooms[1]?.join(",") || "",
            tktCodigos3: ticketsRooms[2]?.join(",") || "",
            tktCodigos4: ticketsRooms[3]?.join(",") || "",
            tpQuarto: typesRooms ? typesRooms[0][0] : "1",
            resLocalizador: "null",
            dataInicial: dataInitial,
            dataFim: dataFinal,
          })
          .then(async (resp) => {
            if (resp.data.Table[0].situacao === 0) {
              q1 = [];
              alert(
                resp.data.Table[0]?.Texto ||
                  "Você ficou muito tempo sem navegar, refaça sua busca"
              );
              window.location.href = `${process.env.PUBLIC_URL}/`;
              return;
            }

            valueQ1 = resp.data.Table1[0].out_valor_Total;
            q1 = resp.data.Table.map((ticket: any) => ({
              ...ticket,
              checked: false,
            }));

            messageQ1 = resp.data.Table1[0].out_descricao || "";

            await returnTickets
              .get({
                t: "returnTickets_C",
                in_token: token,
                quarto: 2,
                tktCodigos1: ticketsRooms[0]?.join(",") || "",
                tktCodigos2: ticketsRooms[1]?.join(",") || "",
                tktCodigos3: ticketsRooms[2]?.join(",") || "",
                tktCodigos4: ticketsRooms[3]?.join(",") || "",
                tpQuarto: typesRooms ? typesRooms[1][0] : "1",
                resLocalizador: "null",
                dataInicial: dataInitial,
                dataFim: dataFinal,
              })
              .then((resp) => {
                if (resp.data.Table[0].situacao === 0) {
                  q2 = [];
                  return;
                }

                valueQ2 = resp.data.Table1[0].out_valor_Total;
                q2 = resp.data.Table.map((ticket: any) => ({
                  ...ticket,
                  checked: false,
                }));

                messageQ2 = resp.data.Table1[0].out_descricao || "";

                setTotalValue(valueQ1 + valueQ2);
              });
          });

        const totalPay = valueQ1 + valueQ2 + valueQ3 + valueQ4;

        if (totalPay) {
          setTotalValueOfRoom(totalPay);
        }

        const ticketsQ1 = q1.map(checkedTicket);

        const ticketsQ2 = q2.map(checkedTicket);

        const newRooms = roons.map((room, index) => {
          if (index === 0) {
            return {
              ...room,
              message: messageQ1,
              tickets: ticketsQ1,
            };
          }

          if (index === 1) {
            return {
              ...room,
              message: messageQ2,
              tickets: ticketsQ2,
            };
          }

          return room;
        });

        setRoons(newRooms);
      }

      if (ticketsRooms.length === 3) {
        await returnTickets
          .get({
            t: "returnTickets_C",
            in_token: token,
            quarto: 1,
            tktCodigos1: ticketsRooms[0]?.join(",") || "",
            tktCodigos2: ticketsRooms[1]?.join(",") || "",
            tktCodigos3: ticketsRooms[2]?.join(",") || "",
            tktCodigos4: ticketsRooms[3]?.join(",") || "",
            tpQuarto: typesRooms ? typesRooms[0][0] : "1",
            resLocalizador: "null",
            dataInicial: dataInitial,
            dataFim: dataFinal,
          })
          .then(async (resp) => {
            if (resp.data.Table[0].situacao === 0) {
              q1 = [];
              alert(
                resp.data.Table[0]?.Texto ||
                  "Você ficou muito tempo sem navegar, refaça sua busca"
              );
              window.location.href = `${process.env.PUBLIC_URL}/`;
              return;
            }

            messageQ1 = resp.data.Table1[0].out_descricao || "";

            valueQ1 = resp.data.Table1[0].out_valor_Total;
            q1 = resp.data.Table.map((ticket: any) => ({
              ...ticket,
              checked: false,
            }));

            await returnTickets
              .get({
                t: "returnTickets_C",
                in_token: token,
                quarto: 2,
                tktCodigos1: ticketsRooms[0]?.join(",") || "",
                tktCodigos2: ticketsRooms[1]?.join(",") || "",
                tktCodigos3: ticketsRooms[2]?.join(",") || "",
                tktCodigos4: ticketsRooms[3]?.join(",") || "",
                tpQuarto: typesRooms ? typesRooms[1][0] : "1",
                resLocalizador: "null",
                dataInicial: dataInitial,
                dataFim: dataFinal,
              })
              .then(async (resp) => {
                if (resp.data.Table[0].situacao === 0) {
                  q2 = [];
                  return;
                }

                valueQ2 = resp.data.Table1[0].out_valor_Total;
                q2 = resp.data.Table.map((ticket: any) => ({
                  ...ticket,
                  checked: false,
                }));

                messageQ2 = resp.data.Table1[0].out_descricao || "";

                await returnTickets
                  .get({
                    t: "returnTickets_C",
                    in_token: token,
                    quarto: 3,
                    tktCodigos1: ticketsRooms[0]?.join(",") || "",
                    tktCodigos2: ticketsRooms[1]?.join(",") || "",
                    tktCodigos3: ticketsRooms[2]?.join(",") || "",
                    tktCodigos4: ticketsRooms[3]?.join(",") || "",
                    tpQuarto: typesRooms ? typesRooms[2][0] : "1",
                    resLocalizador: "null",
                    dataInicial: dataInitial,
                    dataFim: dataFinal,
                  })
                  .then((resp) => {
                    if (resp.data.Table[0].situacao === 0) {
                      q3 = [];
                      return;
                    }

                    valueQ3 = resp.data.Table1[0].out_valor_Total;
                    q3 = resp.data.Table.map((ticket: any) => ({
                      ...ticket,
                      checked: false,
                    }));

                    messageQ3 = resp.data.Table1[0].out_descricao || "";
                  });

                setTotalValue(valueQ1 + valueQ2 + valueQ3);
              });
          });

        const totalPay = valueQ1 + valueQ2 + valueQ3 + valueQ4;

        if (totalPay) {
          setTotalValueOfRoom(totalPay);
        }

        const ticketsQ1 = q1.map(checkedTicket);

        const ticketsQ2 = q2.map(checkedTicket);

        const ticketsQ3 = q3.map(checkedTicket);

        const newRooms = roons.map((room, index) => {
          if (index === 0) {
            return {
              ...room,
              message: messageQ1,
              tickets: ticketsQ1,
            };
          }

          if (index === 1) {
            return {
              ...room,
              message: messageQ2,
              tickets: ticketsQ2,
            };
          }

          if (index === 2) {
            return {
              ...room,
              message: messageQ3,
              tickets: ticketsQ3,
            };
          }

          return room;
        });

        setRoons(newRooms);
      }

      if (ticketsRooms.length === 4) {
        await returnTickets
          .get({
            t: "returnTickets_C",
            in_token: token,
            quarto: 1,
            tktCodigos1: ticketsRooms[0]?.join(",") || "",
            tktCodigos2: ticketsRooms[1]?.join(",") || "",
            tktCodigos3: ticketsRooms[2]?.join(",") || "",
            tktCodigos4: ticketsRooms[3]?.join(",") || "",
            tpQuarto: typesRooms ? typesRooms[0][0] : "1",
            resLocalizador: "null",
            dataInicial: dataInitial,
            dataFim: dataFinal,
          })
          .then(async (resp) => {
            if (resp.data.Table[0].situacao === 0) {
              q1 = [];
              alert(
                resp.data.Table[0]?.Texto ||
                  "Você ficou muito tempo sem navegar, refaça sua busca"
              );
              window.location.href = `${process.env.PUBLIC_URL}/`;
              return;
            }

            valueQ1 = resp.data.Table1[0].out_valor_Total;
            q1 = resp.data.Table.map((ticket: any) => ({
              ...ticket,
              checked: false,
            }));
            messageQ1 = resp.data.Table1[0].out_descricao || "";

            await returnTickets
              .get({
                t: "returnTickets_C",
                in_token: token,
                quarto: 2,
                tktCodigos1: ticketsRooms[0]?.join(",") || "",
                tktCodigos2: ticketsRooms[1]?.join(",") || "",
                tktCodigos3: ticketsRooms[2]?.join(",") || "",
                tktCodigos4: ticketsRooms[3]?.join(",") || "",
                tpQuarto: typesRooms ? typesRooms[1][0] : "1",
                resLocalizador: "null",
                dataInicial: dataInitial,
                dataFim: dataFinal,
              })
              .then(async (resp) => {
                if (resp.data.Table[0].situacao === 0) {
                  q2 = [];
                  return;
                }

                valueQ2 = resp.data.Table1[0].out_valor_Total;
                q2 = resp.data.Table.map((ticket: any) => ({
                  ...ticket,
                  checked: false,
                }));
                messageQ2 = resp.data.Table1[0].out_descricao || "";

                await returnTickets
                  .get({
                    t: "returnTickets_C",
                    in_token: token,
                    quarto: 3,
                    tktCodigos1: ticketsRooms[0]?.join(",") || "",
                    tktCodigos2: ticketsRooms[1]?.join(",") || "",
                    tktCodigos3: ticketsRooms[2]?.join(",") || "",
                    tktCodigos4: ticketsRooms[3]?.join(",") || "",
                    tpQuarto: typesRooms ? typesRooms[2][0] : "1",
                    resLocalizador: "null",
                    dataInicial: dataInitial,
                    dataFim: dataFinal,
                  })
                  .then(async (resp) => {
                    if (resp.data.Table[0].situacao === 0) {
                      q3 = [];
                      return;
                    }

                    valueQ3 = resp.data.Table1[0].out_valor_Total;
                    q3 = resp.data.Table.map((ticket: any) => ({
                      ...ticket,
                      checked: false,
                    }));
                    messageQ3 = resp.data.Table1[0].out_descricao || "";

                    await returnTickets
                      .get({
                        t: "returnTickets_C",
                        in_token: token,
                        quarto: 4,
                        tktCodigos1: ticketsRooms[0]?.join(",") || "",
                        tktCodigos2: ticketsRooms[1]?.join(",") || "",
                        tktCodigos3: ticketsRooms[2]?.join(",") || "",
                        tktCodigos4: ticketsRooms[3]?.join(",") || "",
                        tpQuarto: typesRooms ? typesRooms[3][0] : "1",
                        resLocalizador: "null",
                        dataInicial: dataInitial,
                        dataFim: dataFinal,
                      })
                      .then((resp) => {
                        if (resp.data.Table[0].situacao === 0) {
                          q4 = [];
                          return;
                        }

                        valueQ4 = resp.data.Table1[0].out_valor_Total;
                        q4 = resp.data.Table.map((ticket: any) => ({
                          ...ticket,
                          checked: false,
                        }));
                        messageQ4 = resp.data.Table1[0].out_descricao || "";

                        setTotalValue(valueQ2 + valueQ1 + valueQ3 + valueQ4);
                      });
                  });
              });
          });

        const totalPay = valueQ1 + valueQ2 + valueQ3 + valueQ4;

        if (totalPay) {
          setTotalValueOfRoom(totalPay);
        }

        const ticketsQ1 = q1.map(checkedTicket);

        const ticketsQ2 = q2.map(checkedTicket);

        const ticketsQ3 = q3.map(checkedTicket);

        const ticketsQ4 = q4.map(checkedTicket);

        const newRooms = roons.map((room, index) => {
          if (index === 0) {
            return {
              ...room,
              message: messageQ1,
              tickets: ticketsQ1,
            };
          }

          if (index === 1) {
            return {
              ...room,
              message: messageQ2,
              tickets: ticketsQ2,
            };
          }

          if (index === 2) {
            return {
              ...room,
              message: messageQ3,
              tickets: ticketsQ3,
            };
          }

          if (index === 3) {
            return {
              ...room,
              message: messageQ4,
              tickets: ticketsQ4,
            };
          }

          return room;
        });

        setRoons(newRooms);
      }

      if (!NoSetValue) {
        setShowTotalValue(true);
      }
    },
    [dataFinal, dataInitial, roons, token]
  );

  const handleErrorInResLocalizador = () => {
    setMessage({
      title: "Ops! Parece que encontramos um problema! ",
      body: "Por favor entre em contato com a nossa central no telefone (51) 3018-6900, não refaça sua reserva!",
    });
    setModalReload(true);
  };

  const handleReserveHotel = useCallback(async () => {
    setLoadingReservation(true);
    try {
      if (openFormOtherPerson) {
        formOtherPersonRef.current?.submitForm();
        if (!formOtherPersonRef.current?.getData()) {
          throw new Error();
        }
        const values = formOtherPersonRef.current.getData();
        await validadeFormOtherPerson(values as FormOtherPersonProps);
        // const terceiroInfo = await validadeFormOtherPerson(values as FormOtherPersonProps);
        // const terceirosTempJson = [terceiroInfo]
      }
      if (totalValueOfRoom > 0 && namePayment === "") {
        throw new Error("Escolha uma forma de pagamento");
      }
      await confirmationTickets(true);

      if (namePayment === "pix") {
        navigate(`${process.env.PUBLIC_URL}/pix`);
        setLoadingReservation(false);
        return;
      }

      if (namePayment === "cartao") {
        formRef.current?.submitForm();
        // setLoadingReservation(false); //seting onFinish in form payment
        return;
      }

      const ddd = phoneUser.substring(0, 2);
      const phoneWIthouDDD = phoneUser.substring(2, phoneUser.length);
      const data = {
        EmailPessoal: email,
        resTelefone: phoneWIthouDDD,
        resTelefoneDDD: ddd,
        disCodigo: null,
      };

      const dataPayment = {
        TipoPgto: 0,
        fmCodigo: "",
        total: parseFloat(totalValueOfRoom.toString()).toFixed(2),
      };
      // Caso onde o ticket pagou toda a hospedagem
      api
        .get("/wsCoobrastur/Hotel.asmx/Pagamento_S_Hotel", {
          params: {
            dados: Object.values({
              t: "Pagamento_S_Hotel",
              in_token: token,
              reservaTempJson: JSON.stringify([data]),
              terceirosTempJson: JSON.stringify([]),
              acompanhantesTempJson: JSON.stringify([]),
              pagamentoJson: JSON.stringify([dataPayment]),
            }).join(";"),
          },
        })
        .then((resp) => {
          if (resp.data.situacao === "0" || resp.data.situacao === 0) {
            setMessage({
              title: "Você ficou muito tempo sem navegar",
              body: "refaça sua busca",
            });
            setModalReload(true);
            setLoadingReservation(false);
            return;
          }
          if (resp.data.situacao === 1 || resp.data.situacao === "1") {
            if (!resp?.data?.resLocalizador) {
              handleErrorInResLocalizador();
              setLoadingReservation(false);
              return;
            }
            localStorage.setItem("resLocale", resp.data.resLocalizador);
            const resLocalizador = resp?.data?.resLocalizador || "";
            navigate(`${process.env.PUBLIC_URL}/reserva/${resLocalizador}`, {
              state: {
                resLocalizador: String(resp.data.resLocalizador),
              },
            });
            setLoadingReservation(false);
            return;
          }
          setMessageModalError({
            title: "Ops! Algo deu Errado.",
            htmlBody: !resp?.data?.mensagem ? resp.data : resp.data.mensagem,
          });
          setLoadingReservation(false);
        })
        .catch((e) => {
          console.error({ componenet: "choosePayment", error: e });

          const messageError =
            typeof e?.response?.data === "string"
              ? e?.response?.data
              : e?.message || "Algo deu Errado, tente mais tarde";

          if (messageError?.includes("Pagamento não autorizado")) {
            setMessageModalError({
              title: "Pagamento não autorizado.",
              body: "Contate sua operadora. Reserva não efetuada",
            });
            setLoadingReservation(false);

            return;
          }
          setMessageModalError({
            title: "Ops! Algo deu Errado.",
            htmlBody: messageError,
          });
          setLoadingReservation(false);
        });
    } catch (error) {
      console.error(error);
      setLoadingReservation(false);
    }
  }, [
    namePayment,
    openFormOtherPerson,
    phoneUser,
    email,
    totalValueOfRoom,
    token,
    navigate,
    confirmationTickets,
  ]);

  const hasTicketRecomendation = (tickets: TesteProps[] | undefined) => {
    if (tickets) {
      const tickertSelectRecomentation = tickets?.filter(
        (ticket) => ticket?.Selecionado === 1 && ticket?.sugestao === 1
      );

      if (tickertSelectRecomentation.length > 0) {
        return <BadgeRecomended>Mais recomendado</BadgeRecomended>;
      }
    }

    return null;
  };

  const hasTicketSeletect = () => {
    const ticketsRooms = roons.map((room) =>
      room.tickets
        ?.filter((tickets) => tickets.checked)
        .map((tickets) => tickets.tktCodigo)
    );

    if (ticketsRooms.flat().length > 0) {
      return false;
    } else {
      return true;
    }
  };

  // useEffect(() => {
  //   if (errorList !== null && errorList.length === 0) {
  //     if (namePayment === "pix") {
  //       navigate(`${process.env.PUBLIC_URL}/pix`);
  //       return;
  //     }

  //     if (namePayment === "") {
  //       const resLocalizador = resp?.data?.resLocalizador || "";
  //       navigate(`${process.env.PUBLIC_URL}/reserva/${resLocalizador}`, {
  //       return;
  //     }
  //   }
  // }, [errorList, namePayment, navigate]);

  useEffect(() => {
    returnPequisa({});
  }, [returnPequisa]);

  useEffect(() => {
    api
      .get("/wsCoobrastur/Hotel.asmx/returnLogin", {
        params: {
          dados: Object.values({ t: "returnLogin", token: token }).join(";"),
        },
      })
      .then((resp) => {
        return;
      });
  }, [token]);

  useEffect(() => {
    const inToken = localStorage.getItem("tokenPortalAssociado");
    if (userId !== null && inToken !== null) {
      api
        .get(`/wsCoobrastur/ConsultaHoteis.asmx/PU_1_2`, {
          params: { nic: userId, in_token: inToken },
        })
        .then((resp) => {
          if (resp.status === 200) {
            setEmail(resp.data[0].assEmailPessoal);
            setPhoneUser(
              `${resp.data[0].assNumCelularDDD}${resp.data[0].assNumCelular}`
            );
            const dataUser = {
              ddd: resp.data[0].assNumCelularDDD,
              cel: resp.data[0].assNumCelular,
              birthday: resp.data[0].assDTNascimento,
            };
            localStorage.setItem("completeForm", JSON.stringify(dataUser));
            return;
          }
          alert("algo deu errado, recarregue á pagina");
        })
        .catch((e) => console.error({ component: "Choosepayment", error: e }));
    }
  }, [userId]);

  useEffect(() => {
    setTotalValue(totalPayments);
    localStorage.setItem("total", JSON.stringify(totalPayments));
  }, [totalPayments]);

  return (
    <WrapperSelectRoom>
      {
        <Loading active={!roons?.length}>
          <span></span>
        </Loading>
      }
      {roons.map((element) => (
        <BoxRoom key={element.id}>
          <TitleRoom>{element.room}</TitleRoom>
          <WrapperContent>
            <Subtitle>Selecione o tipo de quarto desejado</Subtitle>
            <BoxTypeOfRoom>
              <Box>
                <span>{setNameRoon(element.optionsTypeOfRoon)}</span>
                <ArrowImage
                  src={ArrowIcon}
                  alt="clicavel"
                  onClick={() => handleToggleModalTypeOfroon(element)}
                  open={element.opened}
                />
              </Box>

              <p>{setDescriptionRoon(element.optionsTypeOfRoon)}</p>
              {element.opened && (
                <ModalTypeOfRoon>
                  <InputRadio
                    fontSize="1rem"
                    options={element.optionsTypeOfRoon}
                    direction="row-reverse"
                    justifyContent="space-between"
                    paddingLeft="0px"
                    border="2px solid var(--purple600)"
                    handleSelected={handleSelectedRoom}
                  />
                </ModalTypeOfRoon>
              )}
            </BoxTypeOfRoom>

            {/* <Subtitle>Para quem é está reserva?</Subtitle>
            <WrapperRadios>
              <InputRadio
                fontSize="0.9rem"
                options={element.optionsRadio}
                direction="row"
                justifyContent="flex-start"
                paddingLeft="18px"
                handleSelected={handleOpenFormOtherPerson}
              />
            </WrapperRadios> */}
          </WrapperContent>
          <Subtitle>Selecione os e-tickets para utilização</Subtitle>
          {element.message && <AlertBox>{element.message}</AlertBox>}
          <WrapperRecomended>
            {hasTicketRecomendation(element.tickets)}
          </WrapperRecomended>
          <TicketsList
            tickets={element.tickets}
            room={element.room}
            update={updateTickets}
            loading={loadingTickets}
          />
        </BoxRoom>
      ))}
      <div
        style={{
          width: "100%",
          maxWidth: "514px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Subtitle>Para quem é está reserva?</Subtitle>
        <WrapperRadios>
          <InputRadio
            fontSize="0.9rem"
            options={optionsRadioInputQ1}
            direction="row"
            justifyContent="flex-start"
            paddingLeft="18px"
            handleSelected={handleOpenFormOtherPerson}
          />
        </WrapperRadios>
      </div>
      {openFormOtherPerson && (
        <Fragment>
          <FormOtherPerson
            formRefOtherPerson={formOtherPersonRef}
            optiosFildSelect={dataInputSelect}
          />
          <div
            style={{
              width: "100%",
              textAlign: "center",
              color: "var(--red600)",
              maxWidth: "460px",
              fontSize: "0.9rem",
              fontWeight: "bold",
              marginTop: 4,
              marginBottom: 4,
              marginLeft: 12,
            }}
          >
            Após a finalização desta reserva,você deverá imprimir e registrar em
            cartório esta autorização para utilização de terceiros.
          </div>
        </Fragment>
      )}

      <Fragment>
        {(totalPayments > 0 || hasTicketSeletect()) && (
          <ChoosePayments
            formRef={formRef}
            value={totalPayments}
            confirmationTickets={confirmationTickets}
            showTotalValue={showTotalValue}
            resetRadio={resetRadio}
            setResetRadio={setResetRadio}
            onFinish={() => {
              setLoadingReservation(false);
            }}
            onErrorInResLocalizador={handleErrorInResLocalizador}
          />
        )}
        {showTotalValue && totalPayments > 0 && (
          <PolyticsArea>
            <Subtitle>Consentimento de reserva</Subtitle>
            {totalPayments > 0 && (
              <WrappertextAndSwitcher>
                <Switcher
                  onClick={handleToggleSwitcherPayment}
                  active={activeSwitcherPayment}
                >
                  <ItemCircle active={activeSwitcherPayment} />
                </Switcher>

                <p>
                  Concordo em debitar o valor de{" "}
                  {totalPayments.toLocaleString("pt-Br", {
                    style: "currency",
                    currency: "BRL",
                  })}{" "}
                  referente ao pagamento do extras desta reserva e com os{" "}
                  <strong onClick={openModal}>termos gerais </strong>ao reservar
                  este(s) quarto(s).
                </p>
              </WrappertextAndSwitcher>
            )}
            {/* <WrappertextAndSwitcher>
            <Switcher onClick={handleToggleSwitcher} active={activeSwitcher}>
              <ItemCircle active={activeSwitcher} />
            </Switcher>

            <p>
              Estou ciente e concordo com os{" "}
              <strong onClick={openModalPolitics}>
                termos e políticas de cancelamento
              </strong>{" "}
              do hotel e COOB+.
            </p>
          </WrappertextAndSwitcher> */}
            <div
              style={{
                color: "var(--orange600)",
                paddingLeft: 34,
                fontWeight: 600,
                fontSize: "14px",
                width: "100%",
              }}
            >
              É preciso selecionar o consentimento para habilitar o botão
            </div>
          </PolyticsArea>
        )}
        {totalPayments > 0 && (
          <WrapperBtnReserve>
            <BtnKeep
              disabled={
                !activeSwitcherPayment ||
                namePayment === "" ||
                loadingReservation
              }
              onClick={handleReserveHotel}
            >
              {loadingReservation ? (
                <LoadingBtn />
              ) : namePayment === "pix" ? (
                "Gerar pix"
              ) : (
                "Confirmar Reserva"
              )}
            </BtnKeep>
          </WrapperBtnReserve>
        )}
        {totalPayments <= 0 && (
          <WrapperBtnReserve style={{ flexDirection: "column" }}>
            <WrappertextAndSwitcher>
              <Switcher
                onClick={handleToggleSwitcherPayment}
                active={activeSwitcherPayment}
              >
                <ItemCircle active={activeSwitcherPayment} />
              </Switcher>

              <p>
                Estou ciente e concordo em aceitar os{" "}
                <strong onClick={openModal}>termos gerais </strong>ao reservar
                este(s) quarto(s).
              </p>
            </WrappertextAndSwitcher>
            <div
              style={{
                color: "var(--orange600)",
                paddingLeft: 34,
                fontWeight: 600,
                fontSize: "14px",
                width: "100%",
              }}
            >
              É preciso selecionar o consentimento para habilitar o botão
            </div>
            <BtnKeep
              disabled={!activeSwitcherPayment || loadingReservation}
              onClick={handleReserveHotel}
            >
              {loadingReservation ? <LoadingBtn /> : "Confirmar Reserva"}
            </BtnKeep>
          </WrapperBtnReserve>
        )}
      </Fragment>
      <ModalFormConfirmation
        modalIsOpen={modalReload}
        message={message}
        setIsOpen={handleCloseModalreload}
      />
      <ModalAlert
        isOpen={!!messageModalError?.htmlBody || !!messageModalError?.body}
        onClose={handleCloseModalError}
        message={messageModalError}
      />
    </WrapperSelectRoom>
  );
};

export default memo(Roons);
