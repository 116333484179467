import { Form } from "@unform/web";
import styled from "styled-components";

export const BoxPayment = styled.div`
  width: 100%;
  max-width: 514px;
  height: auto;
  margin: 10px 0px;
  border: 1px solid var(--gray500Opacity);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  @media (max-width: 500px) {
    border: 0;
  }
`;

export const TitlePayment = styled.h3`
  margin: 0;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-size: 1.3rem;
  font-weight: bold;
  color: var(--purple600);
  margin-bottom: 8px;
`;

export const AreaInfoPayment = styled.div`
  width: 100%;
  max-width: 450px;
  border-top: 2px solid var(--gray500Opacity);
  padding: 16px 8px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ParagraphInfoPayment = styled.p`
  margin: 0;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-weight: 500;
  font-size: 14px;
  color: var(--black);
`;

export const LinkKnowMore = styled.a`
  width: 100%;
  color: var(--purple300);
  margin: 0;
  font-size: 12px;
  text-decoration: none;
  font-weight: bold;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  cursor: pointer;
`;

export const TotalvalueArea = styled.div`
  width: 100%;
  max-width: 450px;
  height: 36px;
  padding: 8px 16px;
  background-color: var(--orange600);
  border-radius: 12px;
  margin-top: 16px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  color: var(--white);
  span {
    font-weight: 600;
    letter-spacing: 1.2px;
  }
  strong {
    letter-spacing: 1.2px;
  }
`;
export const FormCard = styled(Form)`
  width: 100%;
  padding: 10px 12px;
  display: flex;
  flex-wrap: wrap;
`;

export const Paragraph = styled.p`
  width: 100%;
  font-size: 14px;
  color: var(--purple600);
  font-weight: bold;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  letter-spacing: 0.58px;
`;

export const WrapperRecaptcha = styled.div`
  height: 76px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
