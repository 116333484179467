import { useState, useEffect } from "react";
import Modal from "react-modal";
import { useSearchStore } from "../../store/useSearch";

import { ReactComponent as IconDates } from "../../assets/icones/icons outline (48px)/calendario.svg";
import { ReactComponent as IconClose } from "../../assets/icones/icons dense (24px)/fechar - white.svg";

import { ModalDatesProps } from "./types";
import {
  ButtonSave,
  CloseModal,
  ContainerDates,
  CustomCalendar,
  DateInput,
  DateLabel,
  FooterMessage,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "./styles";
import { formatDate, showWarningModal } from "../../utils";
import { ModalPolicy } from "../modalPolicy";
import { useUrlParams } from "../../hooks/useUrlParams";
import { ModalWarning } from "../modalWarningLess30days";

const styleModal = {
  content: {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    padding: "0",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden",
    zIndex: 999,
  },
  overlay: {
    zIndex: 999,
    backgroundColor: "rgba(0,0,0,0.48)",
  },
};

export const ModalDates = ({
  visible,
  onClose,
  dateIn,
  setDateIn,
  dateOut,
  step,
  setStep,
  setDateOut,
}: ModalDatesProps) => {
  const [warn, setWarn] = useState<boolean>(
    dateIn !== undefined && showWarningModal(dateIn)
  );
  const [message, setMessage] = useState<null | string>(null);
  const [days, setDays] = useState<null | string>(null);
  const [toggleModalPolicy, setToggleModalPolicy] = useState(false);
  const { setData, data: searchStore } = useSearchStore();
  const { urlParams } = useUrlParams();

  const selectDay = (value: Date) => {
    if (step === 1) {
      setDateIn(value);
      if (urlParams.tipoUsuario) {
        setDateOut(value);
        setStep(2);
        return;
      }
      setDateOut(undefined);
      setStep(2);
    }

    if (step === 2) {
      if (dateIn === undefined) {
        setDateOut(undefined);
        setStep(1);
        return;
      }
      if (value.getTime() < dateIn.getTime()) {
        setDateOut(dateIn);
        setDateIn(value);
        setStep(1);
        if (showWarningModal(value)) {
          setWarn(true);
        }
        return;
      }

      setDateOut(value);
      setStep(1);
      if (showWarningModal(dateIn)) {
        setWarn(true);
      }
    }
  };

  const save = () => {
    if (dateIn && dateOut) {
      setData({ ...searchStore, dateIn, dateOut });
    }
    onClose();
  };
  const handleToggleModalPolicy = () => {
    setToggleModalPolicy(!toggleModalPolicy);
  };

  const handleSelectOtherDate = () => {
    setStep(1);
    setDateIn(undefined);
    setDateOut(undefined);
    setData({ ...searchStore, dateIn: undefined, dateOut: undefined });
    setToggleModalPolicy(false);
    setWarn(false);
  };

  const handleCloseWarning = () => {
    setWarn(false);
  };

  useEffect(() => {
    if (urlParams.tipoUsuario) {
      if (!dateIn && !dateOut) {
        if (step === 1) {
          setMessage("Escolha sua <strong>data de entrada</strong>");
        }

        if (step === 2) {
          setMessage("Escolha sua <strong>data de saída</strong>");
        }
      }
    } else {
      if (step === 1) {
        setMessage("Escolha sua <strong>data de entrada</strong>");
      }

      if (step === 2) {
        setMessage("Escolha sua <strong>data de saída</strong>");
      }
    }
  }, [dateIn, dateOut, step, urlParams.tipoUsuario]);

  useEffect(() => {
    if (dateIn && dateOut) {
      const diffInMs = Math.abs(dateOut.getTime() - dateIn.getTime());
      const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

      if (urlParams.tipoUsuario) {
        const correctedDifference = diffInDays === 0 ? 1 : diffInDays;
        setDays(`Estadia de ${correctedDifference} diária(s)`);
        return;
      }

      setMessage(diffInDays < 2 ? "Estadia mínima de 2 diárias" : null);
      setDays(`Estadia de ${diffInDays} diária(s)`);
    }
  }, [dateIn, dateOut, urlParams.tipoUsuario]);

  return (
    <Modal isOpen={visible} style={styleModal} ariaHideApp={false}>
      <ModalContent>
        <ModalHeader>
          <CloseModal type="button" title="fechar" onClick={() => onClose()}>
            <IconClose />
          </CloseModal>

          <ModalTitle>Selecione as suas datas</ModalTitle>

          <IconDates />
        </ModalHeader>
        <ModalBody>
          <ContainerDates>
            <div>
              <DateLabel>Entrada</DateLabel>
              <DateInput>{formatDate(dateIn) || "-"}</DateInput>
            </div>
            <div>
              <DateLabel>saída</DateLabel>
              <DateInput>{formatDate(dateOut) || "-"}</DateInput>
            </div>
          </ContainerDates>

          <CustomCalendar
            minDate={new Date()}
            selectRange={true}
            onClickDay={selectDay}
            tileDisabled={({ date }) => {
              const today = new Date();
              today.setTime(date.getTime());
              if (!dateIn || (!!dateIn && !!dateOut)) return date < today;

              const diffInMs = Math.abs(date.getTime() - dateIn.getTime());
              const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
              return date < today || diffInDays < 2;
            }}
            next2AriaLabel=""
            next2Label={null}
            prev2AriaLabel=""
            prev2Label={null}
          />

          <ModalWarning
            isOpen={warn}
            onClose={handleCloseWarning}
            handleConfirmation={handleCloseWarning}
            handleSelectOtherDate={handleSelectOtherDate}
            onClickInformation={() => {
              handleCloseWarning();
              setToggleModalPolicy(true);
            }}
          />
          <ModalPolicy
            isOpen={toggleModalPolicy}
            onClose={handleToggleModalPolicy}
            handleConfirmation={handleToggleModalPolicy}
            handleSelectOtherDate={handleSelectOtherDate}
          />
        </ModalBody>
        <ModalFooter>
          <FooterMessage>
            {message !== null && (
              <span dangerouslySetInnerHTML={{ __html: message }}></span>
            )}

            {message === null && days !== null && (
              <span
                className="success"
                dangerouslySetInnerHTML={{ __html: days }}
              ></span>
            )}
          </FooterMessage>

          <ButtonSave disabled={message !== null} onClick={save}>
            Selecionar datas
          </ButtonSave>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
