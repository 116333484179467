import { WrapperTitle } from "./styles";
import Bag from "./assets/bagOrange.svg";

interface BannerPagesInstProps {
  title: string;
}

const BannerPagesInst = ({ title }: BannerPagesInstProps) => {
  return (
    <WrapperTitle>
      <img src={Bag} alt="logo" />
      <h1>{title}</h1>
    </WrapperTitle>
  );
};

export default BannerPagesInst;
