import styled from "styled-components";
export const Container = styled.div`
  width: 100%;
  height: auto;
  margin-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ContentArea = styled.section`
  width: 100%;
  max-width: 600px;
  height: auto;
  /* background-color: red; */
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px;
`;

export const WrapperItem = styled.div`
  margin: 8px 0px;
`;

export const Subtitle = styled.h2`
  font-size: 1rem;
  color: var(--gray500);
`;

export const ItemTitle = styled.h4`
  margin: 0;
`;

export const ItemParagraph = styled.p`
  color: var(--black);
  font-size: 0.9rem;
  margin: 0;
`;

export const ItemParagraphStrong = styled.strong`
  font-size: 0.9rem;
  margin: 0;
`;

export const List = styled.ul`
  font-size: 0.9rem;
  box-sizing: border-box;
  padding: 0;

  li {
    list-style: none;
  }
`;
