import styled, { keyframes } from "styled-components";
import { Form } from "@unform/web";

interface BtnKeepProps {
  disabled: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: auto;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const WrapperTextSteps = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  color: #3a2e83;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";

  margin-left: auto;
  margin-right: auto;
  h4 {
    margin: 0;
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 0.51px;
  }
  span {
    font-size: 15px;
    text-align: center;
  }
`;

export const WrapperSteps = styled.div`
  width: 100%;
  height: auto;
  background-color: var(--white);
  border-radius: 0px 0px 32px 32px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid #0000001f;
  padding: 25px 10px 0px 10px;
`;

export const SectionForm = styled.section`
  width: 100%;
  padding: 40px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
`;

export const FormItem = styled(Form)`
  width: 100%;
  max-width: 330px;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TitleLogin = styled.h4`
  margin: 0;
  font-size: 1.3rem;
  font-family: "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  color: var(--orange600);
  font-weight: bold;
  letter-spacing: 0.64px;
`;

export const BtnKeep = styled.button<BtnKeepProps>`
  width: 100%;
  height: 40px;
  background-color: var(--pink600);
  border-radius: 25px;
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  margin: 12px 0px;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  border: 0;
  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? "trasparent" : "var(--pink400)"};
  }
`;

export const BtnAssociated = styled.button`
  width: 100%;
  height: 40px;
  background-color: #fff;
  border: 2px solid var(--pink600);
  outline: none;
  margin-top: 8px;
  border-radius: 25px;
  font-size: 1rem;
  color: #e50076;
  font-weight: 700;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--pink600);
    color: #fff;
    border: 0;
  }
`;

export const ForgotThePassword = styled.span`
  width: 100%;
  font-size: 12px;
  color: var(--purple600);
  font-weight: 500;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  letter-spacing: 0.58px;
  cursor: pointer;
`;

export const Paragraph = styled.p`
  width: 100%;
  font-size: 14px;
  color: var(--purple600);
  font-weight: bold;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  letter-spacing: 0.58px;
`;

export const WrapperRecaptcha = styled.div`
  height: 76px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextError = styled.span`
  color: var(--red600);
  font-weight: bold;
  font-size: 0.8rem;
`;

export const Rotate = keyframes`
from{
    transform:rotate(0deg);
}
to{
    transform:rotate(360deg); 
}
`;

export const LoadingBtn = styled.div`
  width: 30px;
  height: 30px;
  background-color: var(--pink600);
  border-radius: 50%;
  border: 4px solid var(--white);
  border-top-color: var(--pink600);
  animation: ${Rotate} 1s linear infinite;
`;
