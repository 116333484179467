import styled from "styled-components";
import { InputWithError, SelectProps } from "./types";

export const WrapperInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const LabelText = styled.label`
  color: var(--gray600);
  font-size: 0.7rem;
  font-weight: bold;
  padding-left: 10px;
  cursor: pointer;
  text-transform: uppercase;
`;

export const InputText = styled.input<InputWithError>`
  width: 100%;
  //max-width: 450px;
  min-width: 285px;
  flex: 1;
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  border-radius: 12px;
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid
    ${({ errorText }) =>
      !!errorText ? "var(--red500)" : "var(--gray500Opacity)"};
  margin: 4px;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  outline: none;
  color: var(--black);
  font-weight: 500;

  ::placeholder {
    color: var(--black);
    font-size: 14px;
    font-weight: 500;
  }
`;

export const BoxInputradio = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 10px 0px;
  padding-left: 10px;
  justify-content: start;
`;
export const WrapperRadiosOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const InputRadio = styled.input`
  margin: 0;
  appearance: none;
  border: 2px solid var(--gray600);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.8s;

  &:before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--purple300);
    opacity: 0;
  }

  &:checked:before {
    opacity: 1;
    transition: all 0.5s;
  }

  &:checked {
    opacity: 1;
    transition: all 0.5s;
    border: 2px solid var(--purple300);
  }
  &:checked ~ label {
    color: var(--purple600);
  }
`;

export const LabelRadio = styled.label`
  color: var(--gray600);
  font-size: 0.9rem;
  font-weight: bold;
  padding-left: 10px;
  cursor: pointer;
`;

export const ErrorText = styled.span`
  font-size: 0.8rem;
  color: var(--red600);
  width: 100%;
  padding: 0 8px 8px 8px;
  margin-top: -4px;
`;

export const Button = styled.button`
  cursor: pointer;
  display: block;
  background-color: var(--pink600);
  border: none;
  color: var(--white);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  border-radius: 20px;
  width: 100%;
  max-width: 400px;
  min-height: 40px;
  margin: 0 auto;

  &:disabled {
    opacity: 0.15;

    &:hover {
      background-color: var(--pink600);
    }
  }

  &:hover {
    background-color: var(--pink500);
  }
`;

export const Select = styled.select<SelectProps>`
  width: 100%;
  max-width: 419px;
  height: 40px;
  padding: 8px;
  margin: 4px;
  border-radius: 12px;
  outline: none;
  border: 2px solid
    ${({ errorText }) =>
      errorText !== undefined ? "var(--red500)" : "var(--gray500Opacity)"};
  font-size: 16px;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  color: "var(--black)";
  opacity: 1;
  background-color: var(--white);
`;

export const OptionSelect = styled.option`
  background-color: buttonface;
  color: var(--black);
  box-shadow: 0px 1px 3px var(--gray500Opacity);
`;
