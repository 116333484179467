import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Accommodation from "../pages/Accommodation";

import HowItsWorks from "../pages/HowItsWorks";
import About from "../pages/About";
import Identify from "../pages/Identify";
import Payments from "../pages/Payments";
import AccommodationInternational from "../pages/AccommodationInternational";
import Pix from "../pages/Pix";
import ReserveConcluded from "../pages/reserveConcluded";
import PoliticsOfUse from "../pages/PoliticsOfUse";
import Security from "../pages/security";
import Help from "../pages/Help";
import PaymentsInternational from "../pages/PaymentsInternational";
import RecoverPassword from "../pages/recoverPassword";
import RedirectPage from "../pages/Redirect";
import ReserveLessThan30Days from "../pages/ReserveLessThan30Days";
import RefundOut from "../pages/RefundOut";
import NotFound from "../pages/NotFound";
import LoginPage from "../pages/LoginPage";

function RoutesComp() {
  return (
    <Routes>
      <Route path={`${process.env.PUBLIC_URL}/`} element={<Home />} />
      <Route path={`${process.env.PUBLIC_URL}/index.html`} element={<Home />} />
      <Route
        path={`${process.env.PUBLIC_URL}/comofunciona`}
        element={<HowItsWorks />}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/sobreaempresa`}
        element={<About />}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/hospedagens`}
        element={<Accommodation />}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/hospedagens-internacional`}
        element={<AccommodationInternational />}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/identifique-se`}
        element={<Identify />}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/identificacao/1/:id`}
        element={<Identify />}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/pagamentos`}
        element={<Payments />}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/pagamentos-internacional`}
        element={<PaymentsInternational />}
      />
      <Route path={`${process.env.PUBLIC_URL}/pix`} element={<Pix />} />
      <Route
        path={`${process.env.PUBLIC_URL}/reserva`}
        element={<ReserveConcluded />}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/reserva/:resLocalizador`}
        element={<ReserveConcluded />}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/politicadeuso`}
        element={<PoliticsOfUse />}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/seguranca`}
        element={<Security />}
      />
      <Route path={`${process.env.PUBLIC_URL}/ajuda`} element={<Help />} />

      <Route
        path={`${process.env.PUBLIC_URL}/redefinirSenha`}
        element={<RecoverPassword />}
      >
        <Route path=":token" element={<RecoverPassword />} />
      </Route>

      {/* Redirects para corrigir links antigos */}
      <Route
        path={`${process.env.PUBLIC_URL}/hotel_lista.html/*`}
        element={<RedirectPage />}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/info.html/*`}
        element={<RedirectPage />}
      />
      {/* Fim dos redirects */}

      {/* Paginas criadas */}
      <Route
        path={`${process.env.PUBLIC_URL}/reservamenos30dias`}
        element={<ReserveLessThan30Days />}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/reembolsoforadarede`}
        element={<RefundOut />}
      />
      <Route path={`${process.env.PUBLIC_URL}/login`} element={<LoginPage />} />
      <Route
        path={`${process.env.PUBLIC_URL}/primeiro-acesso`}
        element={<LoginPage stage="FIRST_ACCESS" />}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/esqueceu-senha`}
        element={<LoginPage stage="FORGOT_PASSWORD" />}
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default RoutesComp;
