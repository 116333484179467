import styled, { keyframes } from "styled-components";

export const Rotate = keyframes`
from{
    transform:rotate(0deg);
}
to{
    transform:rotate(360deg); 
}
`;

export const LoadingItem = styled.div`
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 50%;
  border: 8px solid #4b38b759;
  border-top-color: #3a2e83;
  animation: ${Rotate} 1s linear infinite;
`;
