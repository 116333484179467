import {
    BoldText,
    Button,
    InfoContainer,
    InfoSmallText,
    InfoSubtitle,
    InfoTitle,
    Text,
    Title,
    VStack,
    Container,
    InfoContainerTitle,
    InfoBlock,
    HStack
} from './styles'

export function CoobrasturGroupSection() {
    return(
        <Container>
            <Title>Coob+ agora faz parte do grupo coobrastur</Title>
            <VStack>
                <Text>
                    <BoldText>
                        O maior grupo empresarial de turismo do sul do Brasil surgiu da união de clubes 
                        de viagem por assinatura e agência de viagem com uma mesma missão: facilitar a 
                        vida de viajantes e promover o turismo nacional.
                    </BoldText>
                </Text>
                <Text>
                    Juntas, as empresas descomplicam a forma de viver experiências de viagem. Promovemos um 
                    grande movimento de apoio a fornecedores e viajantes, com mais benefícios aos clientes e 
                    maior demanda para rede hoteleira parceira, além de investimentos diretos em projetos de 
                    hotelaria.
                </Text>
            </VStack>
            <VStack>
                <InfoContainerTitle>
                    <BoldText>Nosso impacto</BoldText> no mercado do turismo
                </InfoContainerTitle>
                <InfoContainer>
                    <InfoBlock>
                        <InfoTitle>+75 mil</InfoTitle>
                        <InfoSubtitle>assinantes</InfoSubtitle>
                        <InfoSmallText>de planos de viagem</InfoSmallText>
                    </InfoBlock>
                    <InfoBlock>
                        <InfoTitle>+6 milhões</InfoTitle>
                        <InfoSubtitle>diárias distribuídas</InfoSubtitle>
                        <InfoSmallText>na hotelaria nacional</InfoSmallText>
                    </InfoBlock>
                    <InfoBlock>
                        <InfoTitle>+500 mil</InfoTitle>
                        <InfoSubtitle>parceiros hoteleiros</InfoSubtitle>
                        <InfoSmallText>no brasil e no exterior</InfoSmallText>
                    </InfoBlock>
                    <InfoBlock>
                        <InfoTitle>+450 mil</InfoTitle>
                        <InfoSubtitle>diárias utilizadas</InfoSubtitle>
                        <InfoSmallText>anualmente</InfoSmallText>
                    </InfoBlock>
                    <InfoBlock>
                        <InfoTitle>35 anos</InfoTitle>
                        <InfoSubtitle>de atuação nos</InfoSubtitle>
                        <InfoSmallText>setores de lazer e turismo</InfoSmallText>
                    </InfoBlock>
                </InfoContainer>
            </VStack>
            <HStack>
                <Button href='https://www.grupocoobrastur.com.br/#video'>assista o vídeo de lançamento</Button>
                <Button href='https://www.grupocoobrastur.com.br/faq'>FAQ - Perguntas e respostas</Button>
            </HStack>
        </Container>
    )
}