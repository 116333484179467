import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const WrapperTextSteps = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  color: #3a2e83;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";

  margin-left: auto;
  margin-right: auto;
  h4 {
    margin: 0;
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 0.51px;
  }
  span {
    font-size: 15px;
    text-align: center;
  }
`;

export const WrapperSteps = styled.div`
  width: 100%;
  height: auto;
  background-color: var(--white);
  border-radius: 0px 0px 32px 32px;
  box-shadow: 0px 1px 0.5px #0000001f;
  border-bottom: 1px solid #0000001f;
  padding: 10px 10px 0px 10px;
`;

export const Section = styled.section`
  height: auto;
  width: 100%;
  max-width: 1280px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 100px;
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const AreaPix = styled.div`
  width: 60%;
  max-width: 514px;
  min-width: 300px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TotalValue = styled.div`
  width: 100%;
  border-radius: 20px;
  border: 1px solid var(--gray500Opacity);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

export const TotalValueItem = styled.div`
  width: 100%;
  background-color: var(--orange600);
  border-radius: 12px;
  color: var(--white);
  font-size: 1rem;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  height: 48px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-weight: 500;
  }
`;

export const AreaPixItem = styled.div`
  width: 100%;
  max-width: 514px;
  margin-top: 32px;
  border: 1px solid var(--gray500Opacity);
  padding: 16px 0px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Title = styled.h4`
  width: 100%;
  max-width: 480px;
  margin: 0;
  color: var(--purple600);
  font-size: 20px;
  text-align: center;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-weight: bold;
`;

export const WrapperPix = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border-top: 1px solid var(--gray500Opacity);

  p {
    margin: 0;
    width: 100%;
    font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
      BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", "Helvetica Neue";
    font-weight: 400;
  }
  p:last-child {
    margin-top: 10px;
  }

  p strong {
    color: var(--purple600);
  }

  p span {
    color: var(--red500);
    font-weight: bold;
    font-size: 1rem;
  }
`;

export const AttentionText = styled.div`
  width: 100%;
  color: var(--red500);
  margin-top: 32px;
  display: flex;
  align-items: center;
  max-width: 400px;

  div {
    max-width: 380px;
    font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
      BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", "Helvetica Neue";
    font-size: 14px;
    margin-left: 10px;
  }
  span {
    font-weight: bold;
    margin: 0;
  }
`;

export const ContentPixItem = styled.div`
  width: 100%;
  max-width: 380px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
`;

export const WrapperCode = styled.div`
  width: 100%;
  max-width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-size: 16px;
  color: var(--gray600);
  font-weight: 500;
  margin-top: 24px;
  text-align: center;
`;

export const BtnCopyCode = styled.button`
  width: 100%;
  max-width: 328px;
  outline: none;
  border: 0;
  height: 40px;
  background-color: var(--green600);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 31px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;

  &:hover {
    background-color: var(--green500);
  }

  span {
    color: var(--white);
    font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
      BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", "Helvetica Neue";
    font-size: 1rem;
    font-weight: bold;
  }
`;
export const BtnCancelPix = styled.button`
  width: 100%;
  max-width: 328px;
  outline: none;
  border: 0;
  height: 40px;
  border: 2px solid var(--red500);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 31px;
  cursor: pointer;
  color: var(--red500);
  font-family: "Mangueira", "Poppins", "Open-sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-size: 1rem;
  font-weight: bold;
`;

export const WrapperQrCode = styled.div`
  height: auto;
  max-width: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
