export const contentModalTerms = `CONDIÇÕES E TERMOS GERAIS PARA AQUISIÇÃO DE SERVIÇOS DE TURISMO

1- DISPOSIÇÕES GERAIS
1.1 A Coobrastur disponibiliza em seu site www.coobrastur.com serviços de busca, através de uma ferramenta de pesquisa aos seus usuários para aquisição de reservas nacionais, bem como para intermediar a aquisição de passagens aéreas, reservas de aluguel de veículos e de hotéis internacionais, ampliando, assim, a rede de produtos e serviços aos seus Usuários. Ressalta-se que estes itens podem ser adquiridos separadamente ou em conjunto;

1.1.1 Defini-se Usuário aqueles inseridos no Sistema de Turismo Social da Coobrastur, por meio do contrato de prestação de serviço do Sistema de Turismo Social Coobrastur formalmente firmado para aquisição de planos de hospedagem denominados Vip/ Master, Gold Vip/Master, Diamante e GO de alta ou baixa temporada, obtendo, por conseguinte, acesso ao Portal do Usuário, através de login e senha, na home-page da Coobrastur;

1.2 A Coobrastur fornece serviços de reservas em hotéis nacionais, através de sua ampla rede credenciada de hotéis, conforme contrato de prestação de serviço do Sistema de Turismo Social Coobrastur, enquanto que os serviços de reservas em hotéis internacionais, passagens aéreas e aluguel de veículos são fornecidos por empresas parceiras, possibilitando que nossos usuários usufruam de outros serviços de turismo não contemplados no contrato de prestação de serviços dos planos de hospedagem;

1.2.1 Os hotéis internacionais não são credenciados pela Coobrastur, ou seja, são oriundos de uma parceria firmada com Operadoras de Turismo, possibilitando aos demais usuários a aquisição de reservas, obedecendo aos critérios de pagamento e regras de utilização de e-tickets para este serviço;

1.3 A partir dos critérios de pesquisa selecionados pelo Usuário, a Coobrastur informa o resultado da pesquisa, apresentando os hotéis disponíveis em sua rede credenciada para hotéis nacionais. No que tange às passagens aéreas, ao aluguel de veículos e às reservas internacionais, a pesquisa de busca destes serviços é possibilitada pela integração de serviços online de companhias/empresas fornecedoras, onde as empresas parceiras são as responsáveis exclusivas pelas informações dispostas aos usuários, atuando a Coobrastur como mera intermediadora;

1.4 O pagamento dos serviços adquiridos no site www.coobrastur.com poderá ser feito de forma parcial ou integral, através de e-tickets adquiridos por meio da contratação dos planos Coobrastur, desde que os mesmos estejam disponíveis para utilização. Para tanto, deve se observar a regra vigente de utilização de e-tickets, ressaltando-se que, caso o valor do(s) e-ticket(s) não seja(m) suficiente(s) ao pagamento integral do(s) serviço(s) contratado(s), o complemento do pagamento deve ser realizado através de cartão de débito ou crédito, e no caso de hotéis nacionais, também em boleto bancário, conforme item 6.

1.5 A utilização dos e-tickets para pagamento de serviços adquiridos no site www.coobrastur.com deve respeitar as regras contratuais definidas neste termo, bem como no contrato de prestação de serviços do Sistema de Turismo Social Coobrastur quando da aquisição do(s) plano(s) de hospedagem;

1.5.1 O objeto do contrato dos planos Coobrastur não se confunde com os serviços que podem ser adquiridos pela home-page da empresa, uma vez que a Coobrastur buscou ampliar a rede de serviços e produtos de turismo, por meio de parcerias com outras empresas e fornecedores, realizando, portanto, a intermediação entre o Usuário e as empresas parceiras para contratação de serviços não contemplados no contrato de aquisição de planos de hospedagem (contrato de prestação de serviços do Sistema de Turismo Social Coobrastur);

1.6 Após a confirmação do pagamento, a compra é confirmada através do voucher da reserva. Este voucher é enviado por correio eletrônico, portanto, o usuário deve manter seu cadastro atualizado junto ao portal do usuário com um endereço de e-mail válido e que seja regularmente acessado. Este voucher, emitido pela Coobrastur, conterá as informações necessárias para que o usuário possa fazer uso dos serviços, contendo códigos de reservas, localizadores, etc. Ressalta-se que o voucher deverá ser impresso e apresentado no check-in juntamente com um documento oficial de identificação;

1.7 Serviços de reservas de passagens aéreas, hotéis internacionais e aluguel de veículos são fornecidos por companhias/empresas fornecedoras, razão pela qual o usuário obriga-se a ler atentamente as políticas de cancelamento de cada um dos serviços, uma vez que cada fornecedor parceiro possui suas próprias políticas para cancelamento de serviços. Salienta-se que a Coobrastur não se responsabiliza pelas políticas de cancelamento e eventuais custos dos serviços contratados, uma vez que não possui gerência sobre as empresas fornecedoras;

1.8 O usuário está ciente de que os hotéis pertencentes à rede credenciada da Coobrastur, se reservam ao direito de estabelecer, em datas previamente definidas (Exemplo: Natal, Ano Novo, Carnaval, Páscoa, São João, entre outros) um número mínimo de dias de hospedagem e, eventualmente, estabelecer os preços diferenciados das diárias regulares, sendo esta prática denominada e reconhecida no mercado de turismo como “pacote”. Nestes casos, caberá ao usuário a confirmação de existência de pacotes no sistema de reservas disponível na home-page da Coobrastur, no momento da solicitação da reserva e providenciar, se for o caso o pagamento da diferença, através do Sistema de Reservas disponibilizado pela Coobrastur. O valor dos pacotes é de competência exclusiva dos hotéis, não havendo, portanto, interferência da Coobrastur;


2 DEVERES DA COOBRASTUR

2.1 A Coobrastur compromete-se a manter disponível no site www.coobrastur.com os serviços de reservas on-line de hotéis Nacionais e Internacionais, passagens aéreas e aluguel de veículos, da seguinte forma: os resultados de pesquisa dos hotéis nacionais são fornecidos através da sua rede credenciada, enquanto o conteúdo da pesquisa fornecida acerca das reservas internacionais, passagens aéreas e aluguéis de carro são resultados da intermediação destes serviços pela Coobrastur, conforme referido no item 1.3;

2.2 A Coobrastur compromete-se a divulgar todas as informações referentes aos hotéis Nacionais e Internacionais, às passagens aéreas e ao aluguel de veículos, fornecidas pelas empresas parceiras, nos mesmos termos e condições informados pelas mesmas, ressaltando-se que as informações veiculadas são de exclusiva responsabilidade das empresas fornecedoras;

2.3 A Coobrastur responsabiliza-se em manter um canal de suporte ao usuário, devidamente divulgado no sítio eletrônico da Coobrastur.


3 DEVERES DAS COMPANHIAS/EMPRESAS FORNECEDORAS DE PRODUTOS TURÍSTICOS

3.1 Manter disponíveis informações e orientações sobre as especificações dos serviços turísticos por eles comercializados, sendo que a Coobrastur apenas veicula as informações e orientações nas mesmas condições e termos recebidas das companhias/empresas fornecedoras, sem qualquer alteração;

3.2 As companhias/empresas fornecedoras são exclusivamente responsáveis pelas informações e orientações fornecidas dos produtos turísticos comercializados por eles e intermediados pela Coobrastur.

4 DEVERES DOS USUÁRIOS COOBRASTUR

4.1 Fornecer informações completas e atualizadas mediante as solicitações do site no processo de reserva, sendo o usuário exclusivamente responsável pela veracidade no fornecimento das informações prestadas;

4.2 Proceder com zelo e atenção no processo de reservas on-line, inserindo informações corretas e verdadeiras, conforme item anterior;

4.3 Ler atentamente as regras deste contrato, ciente que deve contatar diretamente os hotéis e as empresas parceiras para obter informações mais detalhadas sobre o serviço respectivo, caso seja de seu interesse;

4.4 Fica ciente o usuário de que as informações disponíveis no site www.coobrastur.com quanto aos hotéis internacionais, passagens aéreas e aluguel de veículos, tais como, fotos, datas de disponibilidades, valores, políticas de alterações e cancelamento, são obtidas através de consultas realizadas diretamente na base de dados das companhias empresas fornecedoras. Já as informações sobre Hotéis Nacionais, são disponibilizadas pelos próprios hotéis, conforme acordo comercial entre o Hotel e a Coobrastur Turismo;

4.5 Observar a existência de pacotes turísticos em datas comemorativas e o respectivo valor da diferença a ser pago, conforme item 1.8;

4.6 Para realizar reservas nacionais, o usuário deve efetuar as reservas em hotéis de sua livre escolha, com antecedência mínima de 30 (trinta) dias, através do sistema de reservas online, conforme estabelecido no contrato de prestação de serviços do Sistema de Turismo Social Coobrastur;

4.7 Fica ciente o usuário de que quanto maior a antecedência na contratação dos serviços online em relação ao dia da reserva, resta possível a obtenção de um maior número de resultados de busca acerca da disponibilidade dos serviços, bem como das possíveis ofertas existentes, não havendo qualquer obrigatoriedade em disponibilizar o serviço em determinados locais e datas, haja vista depender sempre da disponibilidade do fornecedor.


5 REGRAS PARA PAGAMENTO DE DIÁRIAS REDE GOLD OU DIAMANTE

De acordo com o contrato de prestação de serviços do Sistema de Turismo Social Coobrastur, o Usuário que optar pelos títulos de modalidade GO de alta ou baixa temporada, Vip ou Master poderá utilizar os hotéis pertencentes à rede dos títulos Gold Vip, Gold Master e Diamante, assim como o Usuário que optar pelos títulos Gold poderá fazer uso dos hotéis pertencentes ao título Diamante, desde que pague antecipadamente a diferença dos valores das diárias à Coobrastur, considerando, ainda, o período de utilização estabelecido para cada tipo de título. E, se tratando de Usuário de modalidades Gold Vip, Gold Master e Diamante, o mesmo poderá utilizar a rede hoteleira pertencente aos títulos GO, Vip e Master, sem nenhum custo adicional no que tange à diferença de rede.


6 REGRAS PARA UTILIZAÇÃO DE E-TICKETS

Quando disponível, o Usuário poderá utilizar seus e-tickets para pagamento integral ou parcial dos serviços contratados no site www.coobrastur.com, porém, algumas regras gerais devem ser observadas:

6.1 O e-ticket pode ser utilizado somente para reservas do titular do plano Coobrastur e, a depender do serviço, para reservas de terceiros, devidamente comprovado através de documentos específicos, tais como: Certidão de nascimento, certidão de casamento e ou declaração de união estável, devidamente registrada em cartório ou demais documentos com validade jurídica;

6.2 É expressamente vedado o fracionamento dos e-tickets, conforme regramento expresso no contrato de prestação de serviço do Sistema de Turismo Social Coobrastur;

6.3 Caso o valor do e-ticket não seja suficiente para cobrir o(s) serviço(s) contratados, por meio do sítio eletrônico da Coobrastur, bem como taxas e demais valores cobrados, o usuário deverá efetuar o pagamento complementar através de cartão de débito ou crédito, e no caso de hotéis nacionais, também em boleto bancário;

6.4 Para realização da reserva, bem como para efetiva utilização dos e-ticket(s), os mesmos devem necessariamente estar dentro da validade, ou seja, não basta apenas os e-ticket(s) estarem gerados no sistema, mas sim em período de vigência definido no extrato do Usuário. Ressalta-se, ainda, que o período da reserva deve estar totalmente inserido no período de vigência dos e-ticket(s), sendo vedado o aproveitamento das diárias fora do período de validade estabelecido;

6.5 No que tange à utilização dos e-tickets por terceiros, os serviços que aceitam esta modalidade e regras devem ser consultados com antecedência para este tipo utilização quando possível.


7 REGRAS PARA CANCELAMENTO E ALTERAÇÕES DE RESERVAS

7.1 O cancelamento ou alterações por parte do Usuário Coobrastur de quaisquer reservas on-line realizadas no site www.coobrastur.com se dá conforme as políticas operacionais de cada hotel e/ou companhias/empresas fornecedoras, estando sujeitos a deduções, tais como: penalidades e/ou taxas praticadas pelos Hotéis e empresas fornecedoras e/ou parceiras;

7.2 Nem todas as reservas estarão disponíveis para alteração automática no site da Coobrastur devido às políticas operacionais de cada hotel, empresas fornecedoras e/ou parceiras. Para estes casos, se faz necessário que o Usuário entre em contato com a Coobrastur pelos canais de comunicação divulgados no sítio eletrônico;

7.3 Na hipótese de No-Show (não comparecimento por parte do usuário) em quaisquer reservas adquiridas, serão cobradas as devidas penalidades conforme as políticas operacionais de cada hotel, companhias/empresas fornecedoras e/ou parceiras, conforme informação constante no voucher;

7.4 As regras de cancelamentos e alterações, bem como os valores de penalidades ou taxas a serem aplicadas, estarão explícitas quando o Usuário obtiver retorno das suas buscas no site www.coobrastur.com, portanto, é de extrema importância que o usuário tome conhecimento destas regras antes da finalização da reserva, conferindo a devida aceitação nos termos para tanto. Da mesma forma, estas regras ficarão explícitas no voucher de confirmação das reservas;

7.5 Quaisquer alterações de datas e/ou horários de reservas de passagens aéreas, devem ser solicitadas à Coobrastur com antecedência mínima de 72 horas úteis, porém, o usuário deve estar ciente de que estas alterações dependem de disponibilidade junto às companhias e estarão sujeitas as penalidades conforme as políticas operacionais de cada companhia;

7.6 Em caso de cancelamento da reserva de hotéis nacionais e internacionais, além do previsto nos itens anteriores, será descontado a título de multa administrativa, o percentual equivalente a 10% sobre o montante do valor pago;

7.7 O usuário deve estar ciente que a soma das penalidades e taxas podem chegar ao montante de 100% dos valores de suas reservas, caracterizando assim a perda total dos valores pagos e/ou dos e-tickets utilizados no pagamento;

7.8 Se houver valores a restituir na decorrência de cancelamento e/ou alteração das reservas, desde que respeitados os prazos definidos nas políticas operacionais de cada hotel e/ou companhias/empresas fornecedoras, o valor pago será reembolsado ao usuário, porém, no prazo de até 90 dias após a confirmação dos valores a restituir, em prazo que igualmente dependerá das políticas operacionais acima referidas para efetivação de eventual restituição;

7.9 Eventual inadimplência das mensalidades do plano Coobrastur, bem como de quaisquer valores vinculados a eventuais serviços contratados, implicarão bloqueio dos e-tickets e/ou reservas de hospedagem, passagens aéreas, aluguel de veículos, podendo ocorrer o cancelamento das reservas realizadas, aplicando-se as penalidades previstas nos itens 7.1 e 7.2;


8 INTERMEDIAÇÃO DE COMPRA E VENDA (passagens aéreas, veículos e hotéis internacionais)

8.1 A Coobrastur disponibiliza em seu site www.coobrastur.com serviços de busca, intermediando a aquisição de reservas de passagens aéreas, aluguel de carros e hotéis internacionais;

8.2 O Usuário Coobrastur, sob sua responsabilidade, insere os critérios de pesquisa, tais como: local de origem, local de destino, datas de ida e de volta, horários de ida e de volta, quantidade de adultos, quantidade de crianças, idade, tempo de estadia, local de retirada e entrega de veículos, etc.;

8.3 Após eleger os itens de sua reserva, o Usuário visualiza seus e-tickets (caso tenha e seja usuário do Sistema Coobrastur), conforme a regra de utilização vigente, e poderão ser utilizados como forma de pagamento (não sendo o caso de ter e-tickets, pode realizar todo o pagamento em reais, em cartão de crédito ou débito). Após selecionar os e-tickets, serão expostos os valores em reais, referentes ao complemento do pagamento das reservas, se houver;

8.4 A cobrança é feita através de cartão de crédito ou débito que obedecerá aos critérios das empresas parceiras;

8.5 Quando confirmada a compra por parte das companhias/empresas fornecedoras, um voucher será enviado à Coobrastur, que imediatamente encaminhará este voucher ao endereço de e-mail do Usuário, com acesso também pelo Portal do Usuário, acessível no site www.coobrastur.com;

8.6 Em caso de cancelamento e/ou alteração de reservas adquiridas, o Usuário aceita as regras para cancelamento e alteração de reservas, expostos no item 7 (sete).


9 PASSAGENS AÉREAS

9.1 Os resultados de busca de passagens aéreas realizadas através do site www.coobrastur.com são fornecidos por companhias/empresas fornecedoras, sendo que a Coobrastur opera na qualidade de intermediadora;

9.2 O Usuário Coobrastur deve estar ciente de que as tarifas aéreas são dinâmicas e sujeitas a alterações a qualquer tempo e sem aviso prévio, portanto, dependendo do tempo decorrido para a finalização da compra, poderá ocorrer variação de preço entre a escolha da passagem aérea e a finalização da compra pelo Usuário;

9.3 A passagem aérea está condicionada a efetivação pela companhia aérea através de seus respectivos sistemas de cobrança e emissão de voucher;

9.4 Na qualidade de intermediadora, a Coobrastur fica isenta de qualquer erro de cobrança por parte das companhias/empresas fornecedoras;

9.5 O tipo de aeronave que irão operar os voos é de inteira responsabilidade das Companhias Aéreas, sem qualquer gerência por parte da Coobrastur;

9.6 Para voos com conexão em aeroportos diferentes, o transporte entre aeroportos será de responsabilidade do Usuário/passageiro;

9.7 O Usuário/passageiro deve comparecer com a antecedência mínima de 3 (três) horas para embarque, conforme informação fornecida pelas companhias aéreas;

9.8 Os dados pessoais devem ser preenchidos de forma correta e completa no momento da reserva, onde qualquer alteração destas informações, após a emissão do voucher, serão consideradas alteração de reserva, podendo incidir em penalidades previstas nos itens 7.1 e 7.2;

9.9 No momento do embarque deverão ser apresentados todos os documentos necessários para o embarque e/ou check-in, sendo que estes documentos devem conter as mesmas informações preenchidas no momento da reserva. Importante atentar, também, para a documentação necessária para embarque de crianças de colo e menores de idade, junto às companhias aéreas;

9.10 As passagens aéreas adquiridas pelos Usuários Coobrastur são pessoais e intransferíveis, portanto, se o Usuário optar por adquirir passagens para terceiros, deve ter a ciência de que os dados informados para embarque devem contemplar as informações do passageiro que irá embarcar na aeronave. Essas informações devem ser inseridas de forma correta e completa, onde qualquer alteração destas informações, após a emissão do voucher, será considerada alteração de reserva, podendo incidir em penalidades previstas nos itens 7.1 e 7.2;

9.11 As tarifas promocionais, reembolsos e/ou remarcações ou cancelamentos seguem exclusivamente a política operacional de cada companhia/empresa fornecedora e suas respectivas penalidades;

9.12 O Usuário deve estar atento aos limites de dimensão e peso das bagagens para voos domésticos e internacionais definidos pelas companhias aéreas e da mesma forma, deve atentar para as regras de segurança dos aeroportos para os itens que podem estar inseridos na bagagem de mão;

9.13 Para viagens internacionais é de total responsabilidade do Usuário obter passaportes e vistos conforme o País de destino. Da mesma forma, é de inteira responsabilidade do Usuário a aquisição do Seguro Viagem obrigatório quando o destino for para países da Europa integrantes do tratado de Schengen, seguro este, usado para arcar despesas decorrentes de acidentes, enfermidades, repatriação, etc.;

9.14 É de responsabilidade do Usuário o pagamento de taxas aeroportuárias que são cobradas em alguns aeroportos, sendo que estas despesas não estão contidas nos valores das passagens aéreas adquiridas pelo site www.coobrastur.com;

9.15 Em caso de atrasos, cancelamentos de voos e/ou overbooking, os procedimentos de reembolso se darão exclusivamente conforme as políticas das respectivas companhias aéreas, ressaltando-se que a Coobrastur não se responsabiliza pela ocorrência de eventuais cancelamentos ou overbooking praticados pelas companhias aéreas, uma vez que opera na qualidade de intermediadora;

9.16 Salienta-se que alguns países exigem certificado de vacinação contra algumas doenças (febre amarela, varíola, sarampo, etc). É de exclusiva responsabilidade do Usuário verificar quais são as exigências estabelecidas para o destino contratado, inclusive para conexões e escalas, com a máxima antecedência à data do embarque, haja vista o prazo para realização das vacinas. Desta forma, o Usuário deve consultar diretamente o consulado do país de destino a fim de verificar esta e outras eventuais exigências.


10 LOCAÇÃO DE VEÍCULOS

10.1 Os resultados de busca de veículos realizados através do site www.coobrastur.com são fornecidos por empresas fornecedoras, denominadas locadoras, sendo que a Coobrastur opera na qualidade de intermediadora do serviço de locação;

10.2 O resultado da busca identifica qual a Locadora será responsável pela locação do veículo, portanto, o Usuário deve estar ciente de que as políticas e regras serão aplicadas conforme estabelecido pela locadora e não necessariamente serão idênticas entre si, independentemente do período e local definido para retirada e entrega do veículo;

10.3 No momento da reserva, os itens obrigatórios para locação do veículo estarão explícitos e não será possível excluí-los da reserva. Entretanto, outros acessórios e equipamentos poderão ser contratados pelo Usuário, mediante pagamento prévio, e serão entregues juntamente com o veículo;

10.4 Para quaisquer acessórios e equipamentos extras contratados, serão cobradas tarifas específicas de locação e o Usuário deve estar ciente dos termos e compromissos específicos relativos à utilização e à conservação dos referidos acessórios/equipamentos, conforme suas características;

10.5 O Usuário Coobrastur é totalmente responsável pelo uso destes acessórios/equipamentos dentro das normas de trânsito vigentes. Em caso de danos e/ou avarias causados a terceiros e aos acessórios/equipamentos, o Usuário terá de arcar com o pagamento do valor integral ou do valor de reparação quando possível. A responsabilidade do Usuário independe de sua culpa, ressalvando ao mesmo o direito de ação contra o causador dos danos para ressarcimento de prejuízos;

10.6 No momento da reserva do veículo, o Usuário deve estar ciente dos termos gerais para o locatário, tendo ciência ainda, que os documentos comprobatórios serão exigidos no momento da retirada do veículo pelo titular do plano Coobrastur;

10.7 A retirada e a devolução do veículo alugado são realizadas exclusivamente pelo titular do plano Coobrastur. Portanto, fica expressamente vedada a retirada e/ou a devolução do veículo por qualquer outra pessoa que não seja o titular do plano Coobrastur, ainda que possua autorização do titular.

11 LOCAÇÃO DE VEÍCULOS

Fica expressamente autorizada pelo usuário, por meio deste instrumento, a cobrança de multa com o respectivo lançamento do débito na conta bancária/cartão de crédito, cadastrada do Usuário junto à Coobrastur, ou emissão de boleto se essa for a forma de cobrança escolhida, em caso de cancelamento/alteração de passagem aérea, e/ou aluguel de carro, e/ou​ reserva internacional, bem como por qualquer hipótese de utilização indevida que gere penalidade ao Usuário. Ressaltamos que, na reserva internacional ou no aluguel de carro, o valor da multa será auferido em moeda corrente Nacional, conforme a cotação da moeda no dia de fechamento da respectiva fatura emitida pelo fornecedor.

Condições Gerais:
- Ter idade mínima de 21 anos;
- Apresentar carteira de habilitação (CNH) definitiva, original e válida emitida há pelo menos 1 ano;
- No momento de retirar o veículo, é necessário apresentar cartão de crédito. Com emissão bancária e limite disponível para pré-autorização, em nome do Locatário ou responsável financeiro e apresentar CPF e originais. O não pagamento da pré-autorização/caução junto à locadora impossibilitará a utilização do serviço.

Havendo dúvidas quanto à operacionalização de acessórios, o Usuário deve solicitar auxílio no momento da retirada do veículo junto à locadora;


O Usuário declara estar ciente e concorda com todos os termos e condições constantes neste instrumento. `;

export const contentModalPolitics = `
Lorem ipsum dolor sit amet, 
consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
Eu volutpat odio facilisis mauris sit. Adipiscing elit ut aliquam purus sit. 
In hendrerit gravida rutrum quisque non tellus orci. Nunc vel risus commodo viverra maecenas accumsan. 
Leo integer malesuada nunc vel risus commodo viverra. Interdum velit euismod in pellentesque massa placerat duis. 
Eget sit amet tellus cras adipiscing enim eu turpis. Viverra nam libero justo laoreet sit amet cursus sit amet. 
Blandit turpis cursus in hac habitasse platea dictumst. Consectetur lorem donec massa sapien faucibus et molestie ac. 
Tellus elementum sagittis vitae et leo duis ut. Neque viverra justo nec ultrices dui sapien. 
Faucibus a pellentesque sit amet porttitor eget. Porttitor leo a diam sollicitudin tempor id eu nisl nunc. 
Fermentum et sollicitudin ac orci phasellus egestas tellus. Sit amet nisl suscipit adipiscing bibendum est ultricies integer quis. 
Egestas erat imperdiet sed euismod nisi porta lorem. Sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper eget. 
Ut consequat semper viverra nam libero justo laoreet. Pellentesque habitant morbi tristique senectus et netus. 
Fames ac turpis egestas integer eget aliquet nibh praesent. Phasellus vestibulum lorem sed risus ultricies tristique nulla. 
Pellentesque sit amet porttitor eget dolor morbi non arcu risus. 
Lobortis mattis aliquam faucibus purus in massa tempor. Massa sapien faucibus et molestie ac. A diam sollicitudin tempor id. 
Viverra justo nec ultrices dui sapien eget. At ultrices mi tempus imperdiet nulla malesuada pellentesque. Sagittis orci a scelerisque purus semper. 
Dui accumsan sit amet nulla. Mi sit amet mauris commodo quis imperdiet massa tincidunt nunc. Sed cras ornare arcu dui vivamus arcu. 
Fringilla urna porttitor rhoncus dolor purus non enim praesent elementum. Dolor sed viverra ipsum nunc. 
Aliquet lectus proin nibh nisl condimentum id. Amet consectetur adipiscing elit ut aliquam purus sit amet luctus. 
Fusce id velit ut tortor pretium viverra suspendisse potenti nullam. Euismod in pellentesque massa placerat duis ultricies. 
Eu feugiat pretium nibh ipsum. Tortor posuere ac ut consequat semper viverra nam libero justo. 
Urna duis convallis convallis tellus id interdum velit laoreet id. 
Libero volutpat sed cras ornare arcu dui vivamus arcu. Morbi blandit cursus risus at ultrices. 
Condimentum vitae sapien pellentesque habitant morbi tristique senectus. 
Aliquet bibendum enim facilisis gravida neque convallis a cras. Varius sit amet mattis vulputate enim nulla aliquet porttitor lacus. 
Egestas sed tempus urna et pharetra pharetra massa massa. Nunc sed velit dignissim sodales. 
Sed egestas egestas fringilla phasellus faucibus scelerisque eleifend donec pretium. Commodo elit at imperdiet dui accumsan. 
Nisi est sit amet facilisis magna etiam tempor. Est placerat in egestas erat. In ante metus dictum at tempor. 
Tempus egestas sed sed risus pretium quam vulputate. Viverra accumsan in nisl nisi scelerisque eu ultrices vitae. 
`;
