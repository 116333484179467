import { Fragment, useCallback, useEffect, useState } from "react";
import { InputRadioProps, Options } from "./types";
import { Box, Input, Label, Wrapper, WrapperDescription } from "./styles";

export const InputRadioPayments = ({
  fontSize,
  options,
  direction,
  justifyContent,
  paddingLeft,
  border,
  handleSelected,
  resetRadio,
  setResetRadio,
}: InputRadioProps) => {
  const [optionsInput, setOptionsInput] = useState<Options[]>([]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, option: Options) => {
      const copyOptions = [...options];
      const optionsSelected = copyOptions.map((element) => {
        if (e.target.value === element.value) {
          return { ...element, checked: true };
        }

        return { ...element, checked: false };
      });

      setOptionsInput(optionsSelected);

      if (handleSelected) {
        handleSelected(option);
        return;
      }
    },
    [options, handleSelected]
  );

  useEffect(() => {
    setOptionsInput(options);
  }, [options]);

  useEffect(() => {
    if (resetRadio) {
      console.log({ resetRadio });
      const copyOptions = [...options];

      const optionsSelected = copyOptions.map((element) => {
        return { ...element, checked: false };
      });

      console.log({ optionsSelected });

      setOptionsInput(optionsSelected);
      if (setResetRadio) setResetRadio(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetRadio]);

  return (
    <Fragment>
      {optionsInput.map((option) => (
        <Wrapper key={option.value} border={border} haveBorder={option.checked}>
          <Box direction={direction} justifyContent={justifyContent}>
            <Input
              type="radio"
              id={option.id}
              value={option.value}
              name={option.name}
              onChange={(e) => handleChange(e, option)}
              checked={option.checked}
            />

            {option.icon && option.iconChecked && (
              <img
                src={option.checked ? option.iconChecked : option.icon}
                alt={option.label}
                style={{ marginLeft: "16px" }}
              />
            )}
            <Label
              htmlFor={option.id}
              paddingLeft={paddingLeft}
              fontSize={fontSize}
            >
              {option.label}
            </Label>
          </Box>
          {option.description && (
            <WrapperDescription>{option.description}</WrapperDescription>
          )}
        </Wrapper>
      ))}
    </Fragment>
  );
};

export default InputRadioPayments;
