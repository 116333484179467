import { Fragment, useEffect, useState } from "react";
import { FilterAccordion } from "../../components/filtersAccordion";
import { Search } from "../../components/search";
import { Steps } from "../../components/steps";
import { ReactComponent as IconFilter } from "../../assets/icones/icons dense (24px)/filter.svg";
import { ReactComponent as IconFechar } from "../../assets/icones/icons dense (24px)/fechar.svg";
import { ReactComponent as IconBussola } from "../../assets/icones/icons outline (48px)/bussola.svg";

import {
    ButtonClear,
    ButtonClearFilters,
    ButtonFilter,
    ButtonSubmit,
    Container,
    ContentAside,
    ContentAsideBody,
    ContentAsideFooter,
    ContentAsideHeader,
    ContentBody,
    ContentGrid,
    ContentHeader,
    Header,
    HeaderSearch,
    Hgroup,
    Loading,
    Pagintaion,
    Separator,
    SubtitleAside,
    Text,
    TilteContent,
    Title,
    TitleAside,
} from "./styles";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { CardHotelInternacional } from "../../components/cardHotelInternacional";
import { useLocation, useNavigate } from "react-router-dom";
// import useCreateToken from "../../hooks/createToken";
import { api } from "../../services";
import { formatDate } from "../../utils";
import ReactPaginate from "react-paginate";
import { DataResponse, HotelList, LocationProps } from "./types";
import { useFilters } from "../../hooks/useFilters";
import { SearchDesktop } from "../../components/searchDesktop";
import { BtnKeep, BtnResearch, WrapperResearch } from "../Accommodation/styles";
import { ModalFormConfirmation } from "../../components/modalFormConfirmation";
import { MessageProps } from "../../components/modalFormAvailability/types";

const SEPARATOR_ID = "SEPARATOR_ID";

export const AccommodationInternational = () => {
    const [open, setOpen] = useState(false);
    const mobile = useMediaQuery("(max-width: 990px)");
    // const { tokenApi, createToken } = useCreateToken();
    const { state }: LocationProps = useLocation();
    const [loading, setLoading] = useState(false);
    const [listHotels, setListeHotels] = useState<HotelList[]>([]);
    const [filterHotels, setFilterHotels] = useState<HotelList[]>([]);
    const [pageNumber, setPageNumber] = useState(0);
    const hotelPerPage = 5;
    const pagesVisited = pageNumber * hotelPerPage;
    const pageCount = Math.ceil(filterHotels.length / hotelPerPage);
    const [message, setMessage] = useState<MessageProps>({} as MessageProps);
    const [modalReload, setModalReload] = useState<boolean>(false);
    const { clearAllFilter, filterSelected } = useFilters();
    const navigate = useNavigate();

    const hotelsPagination = filterHotels
        .slice(pagesVisited, pagesVisited + hotelPerPage)
        .map((hotel) => {
            return <CardHotelInternacional key={hotel.id} hotel={hotel} />;
        });

    const handleGoTotop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const handleToSEPARATOR_ID = () => {
        const element = document.querySelector(`#${SEPARATOR_ID}`);
        element?.scrollIntoView({
            behavior: "smooth",
            block: "center",
        });
    };

    useEffect(() => {
        if (mobile) {
            handleToSEPARATOR_ID();
        } else {
            handleGoTotop();
        }
    }, [hotelsPagination, mobile]);

    const changePage = ({ selected }: any) => {
        setPageNumber(selected);
    };

    const handleCloseModalreload = () => {
        setModalReload(false);
        window.location.href = `${process.env.PUBLIC_URL}/`;
    };

    // useEffect(() => {
    //   createToken();
    // }, [createToken]);

    const token = localStorage.getItem("token");
    useEffect(() => {
        if (!!token && !!state?.data) {
            setLoading(true);
            setListeHotels([]);

            const insertPesquisaPayload = {
                in_token: token,
                dataInicial: formatDate(state?.data.dateIn),
                dataFinal: formatDate(state?.data.dateOut),
                qtdeQuartos: state?.data.numRoom,
                adultosQ1:
                    state?.data?.rooms && state?.data?.rooms[0]
                        ? state?.data?.rooms[0]?.adducts
                        : 0,
                adultosQ2:
                    state?.data?.rooms && state?.data?.rooms[1]
                        ? state?.data?.rooms[1]?.adducts
                        : 0,
                adultosQ3:
                    state?.data?.rooms && state?.data?.rooms[2]
                        ? state?.data?.rooms[2]?.adducts
                        : 0,
                adultosQ4:
                    state?.data?.rooms && state?.data?.rooms[3]
                        ? state?.data?.rooms[3]?.adducts
                        : 0,
                criancasQ1:
                    state?.data.rooms && state?.data?.rooms[0]
                        ? state?.data?.rooms[0]?.childrens?.number
                        : 0,
                criancasQ2:
                    state?.data.rooms && state?.data?.rooms[1]
                        ? state?.data?.rooms[1]?.childrens?.number
                        : 0,
                criancasQ3:
                    state?.data.rooms && state?.data?.rooms[2]
                        ? state?.data?.rooms[2]?.childrens?.number
                        : 0,
                criancasQ4:
                    state?.data.rooms && state?.data?.rooms[3]
                        ? state?.data?.rooms[3]?.childrens?.number
                        : 0,
                idadeC1:
                    state?.data.rooms && state?.data.rooms[0]
                        ? state?.data.rooms[0]?.childrens?.children
                            .map((child) => child.year.toString() || "")
                            .join(",")
                        : 0,
                idadeC2:
                    state?.data.rooms && state?.data.rooms[1]
                        ? state?.data.rooms[1]?.childrens?.children
                            .map((child) => child.year.toString() || "")
                            .join(",")
                        : 0,
                idadeC3:
                    state?.data.rooms && state?.data.rooms[2]
                        ? state?.data.rooms[2]?.childrens?.children
                            .map((child) => child.year.toString() || "")
                            .join(",")
                        : 0,
                idadeC4:
                    state?.data.rooms && state?.data.rooms[3]
                        ? state?.data.rooms[3]?.childrens?.children
                            .map((child) => child.year.toString() || "")
                            .join(",")
                        : 0,
                consultaInternacional: 1,
                in_Codigo: state?.data.place?.Codigo,
                hotCodigoSelecionado: 0,
                fotoHotelSelecionado: "",
                enderecoHotelSelecionado: "",
                nomeHotelSelecionado: "",
                nomeDestino: state?.data.place?.Descricao,
                usuarioLogin: 0,
                tktCodigo1: 0,
                tktCodigo2: 0,
                tktCodigo3: 0,
                tktCodigo4: 0,
                totalAdultos: state?.data?.rooms
                    ?.map((room) => room.adducts)
                    ?.reduce((acc, curr) => (acc += curr), 0),
                totalCriancas: state?.data?.rooms
                    ?.map((room) => room.childrens.children.length)
                    .reduce((acc, curr) => (acc += curr), 0),
            };

            api
                .post("/wsCoobrastur/Hotel.asmx/InsertPesquisa_1", {
                    dados: JSON.stringify(insertPesquisaPayload),
                })
                .then((response) => {
                    console.log(response.data)
                    if (response.data.Table[0].situacao === 0) {
                        setMessage({
                            title: "Você ficou muito tempo sem navegar",
                            body: response.data.Table[0].Texto,
                        });
                        setModalReload(true);
                        return;
                    }
                    const data = {
                        LocationId: state?.data?.place?.Codigo,
                        CheckIn: formatDate(state?.data.dateIn) || "",
                        CheckOut: formatDate(state?.data.dateOut) || "",
                        Rooms: state?.data?.rooms?.length || 1,
                        Adults: state?.data?.rooms
                            ?.map((room) => room.adducts.toString())
                            .join(","),
                        Children: state?.data?.rooms
                            ?.map((room) => room.childrens.number.toString())
                            .join(","),
                        ChildAges: state?.data?.rooms
                            ?.map((room) =>
                                room.childrens.children
                                    .map((child) => child.year.toString() || "")
                                    .join(",")
                            )
                            .flat()
                            .join(","),
                        HotelName: "",
                        HotelId: "",
                        hotCodigoSelecionado: 0,
                    };

                    api
                        .post(`/wsCoobrastur/Hotel.asmx/SearchHotel_1`, {
                            dados: JSON.stringify(data),
                        })
                        .then((response) => {
                            const { Hotels, Cotacoes }: DataResponse = response.data;

                            if (Hotels.Errors.length > 0) {
                                throw Hotels.Errors;
                            }

                            const newData = Hotels.HotelResponseData.Hotels.map((hotel) => {
                                return {
                                    id: hotel.Id,
                                    name: hotel.Name,
                                    description: hotel.Description,
                                    address: hotel.Address.Street,
                                    centerDistance: hotel.Address.DistanceToCenterCity,
                                    AirportDistance: hotel.Address.DistanceToNearestAirport,
                                    hotelStars: Math.ceil(hotel.Stars),
                                    images: hotel.Images.map((image) => image.Url),
                                    contacts: hotel.Contacts.map((contact) => ({
                                        description: contact.Description,
                                    })),

                                    rooms: hotel.Rooms.map((room) => {
                                        return {
                                            id: room.Id,
                                            name: room.Name,
                                            board: room.StayOptions[0].Board.Description,
                                            options: room.StayOptions[0],
                                            cotacao: Cotacoes.BRL,
                                        };
                                    }),
                                    roomsGroups: hotel.RoomsGroups,
                                    raw: hotel,
                                };
                            });

                            setListeHotels(newData);
                            setLoading(false);
                        })
                        .catch((erro) => {
                            console.error(erro);
                            setLoading(false);

                            setMessage({
                                title: "",
                                body: erro[0].FriendlyMessage,
                            });
                            setModalReload(true);
                        });
                })
                .catch((err) => {
                    console.error(err);
                    // setMessage({
                    //     title: "Você ficou muito tempo sem navegar",
                    //     body: response.data.Table[0].Texto,
                    //   });

                    setLoading(false);
                });
        }
    }, [state, token]);

    useEffect(() => {
        if (filterSelected.length > 0) {
            let newData = [...listHotels];
            let stars = false;
            let filterList = filterSelected.map((filter) => {
                if (filter.type === "stars") {
                    return filter.value;
                }
            });

            newData = newData.filter((hotel) => {
                if (filterList.includes(hotel.hotelStars.toFixed())) {
                    stars = true;
                    return hotel;
                }
            });

            setFilterHotels(newData);

            // filterSelected.forEach((filter) => {
            //   newData = newData.filter((holtel) => {
            //     let stars = false;
            //     // console.log(filter);
            //     if (filter.type === "stars") {
            //       if (holtel.hotelStars.toFixed() === filter.value) {
            //         stars = true;
            //       }
            //     }

            //     if (stars) {
            //       return true;
            //     } else {
            //       return false;
            //     }
            //   });
            // });

            // setFilterHotels(newData);
        } else {
            setFilterHotels([...listHotels]);
        }
    }, [listHotels, filterSelected]);

    const modalIssueOnInternational = true;

    function handleRedirectToPageOnIssue() {
        navigate("/");
    }

    return (
        <Container>
            {modalIssueOnInternational && (
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.48)",
                        position: "fixed",
                        zIndex: 4,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            maxWidth: "500px",
                            height: "auto",
                            padding: "16px",
                            backgroundColor: "var(--white)",
                            borderRadius: "20px",
                            margin: 12,
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "space-between",
                        }}
                    >
                        <h5 style={{ color: "var(--purple600)" }}>
                            ATENÇÃO
                        </h5>
                        <div>
                            <p>
                                Estamos realizando alterações na reserva de hotéis internacionais para melhor atendê-lo.
                                Caso necessite de suporte, por favor contate a Nacional Turismo pelo telefone (81) 3202-1440.
                            </p>
                        </div>
                        <BtnKeep onClick={handleRedirectToPageOnIssue}>Ok, entendi!</BtnKeep>
                    </div>
                </div>
            )}
            <Header>
                <ContentHeader>
                    <HeaderSearch>
                        <Hgroup>
                            <Title>Etapas para concluir sua reserva</Title>
                            <Text>
                                Você está primeira etapa. Escolha a sua hospedagem dentro os
                                resultados abaixo para prosseguir.
                            </Text>
                        </Hgroup>

                        {mobile ? <Search /> : <SearchDesktop />}
                    </HeaderSearch>
                    <Steps
                        stepOne={"active"}
                        stepTwo={"disabled"}
                        stepThree={"disabled"}
                    />
                </ContentHeader>
            </Header>

            <Separator id={SEPARATOR_ID} />

            <ContentGrid>
                <ButtonFilter type="button" onClick={() => setOpen(!open)}>
                    <span>
                        {filterSelected.length > 0 ? (
                            <span>{filterSelected.length}</span>
                        ) : (
                            <IconFilter />
                        )}
                    </span>
                    Refinar busca
                </ButtonFilter>

                <ContentAside className={open ? "active" : ""}>
                    <ContentAsideHeader>
                        <TitleAside>
                            {mobile && <IconFechar onClick={() => setOpen(!open)} />}
                            <span>Filtrar Resultados</span>
                            {mobile && <IconBussola />}
                        </TitleAside>
                    </ContentAsideHeader>

                    <ContentAsideBody>
                        {!mobile && <Separator />}

                        {filterSelected.length > 0 && (
                            <ButtonClearFilters className="acitve" onClick={clearAllFilter}>
                                Limpar
                            </ButtonClearFilters>
                        )}

                        <SubtitleAside>Categoria</SubtitleAside>

                        <FilterAccordion
                            filters={[
                                { name: "5 estrelas", value: "5" },
                                { name: "4 estrelas", value: "4" },
                                { name: "3 estrelas", value: "3" },
                                { name: "2 estrelas", value: "2" },
                                { name: "1 estrelas", value: "1" },
                            ]}
                            type={"stars"}
                        />
                    </ContentAsideBody>

                    <ContentAsideFooter>
                        <ButtonClear
                            type="button"
                            onClick={() => {
                                clearAllFilter();
                                setOpen(!open);
                            }}
                        >
                            LIMPAR
                        </ButtonClear>
                        <ButtonSubmit type="button" onClick={() => setOpen(!open)}>
                            Filtrar
                        </ButtonSubmit>
                    </ContentAsideFooter>
                </ContentAside>

                <ContentBody>
                    {loading ? (
                        <Loading active={loading}>
                            <span></span>
                        </Loading>
                    ) : (
                        <Fragment>
                            <TilteContent>
                                Foram encontrados{" "}
                                <strong>
                                    {filterHotels.length}{" "}
                                    {filterHotels.length > 1 ? "hotéis" : "hotel"}
                                </strong>
                            </TilteContent>
                            {filterHotels.length <= 0 && (
                                <WrapperResearch>
                                    <TilteContent>
                                        <strong> Remarque os filtros ou </strong>
                                    </TilteContent>
                                    <BtnResearch onClick={handleGoTotop}>
                                        Refazer busca
                                    </BtnResearch>
                                </WrapperResearch>
                            )}
                        </Fragment>
                    )}

                    <Pagintaion>
                        {hotelsPagination}
                        {filterHotels.length > hotelPerPage && (
                            <ReactPaginate
                                previousLabel={""}
                                nextLabel={""}
                                pageCount={pageCount}
                                onPageChange={changePage}
                                containerClassName={"paginationBtns"}
                                previousClassName={"previousBtn"}
                                nextClassName={"nextBtn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                                pageRangeDisplayed={2}
                            />
                        )}
                    </Pagintaion>
                </ContentBody>
            </ContentGrid>
            <ModalFormConfirmation
                modalIsOpen={modalReload}
                message={message}
                setIsOpen={handleCloseModalreload}
            />
        </Container>
    );
};

export default AccommodationInternational;
