import {
  useState,
  useEffect,
  useCallback,
  SyntheticEvent,
  Fragment,
} from "react";

import { ReactComponent as IconRoom } from "../../assets/icones/icons outline (48px)/cama casal.svg";
import { ReactComponent as IconArrow } from "../../assets/icones/icons dense (24px)/seta direita.svg";
import { ReactComponent as IconAdd } from "../../assets/icones/icons minimal (20px)/add.svg";
import { ReactComponent as IconTrash } from "../../assets/icones/icons minimal (20px)/trash.svg";
import { ReactComponent as IconClose } from "../../assets/icones/icons dense (24px)/fechar - white.svg";

import Modal from "react-modal";

import {
  Room,
  RoomTitle,
  RoomOptions,
  RoomButtonsGroup,
  RoomContent,
  ButtonSelect,
  MessageHelp,
  OptionsSelect,
  OptionsContent,
  OptionsSelectLabel,
  OptionsSelectInput,
  ButtonAddRoom,
  ModalContent,
  ModalHeader,
  CloseModal,
  ModalTitle,
  ModalBody,
  ModalFooter,
  FooterMessage,
  ButtonSave,
  RoomHeader,
  ButtonRemove,
} from "./styles";
import { ModalRoomsProps, RoomProps } from "./types";
import { useSearchStore } from "../../store/useSearch";
import { useUrlParams } from "../../hooks/useUrlParams";
import { MaxAndMinPassengersPerRoom } from "../searchDesktop/types";

const styleModal = {
  content: {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    padding: "0",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden",
    zIndex: 999,
  },
  overlay: {
    zIndex: 999,
    backgroundColor: "rgba(0,0,0,0.48)",
  },
};

export const ModalRooms = ({ modalRooms, setModalRooms }: ModalRoomsProps) => {
  const { urlParams } = useUrlParams();
  const INITIAL_START_LIMIT = urlParams.tipoUsuario ? 4 : 3;

  const initialStart = {
    adducts: 2,
    childrens: {
      number: 0,
      children: [],
    },
    limit: INITIAL_START_LIMIT,
  };

  const MAX_AND_MIN_PASSENGERS: MaxAndMinPassengersPerRoom = {
    minAdult: 1,
    maxAdult: 3,
    minChildren: 0,
    maxChildren: 1,
    maxTotal: 3, // using limit per room insted
  };
  const [maxAndMinPassengers, setMaxAndMinPassengers] =
    useState<MaxAndMinPassengersPerRoom>(MAX_AND_MIN_PASSENGERS);

  const [rooms, setRooms] = useState<RoomProps[]>([initialStart]);
  const [open, setOpen] = useState(false);
  const [messagem, setMessage] = useState<null | string>(null);
  const [order, setOrder] = useState<null | string>(null);
  const { setData, data: searchStore } = useSearchStore();

  useEffect(() => {
    // Aqui muda se o limite por quarto se tipo de usuario estiver correto
    if (urlParams.tipoUsuario) {
      const initialStartObj: RoomProps = {
        adducts: 2,
        childrens: {
          number: 0,
          children: [],
        },
        limit: 4,
      };

      const newMax: MaxAndMinPassengersPerRoom = {
        minAdult: 1,
        maxAdult: 4,
        minChildren: 0,
        maxChildren: 2,
        maxTotal: 4,
      };

      setMaxAndMinPassengers(newMax);
      setRooms([initialStartObj]);
    }
  }, [urlParams]);

  const addRoom = () => {
    const newRooms = [...rooms];

    if (newRooms.length <= INITIAL_START_LIMIT) {
      newRooms.push(initialStart);
    }

    setRooms(() => newRooms);
  };

  const removeRoom = (index: number) => {
    const newRooms = [...rooms];
    newRooms.splice(index, 1);

    setRooms(() => newRooms);
  };

  const addAdduct = (index: number) => {
    console.log({ maxAndMinPassengers });
    const newRooms = [...rooms];
    const roomLimit =
      newRooms[index].adducts + newRooms[index].childrens.number;

    if (
      roomLimit < newRooms[index].limit && // roomLimit < maxAndMinPassengers.maxTotal &&
      newRooms[index].adducts < maxAndMinPassengers.maxAdult
    ) {
      newRooms[index].adducts += 1;
    }

    setRooms(newRooms);
  };

  const removeAdduct = (index: number) => {
    const newRooms = [...rooms];

    if (newRooms[index].adducts > maxAndMinPassengers.minAdult) {
      newRooms[index].adducts -= 1;
    }

    setRooms(() => newRooms);
  };

  const addChildrens = (index: number) => {
    const newRooms = [...rooms];

    const hasRequired = newRooms[index].childrens.children.filter(
      (children) => children.year < 0
    );

    if (hasRequired.length > 0) {
      setMessage(
        "selecione a <strong>idade</strong> da(s) <strong>criança(s)</strong>"
      );
      return;
    } else {
      setMessage(null);
    }

    const roomLimit =
      newRooms[index].adducts + newRooms[index].childrens.number;

    if (
      roomLimit < newRooms[index].limit && // roomLimit < maxAndMinPassengers.maxTotal &&
      newRooms[index].childrens.number < maxAndMinPassengers.maxChildren
    ) {
      newRooms[index].childrens.number += 1;
      newRooms[index].childrens.children.push({ year: -1 });
    }

    setMessage(
      "selecione a <strong>idade</strong> da(s) <strong>criança(s)</strong>"
    );
    setRooms(() => newRooms);
  };

  const removeChildrens = (index: number) => {
    const newRooms = [...rooms];

    if (newRooms[index].childrens.number > maxAndMinPassengers.minChildren) {
      newRooms[index].childrens.number -= 1;
      newRooms[index].childrens.children.pop();
    }

    setMessage(null);
    setRooms(() => newRooms);
  };

  const handleInput = (event: SyntheticEvent<HTMLInputElement>) => {
    return (indexRoom: number) => {
      return (indexChildren: number) => {
        const { value } = event.currentTarget;
        const newRooms = [...rooms];

        newRooms[indexRoom].childrens.children[indexChildren].year =
          Number(value);

        setRooms(() => newRooms);
        setMessage(null);
        setOpen(!open);
      };
    };
  };

  const numVisitant = useCallback(() => {
    const newObject = [...rooms];

    const number = newObject.reduce((acc, curr) => {
      return (acc += curr.adducts + curr.childrens.number);
    }, 0);

    return number;
  }, [rooms]);

  const save = () => {
    const number = numVisitant();

    setData({
      ...searchStore,
      numRoom: rooms.length,
      visitant: number,
      rooms: rooms,
    });
    setModalRooms(!modalRooms);
  };

  useEffect(() => {
    const number = numVisitant();

    setOrder(`${rooms.length} quarto, ${number} hóspedes`);
  }, [rooms, numVisitant]);

  useEffect(() => {
    if (rooms) {
      let required: any | null = null;

      rooms.map((room) => {
        const result = room.childrens.children.filter(
          (children) => children.year < 0
        );

        required = result;

        return room;
      });

      if (required?.length === 0) setMessage(null);
    }
  }, [rooms]);

  return (
    <Modal isOpen={modalRooms} style={styleModal} ariaHideApp={false}>
      <ModalContent>
        <ModalHeader>
          <CloseModal
            type="button"
            title="fechar"
            onClick={() => setModalRooms(!modalRooms)}
          >
            <IconClose />
          </CloseModal>

          <ModalTitle>Quartos e hóspedes</ModalTitle>

          <IconRoom />
        </ModalHeader>
        <ModalBody>
          {rooms.map((room, indexRoom) => (
            <Room key={indexRoom.toString()}>
              <RoomHeader>
                <RoomTitle>Quarto {indexRoom + 1}</RoomTitle>

                {indexRoom > 0 && (
                  <ButtonRemove
                    type="button"
                    onClick={() => removeRoom(indexRoom)}
                  >
                    eliminar <IconTrash />
                  </ButtonRemove>
                )}
              </RoomHeader>
              <RoomOptions>
                <RoomContent>
                  <p>Adultos</p>
                  <sup>Maiores de 18 anos</sup>
                </RoomContent>
                <RoomButtonsGroup>
                  <button
                    type="button"
                    onClick={() => removeAdduct(indexRoom)}
                    disabled={room.adducts <= maxAndMinPassengers.minAdult}
                  >
                    -
                  </button>
                  <span>{room.adducts}</span>
                  <button
                    type="button"
                    onClick={() => addAdduct(indexRoom)}
                    disabled={
                      room.adducts >= maxAndMinPassengers.maxAdult ||
                      room.childrens.number + room.adducts >=
                        maxAndMinPassengers.maxTotal
                    }
                  >
                    +
                  </button>
                </RoomButtonsGroup>
              </RoomOptions>
              <RoomOptions>
                <RoomContent>
                  <p>Crianças</p>
                  <sup>Até 17 anos</sup>
                </RoomContent>
                <RoomButtonsGroup>
                  <button
                    type="button"
                    onClick={() => removeChildrens(indexRoom)}
                    disabled={
                      room.childrens.number <= maxAndMinPassengers.minChildren
                    }
                  >
                    -
                  </button>
                  <span>{room.childrens.number}</span>
                  <button
                    type="button"
                    onClick={() => addChildrens(indexRoom)}
                    disabled={
                      room.childrens.number >=
                        maxAndMinPassengers.maxChildren ||
                      room.childrens.number + room.adducts >=
                        maxAndMinPassengers.maxTotal
                    }
                  >
                    +
                  </button>
                </RoomButtonsGroup>
              </RoomOptions>

              {room.childrens.number > 0 && (
                <MessageHelp>
                  Idades das crianças na data de entrada
                </MessageHelp>
              )}

              {room.childrens.children.map((children, indexChildren) => (
                <Fragment key={indexChildren.toString()}>
                  <RoomOptions>
                    <RoomContent>
                      <p>Criança {indexChildren + 1}</p>
                    </RoomContent>

                    <ButtonSelect type="button" onClick={() => setOpen(!open)}>
                      {children.year === -1 && <span>Idade</span>}
                      {children.year === 0 && (
                        <span className="active">Até 1 ano</span>
                      )}
                      {children.year > 0 && (
                        <span className="active">{children.year} anos</span>
                      )}

                      <IconArrow />
                    </ButtonSelect>

                    <OptionsSelect open={open}>
                      <OptionsContent>
                        <OptionsSelectLabel>
                          <p>Até 1 ano</p>
                          <OptionsSelectInput
                            type="radio"
                            name="year-children1"
                            value={0}
                            onInput={(event) =>
                              handleInput(event)(indexRoom)(indexChildren)
                            }
                          />
                        </OptionsSelectLabel>
                        <OptionsSelectLabel>
                          <p>1 ano</p>
                          <OptionsSelectInput
                            type="radio"
                            name="year-children1"
                            value={1}
                            onInput={(event) =>
                              handleInput(event)(indexRoom)(indexChildren)
                            }
                          />
                        </OptionsSelectLabel>
                        <OptionsSelectLabel>
                          <p>2 ano</p>
                          <OptionsSelectInput
                            type="radio"
                            name="year-children1"
                            value={2}
                            onInput={(event) =>
                              handleInput(event)(indexRoom)(indexChildren)
                            }
                          />
                        </OptionsSelectLabel>
                        <OptionsSelectLabel>
                          <p>3 ano</p>
                          <OptionsSelectInput
                            type="radio"
                            name="year-children1"
                            value={3}
                            onInput={(event) =>
                              handleInput(event)(indexRoom)(indexChildren)
                            }
                          />
                        </OptionsSelectLabel>
                        <OptionsSelectLabel>
                          <p>4 ano</p>
                          <OptionsSelectInput
                            type="radio"
                            name="year-children1"
                            value={4}
                            onInput={(event) =>
                              handleInput(event)(indexRoom)(indexChildren)
                            }
                          />
                        </OptionsSelectLabel>
                        <OptionsSelectLabel>
                          <p>5 ano</p>
                          <OptionsSelectInput
                            type="radio"
                            name="year-children1"
                            value={5}
                            onInput={(event) =>
                              handleInput(event)(indexRoom)(indexChildren)
                            }
                          />
                        </OptionsSelectLabel>
                        <OptionsSelectLabel>
                          <p>6 ano</p>
                          <OptionsSelectInput
                            type="radio"
                            name="year-children1"
                            value={6}
                            onInput={(event) =>
                              handleInput(event)(indexRoom)(indexChildren)
                            }
                          />
                        </OptionsSelectLabel>
                        <OptionsSelectLabel>
                          <p>7 ano</p>
                          <OptionsSelectInput
                            type="radio"
                            name="year-children1"
                            value={7}
                            onInput={(event) =>
                              handleInput(event)(indexRoom)(indexChildren)
                            }
                          />
                        </OptionsSelectLabel>
                        <OptionsSelectLabel>
                          <p>8 ano</p>
                          <OptionsSelectInput
                            type="radio"
                            name="year-children1"
                            value={8}
                            onInput={(event) =>
                              handleInput(event)(indexRoom)(indexChildren)
                            }
                          />
                        </OptionsSelectLabel>
                        <OptionsSelectLabel>
                          <p>9 ano</p>
                          <OptionsSelectInput
                            type="radio"
                            name="year-children1"
                            value={9}
                            onInput={(event) =>
                              handleInput(event)(indexRoom)(indexChildren)
                            }
                          />
                        </OptionsSelectLabel>
                        <OptionsSelectLabel>
                          <p>10 ano</p>
                          <OptionsSelectInput
                            type="radio"
                            name="year-children1"
                            value={10}
                            onInput={(event) =>
                              handleInput(event)(indexRoom)(indexChildren)
                            }
                          />
                        </OptionsSelectLabel>
                        <OptionsSelectLabel>
                          <p>11 ano</p>
                          <OptionsSelectInput
                            type="radio"
                            name="year-children1"
                            value={11}
                            onInput={(event) =>
                              handleInput(event)(indexRoom)(indexChildren)
                            }
                          />
                        </OptionsSelectLabel>
                        <OptionsSelectLabel>
                          <p>12 ano</p>
                          <OptionsSelectInput
                            type="radio"
                            name="year-children1"
                            value={12}
                            onInput={(event) =>
                              handleInput(event)(indexRoom)(indexChildren)
                            }
                          />
                        </OptionsSelectLabel>
                        <OptionsSelectLabel>
                          <p>13 ano</p>
                          <OptionsSelectInput
                            type="radio"
                            name="year-children1"
                            value={13}
                            onInput={(event) =>
                              handleInput(event)(indexRoom)(indexChildren)
                            }
                          />
                        </OptionsSelectLabel>
                        <OptionsSelectLabel>
                          <p>14 ano</p>
                          <OptionsSelectInput
                            type="radio"
                            name="year-children1"
                            value={14}
                            onInput={(event) =>
                              handleInput(event)(indexRoom)(indexChildren)
                            }
                          />
                        </OptionsSelectLabel>
                        <OptionsSelectLabel>
                          <p>15 ano</p>
                          <OptionsSelectInput
                            type="radio"
                            name="year-children1"
                            value={15}
                            onInput={(event) =>
                              handleInput(event)(indexRoom)(indexChildren)
                            }
                          />
                        </OptionsSelectLabel>
                        <OptionsSelectLabel>
                          <p>16 ano</p>
                          <OptionsSelectInput
                            type="radio"
                            name="year-children1"
                            value={16}
                            onInput={(event) =>
                              handleInput(event)(indexRoom)(indexChildren)
                            }
                          />
                        </OptionsSelectLabel>
                        <OptionsSelectLabel>
                          <p>17 ano</p>
                          <OptionsSelectInput
                            type="radio"
                            name="year-children1"
                            value={17}
                            onInput={(event) =>
                              handleInput(event)(indexRoom)(indexChildren)
                            }
                          />
                        </OptionsSelectLabel>
                      </OptionsContent>
                    </OptionsSelect>
                  </RoomOptions>
                </Fragment>
              ))}
            </Room>
          ))}

          {rooms.length < 4 && (
            <ButtonAddRoom type="button" onClick={addRoom}>
              <IconAdd />
              Adicionar quarto
            </ButtonAddRoom>
          )}
        </ModalBody>
        <ModalFooter>
          <FooterMessage>
            {messagem !== null && (
              <span dangerouslySetInnerHTML={{ __html: messagem }}></span>
            )}
            {messagem === null && order !== null && (
              <span
                className="success"
                dangerouslySetInnerHTML={{ __html: order }}
              ></span>
            )}
          </FooterMessage>

          <ButtonSave
            disabled={rooms === null || messagem !== null}
            onClick={save}
          >
            SALVAR
          </ButtonSave>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
