import styled, { keyframes } from "styled-components";
import { LoadingProps } from "./types";

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
`;

export const Header = styled.div`
  width: 100%;
  max-width: 100%;
  height: auto;
  background-color: var(--white);
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.12);
`;

export const ContentHeader = styled.div`
  display: block;
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  padding: 0 1rem;
`;

export const HeaderSearch = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
`;

export const Hgroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 1rem;
  font-weight: 700;
  color: var(--purple600);
  margin: 0;
`;

export const Text = styled.p`
  font-size: 0.938rem;
  color: var(--purple600);
  margin: 0;
`;

export const Separator = styled.hr`
  display: none;
  width: 100%;
  max-width: 90%;
  height: 1px;
  margin: 8px auto;
  color: var(--gray400);
  background-color: var(--gray400);
  box-shadow: none;
  border: 0;

  @media screen and (max-width: 990px) {
    display: block;
  }
`;

export const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  width: 100%;
  max-width: calc(980px + 1rem);
  margin: 0 auto;
  margin-top: 25px;
  padding: 0 1rem;

  @media screen and (max-width: 990px) {
    grid-template-columns: 1fr;
    margin-top: 0.75rem;
  }
`;

export const ButtonFilter = styled.button`
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  border: 2px solid var(--orange600);
  border-radius: 20px;
  min-height: 40px;

  font-size: 1rem;
  font-weight: 700;
  color: var(--orange600);

  span {
    margin-right: 15px;

    svg {
      margin-top: 3px;

      g {
        g,
        line {
          stroke: var(--orange600);
          fill: var(--orange600);
        }
      }
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 25px;
      background-color: var(--orange600);
      color: var(--white);
      border-radius: 8px;
      font-size: 1rem;
      font-weight: 700;
      margin: 0;
    }
  }

  &:focus,
  &:hover {
    background-color: rgba(236, 103, 38, 0.08);
  }

  @media screen and (max-width: 990px) {
    display: flex;
  }
`;

export const ContentAside = styled.div`
  width: 230px;

  hr {
    display: block;
    max-width: 100%;
  }

  @media screen and (max-width: 990px) {
    width: 100%;
    height: 100%;
    display: none;
    flex-direction: column;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;

    background-color: var(--white);

    &.active {
      display: flex;
    }
  }
`;

export const ContentAsideHeader = styled.div`
  @media screen and (max-width: 990px) {
    border-bottom: 1px solid var(--gray400);
    padding: 1rem;
  }
`;

export const ContentAsideBody = styled.div`
  @media screen and (max-width: 990px) {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0.35rem 1rem;
  }
`;

export const ContentAsideFooter = styled.div`
  display: none;

  @media screen and (max-width: 990px) {
    width: 100%;
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 15px;
    padding: 1rem;
    border-top: 1px solid var(--gray400);
  }
`;

export const ButtonClear = styled.button`
  width: 100%;
  max-width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  background-color: transparent;
  color: var(--pink600);
  border: 2px solid var(--pink600);
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.2;
    &:focus,
    &:hover {
      background-color: transparent;
    }
  }

  &:focus,
  &:hover {
    background-color: rgba(229, 0, 118, 0.08);
  }
`;

export const ButtonSubmit = styled.button`
  width: 100%;
  max-width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  background-color: var(--pink600);
  color: var(--white);
  border: 2px solid var(--pink600);
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.2;

    &:focus,
    &:hover {
      background-color: var(--pink600);
      border-color: var(--pink600);
      opacity: 0.2;
    }
  }

  &:focus,
  &:hover {
    background-color: var(--pink500);
    border-color: var(--pink500);
  }
`;

export const TitleAside = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0;
  color: var(--purple600);

  @media screen and (max-width: 990px) {
    display: flex;
    align-items: center;

    span {
      flex-grow: 1;
      margin: 0 12px;
    }
  }
`;

export const ButtonClearFilters = styled.button`
  cursor: pointer;
  display: none;
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--purple600);
  text-transform: uppercase;
  background-color: transparent;
  border: 2px solid var(--gray400);
  border-radius: 20px;
  padding: 8px 18px;
  letter-spacing: 1px;
  transition: all 0.3s ease;

  &.acitve {
    display: block;
  }

  &:hover {
    background-color: var(--gray400);
  }

  @media screen and (max-width: 990px) {
    display: none !important;
  }
`;

export const SubtitleAside = styled.h3`
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--purple600);
  margin: 10px 0;
`;

export const ContentBody = styled.div`
  display: block;
  width: 100%;
  max-width: 100%;
`;

export const TilteContent = styled.h2`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.125rem;
  text-align: center;
  color: var(--gray600);
  text-align: center;
  margin: 0;
  margin-bottom: 20px;

  strong {
    font-weight: 700;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div<LoadingProps>`
  display: ${({ active }) => (active ? "flex" : "none")};
  align-items: center;
  flex-direction: column;

  span {
    width: 60px;
    height: 60px;
    border-radius: 60%;
    border: 6px solid #c9c8ea;
    border-top-color: #3a2e83;
    animation: ${rotate} 1s linear infinite;
  }
`;

export const Pagintaion = styled.div`
  .paginationBtns {
    padding: 0;
    margin: 0;
    list-style: none;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    margin-top: 1rem;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      height: auto;
      border-radius: 50%;
      border: 2px solid var(--gray400);
      background-color: transparent;
      margin-left: 0.45rem;
      transition: all 0.3s ease;

      &:first-of-type {
        margin-left: 0;
      }

      &:hover {
        background-color: rgba(218, 218, 218, 0.24);
      }

      a {
        position: relative;
        z-index: 1;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        margin-top: 2px;
        font-size: 1.25rem;
        line-height: 1;
        font-weight: 700;
      }

      &.paginationActive {
        border-color: var(--orange600);
        background-color: var(--orange600);
        color: var(--white);

        &:hover {
          background-color: var(--orange600);
        }
      }

      &.previousBtn,
      &.nextBtn {
        background-color: var(--gray400);

        &:hover {
          background-color: var(--gray400);
        }
      }

      &.previousBtn {
        display: block;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: 55%;
          top: 50%;
          display: block;
          width: 12px;
          height: 12px;
          border-top: 3px solid #bbbbbb;
          border-right: 3px solid #bbbbbb;
          border-radius: 4px;
          transform: translate(-50%, -50%) rotate(225deg);
        }
      }

      &.nextBtn {
        display: block;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: 45%;
          top: 50%;
          display: block;
          width: 12px;
          height: 12px;
          border-top: 3px solid #bbbbbb;
          border-right: 3px solid #bbbbbb;
          border-radius: 4px;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }

      &.paginationDisabled {
        opacity: 0.5;
      }
    }
  }
`;
