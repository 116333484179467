import { useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import { api } from "../../services";
import { Errors } from "../../pages/Identify/types";
import Input from "../input";
import { BtnLoginUser, FormItem, LoadingBtn } from "./styles";
import {
  ForgotPasswordFormComponentProps,
  FormForgottPassProps,
} from "./types";

const ForgotPasswordForm = ({
  tokenApi,
  onSuccessForgot,
  onClickFirstAccess,
  onClickLogin,
  initialData,
}: ForgotPasswordFormComponentProps) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [errorLogin, setErroLogin] = useState<string>("");
  const [succesLogin, setSuccessLogin] = useState<string>("");

  const handleForgot = (data: FormForgottPassProps) => {
    setLoading(true);
    api
      .get(`/wsCoobrastur/ConsultaHoteis.asmx/C62_1`, {
        params: {
          email: data.email,
          token: tokenApi,
        },
      })
      .then((resp) => {
        if (resp.status === 200) {
          if (resp.data[0].resultado === "0") {
            setErroLogin(resp.data[0].mensagem);
            setSuccessLogin("");
            setLoading(false);
            return;
          }

          if (resp.data[0].resultado === "1") {
            setErroLogin("");
            setSuccessLogin(resp.data[0].mensagem);
            setLoading(false);
            onSuccessForgot?.({
              email: data.email,
              message: resp.data[0].mensagem,
            });

            return;
          }
        }
      })
      .catch((err) => {
        console.error(err);
        setErroLogin(
          err?.mensagem || err.data.message || "ops, algo deu errado"
        );
        setLoading(false);
      });
  };
  const handleSubitforgottPass = async (data: FormForgottPassProps) => {
    try {
      const schema = Yup.object().shape({
        email: Yup.string().email("campo obrigatório"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await handleForgot(data);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessage: Errors = {};
        err.inner.forEach((error) => {
          if (error.path) {
            errorMessage[error.path] = error.message;
          }
          return formRef.current?.setErrors(errorMessage);
        });
        return;
      }
      formRef.current?.setErrors({});
    }
  };

  return (
    <>
      <div style={{ paddingLeft: "16px" }}>
        Insira o endereço de email associado à sua conta que lhe enviaremos um
        link de redefinição de senha.
      </div>

      <FormItem
        ref={formRef}
        onSubmit={handleSubitforgottPass}
        initialData={initialData}
      >
        <Input
          name="email"
          label="email"
          key="email"
          id="email"
          description="Insira seu-email"
          type="email"
        />

        {!!onClickLogin && (
          <div style={{ width: "100%" }}>
            <span style={{ color: "var(--purple500)" }}>
              Você já possui conta?
              <strong
                style={{ cursor: "pointer", color: "var(--pink500)" }}
                onClick={onClickLogin}
              >
                {" "}
                Entrar
              </strong>
            </span>
          </div>
        )}

        {!!onClickFirstAccess && (
          <div style={{ width: "100%" }}>
            <span style={{ color: "var(--purple600)" }}>
              Primeiro Acesso?
              <strong
                style={{ cursor: "pointer", color: "var(--pink500)" }}
                onClick={onClickFirstAccess}
              >
                {" "}
                Acesse aqui
              </strong>
            </span>
          </div>
        )}
        {succesLogin !== "" && (
          <span style={{ color: "var(--green600)" }}>{succesLogin}</span>
        )}
        {errorLogin !== "" && (
          <span style={{ color: "var(--red600)" }}>{errorLogin}</span>
        )}
        <BtnLoginUser type="submit" disabled={loading || !!succesLogin}>
          {loading ? <LoadingBtn /> : "redefinir senha"}
        </BtnLoginUser>
      </FormItem>
    </>
  );
};

export default ForgotPasswordForm;
