import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background: #4b48b7;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  opacity: 1;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30%;
  right: 30px;
  z-index: 2;
  cursor: pointer;
  border: 5px solid white;

  @media (max-width: 1024px) {
    right: 60px;
  }
  @media (max-width: 768px) {
    right: -30px;
  }

  @media (max-width: 500px) {
    right: 90px;
  }

  @media (max-width: 430px) {
    right: 50px;
  }

  @media (max-width: 400px) {
    right: 20px;
  }

  @media (max-width: 375px) {
    right: 10px;
  }

  @media (max-width: 360px) {
    right: 0px;
  }
`;
