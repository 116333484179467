import styled from "styled-components";

export const WrapperTitle = styled.div`
  width: 100%;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--purple600);
  font-size: 1rem;
  font-family: "Mangueira", "Poppins", sans-serif, -apple-system;
  text-transform: uppercase;
  color: var(--white);
  font-weight: bold;
  letter-spacing: 0.51px;
  h1 {
    margin-left: 8px;
  }

  @media (max-width: 500px) {
    padding: 0px 20px;

    h1 {
      font-size: 1.2rem;
      text-align: center;
    }
    img {
      width: 80px;
    }
  }

  @media (max-width: 400px) {
    padding: 0px 20px;
    font-size: 1rem;
  }
`;
