import styled, { keyframes } from "styled-components";

import iconClose from "../../assets/icones/close.svg";
import { CompassProps, LoadingProps } from "./types";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  max-height: 100%;
`;

export const ModalHeader = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 0.75rem;
  padding: 0.65rem 1rem;
  border-bottom: 1px solid rgba(218, 218, 218, 0.24);
`;

export const CloseModal = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    g {
      path {
        stroke: var(--purple600);
      }
    }
  }
`;

export const ModalTitle = styled.h3`
  font-size: 1rem;
  font-weight: 700;
  color: var(--purple600);
  margin: 0;
`;

export const ModalBody = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const InputSearch = styled.input`
  display: flex;
  width: 100%;
  max-width: 100%;
  min-height: 40px;
  border: 1px solid var(--gray400);
  outline: none;
  border-radius: 20px;
  padding: 0 1.25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  font-size: 1rem;
  font-weight: 700;

  &::placeholder {
    font-size: 1rem;
    font-weight: 400;
    color: var(--gray500);
  }

  &::-webkit-search-cancel-button {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background: url(${iconClose}) no-repeat 50% 50%;
    background-size: contain;
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  max-height: 100%;
`;

export const Compass = styled.div<CompassProps>`
  display: ${({ active }) => (active ? "flex" : "none")};
  align-items: center;
  flex-direction: column;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    g {
      rect,
      path {
        fill: var(--gray400);
        stroke: var(--gray400);
      }

      g {
        path {
          fill: var(--gray400);
          stroke: var(--gray400);
        }
      }
    }
  }

  span {
    font-size: 0.875rem;
    color: var(--gray400);
    margin-top: 0.25rem;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div<LoadingProps>`
  display: ${({ active }) => (active ? "flex" : "none")};
  align-items: center;
  flex-direction: column;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  span {
    width: 60px;
    height: 60px;
    border-radius: 60%;
    border: 6px solid #c9c8ea;
    border-top-color: #3a2e83;
    animation: ${rotate} 1s linear infinite;
  }
`;

export const Results = styled.ul`
  flex-grow: 1;
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ResultItem = styled.li`
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  align-self: start;
  gap: 0.75rem;
  width: 100%;
  max-width: 100%;
  border-bottom: 1px solid rgba(218, 218, 218, 0.24);
  padding-bottom: 0.75rem;

  & + & {
    margin-top: 0.75rem;
  }
`;

export const ResultTtilte = styled.p`
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0;
`;

export const ResultSubtitle = styled.p`
  font-size: 0.75rem;
  margin: 0;
`;
