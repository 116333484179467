import { CSSProperties, useEffect } from "react";
import BannerPagesInst from "../../components/BannerPagesInst";
import { content } from "./constants";
import IconToTop from "./assets/toTop.svg";
import {
  Container,
  ContentArea,
  Image,
  Paragraph,
  Title,
  TitleQUestion,
  WrapperItemQUestion,
  WrapperQuestion,
} from "./styles";

const Help = () => {
  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    handleGoToTop();
  }, []);

  const styleList = (listStyleType?: string): CSSProperties | undefined => {
    if (!listStyleType) return undefined;
    return { listStyleType };
  };
  return (
    <Container>
      <BannerPagesInst title="Central de relacionamento" />
      <ContentArea>
        <Title>PERGUNTAS FREQUENTES</Title>
        <WrapperQuestion>
          {content.map((item) => (
            <WrapperItemQUestion key={item.title}>
              <TitleQUestion>{item.title}</TitleQUestion>
              {item.paragraph && (
                <Paragraph
                  dangerouslySetInnerHTML={{ __html: item.paragraph }}
                />
              )}
              {item.paragraph2 && (
                <Paragraph
                  dangerouslySetInnerHTML={{ __html: item.paragraph2 }}
                />
              )}
              {item.paragraph3 && (
                <Paragraph
                  dangerouslySetInnerHTML={{ __html: item.paragraph3 }}
                />
              )}
              {item.paragraph4 && (
                <Paragraph
                  dangerouslySetInnerHTML={{ __html: item.paragraph4 }}
                />
              )}
              {item.paragraph5 && (
                <Paragraph
                  dangerouslySetInnerHTML={{ __html: item.paragraph5 }}
                />
              )}
              {item.paragraph6 && (
                <Paragraph
                  dangerouslySetInnerHTML={{ __html: item.paragraph6 }}
                />
              )}
              {item.paragraph7 && (
                <Paragraph
                  dangerouslySetInnerHTML={{ __html: item.paragraph7 }}
                />
              )}
              {item.paragraph8 && (
                <Paragraph
                  dangerouslySetInnerHTML={{ __html: item.paragraph8 }}
                />
              )}
              {item.paragraph9 && (
                <Paragraph
                  dangerouslySetInnerHTML={{ __html: item.paragraph9 }}
                />
              )}
              {item.paragraph10 && (
                <Paragraph
                  dangerouslySetInnerHTML={{ __html: item.paragraph10 }}
                />
              )}
              {item.paragraph11 && (
                <Paragraph
                  dangerouslySetInnerHTML={{ __html: item.paragraph11 }}
                />
              )}
              {item.image && <Image src={item.image} alt="banner" />}

              {item.list1 && (
                <ul>
                  <li style={styleList(item?.listStyle)}>{item.list1}</li>
                  {item.list2 && (
                    <li style={styleList(item?.listStyle)}>{item.list2}</li>
                  )}
                  {item.list3 && (
                    <li style={styleList(item?.listStyle)}>{item.list3}</li>
                  )}
                  {item.list4 && (
                    <li style={styleList(item?.listStyle)}>{item.list4}</li>
                  )}
                  {item.list5 && (
                    <li style={styleList(item?.listStyle)}>{item.list5}</li>
                  )}
                  {item.list6 && (
                    <li style={styleList(item?.listStyle)}>{item.list6}</li>
                  )}
                  {item.list7 && (
                    <li style={styleList(item?.listStyle)}>{item.list7}</li>
                  )}
                  {item.list8 && (
                    <li style={styleList(item?.listStyle)}>{item.list8}</li>
                  )}
                  {item.list9 && (
                    <li style={styleList(item?.listStyle)}>{item.list9}</li>
                  )}
                  {item.list10 && (
                    <li style={styleList(item?.listStyle)}>{item.list10}</li>
                  )}
                  {item.list11 && (
                    <li style={styleList(item?.listStyle)}>{item.list11}</li>
                  )}
                  {item.list12 && (
                    <li style={styleList(item?.listStyle)}>{item.list12}</li>
                  )}
                </ul>
              )}
              {item.imageAfterList && (
                <Image src={item.imageAfterList} alt="banner" width="100%" />
              )}
              {item.paragraphAfterImage && (
                <Paragraph>{item.paragraphAfterImage}</Paragraph>
              )}
              {item.imageAfterList2 && (
                <Image src={item.imageAfterList2} alt="banner" width="100%" />
              )}
              {item.paragraphAfterImage2 && (
                <Paragraph>{item.paragraphAfterImage2}</Paragraph>
              )}
            </WrapperItemQUestion>
          ))}
        </WrapperQuestion>
      </ContentArea>
      <button
        style={{
          position: "fixed",
          zIndex: 6,
          bottom: 15,
          width: 40,
          height: 40,
          right: 0,
          outline: 0,
          border: 0,
          backgroundColor: "var(--pink600)",
          borderRadius: 6,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={handleGoToTop}
      >
        <img src={IconToTop} alt="volta ao topo" width={25} />
      </button>
    </Container>
  );
};

export default Help;
