import Calendar from "react-calendar";
import prevDobleArrow from "../../assets/icones/icons minimal (20px)/seta esquerda dupla.svg";
import prevArrow from "../../assets/icones/icons minimal (20px)/seta_esquerda.svg";
import nextDobleArrow from "../../assets/icones/icons minimal (20px)/seta direita dupla.svg";
import nextvArrow from "../../assets/icones/icons minimal (20px)/seta_direita.svg";
import styled, { keyframes } from "styled-components";
import { ContainerWarnningProps, LoadingProps } from "./types";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  max-height: 100%;
`;

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid rgba(218, 218, 218, 0.25);

  @media screen and (max-width: 990px) {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: start;
  }
`;

export const ModalHeaderTilte = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--purple400);
  margin: 0;
`;

export const ButtonCloseModal = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: 0;
  padding: 0;
  margin-right: 15px;

  svg {
    display: block;
    width: 24px;
    height: 24px;
  }

  @media screen and (max-width: 990px) {
    margin-top: 4px;
  }
`;

export const ModalBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  padding: 0 1rem;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Content = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 50%);

  &::before {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background-color: rgba(218, 218, 218, 0.25);

    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }

  @media screen and (max-width: 990px) {
    grid-template-columns: 1fr;

    &::before {
      display: none;
    }
  }
`;

export const ContentInfos = styled.div`
  padding-right: 10px;

  @media screen and (max-width: 990px) {
    padding: 0;
  }
`;

export const ContainerDates = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  gap: 8px;

  div {
    padding: 0.5rem 0;
  }

  &::before {
    content: "";
    display: block;
    width: 2px;
    height: 48px;
    background-color: rgba(218, 218, 218, 0.25);

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media screen and (max-width: 990px) {
    &::after {
      content: "";
      display: block;
      width: calc(100% + 1rem);
      height: 1px;
      background-color: rgba(218, 218, 218, 0.25);

      position: absolute;
      left: -1rem;
      top: 100%;
    }
  }
`;

export const DateLabel = styled.p`
  font-size: 0.625rem;
  text-transform: uppercase;
  color: var(--gray600);
  margin: 0;
  margin-top: 8px;
`;

export const DateInput = styled.span`
  display: block;
  font-size: 1rem;
  font-weight: 700;
  color: var(--purple400);
`;

export const TextInfo = styled.p`
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin: 8px 0;

  @media screen and (max-width: 990px) {
    color: var(--black);
    font-weight: 400;
    margin: 0;
  }
`;

export const ContainerWarnning = styled.div<ContainerWarnningProps>`
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 3px;
  background-color: var(--orange200);
  border-radius: 8px;
  padding: 6px;
  opacity: ${({ open }) => (open ? "1" : "0")};
  visibility: ${({ open }) => (open ? "visibility" : "hidden")};
  transition: all 0.3s ease;

  svg {
    &:nth-of-type(1) {
      path {
        fill: var(--white);
      }
    }

    &:nth-of-type(2) {
      cursor: pointer;
      transform: translate(3px, -3px);
    }
  }

  @media screen and (max-width: 990px) {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99;
    transform: translate(-50%, -50%);
  }
`;

export const TextWarnning = styled.p`
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin: 0;
  color: var(--white);
`;

export const ButtonText = styled.button`
  cursor: pointer;
  display: inline-block;
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;

  font-size: 0.75rem;
  line-height: 0.875rem;
  text-align: left;
  text-decoration: underline;
  margin: 0;
  color: var(--white);
`;

export const ContentCalendar = styled.div<LoadingProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  span.spining {
    display: ${({ loading }) => (loading ? "flex" : "none")};
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 60%;
    border: 6px solid #c9c8ea;
    border-top-color: #3a2e83;
    animation: ${rotate} 1s linear infinite;
  }

  @media screen and (max-width: 990px) {
    padding: 0;
  }
`;

export const CustomCalendar = styled(Calendar)`
  border: 0;
  max-height: 285px;

  .react-calendar {
    display: block;
    margin: 0 auto;

    &__navigation {
      margin: 0;
      align-items: center;

      &__arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 28px;
        height: 28px;
        font-size: 1.8rem;
        font-weight: 700;
        color: var(--purple600);
        border-radius: 50%;
        padding: 5px;
        margin: 0;
      }

      &__prev2-button {
        content: url("${prevDobleArrow}");
      }

      &__prev-button {
        content: url("${prevArrow}");
      }

      &__label {
        &:enabled,
        &:focus,
        &:hover {
          background-color: transparent !important;
        }

        &__labelText {
          font-family: "Mangueira", "Poppins", sans-serif;
          font-size: 1rem;
          color: var(--purple600);
        }
      }

      &__next-button {
        content: url("${nextvArrow}");
      }

      &__next2-button {
        content: url("${nextDobleArrow}");
      }
    }

    &__viewContainer {
      .react-calendar__month-view {
        &__weekdays {
          margin-bottom: 2px;

          &__weekday {
            padding: 2px;
            overflow: inherit !important;

            abbr {
              position: relative;
              font-family: "Mangueira", "Poppins", sans-serif;
              font-size: 0.75rem;
              font-weight: 400;
              color: var(--black);
              text-decoration: none;

              &::before {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background-color: var(--black);

                position: absolute;
                left: 0;
                top: 100%;
              }
            }
          }
        }

        &__days {
          display: grid !important;
          grid-template-columns: repeat(7, 1fr);
          align-items: center;
          justify-items: center;
          gap: 4px;

          &__day {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: 30px;
            border-radius: 4px;
            background-color: var(--green600);
            padding: 0;

            :disabled {
              background-color: transparent;

              abbr {
                color: var(--gray400);
              }
            }

            abbr {
              font-family: "Mangueira", "Poppins", sans-serif;
              font-size: 1.125rem;
              font-weight: 700;
              color: var(--white);
              margin-top: 2px;
            }
          }
        }

        .react-calendar__tile {
          &--active {
            background-color: var(--purple600);
            abbr {
              color: var(--white);
            }
          }
        }
      }
    }
  }
`;

export const ModalFooter = styled.div`
  border-top: 1px solid var(--gray400);
  padding: 1rem;
`;

export const FooterMessage = styled.p`
  font-size: 1rem;
  text-align: center;
  color: var(--red500);
  margin: 0;
  margin-bottom: 12px;

  .success {
    color: var(--purple600);
    font-weight: 700;
  }
`;

export const ButtonSelectDate = styled.button`
  cursor: pointer;
  display: block;
  background-color: var(--pink600);
  border: none;
  color: var(--white);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  border-radius: 20px;
  width: 100%;
  max-width: 265px;
  min-height: 40px;
  margin: 0 auto;

  &:disabled {
    opacity: 0.15;

    &:hover {
      background-color: var(--pink600);
    }
  }

  &:hover {
    background-color: var(--pink500);
  }
`;

export const Loading = styled.div<LoadingProps>`
  display: ${({ loading }) => (loading ? "flex" : "none")};
  align-items: center;
  flex-direction: column;

  span {
    width: 60px;
    height: 60px;
    border-radius: 60%;
    border: 6px solid #c9c8ea;
    border-top-color: #3a2e83;
    animation: ${rotate} 1s linear infinite;
  }
`;
