import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  margin-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const AreaContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: 40px 20px;

  @media (max-width: 500px) {
    flex-direction: column-reverse;
    padding-top: 0px;
  }
`;
export const ImageWorks = styled.img`
  object-fit: cover;
  width: 100%;
  max-width: 470px;
  border-top-left-radius: 128px;
  border-top-right-radius: 32px;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 128px;
  height: 341px;

  @media (max-width: 500px) {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const ContentInfos = styled.div`
  width: 100%;
  max-width: 500px;
  height: auto;
  padding: 10px 20px;

  p {
    font-size: 1rem;
    font-weight: 400;
    color: var(--black);
    letter-spacing: 0.38px;
  }

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      text-align: center;
    }
    h4 {
      text-align: center;
    }
  }
`;

export const TitleContent = styled.h4`
  // width: 300px;
  margin: 0;
  font-size: 1.4rem;
  font-family: "Mangueira", "Poppins", sans-serif, -apple-system;
  text-transform: uppercase;
  color: var(--orange600);
  font-weight: bold;
`;

export const AreaBenefitys = styled.section`
  width: 100%;
  max-width: 850px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
