import styled from "styled-components";
export const Container = styled.div`
  width: 100%;
  height: auto;
  margin-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ContentArea = styled.section`
  width: 100%;
  max-width: 1200px;
  height: auto;
  background-color: var(--white);
  box-shadow: 0px 10px 20px #c3c3c3;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 16px;
  border-radius: 10px;
  margin-top: 20px;
`;

export const Image = styled.img`
  width: 100%;
  max-width: 600px;
`;

export const Title = styled.h3`
  color: var(--black);
  font-size: 1rem;
`;

export const WrapperQuestion = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const WrapperItemQUestion = styled.div`
  margin-top: 12px;

  ul li {
    list-style: none;
  }

  &:hover {
    color: var(--purple600);
    h4 {
      color: var(--purple600);
    }
  }
`;

export const TitleQUestion = styled.h4`
  margin: 0;
  font-size: 1.1rem;
  color: var(--black);
  font-weight: 500;
`;

export const Paragraph = styled.p`
  margin: 8px 0px;
  margin-left: 18px;
`;

export const Button = styled.a`
  width: 300px;
  max-width: 300px;
  height: 40px;
  background-color: #e50076;
  border-radius: 25px;
  font-size: 1rem;
  color: #fff;
  font-weight: 700;
  margin-top: 8px;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: #f489ba;
  }

  @media (max-width: 780px) {
    width: 100%;
    max-width: 250px;
  }
`;
