import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ListPromotionProps } from "../../pages/Home/types";
import { api } from "../../services";

import {
  BtnClose,
  BtnReserve,
  Content,
  Header,
  OverLay,
  WrapperImage,
} from "./styles";

interface ModalPromotionProps {
  item?: ListPromotionProps;
  onClose: () => void;
}

const ModalPromotion = ({ item, onClose }: ModalPromotionProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleRseserve = (item?: ListPromotionProps) => {
    const token = localStorage.getItem("token");
    setLoading(true);

    api
      .post(`wsCoobrastur/ConsultaHoteis.asmx/C20_1`, {
        token: token,
        consulta: item?.nome_hotel,
        iata: false,
      })
      .then((resp) => {
        setLoading(false);

        navigate(`${process.env.PUBLIC_URL}/hospedagens`, {
          state: {
            data: {
              Codigo: resp.data[0].Codigo,
              CodigoId: resp.data[0].CodigoID,
              Descricao: resp.data[0].Descricao,
              Tipo: resp.data[0].Tipo,
              UF: resp.data[0].UF,
              dateIn: "",
              dateOut: "",
              numRoom: 1,
              place: {
                Codigo: resp.data[0].Codigo,
                CodigoId: resp.data[0].CodigoID,
                Descricao: resp.data[0].Descricao,
                Tipo: resp.data[0].Tipo,
                UF: resp.data[0].UF,
                iata: resp.data[0].iata,
                pais: resp.data[0].pais,
                situcacao: resp.data[0].situacao,
              },
              rooms: [
                {
                  adducts: 2,
                  childrens: { children: [], number: 0 },
                  limit: 3,
                },
              ],
              visitant: 2,
            },
          },
        });
      });
  };

  return (
    <OverLay>
      <Content>
        <Header>{item?.nome_hotel}</Header>
        <WrapperImage>
          <img
            src={
              item?.nome_hotel === "RIO DAS GARÇAS ECO RESORT"
                ? `https://www.coobmais.com.br/images/hotel/${item?.hotCodigo}/02.jpg`
                : `https://www.coobmais.com.br/images/hotel/${item?.hotCodigo}/01.jpg`
            }
            alt="banner-hotel"
          />
        </WrapperImage>
        <div style={{ paddingTop: 10 }}>{item?.nome_oferta}</div>
        <BtnReserve onClick={() => handleRseserve(item)}>
          {loading ? "buscando promoção..." : "reservar agora"}
        </BtnReserve>
        <BtnClose onClick={onClose}>Fechar</BtnClose>
      </Content>
    </OverLay>
  );
};

export default ModalPromotion;
