import FacebookIcon from "./assets/Facebook.svg";
import InstagramIcon from "./assets/Instagram.svg";
import YoutubeIcon from "./assets/YouTube.svg";
import LinkedinIcon from "./assets/Linkedin.svg";
import SpotifyIcon from "./assets/Shopify.svg";
import BlogIcon from "./assets/Blog.svg";
import TiktokIcon from "./assets/Tiktok.svg";
export const IconSocial = [
  {
    icon: FacebookIcon,
    name: "facebook",
    id: 1,
    link: `https://www.facebook.com/coobmais`,
  },
  {
    icon: InstagramIcon,
    name: "instagram",
    id: 2,
    link: `https://www.instagram.com/coobmaisviagens/`,
  },
  {
    icon: YoutubeIcon,
    name: "youtube",
    id: 3,
    link: `https://www.youtube.com/channel/UC85XIUgu7cBSdtCwRpxhDkg`,
  },
  {
    icon: LinkedinIcon,
    name: "linkedin",
    id: 4,
    link: `https://www.linkedin.com/company/coobmaisviagens/`,
  },
  {
    icon: SpotifyIcon,
    name: "spotify",
    id: 5,
    link: `https://open.spotify.com/user/ych9gz1nmnidx0o6ei6npq7md?si=59d5b09ea50d4d2c&nd=1`,
  },
  {
    icon: BlogIcon,
    name: "blog",
    id: 6,
    link: `https://viajantecoobmais.com.br/`,
  },
  {
    icon: TiktokIcon,
    name: "tiktok",
    id: 7,
    link: `https://www.tiktok.com/@coobmais?lang=pt-BR`,
  },
];
