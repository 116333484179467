import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { Fragment, useEffect, useRef, useState } from "react";
import InputRadio from "../../components/InputRadio";
import { Options } from "../../components/InputRadio/types";
import Modal from "../../components/modal";
import { Message as MessageProps } from "../../components/modalFormConfirmation/types";
import { ModalFormConfirmation } from "../../components/modalFormConfirmation";
import { Steps } from "../../components/steps";
import { api } from "../../services";
import { useChoosePayment } from "../../store/useChoosePayment";
import { contentModalTerms } from "../Payments/constants";
import DetailsOfReserve from "../Payments/DetailsOfReserve";
import FormOtherPerson from "../Payments/Roons/FormOtherPerson";
import ChoosePaymentsInternational from "./ChoosePaymentsInternational";
import {
  validateFormChoosePaymentsInternational,
  DataPaymentProps,
} from "./ChoosePaymentsInternational/validateForm";
import {
  dataFormOtherPerson,
  fourRoonItem,
  oneRoonItem,
  optionsRadioInputQ1,
  threeRoonItem,
  twoRoonItem,
} from "./constants";
import FormPassenger, { getGender } from "./FormPassengers";
import {
  validadeFormPassengers,
  validadeHasChildren,
  idade,
  DataFormPassengersProps,
} from "./FormPassengers/validateForm";
import {
  BoxRoom,
  BtnKeep,
  Container,
  ItemCircle,
  LoadingBtn,
  PenaltyAmount,
  PolyticsArea,
  SectionPayment,
  Subtitle,
  Switcher,
  TextIndicate,
  TitleRoom,
  Wrapper,
  WrapperBtnReserve,
  WrapperContent,
  WrapperRadios,
  WrapperSelectRoom,
  WrapperSteps,
  WrappertextAndSwitcher,
  WrapperTextSteps,
} from "./styles";
import TicketsInternational from "./TicketsInternational";
import {
  RoonsProps,
  ExtraRoonsProps,
  ContactInformationPayload,
  PagamentoSHotelInternacional1Payload,
  pagamentosJsonPayload,
  reservaTempJson,
  TerceirosInfoPayload,
  TicketsProps,
  passengerPayload,
} from "./types";
import {
  FormOtherPersonProps,
  validadeFormOtherPerson,
} from "../Payments/Roons/FormOtherPerson/validateForm";
import { ModalAlert } from "../../components/modalAlert";
import { MessageAlertProps } from "../../components/modalAlert/types";

const IDS_FORMS = {
  FormRoom1: "FormRoom1",
  FormRoom2: "FormRoom2",
  FormRoom3: "FormRoom3",
  FormRoom4: "FormRoom4",
  FormOtherPerson: "FormOtherPerson",
  FormPayment: "FormPayment",
};
const getIdFormRoom = (room: number): string => {
  switch (room) {
    case 4:
      return IDS_FORMS.FormRoom4;
    case 3:
      return IDS_FORMS.FormRoom3;
    case 2:
      return IDS_FORMS.FormRoom2;
    default:
      return IDS_FORMS.FormRoom1;
  }
};

const PaymentsInternational = () => {
  const [openFormOtherPerson, setOpenFormOtherPerson] =
    useState<boolean>(false);
  const [activeSwitcherPayment, setActiveSwitcherPayment] =
    useState<boolean>(false);
  const [OpenModalTerms, setOpenModalTerms] = useState<boolean>(false);
  const [modalReload, setModalReload] = useState<boolean>(false);
  const [message, setMessage] = useState<MessageProps>({} as MessageProps);
  const [messageModalError, setMessageModalError] = useState<MessageAlertProps>(
    {} as MessageAlertProps
  );
  const [loadingReservation, setLoadingReservation] = useState<boolean>(false);
  const [totalValue, setTotalValue] = useState<number>(0);
  const [roons, setRoons] = useState<RoonsProps[]>([]);
  const [arrayPreOrderValues, setArrayPreOrderValues] = useState<number[]>([]);
  const namePayment = useChoosePayment((state) => state.name);
  const [roomInfoExtra, serRoomInfoExtra] = useState<ExtraRoonsProps[]>([]);
  const token = localStorage.getItem("token");
  const [assNic, setAssNic] = useState<number>();
  const [reservaTempJson, setReservaTemp] = useState<reservaTempJson>();
  const [hotels, setHotels] = useState<any[]>();
  const [ContactInformation, setContactInformation] =
    useState<ContactInformationPayload>();
  const [errorPayment, setErrorPayment] = useState<string>("");
  const [penaltyAmount, setPenatyAmount] = useState<string>("");
  const [errorSomeForm, setErrorSomeForm] = useState<string>("");
  const navigate = useNavigate();
  const dataInitial = localStorage.getItem("dataInicial");
  const dataFinal = localStorage.getItem("dataFinal");
  const formRef = useRef<FormHandles>(null);
  const formOtherPersonRef = useRef<FormHandles>(null);
  const formPassengerRefQ1 = useRef<FormHandles>(null);
  const formPassengerRefQ2 = useRef<FormHandles>(null);
  const formPassengerRefQ3 = useRef<FormHandles>(null);
  const formPassengerRefQ4 = useRef<FormHandles>(null);
  const totalPayments = totalValue;
  const [loadingTickets, setLoadingTickets] = useState(false);

  const errorCritical = useCallback((): boolean => {
    return (
      !reservaTempJson ||
      !hotels ||
      !ContactInformation ||
      !ContactInformation?.Name
    );
  }, [reservaTempJson, hotels, ContactInformation]);

  const handleCloseModalError = () => {
    setMessageModalError({} as MessageProps);
    if (errorCritical()) {
      window.location.reload();
    }
  };
  const handleOpenModal = () => {
    setOpenModalTerms((current) => !current);
  };
  const stripHTML = (html: string): string => {
    let doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const parseHTML = (string: string) => {
    const html: string = string.replaceAll("&lt;", "<").replaceAll("&gt;", ">");
    return html;
  };

  const handleToggleSwitcherPayment = () => {
    setActiveSwitcherPayment(!activeSwitcherPayment);
  };

  const handleCloseModalreload = () => {
    setModalReload(false);
    window.location.href = `${process.env.PUBLIC_URL}/`;
  };

  const handleOpenFormOtherPerson = (item: Options) => {
    const validId = ["outra pessoa"].includes(item.id);

    if (validId) {
      setOpenFormOtherPerson(true);
      return;
    }

    setOpenFormOtherPerson(false);
  };

  const updateTickets = async (tickets: TicketsProps[], nameRoom: string) => {
    let ticketsQ1: TicketsProps[] = [];
    let ticketsQ2: TicketsProps[] = [];
    let ticketsQ3: TicketsProps[] = [];
    let ticketsQ4: TicketsProps[] = [];
    let valuePayQ1: number = 0;
    let valuePayQ2: number = 0;
    let valuePayQ3: number = 0;
    let valuePayQ4: number = 0;

    // dados quarto 1
    const ticketsQ1Payload = roons[0].tickets
      ?.filter((ticket) => ticket.checked === true)
      .map((ticket) => ticket.tktCodigo);

    // dados quarto 2
    const ticketsQ2Payload = roons[1]?.tickets
      ?.filter((ticket) => ticket.checked === true)
      .map((ticket) => ticket.tktCodigo);

    // dados quarto 3
    const ticketsQ3Payload = roons[2]?.tickets
      ?.filter((ticket) => ticket.checked === true)
      .map((ticket) => ticket.tktCodigo);

    // dados quarto 4
    const ticketsQ4Payload = roons[3]?.tickets
      ?.filter((ticket) => ticket.checked === true)
      .map((ticket) => ticket.tktCodigo);

    const endpointTicketsInternacionais = `wsCoobrastur/Hotel.asmx/returnTicketsInternacionais_C`;
    if (nameRoom === "quarto 1") {
      const codeTktList = tickets.filter((item) => item.checked === true);
      let tktCode = codeTktList.map((item) => item.tktCodigo);

      let messageQ1 = "";
      let messageQ2 = "";
      let messageQ3 = "";
      let messageQ4 = "";

      setLoadingTickets(true);
      await api
        .get(endpointTicketsInternacionais, {
          params: {
            dados: JSON.stringify({
              t: "returnTicketsInternacionais_C",
              in_token: token,
              quarto: 1,
              resLocalizador: null,
              dataInicial: dataInitial ?? "",
              dataFim: dataFinal ?? "",
              valorTotal: arrayPreOrderValues[0],
              assNic: null,
              tktCodigos1: tktCode.join() ?? "",
              tktCodigos2: ticketsQ2Payload?.length
                ? ticketsQ2Payload.join()
                : "",
              tktCodigos3: ticketsQ3Payload?.length
                ? `${ticketsQ3Payload}`
                : "",
              tktCodigos4: ticketsQ4Payload?.length
                ? `${ticketsQ4Payload}`
                : "",
            }),
          },
        })
        .then(async (resp) => {
          if (resp.data.Table[0].situacao === 0) {
            setMessage({
              title: "Você ficou muito tempo sem navegar, refaça sua busca",
              body: resp.data.Table[0]?.Texto || "",
            });
            setModalReload(true);
            return;
          }
          if (resp.status === 200) {
            valuePayQ1 = resp.data.Table1[0].out_valor_Total;
            ticketsQ1 = resp.data.Table.map((ticket: TicketsProps) => {
              if (
                tktCode.includes(ticket.tktCodigo) &&
                ticket.Selecionado === 1
              ) {
                return { ...ticket, checked: true };
              }
              return { ...ticket, checked: false };
            });
            const hasTicket = ticketsQ1.filter(
              (ticket) => ticket.checked === true
            ).length;

            if (hasTicket === 0) {
              tktCode = [];
            }
            messageQ1 = resp.data.Table1[0].out_descricao || "";

            if (roons.length > 1) {
              await api
                .get(endpointTicketsInternacionais, {
                  params: {
                    dados: JSON.stringify({
                      t: "returnTicketsInternacionais_C",
                      in_token: token,
                      quarto: 2,
                      resLocalizador: null,
                      dataInicial: dataInitial,
                      dataFim: dataFinal,
                      valorTotal: arrayPreOrderValues[1],
                      assNic: null,
                      tktCodigos1: tktCode?.length ? tktCode.join() : "",
                      tktCodigos2: ticketsQ2Payload?.length
                        ? `${ticketsQ2Payload.join()}`
                        : "",
                      tktCodigos3: "",
                      tktCodigos4: "",
                    }),
                  },
                })
                .then((resp) => {
                  if (resp.data.Table[0].situacao === 0) {
                    setMessage({
                      title:
                        "Você ficou muito tempo sem navegar, refaça sua busca",
                      body: resp.data.Table[0]?.Texto || "",
                    });
                    setModalReload(true);
                    return;
                  }
                  valuePayQ2 = resp.data.Table1[0].out_valor_Total;
                  ticketsQ2 = resp.data.Table.map((ticket: TicketsProps) => {
                    if (
                      ticketsQ2Payload?.includes(ticket.tktCodigo) &&
                      ticket.Selecionado === 1
                    ) {
                      return { ...ticket, checked: true };
                    }
                    return { ...ticket, checked: false };
                  });

                  messageQ2 = resp.data.Table1[0].out_descricao || "";
                });
            }

            if (roons.length > 2) {
              await api
                .get(endpointTicketsInternacionais, {
                  params: {
                    dados: JSON.stringify({
                      t: "returnTicketsInternacionais_C",
                      in_token: token,
                      quarto: 3,
                      resLocalizador: null,
                      dataInicial: dataInitial,
                      dataFim: dataFinal,
                      valorTotal: arrayPreOrderValues[2],
                      assNic: null,
                      tktCodigos1: tktCode?.length ? tktCode.join() : "",
                      tktCodigos2: ticketsQ2Payload?.length
                        ? `${ticketsQ2Payload.join()}`
                        : "",
                      tktCodigos3: ticketsQ3Payload?.length
                        ? `${ticketsQ3Payload.join()}`
                        : "",
                      tktCodigos4: "",
                    }),
                  },
                })
                .then((resp) => {
                  if (resp.data.Table[0].situacao === 0) {
                    setMessage({
                      title:
                        "Você ficou muito tempo sem navegar, refaça sua busca",
                      body: resp.data.Table[0]?.Texto || "",
                    });
                    setModalReload(true);
                    return;
                  }
                  valuePayQ3 = resp.data.Table1[0].out_valor_Total;
                  ticketsQ3 = resp.data.Table.map((ticket: TicketsProps) => {
                    if (
                      ticketsQ3Payload?.includes(ticket.tktCodigo) &&
                      ticket.Selecionado === 1
                    ) {
                      return { ...ticket, checked: true };
                    }
                    return { ...ticket, checked: false };
                  });

                  messageQ3 = resp.data.Table1[0].out_descricao || "";
                });
            }

            if (roons.length > 3) {
              await api
                .get(endpointTicketsInternacionais, {
                  params: {
                    dados: JSON.stringify({
                      t: "returnTicketsInternacionais_C",
                      in_token: token,
                      quarto: 4,
                      resLocalizador: null,
                      dataInicial: dataInitial,
                      dataFim: dataFinal,
                      valorTotal: arrayPreOrderValues[3],
                      assNic: null,
                      tktCodigos1: tktCode?.length ? tktCode.join() : "",
                      tktCodigos2: ticketsQ2Payload?.length
                        ? `${ticketsQ2Payload.join()}`
                        : "",
                      tktCodigos3: ticketsQ3Payload?.length
                        ? `${ticketsQ3Payload.join()}`
                        : "",
                      tktCodigos4: ticketsQ4Payload?.length
                        ? `${ticketsQ4Payload.join()}`
                        : "",
                    }),
                  },
                })
                .then((resp) => {
                  if (resp.data.Table[0].situacao === 0) {
                    setMessage({
                      title:
                        "Você ficou muito tempo sem navegar, refaça sua busca",
                      body: resp.data.Table[0]?.Texto || "",
                    });
                    setModalReload(true);
                    return;
                  }
                  valuePayQ4 = resp.data.Table1[0].out_valor_Total;
                  ticketsQ4 = resp.data.Table.map((ticket: TicketsProps) => {
                    if (
                      ticketsQ4Payload?.includes(ticket.tktCodigo) &&
                      ticket.Selecionado === 1
                    ) {
                      return { ...ticket, checked: true };
                    }
                    return { ...ticket, checked: false };
                  });

                  messageQ4 = resp.data.Table1[0].out_descricao || "";
                });
            }
            const roonClone = [...roons];

            const newRoon = roonClone.map((item) => {
              if (item.room === "quarto 1") {
                return {
                  ...item,
                  tickets: ticketsQ1,
                  message: messageQ1,
                };
              }

              if (item.room === "quarto 2") {
                return {
                  ...item,
                  tickets: ticketsQ2,
                  message: messageQ2,
                };
              }

              if (item.room === "quarto 3") {
                return {
                  ...item,
                  tickets: ticketsQ3,
                  message: messageQ3,
                };
              }

              if (item.room === "quarto 4") {
                return {
                  ...item,
                  tickets: ticketsQ4,
                  message: messageQ4,
                };
              }

              return item;
            });

            setRoons(newRoon);
            // setTotalValueOfRoom(
            //   valuePayQ2 + valuePayQ1 + valuePayQ3 + valuePayQ4
            // );
            setTotalValue(valuePayQ2 + valuePayQ1 + valuePayQ3 + valuePayQ4);
            return;
          }
        });
      setLoadingTickets(false);
    }

    if (nameRoom === "quarto 2") {
      const codeTktList = tickets.filter((item) => item.checked === true);
      let tktCode2 = codeTktList.map((item) => item.tktCodigo);
      let messageQ1 = "";
      let messageQ2 = "";
      let messageQ3 = "";
      let messageQ4 = "";

      setLoadingTickets(true);
      await api
        .get(endpointTicketsInternacionais, {
          params: {
            dados: JSON.stringify({
              t: "returnTicketsInternacionais_C",
              in_token: token,
              quarto: 1,
              resLocalizador: null,
              dataInicial: dataInitial ?? "",
              dataFim: dataFinal ?? "",
              valorTotal: arrayPreOrderValues[0],
              assNic: null,
              tktCodigos1: ticketsQ1Payload?.length
                ? `${ticketsQ1Payload}`
                : "",
              tktCodigos2: tktCode2.join(),
              tktCodigos3: ticketsQ3Payload?.length
                ? `${ticketsQ3Payload}`
                : "",
              tktCodigos4: ticketsQ4Payload?.length
                ? `${ticketsQ4Payload}`
                : "",
            }),
          },
        })
        .then(async (resp) => {
          if (resp.data.Table[0].situacao === 0) {
            setMessage({
              title: "Você ficou muito tempo sem navegar, refaça sua busca",
              body: resp.data.Table[0]?.Texto || "",
            });
            setModalReload(true);
            return;
          }
          if (resp.status === 200) {
            valuePayQ1 = resp.data.Table1[0].out_valor_Total;
            ticketsQ1 = resp.data.Table.map((ticket: TicketsProps) => {
              if (
                ticketsQ1Payload?.includes(ticket.tktCodigo) &&
                ticket.Selecionado === 1
              ) {
                return { ...ticket, checked: true };
              }
              return { ...ticket, checked: false };
            });

            messageQ1 = resp.data.Table1[0].out_descricao || "";

            await api
              .get(endpointTicketsInternacionais, {
                params: {
                  dados: JSON.stringify({
                    t: "returnTicketsInternacionais_C",
                    in_token: token,
                    quarto: 2,
                    resLocalizador: null,
                    dataInicial: dataInitial ?? "",
                    dataFim: dataFinal ?? "",
                    valorTotal: arrayPreOrderValues[1],
                    assNic: null,
                    tktCodigos1: ticketsQ1Payload?.length
                      ? `${ticketsQ1Payload}`
                      : "",
                    tktCodigos2: tktCode2.join(),
                    tktCodigos3: ticketsQ3Payload?.length
                      ? `${ticketsQ3Payload}`
                      : "",
                    tktCodigos4: ticketsQ4Payload?.length
                      ? `${ticketsQ4Payload}`
                      : "",
                  }),
                },
              })
              .then(async (resp) => {
                if (resp.data.Table[0].situacao === 0) {
                  setMessage({
                    title:
                      "Você ficou muito tempo sem navegar, refaça sua busca",
                    body: resp.data.Table[0]?.Texto || "",
                  });
                  setModalReload(true);
                  return;
                }
                valuePayQ2 = resp.data.Table1[0].out_valor_Total;
                ticketsQ2 = resp.data.Table.map((ticket: TicketsProps) => {
                  if (
                    tktCode2.includes(ticket.tktCodigo) &&
                    ticket.Selecionado === 1
                  ) {
                    return { ...ticket, checked: true };
                  }
                  return { ...ticket, checked: false };
                });

                messageQ2 = resp.data.Table1[0].out_descricao || "";

                const hasTicket = ticketsQ2.filter(
                  (ticket) => ticket.checked === true
                ).length;

                if (hasTicket === 0) {
                  tktCode2 = [];
                }

                if (roons.length > 2) {
                  await api
                    .get(endpointTicketsInternacionais, {
                      params: {
                        dados: JSON.stringify({
                          t: "returnTicketsInternacionais_C",
                          in_token: token,
                          quarto: 3,
                          resLocalizador: null,
                          dataInicial: dataInitial ?? "",
                          dataFim: dataFinal ?? "",
                          valorTotal: arrayPreOrderValues[2],
                          assNic: null,
                          tktCodigos1: ticketsQ1Payload?.length
                            ? `${ticketsQ1Payload.join()}`
                            : "",
                          tktCodigos2: tktCode2.join(),
                          tktCodigos3: ticketsQ3Payload?.length
                            ? `${ticketsQ3Payload}`
                            : "",
                          tktCodigos4: ticketsQ4Payload?.length
                            ? `${ticketsQ4Payload}`
                            : "",
                        }),
                      },
                    })
                    .then(async (resp) => {
                      if (resp.data.Table[0].situacao === 0) {
                        setMessage({
                          title:
                            "Você ficou muito tempo sem navegar, refaça sua busca",
                          body: resp.data.Table[0]?.Texto || "",
                        });
                        setModalReload(true);
                        return;
                      }
                      valuePayQ3 = resp.data.Table1[0].out_valor_Total;
                      ticketsQ3 = resp.data.Table.map(
                        (ticket: TicketsProps) => {
                          if (
                            ticketsQ3Payload?.includes(ticket.tktCodigo) &&
                            ticket.Selecionado === 1
                          ) {
                            return { ...ticket, checked: true };
                          }
                          return { ...ticket, checked: false };
                        }
                      );

                      messageQ3 = resp.data.Table1[0].out_descricao || "";
                    });
                }

                if (roons.length > 3) {
                  await api
                    .get(endpointTicketsInternacionais, {
                      params: {
                        dados: JSON.stringify({
                          t: "returnTicketsInternacionais_C",
                          in_token: token,
                          quarto: 4,
                          resLocalizador: null,
                          dataInicial: dataInitial ?? "",
                          dataFim: dataFinal ?? "",
                          valorTotal: arrayPreOrderValues[3],
                          assNic: null,
                          tktCodigos1: ticketsQ1Payload?.length
                            ? `${ticketsQ1Payload.join()}`
                            : "",
                          tktCodigos2: tktCode2.join(),
                          tktCodigos3: ticketsQ3Payload?.length
                            ? `${ticketsQ3Payload}`
                            : "",
                          tktCodigos4: ticketsQ4Payload?.length
                            ? `${ticketsQ4Payload}`
                            : "",
                        }),
                      },
                    })
                    .then(async (resp) => {
                      if (resp.data.Table[0].situacao === 0) {
                        setMessage({
                          title:
                            "Você ficou muito tempo sem navegar, refaça sua busca",
                          body: resp.data.Table[0]?.Texto || "",
                        });
                        setModalReload(true);
                        return;
                      }
                      valuePayQ4 = resp.data.Table1[0].out_valor_Total;
                      ticketsQ4 = resp.data.Table.map(
                        (ticket: TicketsProps) => {
                          if (
                            ticketsQ4Payload?.includes(ticket.tktCodigo) &&
                            ticket.Selecionado === 1
                          ) {
                            return { ...ticket, checked: true };
                          }
                          return { ...ticket, checked: false };
                        }
                      );

                      messageQ4 = resp.data.Table1[0].out_descricao || "";
                    });
                }
              });
            const roonClone = [...roons];
            const newRoon = roonClone.map((item) => {
              if (item.room === "quarto 1") {
                return {
                  ...item,
                  tickets: ticketsQ1,
                  message: messageQ1,
                };
              }

              if (item.room === "quarto 2") {
                return {
                  ...item,
                  tickets: ticketsQ2,
                  message: messageQ2,
                };
              }

              if (item.room === "quarto 3") {
                return {
                  ...item,
                  tickets: ticketsQ3,
                  message: messageQ3,
                };
              }

              if (item.room === "quarto 4") {
                return {
                  ...item,
                  tickets: ticketsQ4,
                  message: messageQ4,
                };
              }
              return item;
            });
            setRoons(newRoon);
            // setTotalValueOfRoom(
            //   valuePayQ2 + valuePayQ1 + valuePayQ3 + valuePayQ4
            // );
            setTotalValue(valuePayQ2 + valuePayQ1 + valuePayQ3 + valuePayQ4);
            return;
          }
        });
      setLoadingTickets(false);
    }

    if (nameRoom === "quarto 3") {
      const codeTktList = tickets.filter((item) => item.checked === true);
      const tktCode3 = codeTktList.map((item) => item.tktCodigo);
      let messageQ1 = "";
      let messageQ2 = "";
      let messageQ3 = "";
      let messageQ4 = "";

      setLoadingTickets(true);
      await api
        .get(endpointTicketsInternacionais, {
          params: {
            dados: JSON.stringify({
              t: "returnTicketsInternacionais_C",
              in_token: token,
              quarto: 1,
              resLocalizador: null,
              dataInicial: dataInitial ?? "",
              dataFim: dataFinal ?? "",
              valorTotal: arrayPreOrderValues[0],
              assNic: null,
              tktCodigos1: ticketsQ1Payload?.length
                ? `${ticketsQ1Payload}`
                : "",
              tktCodigos2: ticketsQ2Payload?.length
                ? `${ticketsQ2Payload}`
                : "",
              tktCodigos3: tktCode3.join(),
              tktCodigos4: ticketsQ4Payload?.length
                ? `${ticketsQ4Payload}`
                : "",
            }),
          },
        })
        .then(async (resp) => {
          if (resp.data.Table[0].situacao === 0) {
            setMessage({
              title: "Você ficou muito tempo sem navegar, refaça sua busca",
              body: resp.data.Table[0]?.Texto || "",
            });
            setModalReload(true);
            return;
          }
          if (resp.status === 200) {
            valuePayQ1 = resp.data.Table1[0].out_valor_Total;
            ticketsQ1 = resp.data.Table.map((ticket: TicketsProps) => {
              if (
                ticketsQ1Payload?.includes(ticket.tktCodigo) &&
                ticket.Selecionado === 1
              ) {
                return { ...ticket, checked: true };
              }
              return { ...ticket, checked: false };
            });

            messageQ1 = resp.data.Table1[0].out_descricao || "";

            await api
              .get(endpointTicketsInternacionais, {
                params: {
                  dados: JSON.stringify({
                    t: "returnTicketsInternacionais_C",
                    in_token: token,
                    quarto: 2,
                    resLocalizador: null,
                    dataInicial: dataInitial ?? "",
                    dataFim: dataFinal ?? "",
                    valorTotal: arrayPreOrderValues[1],
                    assNic: null,
                    tktCodigos1: ticketsQ1Payload?.length
                      ? `${ticketsQ1Payload}`
                      : "",
                    tktCodigos2: ticketsQ2Payload?.length
                      ? `${ticketsQ2Payload}`
                      : "",
                    tktCodigos3: tktCode3.join(),
                    tktCodigos4: ticketsQ4Payload?.length
                      ? `${ticketsQ4Payload}`
                      : "",
                  }),
                },
              })
              .then(async (resp) => {
                if (resp.data.Table[0].situacao === 0) {
                  setMessage({
                    title:
                      "Você ficou muito tempo sem navegar, refaça sua busca",
                    body: resp.data.Table[0]?.Texto || "",
                  });
                  setModalReload(true);
                  return;
                }
                valuePayQ2 = resp.data.Table1[0].out_valor_Total;
                ticketsQ2 = resp.data.Table.map((ticket: TicketsProps) => {
                  if (
                    ticketsQ2Payload?.includes(ticket.tktCodigo) &&
                    ticket.Selecionado === 1
                  ) {
                    return { ...ticket, checked: true };
                  }
                  return { ...ticket, checked: false };
                });

                messageQ2 = resp.data.Table1[0].out_descricao || "";

                if (roons.length > 2) {
                  await api
                    .get(endpointTicketsInternacionais, {
                      params: {
                        dados: JSON.stringify({
                          t: "returnTicketsInternacionais_C",
                          in_token: token,
                          quarto: 3,
                          resLocalizador: null,
                          dataInicial: dataInitial ?? "",
                          dataFim: dataFinal ?? "",
                          valorTotal: arrayPreOrderValues[2],
                          assNic: null,
                          tktCodigos1: ticketsQ1Payload?.length
                            ? `${ticketsQ1Payload}`
                            : "",
                          tktCodigos2: ticketsQ2Payload?.length
                            ? `${ticketsQ2Payload}`
                            : "",
                          tktCodigos3: tktCode3.join(),
                          tktCodigos4: ticketsQ4Payload?.length
                            ? `${ticketsQ4Payload}`
                            : "",
                        }),
                      },
                    })
                    .then(async (resp) => {
                      if (resp.data.Table[0].situacao === 0) {
                        setMessage({
                          title:
                            "Você ficou muito tempo sem navegar, refaça sua busca",
                          body: resp.data.Table[0]?.Texto || "",
                        });
                        setModalReload(true);
                        return;
                      }
                      valuePayQ3 = resp.data.Table1[0].out_valor_Total;
                      ticketsQ3 = resp.data.Table.map(
                        (ticket: TicketsProps) => {
                          if (
                            tktCode3.includes(ticket.tktCodigo) &&
                            ticket.Selecionado === 1
                          ) {
                            return { ...ticket, checked: true };
                          }
                          return { ...ticket, checked: false };
                        }
                      );

                      messageQ3 = resp.data.Table1[0].out_descricao || "";
                      // const hasTicket = ticketsQ3.filter(
                      //   (ticket) => ticket.checked === true
                      // ).length;

                      // if (hasTicket === 0) {
                      //   ticketsQ3 = [];
                      // }
                    });
                }

                if (roons.length > 3) {
                  await api
                    .get(endpointTicketsInternacionais, {
                      params: {
                        dados: JSON.stringify({
                          t: "returnTicketsInternacionais_C",
                          in_token: token,
                          quarto: 4,
                          resLocalizador: null,
                          dataInicial: dataInitial ?? "",
                          dataFim: dataFinal ?? "",
                          valorTotal: arrayPreOrderValues[3],
                          assNic: null,
                          tktCodigos1: ticketsQ1Payload?.length
                            ? `${ticketsQ1Payload}`
                            : "",
                          tktCodigos2: ticketsQ2Payload?.length
                            ? `${ticketsQ2Payload}`
                            : "",
                          tktCodigos3: tktCode3.join(),
                          tktCodigos4: ticketsQ4Payload?.length
                            ? `${ticketsQ4Payload}`
                            : "",
                        }),
                      },
                    })
                    .then((resp) => {
                      if (resp.data.Table[0].situacao === 0) {
                        setMessage({
                          title:
                            "Você ficou muito tempo sem navegar, refaça sua busca",
                          body: resp.data.Table[0]?.Texto || "",
                        });
                        setModalReload(true);
                        return;
                      }
                      valuePayQ4 = resp.data.Table1[0].out_valor_Total;
                      ticketsQ4 = resp.data.Table.map(
                        (ticket: TicketsProps) => {
                          if (
                            ticketsQ4Payload?.includes(ticket.tktCodigo) &&
                            ticket.Selecionado === 1
                          ) {
                            return { ...ticket, checked: true };
                          }
                          return { ...ticket, checked: false };
                        }
                      );

                      messageQ4 = resp.data.Table1[0].out_descricao || "";
                    });
                }
              });
            const roonClone = [...roons];
            const newRoon = roonClone.map((item) => {
              if (item.room === "quarto 1") {
                return {
                  ...item,
                  tickets: ticketsQ1,
                  message: messageQ1,
                };
              }

              if (item.room === "quarto 2") {
                return {
                  ...item,
                  tickets: ticketsQ2,
                  message: messageQ2,
                };
              }

              if (item.room === "quarto 3") {
                return {
                  ...item,
                  tickets: ticketsQ3,
                  message: messageQ3,
                };
              }

              if (item.room === "quarto 4") {
                return {
                  ...item,
                  tickets: ticketsQ4,
                  message: messageQ4,
                };
              }
              return item;
            });

            setRoons(newRoon);
            // setTotalValueOfRoom(
            //   valuePayQ2 + valuePayQ1 + valuePayQ3 + valuePayQ4
            // );
            setTotalValue(valuePayQ2 + valuePayQ1 + valuePayQ3 + valuePayQ4);

            return;
          }
        });
      setLoadingTickets(false);
    }

    if (nameRoom === "quarto 4") {
      const codeTktList = tickets.filter((item) => item.checked === true);
      const tktCode4 = codeTktList.map((item) => item.tktCodigo);
      let messageQ1 = "";
      let messageQ2 = "";
      let messageQ3 = "";
      let messageQ4 = "";

      setLoadingTickets(true);
      await api
        .get(endpointTicketsInternacionais, {
          params: {
            dados: JSON.stringify({
              t: "returnTicketsInternacionais_C",
              in_token: token,
              quarto: 1,
              resLocalizador: null,
              dataInicial: dataInitial ?? "",
              dataFim: dataFinal ?? "",
              valorTotal: arrayPreOrderValues[0],
              assNic: null,
              tktCodigos1: ticketsQ1Payload?.length
                ? `${ticketsQ1Payload}`
                : "",
              tktCodigos2: ticketsQ2Payload?.length
                ? `${ticketsQ2Payload}`
                : "",
              tktCodigos3: ticketsQ3Payload?.length
                ? `${ticketsQ3Payload}`
                : "",
              tktCodigos4: tktCode4.join(),
            }),
          },
        })
        .then(async (resp) => {
          if (resp.data.Table[0].situacao === 0) {
            setMessage({
              title: "Você ficou muito tempo sem navegar, refaça sua busca",
              body: resp.data.Table[0]?.Texto || "",
            });
            setModalReload(true);
            return;
          }
          if (resp.status === 200) {
            valuePayQ1 = resp.data.Table1[0].out_valor_Total;
            ticketsQ1 = resp.data.Table.map((ticket: TicketsProps) => {
              if (
                ticketsQ1Payload?.includes(ticket.tktCodigo) &&
                ticket.Selecionado === 1
              ) {
                return { ...ticket, checked: true };
              }
              return { ...ticket, checked: false };
            });

            messageQ1 = resp.data.Table1[0].out_descricao || "";
            await api
              .get(endpointTicketsInternacionais, {
                params: {
                  dados: JSON.stringify({
                    t: "returnTicketsInternacionais_C",
                    in_token: token,
                    quarto: 2,
                    resLocalizador: null,
                    dataInicial: dataInitial ?? "",
                    dataFim: dataFinal ?? "",
                    valorTotal: arrayPreOrderValues[1],
                    assNic: null,
                    tktCodigos1: ticketsQ1Payload?.length
                      ? `${ticketsQ1Payload}`
                      : "",
                    tktCodigos2: ticketsQ2Payload?.length
                      ? `${ticketsQ2Payload}`
                      : "",
                    tktCodigos3: ticketsQ3Payload?.length
                      ? `${ticketsQ3Payload}`
                      : "",
                    tktCodigos4: tktCode4.join(),
                  }),
                },
              })
              .then(async (resp) => {
                if (resp.data.Table[0].situacao === 0) {
                  setMessage({
                    title:
                      "Você ficou muito tempo sem navegar, refaça sua busca",
                    body: resp.data.Table[0]?.Texto || "",
                  });
                  setModalReload(true);
                  return;
                }
                valuePayQ2 = resp.data.Table1[0].out_valor_Total;
                ticketsQ2 = resp.data.Table.map((ticket: TicketsProps) => {
                  if (
                    ticketsQ2Payload?.includes(ticket.tktCodigo) &&
                    ticket.Selecionado === 1
                  ) {
                    return { ...ticket, checked: true };
                  }
                  return { ...ticket, checked: false };
                });
                messageQ2 = resp.data.Table1[0].out_descricao || "";

                if (roons.length > 2) {
                  await api
                    .get(endpointTicketsInternacionais, {
                      params: {
                        dados: JSON.stringify({
                          t: "returnTicketsInternacionais_C",
                          in_token: token,
                          quarto: 3,
                          resLocalizador: null,
                          dataInicial: dataInitial ?? "",
                          dataFim: dataFinal ?? "",
                          valorTotal: arrayPreOrderValues[2],
                          assNic: null,
                          tktCodigos1: ticketsQ1Payload?.length
                            ? `${ticketsQ1Payload}`
                            : "",
                          tktCodigos2: ticketsQ2Payload?.length
                            ? `${ticketsQ2Payload}`
                            : "",
                          tktCodigos3: ticketsQ3Payload?.length
                            ? `${ticketsQ3Payload}`
                            : "",
                          tktCodigos4: tktCode4.join(),
                        }),
                      },
                    })
                    .then(async (resp) => {
                      if (resp.data.Table[0].situacao === 0) {
                        setMessage({
                          title:
                            "Você ficou muito tempo sem navegar, refaça sua busca",
                          body: resp.data.Table[0]?.Texto || "",
                        });
                        setModalReload(true);
                        return;
                      }
                      valuePayQ3 = resp.data.Table1[0].out_valor_Total;
                      ticketsQ3 = resp.data.Table.map(
                        (ticket: TicketsProps) => {
                          if (
                            ticketsQ3Payload?.includes(ticket.tktCodigo) &&
                            ticket.Selecionado === 1
                          ) {
                            return { ...ticket, checked: true };
                          }
                          return { ...ticket, checked: false };
                        }
                      );

                      messageQ3 = resp.data.Table1[0].out_descricao || "";

                      if (roons.length > 3) {
                        await api
                          .get(endpointTicketsInternacionais, {
                            params: {
                              dados: JSON.stringify({
                                t: "returnTicketsInternacionais_C",
                                in_token: token,
                                quarto: 4,
                                resLocalizador: null,
                                dataInicial: dataInitial ?? "",
                                dataFim: dataFinal ?? "",
                                valorTotal: arrayPreOrderValues[3],
                                assNic: null,
                                tktCodigos1: ticketsQ1Payload?.length
                                  ? `${ticketsQ1Payload}`
                                  : "",
                                tktCodigos2: ticketsQ2Payload?.length
                                  ? `${ticketsQ2Payload}`
                                  : "",
                                tktCodigos3: ticketsQ3Payload?.length
                                  ? `${ticketsQ3Payload}`
                                  : "",
                                tktCodigos4: tktCode4.join(),
                              }),
                            },
                          })
                          .then((resp) => {
                            if (resp.data.Table[0].situacao === 0) {
                              setMessage({
                                title:
                                  "Você ficou muito tempo sem navegar, refaça sua busca",
                                body: resp.data.Table[0]?.Texto || "",
                              });
                              setModalReload(true);
                              return;
                            }
                            valuePayQ4 = resp.data.Table1[0].out_valor_Total;
                            ticketsQ4 = resp.data.Table.map(
                              (ticket: TicketsProps) => {
                                if (
                                  tktCode4?.includes(ticket.tktCodigo) &&
                                  ticket.Selecionado === 1
                                ) {
                                  return { ...ticket, checked: true };
                                }
                                return { ...ticket, checked: false };
                              }
                            );

                            // const hasTicket = ticketsQ4.filter(
                            //   (ticket) => ticket.checked === true
                            // ).length;

                            // if (hasTicket === 0) {
                            //   ticketsQ4 = [];
                            // }

                            messageQ4 = resp.data.Table1[0].out_descricao || "";
                          });
                      }
                    });
                }
              });
            const roonClone = [...roons];
            const newRoon = roonClone.map((item) => {
              if (item.room === "quarto 1") {
                return {
                  ...item,
                  tickets: ticketsQ1,
                  message: messageQ1,
                };
              }

              if (item.room === "quarto 2") {
                return {
                  ...item,
                  tickets: ticketsQ2,
                  message: messageQ2,
                };
              }

              if (item.room === "quarto 3") {
                return {
                  ...item,
                  tickets: ticketsQ3,
                  message: messageQ3,
                };
              }

              if (item.room === "quarto 4") {
                return {
                  ...item,
                  tickets: ticketsQ4,
                  message: messageQ4,
                };
              }
              return item;
            });

            setRoons(newRoon);
            // setTotalValueOfRoom(
            //   valuePayQ2 + valuePayQ1 + valuePayQ3 + valuePayQ4
            // );
            setTotalValue(valuePayQ2 + valuePayQ1 + valuePayQ3 + valuePayQ4);

            return;
          }
        });
      setLoadingTickets(false);
    }
  };

  const getTickets = useCallback(
    async (
      numberRoom: number,
      quantityRoons: number,
      preOrderValueRoom: number
    ) => {
      let q1: TicketsProps[] = [];
      let q2: TicketsProps[] = [];
      let q3: TicketsProps[] = [];
      let q4: TicketsProps[] = [];
      let valueQ1 = 0;
      let valueQ2 = 0;
      let valueQ3 = 0;
      let valueQ4 = 0;
      let messageQ1 = "";
      let messageQ2 = "";
      let messageQ3 = "";
      let messageQ4 = "";
      // const returnTicketPayload = {
      //   t: "returnTicketsInternacionais_C",
      //   in_token: token,
      //   quarto: String(numberRoom),
      //   resLocalizador: null,
      //   dataInicial: dataInitial,
      //   dataFim: dataFinal,
      //   valorTotal: preOrderValueRoom,
      //   assNic: null,
      // };

      const endpointTicketsInternacionais = `wsCoobrastur/Hotel.asmx/returnTicketsInternacionais_C`;

      if (quantityRoons === 1) {
        setLoadingTickets(true);
        await api
          .get(endpointTicketsInternacionais, {
            params: {
              dados: JSON.stringify({
                t: "returnTicketsInternacionais_C",
                in_token: token,
                quarto: 1,
                resLocalizador: null,
                dataInicial: dataInitial,
                dataFim: dataFinal,
                valorTotal: preOrderValueRoom,
                assNic: null,
                tktCodigos1: null,
                tktCodigos2: null,
                tktCodigos3: null,
                tktCodigos4: null,
              }),
            },
          })
          .then((resp) => {
            if (resp.data.Table[0].situacao === 0) {
              q1 = [];
              setMessage({
                title: "Você ficou muito tempo sem navegar, refaça sua busca",
                body: resp.data.Table[0]?.Texto || "",
              });
              setModalReload(true);
              return;
            }
            valueQ1 = resp.data.Table1[0].out_valor_Total;

            q1 = resp.data.Table.map((ticket: any) => ({
              ...ticket,
              checked: false,
            }));

            messageQ1 = resp.data.Table1[0].out_descricao || "";

            setTotalValue(valueQ1);
          });
        setLoadingTickets(false);
        return {
          q1: q1,
          totalPay: valueQ1,
          messageQ1,
          messageQ2,
          messageQ3,
          messageQ4,
        };
      }

      if (quantityRoons === 2) {
        setLoadingTickets(true);
        await api
          .get(endpointTicketsInternacionais, {
            params: {
              dados: JSON.stringify({
                t: "returnTicketsInternacionais_C",
                in_token: token,
                quarto: 1,
                resLocalizador: null,
                dataInicial: dataInitial,
                dataFim: dataFinal,
                valorTotal: preOrderValueRoom,
                assNic: null,
                tktCodigos1: null,
                tktCodigos2: null,
                tktCodigos3: null,
                tktCodigos4: null,
              }),
            },
          })
          .then(async (resp) => {
            if (resp.data.Table[0].situacao === 0) {
              q1 = [];
              setMessage({
                title: "Você ficou muito tempo sem navegar, refaça sua busca",
                body: resp.data.Table[0]?.Texto || "",
              });
              setModalReload(true);
              return;
            }

            valueQ1 = resp.data.Table1[0].out_valor_Total;
            q1 = resp.data.Table.map((ticket: any) => ({
              ...ticket,
              checked: false,
            }));

            messageQ1 = resp.data.Table1[0].out_descricao || "";

            await api
              .get(endpointTicketsInternacionais, {
                params: {
                  dados: JSON.stringify({
                    t: "returnTicketsInternacionais_C",
                    in_token: token,
                    quarto: 2,
                    resLocalizador: null,
                    dataInicial: dataInitial,
                    dataFim: dataFinal,
                    valorTotal: preOrderValueRoom,
                    assNic: null,
                    tktCodigos1: null,
                    tktCodigos2: null,
                    tktCodigos3: null,
                    tktCodigos4: null,
                  }),
                },
              })
              .then((resp) => {
                if (resp.data.Table[0].situacao === 0) {
                  q2 = [];
                  setMessage({
                    title:
                      "Você ficou muito tempo sem navegar, refaça sua busca",
                    body: resp.data.Table[0]?.Texto || "",
                  });
                  setModalReload(true);
                  return;
                }

                valueQ2 = resp.data.Table1[0].out_valor_Total;
                q2 = resp.data.Table.map((ticket: any) => ({
                  ...ticket,
                  checked: false,
                }));

                messageQ2 = resp.data.Table1[0].out_descricao || "";

                setTotalValue(valueQ1 + valueQ2);
              });
          });
        setLoadingTickets(false);

        return {
          q1: q1,
          q2: q2,
          totalPay: valueQ2 + valueQ1,
          messageQ1,
          messageQ2,
          messageQ3,
          messageQ4,
        };
      }

      if (quantityRoons === 3) {
        setLoadingTickets(true);
        await api
          .get(endpointTicketsInternacionais, {
            params: {
              dados: JSON.stringify({
                t: "returnTicketsInternacionais_C",
                in_token: token,
                quarto: 1,
                resLocalizador: null,
                dataInicial: dataInitial,
                dataFim: dataFinal,
                valorTotal: preOrderValueRoom,
                assNic: null,
                tktCodigos1: null,
                tktCodigos2: null,
                tktCodigos3: null,
                tktCodigos4: null,
              }),
            },
          })
          .then(async (resp) => {
            if (resp.data.Table[0].situacao === 0) {
              q1 = [];
              setMessage({
                title: "Você ficou muito tempo sem navegar, refaça sua busca",
                body: resp.data.Table[0]?.Texto || "",
              });
              setModalReload(true);
              return;
            }

            messageQ1 = resp.data.Table1[0].out_descricao || "";

            valueQ1 = resp.data.Table1[0].out_valor_Total;
            q1 = resp.data.Table.map((ticket: any) => ({
              ...ticket,
              checked: false,
            }));

            await api
              .get(endpointTicketsInternacionais, {
                params: {
                  dados: JSON.stringify({
                    t: "returnTicketsInternacionais_C",
                    in_token: token,
                    quarto: 2,
                    resLocalizador: null,
                    dataInicial: dataInitial,
                    dataFim: dataFinal,
                    valorTotal: preOrderValueRoom,
                    assNic: null,
                    tktCodigos1: null,
                    tktCodigos2: null,
                    tktCodigos3: null,
                    tktCodigos4: null,
                  }),
                },
              })
              .then(async (resp) => {
                if (resp.data.Table[0].situacao === 0) {
                  q2 = [];
                  setMessage({
                    title:
                      "Você ficou muito tempo sem navegar, refaça sua busca",
                    body: resp.data.Table[0]?.Texto || "",
                  });
                  setModalReload(true);
                  return;
                }

                valueQ2 = resp.data.Table1[0].out_valor_Total;
                q2 = resp.data.Table.map((ticket: any) => ({
                  ...ticket,
                  checked: false,
                }));

                messageQ2 = resp.data.Table1[0].out_descricao || "";

                await api
                  .get(endpointTicketsInternacionais, {
                    params: {
                      dados: JSON.stringify({
                        t: "returnTicketsInternacionais_C",
                        in_token: token,
                        quarto: 3,
                        resLocalizador: null,
                        dataInicial: dataInitial,
                        dataFim: dataFinal,
                        valorTotal: preOrderValueRoom,
                        assNic: null,
                        tktCodigos1: null,
                        tktCodigos2: null,
                        tktCodigos3: null,
                        tktCodigos4: null,
                      }),
                    },
                  })
                  .then((resp) => {
                    if (resp.data.Table[0].situacao === 0) {
                      q3 = [];
                      setMessage({
                        title:
                          "Você ficou muito tempo sem navegar, refaça sua busca",
                        body: resp.data.Table[0]?.Texto || "",
                      });
                      setModalReload(true);
                      return;
                    }

                    valueQ3 = resp.data.Table1[0].out_valor_Total;
                    q3 = resp.data.Table.map((ticket: any) => ({
                      ...ticket,
                      checked: false,
                    }));

                    messageQ3 = resp.data.Table1[0].out_descricao || "";
                  });

                setTotalValue(valueQ1 + valueQ2 + valueQ3);
              });
          });
        setLoadingTickets(false);

        return {
          q1: q1,
          q2: q2,
          q3: q3,
          q4: q4,
          totalPay: valueQ2 + valueQ1 + valueQ3 + valueQ4,
          messageQ1,
          messageQ2,
          messageQ3,
          messageQ4,
        };
      }

      if (quantityRoons === 4) {
        setLoadingTickets(true);
        await api
          .get(endpointTicketsInternacionais, {
            params: {
              dados: JSON.stringify({
                t: "returnTicketsInternacionais_C",
                in_token: token,
                quarto: 1,
                resLocalizador: null,
                dataInicial: dataInitial,
                dataFim: dataFinal,
                valorTotal: preOrderValueRoom,
                assNic: null,
                tktCodigos1: null,
                tktCodigos2: null,
                tktCodigos3: null,
                tktCodigos4: null,
              }),
            },
          })
          .then(async (resp) => {
            if (resp.data.Table[0].situacao === 0) {
              q1 = [];
              setMessage({
                title: "Você ficou muito tempo sem navegar, refaça sua busca",
                body: resp.data.Table[0]?.Texto || "",
              });
              setModalReload(true);
              return;
            }

            valueQ1 = resp.data.Table1[0].out_valor_Total;
            q1 = resp.data.Table.map((ticket: any) => ({
              ...ticket,
              checked: false,
            }));
            messageQ1 = resp.data.Table1[0].out_descricao || "";

            await api
              .get(endpointTicketsInternacionais, {
                params: {
                  dados: JSON.stringify({
                    t: "returnTicketsInternacionais_C",
                    in_token: token,
                    quarto: 2,
                    resLocalizador: null,
                    dataInicial: dataInitial,
                    dataFim: dataFinal,
                    valorTotal: preOrderValueRoom,
                    assNic: null,
                    tktCodigos1: null,
                    tktCodigos2: null,
                    tktCodigos3: null,
                    tktCodigos4: null,
                  }),
                },
              })
              .then(async (resp) => {
                if (resp.data.Table[0].situacao === 0) {
                  q2 = [];
                  setMessage({
                    title:
                      "Você ficou muito tempo sem navegar, refaça sua busca",
                    body: resp.data.Table[0]?.Texto || "",
                  });
                  setModalReload(true);
                  return;
                }

                valueQ2 = resp.data.Table1[0].out_valor_Total;
                q2 = resp.data.Table.map((ticket: any) => ({
                  ...ticket,
                  checked: false,
                }));
                messageQ2 = resp.data.Table1[0].out_descricao || "";

                await api
                  .get(endpointTicketsInternacionais, {
                    params: {
                      dados: JSON.stringify({
                        t: "returnTicketsInternacionais_C",
                        in_token: token,
                        quarto: 3,
                        resLocalizador: null,
                        dataInicial: dataInitial,
                        dataFim: dataFinal,
                        valorTotal: preOrderValueRoom,
                        assNic: null,
                        tktCodigos1: null,
                        tktCodigos2: null,
                        tktCodigos3: null,
                        tktCodigos4: null,
                      }),
                    },
                  })
                  .then(async (resp) => {
                    if (resp.data.Table[0].situacao === 0) {
                      q3 = [];
                      setMessage({
                        title:
                          "Você ficou muito tempo sem navegar, refaça sua busca",
                        body: resp.data.Table[0]?.Texto || "",
                      });
                      setModalReload(true);
                      return;
                    }

                    valueQ3 = resp.data.Table1[0].out_valor_Total;
                    q3 = resp.data.Table.map((ticket: any) => ({
                      ...ticket,
                      checked: false,
                    }));
                    messageQ3 = resp.data.Table1[0].out_descricao || "";

                    await api
                      .get(endpointTicketsInternacionais, {
                        params: {
                          dados: JSON.stringify({
                            t: "returnTicketsInternacionais_C",
                            in_token: token,
                            quarto: 4,
                            resLocalizador: null,
                            dataInicial: dataInitial,
                            dataFim: dataFinal,
                            valorTotal: preOrderValueRoom,
                            assNic: null,
                            tktCodigos1: null,
                            tktCodigos2: null,
                            tktCodigos3: null,
                            tktCodigos4: null,
                          }),
                        },
                      })
                      .then((resp) => {
                        if (resp.data.Table[0].situacao === 0) {
                          q4 = [];
                          setMessage({
                            title:
                              "Você ficou muito tempo sem navegar, refaça sua busca",
                            body: resp.data.Table[0]?.Texto || "",
                          });
                          setModalReload(true);
                          return;
                        }

                        valueQ4 = resp.data.Table1[0].out_valor_Total;
                        q4 = resp.data.Table.map((ticket: any) => ({
                          ...ticket,
                          checked: false,
                        }));
                        messageQ4 = resp.data.Table1[0].out_descricao || "";

                        setTotalValue(valueQ1 + valueQ2 + valueQ3 + valueQ4);
                      });
                  });
              });
          });
        setLoadingTickets(false);

        return {
          q1: q1,
          q2: q2,
          q3: q3,
          q4: q4,
          totalPay: valueQ2 + valueQ1 + valueQ3 + valueQ4,
          messageQ1,
          messageQ2,
          messageQ3,
          messageQ4,
        };
      }
    },
    [setTotalValue, dataFinal, dataInitial, token]
  );

  const scrollTo = (idForm: string) => {
    const element = document.querySelector(`#${idForm}`);
    element?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const submitReservation = async (
    data: PagamentoSHotelInternacional1Payload
  ) => {
    const parsedData = parseHTML(JSON.stringify(data));
    const htmlStrippedData = stripHTML(parsedData);
    const formData = new FormData();
    formData.append("dados", htmlStrippedData);
    api
      .post(
        "/wsCoobrastur/Hotel.asmx/Pagamento_S_Hotel_Internacional_1",
        formData
      )
      .then((resp) => {
        if (resp?.data?.situacao === 0 || resp?.data?.situacao === "0") {
          // success
          setErrorPayment("");
          localStorage.setItem("resLocale", resp.data.resLocalizador);
          if (!resp?.data?.resLocalizador) {
            throw new Error("Sem resLocalizador");
          }
          const resLocalizador = resp?.data?.resLocalizador || "";
          navigate(`${process.env.PUBLIC_URL}/reserva/${resLocalizador}`, {
            state: {
              resLocalizador: String(resp.data.resLocalizador),
              internationalReserveToken: data.reservaTempJson.token,
            },
          });
          setLoadingReservation(false);
          return;
        }

        if (
          resp?.data ===
          "Pagamento não autorizado.<br>Contate sua operadora.<br>Reserva não efetuada."
        ) {
          setErrorPayment(resp.data);
          setLoadingReservation(false);
          return;
        }

        if (resp?.data?.situacao === 2 || resp?.data?.situacao === "2") {
          setMessage({
            title: "Você ficou muito tempo sem navegar",
            body: resp.data.mensagem,
          });
          setModalReload(true);
          return;
        }
        setMessageModalError({
          title: "Ops. Algo Deu Errado",
          htmlBody:
            resp?.data?.mensagem || resp?.data || "Desculpe o transtorno",
        });
        setLoadingReservation(false);
        return;
      })
      .catch((err) => {
        console.error(err);
        // if error on payment
        if (
          err?.response?.data ===
          "Pagamento não autorizado.<br>Contate sua operadora.<br>Reserva não efetuada."
        ) {
          setErrorPayment(
            "Pagamento não autorizado. Contate sua operadora. Reserva não efetuada."
          );
          // tag html nao funcionou para quebrar linha
          scrollTo(IDS_FORMS.FormPayment);
          setMessageModalError({
            title: "Pagamento não autorizado.",
            body: "Contate sua operadora. Reserva não efetuada",
          });
          setLoadingReservation(false);
          return;
        }
        // remove o erro se o erro for outro e o return de cima garante que nao remove o que foi add
        setErrorPayment("");

        const appendMessage =
          " Entre em contato com a central de atendimento para mais informações!";
        setMessageModalError({
          title: "Ops Algo Deu errado",
          htmlBody:
            err?.response?.data === "Quarto indisponível. Refaça sua consulta."
              ? `${err.response.data}${appendMessage}`
              : err?.response?.data || appendMessage,
        });
        setLoadingReservation(false);
        // handleGoToTop();
      });
  };

  // usa formRef
  const getPagamento = async (
    totalPayment: number
  ): Promise<pagamentosJsonPayload> => {
    if (totalPayment <= 0) {
      return {
        TipoPgto: 0,
        fmCodigo: "",
        Total: "0.00",
      };
    }

    try {
      if (!formRef.current?.getData()) {
        throw new Error();
      }
      const values = formRef.current.getData();
      await validateFormChoosePaymentsInternational(
        values as DataPaymentProps,
        totalPayment
      ); // if fails throw error
      const parcelas = values.parcelas.substring(0, 1);
      const Total = totalPayment.toFixed(2).toString();

      return {
        TipoPgto: 1,
        Titular: values.nome,
        Numero: values.numeroDoCartao,
        fmCodigo: 2,
        Mes: values.mes,
        Ano: `20${values.ano}`,
        CVC: values.cvv,
        Parcelas: `0${parcelas}`,
        Total,
      };
    } catch (err) {
      scrollTo(IDS_FORMS.FormPayment);
      throw new Error("Erro formulario de pagamento");
      // throw error on function call
    }
  };

  const getTerceirosInfo = async (): Promise<TerceirosInfoPayload | null> => {
    try {
      if (!formOtherPersonRef.current?.getData()) {
        throw new Error();
      }
      const values = formOtherPersonRef.current.getData();
      await validadeFormOtherPerson(values as FormOtherPersonProps); // if fails throw error
      return null;

      // return {
      //   utiterNome: values.nameUser,
      //   utiterCPF: values.cpfUser,
      //   utiterRG: values.rgUser,
      //   utiterEndereco: values.addressUser,
      //   utiterCidade: values.cityUser,
      //   UFCodigo: values.ufUser,
      //   utiterDDDRes: values.phoneRes.substring(1, 3),
      //   utiterFoneRes: values.phoneRes
      //     .substring(4, values.phoneRes.length)
      //     .replace("-", "")
      //     .replace(/\s/g, ""),
      //   utiterDDDComl: "0",
      //   utiterFoneComl: "0",
      //   utiterDDDCelular: values.phoneCel.substring(1, 3),
      //   utiterFoneCelular: values.phoneCel
      //     .substring(4, values.phoneCel.length)
      //     .replace("-", "")
      //     .replace(/\s/g, ""),
      //   utiterIdade: values.ageUser,
      //   utiterEmail: values.emailUser,
      //   grauparCodigo: values.parentUser,
      // };
    } catch (err) {
      scrollTo(IDS_FORMS.FormOtherPerson);
      throw new Error("Erro formulario de terceiro");
      // throw error on function call
    }
  };

  const getPassenger = async (
    formPassengerRef: React.RefObject<FormHandles>,
    room: number
  ): Promise<passengerPayload[]> => {
    try {
      if (!formPassengerRef.current?.getData()) {
        throw new Error("Erro ao pegar dados dos hospedes");
      }
      const value = formPassengerRef.current.getData();

      /* validade before return value */
      await validadeFormPassengers(value as DataFormPassengersProps, room); // if fails throw error
      await validadeHasChildren(
        value as DataFormPassengersProps,
        roomInfoExtra[room - 1].criancasQuant,
        roomInfoExtra[room - 1].idadeCrianca
      ); // if fails throw error
      /*** end validation */

      const today = new Date();
      const ehMenorDe18 = (ano: string, mes: string, dia: string): boolean => {
        return (
          idade(new Date(Number(ano), Number(mes) - 1, Number(dia)), today) < 18
        );
      };
      const resp =
        value?.hospede?.map((data: any, i: number): passengerPayload => {
          // nasc is type YYYY-MM-DD
          const nascSplice = data.nasc.split("-");
          const gender = getGender(data, room, i + 1) || "0"; // if not found send masc
          return {
            FirstName: data.nameUser,
            LastName: data.lastNameUser,
            BirthDate: {
              Day: nascSplice[2],
              Month: nascSplice[1],
              Year: nascSplice[0],
            },
            gender,
            Type: ehMenorDe18(nascSplice[0], nascSplice[1], nascSplice[2])
              ? 1
              : 0,
            RoomIndex: room - 1,
            Title: gender,
          };
        }) || [];

      return resp;
    } catch (err) {
      scrollTo(getIdFormRoom(room));
      throw new Error(`Erro formulario do quarto ${room}`);
      // throw error on function call
    }
  };

  // const redirectNotificaAgencia = async () => {
  //   const dados = JSON.stringify({
  //     token,
  //   });
  //   await api.post(
  //     `/wsCoobrastur/Administracao.asmx/NotificaReservaAcimaLimite`,
  //     dados
  //   );
  //   alert(
  //     "Esta reserva deverá ser finalizada através da nossa Agência de Viagens pelo telefone (54) 3278-9880."
  //   );
  //   window.location.href = `${process.env.PUBLIC_URL}/`;
  // };

  // usa os 4 formRef e os states reservaTempJson, hotels, ContactInformation e totalPayments
  const handleReserveHotel = async () => {
    setLoadingReservation(true);
    // if (totalPayments > 5000) {
    //   return await redirectNotificaAgencia();
    // }
    try {
      const TerceirosInfo: TerceirosInfoPayload[] = [];

      //submit All to generated form errors
      if (openFormOtherPerson) formOtherPersonRef.current?.submitForm();
      formPassengerRefQ1.current?.submitForm();
      if (roons.length >= 2) formPassengerRefQ2.current?.submitForm();
      if (roons.length >= 3) formPassengerRefQ3.current?.submitForm();
      if (roons.length >= 4) formPassengerRefQ4.current?.submitForm();
      if (totalPayments > 0) formRef.current?.submitForm();
      // end submit

      // ALL GETS ARE ORDENADE TO SCROLL WHEN HAS ERROR
      const passangersFormQ1 = await getPassenger(formPassengerRefQ1, 1);
      let passangersFormQ2: passengerPayload[] = [];
      let passangersFormQ3: passengerPayload[] = [];
      let passangersFormQ4: passengerPayload[] = [];
      if (roons.length >= 2) {
        passangersFormQ2 = await getPassenger(formPassengerRefQ2, 2);
      }
      if (roons.length >= 3) {
        passangersFormQ3 = await getPassenger(formPassengerRefQ3, 3);
      }
      if (roons.length >= 4) {
        passangersFormQ4 = await getPassenger(formPassengerRefQ4, 4);
      }
      const passenger = [
        ...passangersFormQ1,
        ...passangersFormQ2,
        ...passangersFormQ3,
        ...passangersFormQ4,
      ];
      if (openFormOtherPerson) {
        const terceiroInfo = await getTerceirosInfo();
        if (terceiroInfo) TerceirosInfo.push(terceiroInfo);
      }

      const pagamentosJson: pagamentosJsonPayload = await getPagamento(
        totalPayments
      );

      if (!reservaTempJson) {
        throw new Error("Dados da Reserva Inválidos");
      }
      if (!hotels) {
        throw new Error("Dados do Hotel Inválidos");
      }
      if (!ContactInformation || !ContactInformation.Name) {
        throw new Error("Dados do usuário Inválidos");
      }

      console.log(hotels);

      const payload: PagamentoSHotelInternacional1Payload = {
        pagamentosJson,
        reservaTempJson,
        hotels,
        passenger,
        ContactInformation: [ContactInformation],
        TerceirosInfo,
      };

      await submitReservation(payload);
      return;
    } catch (err: any) {
      //handleGoToTop();
      setErrorSomeForm("Dados inválidos, por favor verifique.");
      console.error("erro reserve: ", err);
      if (errorCritical()) {
        setMessageModalError({
          title: "Ops! Um erro crítico aconteceu, preencha novamente!",
          body: err.message,
        });
      }
      setLoadingReservation(false);
    }
  };

  const handleGoTotop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  /* setContactInformation and  setAssNic */
  useEffect(() => {
    handleGoTotop();
    if (!!token) {
      api
        .get(`wsCoobrastur/Hotel.asmx/returnLogin_1`, {
          params: { token: token },
        })
        .then((resp) => {
          if (resp.status === 200 && resp.data.Table[0]) {
            const {
              assCPF_CNPJ,
              assEmailPessoal,
              assNome_RazaoSocial,
              assNumCelularDDD,
              assNumCelular,
              assnic,
            } = resp.data.Table[0];
            setContactInformation({
              Document: assCPF_CNPJ,
              Email: assEmailPessoal,
              Name: assNome_RazaoSocial,
              PhoneDDI: assNumCelularDDD,
              PhoneNumber: assNumCelular,
            });
            setAssNic(assnic);
            return;
          }
          console.error("error", resp);
        });
    }
  }, [token]);

  useEffect(() => {
    if (!!token) {
      api
        .get(`wsCoobrastur/Hotel.asmx/returnPesquisa_1`, { params: { token } })
        .then(async (resp) => {
          if (resp.status === 200 && resp.data.Table[0]) {
            if (resp?.data?.Table?.[0]?.situacao === 0) {
              setMessage({
                title: "Você ficou muito tempo sem navegar, refaça sua busca",
                body: resp.data.Table[0]?.Texto || "",
              });
              setModalReload(true);
              return;
            }

            const respInfo = resp.data.Table[0];
            console.log(respInfo.dataInicial);
            const preOrderRQ = JSON.parse(respInfo.preOrderRQ);
            const preOrder = JSON.parse(respInfo.preOrder);
            setReservaTemp({
              token,
              in_resLocalizador: null,
              hotelNome: respInfo.nomeHotelSelecionado,
              hotelId: respInfo.hotCodigoSelecionado,
              resdtIni: respInfo.dataInicial,
              resdtFim: respInfo.dataFinal,
              resQtdQuartos: respInfo.qtdeQuartos,
              resQtdAdultos: respInfo.totalAdultos,
              resQtdCriancas: respInfo.totalCriancas,
              resValor: preOrder.Summary.TotalAmount,
              destinoId: respInfo.in_Codigo,
              destinoNome: respInfo.nomeDestino,
            });
            setHotels(preOrder.Hotels);
            setPenatyAmount(
              preOrder.Hotels[0].Rooms[0].ReservationInfo
                .CancellationPolicies[0].Description
            );

            const daysStay = Number(preOrder.Summary.DaysStay);
            const arrayValuesPreOrderRQ: number[] = preOrderRQ[0].Rooms.map(
              (room: any) =>
                (Number(room.StayOptions[0].DailyFare) +
                  Number(room.StayOptions[0].DailyTax) +
                  Number(room.StayOptions[0].FareDetails.Markup)) *
                daysStay
            );
            setArrayPreOrderValues(arrayValuesPreOrderRQ);

            let hospedeQ1 = respInfo.adultosQ1 + respInfo.criancasQ1;
            let hospedeQ2 = respInfo.adultosQ2 + respInfo.criancasQ2;
            let hospedeQ3 = respInfo.adultosQ3 + respInfo.criancasQ3;
            let hospedeQ4 = respInfo.adultosQ4 + respInfo.criancasQ4;
            let numeroQuartos = respInfo.qtdeQuartos;

            serRoomInfoExtra([
              {
                adultosQuant: respInfo.adultosQ1 || 0,
                criancasQuant: respInfo.criancasQ1 || 0,
                idadeCrianca: respInfo.idadeC1 || 0,
              },
              {
                adultosQuant: respInfo.adultosQ2 || 0,
                criancasQuant: respInfo.criancasQ2 || 0,
                idadeCrianca: respInfo.idadeC2 || 0,
              },
              {
                adultosQuant: respInfo.adultosQ3 || 0,
                criancasQuant: respInfo.criancasQ3 || 0,
                idadeCrianca: respInfo.idadeC3 || 0,
              },
              {
                adultosQuant: respInfo.adultosQ4 || 0,
                criancasQuant: respInfo.criancasQ4 || 0,
                idadeCrianca: respInfo.idadeC4 || 0,
              },
            ]);

            /* Teste */

            // const tikets2 = await getTickets(2, 2, arrayValuesPreOrderRQ[1]);
            // console.log("tikets", tikets1, tikets2);
            /** --- */

            if (numeroQuartos === 1) {
              const resp = await getTickets(1, 1, arrayValuesPreOrderRQ[0]);

              const newRoon = oneRoonItem.map((item) => {
                if (item.room === "quarto 1") {
                  if (hospedeQ1 === 1) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ1,
                      numberPassenger: 1,
                      tickets: resp?.q1,
                    };
                  }

                  if (hospedeQ1 === 2) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ1,
                      numberPassenger: 2,
                      tickets: resp?.q1,
                    };
                  }

                  if (hospedeQ1 === 3) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ1,
                      numberPassenger: 3,
                      tickets: resp?.q1,
                    };
                  }
                }

                return item;
              });

              setRoons(newRoon);
              return;
            }

            if (numeroQuartos === 2) {
              const resp = await getTickets(2, 2, arrayValuesPreOrderRQ[1]);
              const newRoon = twoRoonItem.map((item) => {
                if (item.room === "quarto 1") {
                  if (hospedeQ1 === 1) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ1,
                      numberPassenger: 1,
                      tickets: resp?.q1,
                    };
                  }

                  if (hospedeQ1 === 2) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ1,
                      numberPassenger: 2,
                      tickets: resp?.q1,
                    };
                  }

                  if (hospedeQ1 === 3) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ1,
                      numberPassenger: 3,
                      tickets: resp?.q1,
                    };
                  }
                }

                if (item.room === "quarto 2") {
                  if (hospedeQ2 === 1) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ2,
                      numberPassenger: 1,
                      tickets: resp?.q2,
                    };
                  }

                  if (hospedeQ2 === 2) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ2,
                      numberPassenger: 2,
                      tickets: resp?.q2,
                    };
                  }
                  if (hospedeQ2 === 3) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ2,
                      numberPassenger: 3,
                      tickets: resp?.q2,
                    };
                  }
                }
                return item;
              });

              setRoons(newRoon);
              return;
            }

            if (numeroQuartos === 3) {
              const resp = await getTickets(3, 3, arrayValuesPreOrderRQ[2]);
              const newRoon = threeRoonItem.map((item) => {
                if (item.room === "quarto 1") {
                  if (hospedeQ1 === 1) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ1,
                      numberPassenger: 1,
                      tickets: resp?.q1,
                    };
                  }

                  if (hospedeQ1 === 2) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ1,
                      numberPassenger: 2,
                      tickets: resp?.q1,
                    };
                  }

                  if (hospedeQ1 === 3) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ1,
                      numberPassenger: 3,
                      tickets: resp?.q1,
                    };
                  }
                }

                if (item.room === "quarto 2") {
                  if (hospedeQ2 === 1) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ2,
                      tickets: resp?.q2,
                      numberPassenger: 1,
                    };
                  }

                  if (hospedeQ2 === 2) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ2,
                      tickets: resp?.q2,
                      numberPassenger: 2,
                    };
                  }
                  if (hospedeQ2 === 3) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ2,
                      tickets: resp?.q2,
                      numberPassenger: 3,
                    };
                  }
                }

                if (item.room === "quarto 3") {
                  if (hospedeQ3 === 1) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ3,
                      tickets: resp?.q3,
                      numberPassenger: 1,
                    };
                  }

                  if (hospedeQ3 === 2) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ3,
                      tickets: resp?.q3,
                      numberPassenger: 2,
                    };
                  }
                  if (hospedeQ3 === 3) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ3,
                      tickets: resp?.q3,
                      numberPassenger: 3,
                    };
                  }
                }

                return item;
              });

              setRoons(newRoon);
              return;
            }

            if (numeroQuartos === 4) {
              const resp = await getTickets(4, 4, arrayValuesPreOrderRQ[3]);
              const newRoon = fourRoonItem.map((item) => {
                if (item.room === "quarto 1") {
                  if (hospedeQ1 === 1) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ1,
                      numberPassenger: 1,
                      tickets: resp?.q1,
                    };
                  }

                  if (hospedeQ1 === 2) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ1,
                      numberPassenger: 2,
                      tickets: resp?.q1,
                    };
                  }

                  if (hospedeQ1 === 3) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ1,
                      numberPassenger: 3,
                      tickets: resp?.q1,
                    };
                  }
                }

                if (item.room === "quarto 2") {
                  if (hospedeQ2 === 1) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ2,
                      numberPassenger: 1,
                      tickets: resp?.q2,
                    };
                  }

                  if (hospedeQ2 === 2) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ2,
                      numberPassenger: 2,
                      tickets: resp?.q2,
                    };
                  }
                  if (hospedeQ2 === 3) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ2,
                      numberPassenger: 3,
                      tickets: resp?.q2,
                    };
                  }
                }

                if (item.room === "quarto 3") {
                  if (hospedeQ3 === 1) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ3,
                      numberPassenger: 1,
                      tickets: resp?.q3,
                    };
                  }

                  if (hospedeQ3 === 2) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ3,
                      numberPassenger: 2,
                      tickets: resp?.q3,
                    };
                  }
                  if (hospedeQ3 === 3) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ3,
                      numberPassenger: 3,
                      tickets: resp?.q3,
                    };
                  }
                }

                if (item.room === "quarto 4") {
                  if (hospedeQ4 === 1) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ4,
                      numberPassenger: 1,
                      tickets: resp?.q4,
                    };
                  }

                  if (hospedeQ4 === 2) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ4,
                      numberPassenger: 2,
                      tickets: resp?.q4,
                    };
                  }
                  if (hospedeQ4 === 3) {
                    return {
                      ...item,
                      formPassengerRef: formPassengerRefQ4,
                      numberPassenger: 3,
                      tickets: resp?.q4,
                    };
                  }
                }
                return item;
              });
              setRoons(newRoon);
              return;
            }

            return;
          }

          const err = {
            message: "API retornou vazio",
          };

          throw err;
        })
        .catch((e) => {
          setLoadingTickets(false);
          console.error(e);

          setMessage({
            title: "Ocorreu um erro inesperado!!!",
            body: "Você será redirecionado ao início!",
          });
          setModalReload(true);
        });
    }
  }, [getTickets, token]);

  useEffect(() => {
    const inToken = localStorage.getItem("tokenPortalAssociado");
    if (assNic && inToken)
      api
        .get(`/wsCoobrastur/ConsultaHoteis.asmx/PU_1_2`, {
          params: { nic: assNic, in_token: inToken },
        })
        .then((resp) => {
          if (resp.status === 200) {
            const dataUser = {
              ddd: resp.data[0].assNumCelularDDD,
              cel: resp.data[0].assNumCelular,
              birthday: resp.data[0].assDTNascimento,
            };
            localStorage.setItem("completeForm", JSON.stringify(dataUser));
            return;
          }
          alert("algo deu errado, recarregue á pagina");
        })
        .catch((e) => console.error({ component: "Choosepayment", error: e }));
  }, [assNic]);

  return (
    <Fragment>
      {OpenModalTerms && (
        <Modal
          close={handleOpenModal}
          title={"Termos Gerais"}
          content={contentModalTerms}
        />
      )}
      <Container>
        <WrapperSteps>
          <WrapperTextSteps>
            <h4> Etapas para concluir sua reserva</h4>
            <span>
              Você está na terceira etapa. Escolha a sua hospedagem dentro os
              resultados abaixo para prosseguir.
            </span>
          </WrapperTextSteps>
          <Steps stepOne="disabled" stepTwo="disabled" stepThree="active" />
        </WrapperSteps>
        <SectionPayment>
          <Wrapper>
            <DetailsOfReserve />
            <WrapperSelectRoom>
              <div style={{ width: "100%" }}>
                {roons.map((roon, i) => (
                  <BoxRoom key={roon.id} id={getIdFormRoom(i + 1)}>
                    <TitleRoom>{roon.room}</TitleRoom>
                    <WrapperContent>
                      <Subtitle>Informe os hóspedes do quarto</Subtitle>
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "transparent",
                        }}
                      >
                        <FormPassenger
                          formPassengerRef={roon.formPassengerRef}
                          numberOfPassenger={roon.numberPassenger}
                          room={roon.room}
                          numberOfChildrenThisRoom={
                            roomInfoExtra[i].criancasQuant
                          }
                          ageChildrenThisRoom={roomInfoExtra[i].idadeCrianca}
                        />
                      </div>
                    </WrapperContent>
                    <Subtitle>Selecione os e-tickets para utilização</Subtitle>

                    <TicketsInternational
                      tickets={roon?.tickets}
                      update={updateTickets}
                      room={roon.room}
                      loading={loadingTickets}
                    />
                  </BoxRoom>
                ))}
              </div>
              <WrapperRadios id={IDS_FORMS.FormOtherPerson}>
                <InputRadio
                  fontSize="0.9rem"
                  options={optionsRadioInputQ1}
                  direction="row"
                  justifyContent="flex-start"
                  paddingLeft="18px"
                  handleSelected={handleOpenFormOtherPerson}
                />
              </WrapperRadios>

              {openFormOtherPerson && (
                <FormOtherPerson
                  formRefOtherPerson={formOtherPersonRef}
                  optiosFildSelect={dataFormOtherPerson}
                />
              )}
              <div style={{ display: totalPayments > 0 ? "block" : "none" }}>
                <ChoosePaymentsInternational
                  id={IDS_FORMS.FormPayment}
                  formRef={formRef}
                  value={totalPayments}
                  errorPayment={errorPayment}
                />
              </div>
              {errorSomeForm !== "" && (
                <div
                  style={{
                    width: "100%",
                    maxWidth: "510px",
                    backgroundColor: "var(--red600)",
                    marginBottom: 4,
                    borderRadius: 12,
                    color: "var(--white)",
                    fontWeight: "bold",
                    padding: 4,
                    textAlign: "center",
                  }}
                >
                  {errorSomeForm}
                </div>
              )}

              {totalPayments > 0 && (
                <PolyticsArea>
                  <Subtitle>Consentimento de reserva</Subtitle>
                  {totalPayments > 0 && (
                    <WrappertextAndSwitcher>
                      <Switcher
                        onClick={handleToggleSwitcherPayment}
                        active={activeSwitcherPayment}
                      >
                        <ItemCircle active={activeSwitcherPayment} />
                      </Switcher>
                      <p>
                        Concordo em debitar o valor de{" "}
                        {totalPayments.toLocaleString("pt-Br", {
                          style: "currency",
                          currency: "BRL",
                        })}{" "}
                        referente ao pagamento do extras desta reserva e com os{" "}
                        <strong onClick={handleOpenModal}>
                          termos gerais{" "}
                        </strong>
                        ao reservar este(s) quarto(s).
                      </p>
                    </WrappertextAndSwitcher>
                  )}
                  <TextIndicate>
                    É preciso selecionar o consentimento para habilitar o botão
                  </TextIndicate>
                </PolyticsArea>
              )}

              {totalPayments > 0 && (
                <WrapperBtnReserve>
                  <PenaltyAmount>{penaltyAmount}</PenaltyAmount>
                  <BtnKeep
                    disabled={
                      !activeSwitcherPayment ||
                      namePayment === "" ||
                      loadingReservation
                    }
                    onClick={handleReserveHotel}
                  >
                    {loadingReservation ? <LoadingBtn /> : "Confirmar Reserva"}
                  </BtnKeep>
                </WrapperBtnReserve>
              )}
              {totalPayments <= 0 && (
                <WrapperBtnReserve style={{ flexDirection: "column" }}>
                  <WrappertextAndSwitcher>
                    <Switcher
                      onClick={handleToggleSwitcherPayment}
                      active={activeSwitcherPayment}
                    >
                      <ItemCircle active={activeSwitcherPayment} />
                    </Switcher>

                    <p>
                      Estou ciente e concordo em aceitar os{" "}
                      <strong onClick={handleOpenModal}>termos gerais </strong>
                      ao reservar este(s) quarto(s).
                    </p>
                  </WrappertextAndSwitcher>
                  <TextIndicate>
                    É preciso selecionar o consentimento para habilitar o botão
                  </TextIndicate>
                  <PenaltyAmount>{penaltyAmount}</PenaltyAmount>
                  <BtnKeep
                    disabled={!activeSwitcherPayment || loadingReservation}
                    onClick={handleReserveHotel}
                  >
                    {loadingReservation ? <LoadingBtn /> : "Confirmar Reserva"}
                  </BtnKeep>
                </WrapperBtnReserve>
              )}
            </WrapperSelectRoom>
          </Wrapper>
        </SectionPayment>
        <ModalFormConfirmation
          modalIsOpen={modalReload}
          message={message}
          setIsOpen={handleCloseModalreload}
        />
        <ModalAlert
          isOpen={!!messageModalError?.htmlBody || !!messageModalError?.body}
          onClose={handleCloseModalError}
          message={messageModalError}
        />
      </Container>
    </Fragment>
  );
};

export default PaymentsInternational;
