import {
  Section,
  TitleSection,
  ImagePartner,
  WrapperPartnersContent,
  CardPartnerImage,
  WrapperPartnersCards,
} from "../styles";

type PartnerSectionProps = {
  itens: { id: number; img: string; link?: string }[];
};

function PartnerSection({ itens }: PartnerSectionProps) {
  return (
    <Section id="Parceiros">
      <TitleSection>com os parceiros coob+, você tem +vantagens</TitleSection>
      <WrapperPartnersContent>
        <WrapperPartnersCards>
          {itens.map((item) => (
            <CardPartnerImage key={item.id}>
              {!item?.link ? (
                <ImagePartner src={item.img} alt="logo" />
              ) : (
                <a href={item.link} rel="noreferrer" target="_blank">
                  <ImagePartner src={item.img} alt="logo" />
                </a>
              )}
            </CardPartnerImage>
          ))}
        </WrapperPartnersCards>
      </WrapperPartnersContent>
    </Section>
  );
}

export default PartnerSection;
