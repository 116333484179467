import { ReactNode, SyntheticEvent, useEffect, useState } from "react";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";
import { ReactComponent as IconFechar } from "../../assets/icones/icons dense (24px)/fechar.svg";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { formatDate } from "../../utils";
import {
  ButtonCloseModal,
  ButtonSelect,
  InfoContent,
  Label,
  Input,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderTilte,
  ReservationInfo,
  SelectAccommodation,
  SelectAccommodationTitle,
  LabelContent,
  LabelTitle,
  LabelText,
  LabelContainer,
  LabelsContainer,
  ButtonSelectRooms,
  Helpers,
  LoadingBtn,
} from "./styles";
import { ModalRoomSelectionProps, roomChosenProps } from "./types";

export const ModalRoomSelection = ({
  modalIsOpen,
  setIsOpen,
  hotel,
  handleReserve,
  handleSelectRoom,
  isCreatingReservation,
}: ModalRoomSelectionProps) => {
  const mobile = useMediaQuery("(max-width: 990px)");
  const [diffInDays, setdiffInDays] = useState(0);
  const [roomSelect, setRoomSlect] = useState(0);
  const [roomValidationNumber, setRoomValidationNumber] = useState(0);
  const [roomChosen, setRoomChosen] = useState<roomChosenProps[]>([]);
  const [validationRoom, setValidationRoom] = useState([
    { id: 0, validade: false },
  ]);

  const [messagem, setMessage] = useState("");
  const { state } = useLocation();

  const selectListRooms = (id: number) => {
    setRoomSlect(id);
  };

  const handleInput = (event: SyntheticEvent<HTMLInputElement>) => {
    return (roomID: number) => {
      return (groupID: number) => {
        const { checked } = event.currentTarget;

        if (checked) {
          selectRoomChosen({
            roomID,
            groupID,
          });
        }
      };
    };
  };

  const calcValueDailyRoom = (
    item?: {
      OriginalDailyFare: number;
      OriginalDailyTax: number;
      ExchangeRate: number;
      FareDetails: {
        Markup: number;
      };
    },
    cotation?: number
  ) => {
    if (item && cotation) {
      const valueOriginal =
        item.OriginalDailyFare +
        item.OriginalDailyTax +
        item.FareDetails.Markup / item.ExchangeRate;
      const valorDolar = valueOriginal * cotation;

      if (valorDolar <= 100) {
        return "Incluso no Plano Diamante";
      }
      return `${valueOriginal.toLocaleString("pt-Br", {
        style: "currency",
        currency: "BRL",
      })} por diária`;
    }

    return "Incluso no Plano Diamante";
  };

  const renderRoomsSelect = (groupID: number) => {
    return (ids: number[]): ReactNode => {
      return ids.map((id) => {
        const room = hotel?.rooms?.find((room) => room.id === id);

        // console.log(`room${groupID}`,room?.name, room?.id, room?.board || "Não informado");

        if (room) {
          return (
            <Label key={room.id}>
              <Input
                type="radio"
                name={`room${groupID}`}
                value={room.id}
                onInput={(event) => handleInput(event)(room.id)(groupID)}
                disabled={isCreatingReservation}
              />
              <LabelContainer>
                <LabelContent>
                  <LabelTitle>Acomodação</LabelTitle>
                  <LabelText>{room.name}</LabelText>
                </LabelContent>
                <LabelContent>
                  <LabelTitle>Alimentação</LabelTitle>
                  <LabelText>{room.board || "Não informado"}</LabelText>
                </LabelContent>
                <LabelContent>
                  <LabelTitle>Custo</LabelTitle>

                  <LabelText>
                    {calcValueDailyRoom(room.options, room.cotacao)}
                  </LabelText>
                </LabelContent>
              </LabelContainer>
            </Label>
          );
        }

        return null;
      });
    };
  };

  const selectRoomChosen = ({ roomID, groupID }: roomChosenProps) => {
    const newData = [...roomChosen];
    const newChosen = {
      id: roomSelect,
      roomID,
      groupID,
    };

    const chosenIndex = newData.findIndex(
      (chosen) => chosen.groupID === roomSelect
    );

    if (chosenIndex !== -1) {
      newData.splice(chosenIndex, 1, newChosen);
    } else {
      newData.push(newChosen);
    }

    let validation = [...validationRoom];

    validation = validation.map((validation) => {
      if (validation.id === groupID) {
        return {
          ...validation,
          validade: true,
        };
      }

      return validation;
    });

    setValidationRoom(validation);

    setRoomChosen(newData);
    handleSelectRoom?.(newData);
  };

  useEffect(() => {
    const { dateIn, dateOut } = state.data;

    if (dateIn && dateOut) {
      const diffInMs = dateOut.getTime() - dateIn.getTime();
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

      setdiffInDays(diffInDays);
    }
  }, [state]);

  useEffect(() => {
    setRoomValidationNumber(hotel.roomsGroups.length);
  }, [hotel]);

  useEffect(() => {
    const newData = hotel.roomsGroups.map((room) => ({
      id: room.SearchIndex,
      validade: false,
    }));

    setValidationRoom(newData);
  }, [hotel]);

  useEffect(() => {
    const newData = validationRoom.filter((room) => room.validade);

    if (newData.length === roomValidationNumber) {
      setMessage("");
      return;
    }

    setMessage(
      `Escolha a acomodação <strong>(${validationRoom
        .filter((room) => !room.validade)
        .map((room) => `quarto ${room.id + 1}`)
        .join(", ")})</strong>`
    );
  }, [roomValidationNumber, validationRoom]);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      style={{
        content: {
          width: "95%",
          maxWidth: "815px",
          height: mobile ? "92vh" : "485px",
          padding: "0",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "hidden",
          zIndex: 999,
          borderRadius: "20px",
        },
        overlay: {
          zIndex: 999,
          backgroundColor: "rgba(0,0,0,0.48)",
        },
      }}
    >
      <ModalContent>
        <ModalHeader>
          <ButtonCloseModal
            type="button"
            title="fechar"
            onClick={() => setIsOpen(!modalIsOpen)}
            disabled={isCreatingReservation}
          >
            <IconFechar />
          </ButtonCloseModal>
          <div>
            <ModalHeaderTilte>{hotel.name}</ModalHeaderTilte>
            {!mobile && (
              <ReservationInfo>
                <InfoContent>
                  <sub>Entrada</sub>
                  <p>{formatDate(state?.data.dateIn) || ""}</p>
                </InfoContent>
                <InfoContent>
                  <sub>Saída</sub>
                  <p>{formatDate(state?.data.dateOut) || ""}</p>
                </InfoContent>
                <InfoContent>
                  <sub>Duração</sub>
                  <p>{diffInDays} diárias</p>
                </InfoContent>
              </ReservationInfo>
            )}
          </div>
          {mobile && (
            <ReservationInfo>
              <InfoContent>
                <sub>Entrada</sub>
                <p>{formatDate(state?.data.dateIn) || ""}</p>
              </InfoContent>
              <InfoContent>
                <sub>Saída</sub>
                <p>{formatDate(state?.data.dateOut) || ""}</p>
              </InfoContent>
              <InfoContent>
                <sub>Duração</sub>
                <p>{diffInDays} diárias</p>
              </InfoContent>
            </ReservationInfo>
          )}
        </ModalHeader>

        <ModalBody>
          <SelectAccommodation>
            <SelectAccommodationTitle>
              Selecione um tipo de acomodação
            </SelectAccommodationTitle>

            <div>
              {validationRoom.map((room) => (
                <ButtonSelect
                  key={room.id}
                  className={`${room.id === roomSelect ? "select" : ""} ${
                    room.validade ? "complet" : ""
                  }`}
                  onClick={() => selectListRooms(room.id)}
                >
                  Quarto {room.id + 1}
                </ButtonSelect>
              ))}
            </div>
          </SelectAccommodation>

          <LabelsContainer>
            {hotel.roomsGroups.map((group) => (
              <div
                key={group.SearchIndex}
                style={{
                  display: roomSelect === group.SearchIndex ? "block" : "none",
                }}
              >
                {renderRoomsSelect(group.SearchIndex)(group.RoomsIds)}
              </div>
            ))}
          </LabelsContainer>
        </ModalBody>

        <ModalFooter>
          <Helpers dangerouslySetInnerHTML={{ __html: messagem }} />
          {messagem === "" && (
            <Helpers>
              <span className="success">Continuar com a reserva</span>
            </Helpers>
          )}
          <ButtonSelectRooms
            disabled={messagem !== "" || isCreatingReservation}
            onClick={() => handleReserve(hotel)}
          >
            {isCreatingReservation ? <LoadingBtn /> : "RESERVAR"}
          </ButtonSelectRooms>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
