import { SyntheticEvent } from "react";
import { InputProps } from "../input";

const maskCpfOrCNPJ = (input: SyntheticEvent<HTMLInputElement>) => {
  input.currentTarget.value = input.currentTarget.value
    .trim()
    .replace(/\D/g, "")
    .substring(0, 14); //max numbers
};
export const dataInput: InputProps[] = [
  {
    name: "user",
    label: "Usuário (seu CPF)",
    id: "usuario",
    description: "Mesmo CPF do Portal do Usuário",
    type: "text",
    maxLength: 18,
    onChange: maskCpfOrCNPJ,
  },
  {
    name: "email",
    label: "Email",
    id: "email",
    description: "O e-mail a ser enviado está solicitação",
    type: "text",
  },
  {
    name: "password",
    label: "Senha",
    id: "senha",
    description: "Mesma senha do Portal do Usuário",
    type: "password",
  },
];
