import Modal from "react-modal";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import {
  ButtonSelectDate,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderTilte,
  Text,
} from "./styles";
import { ModalFormConfirmationProps } from "./types";

export const ModalFormConfirmation = ({
  modalIsOpen,
  setIsOpen,
  message,
}: ModalFormConfirmationProps) => {
  const mobile = useMediaQuery("(max-width: 990px)");

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      style={{
        content: {
          width: "95%",
          maxWidth: "482px",
          height: mobile ? "213px" : "194px",
          padding: "0",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "hidden",
          zIndex: 999,
          borderRadius: "20px",
        },
        overlay: {
          zIndex: 999,
          backgroundColor: "rgba(0,0,0,0.48)",
        },
      }}
    >
      <ModalContent>
        <ModalHeader>
          <ModalHeaderTilte>{message.title}</ModalHeaderTilte>
        </ModalHeader>

        <ModalBody>
          <Text>{message.body}</Text>
        </ModalBody>

        <ModalFooter>
          <ButtonSelectDate onClick={() => setIsOpen(!modalIsOpen)}>
            FECHAR
          </ButtonSelectDate>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
