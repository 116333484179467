import { Options } from "../../../components/InputRadio/types";
import IconCard from "../../../assets/icones/icons dense (24px)/cartão - grey.svg";
import IconCardChecked from "../../../assets/icones/icons dense (24px)/cartão.svg";
import { FieldPaymentPropsWithoutTotal } from "../../Payments/Roons/ChoosePayments/FieldsPaymentcard/types";

export const optionsPayments: Options[] = [
  {
    id: "cartao",
    label: "Cartão de crédito",
    value: "cartao",
    name: "cartao",
    checked: false,
    icon: IconCard,
    iconChecked: IconCardChecked,
  },
];

export const optionsPaymentcard: FieldPaymentPropsWithoutTotal[] = [
  {
    name: "parcelas",
    id: "parcelas1",
    type: "select",
    options: [
      { label: "Número de parcelas", value: "numero de parcelas" },
      { label: "1x", value: 1 },
    ],
  },
  {
    name: "numeroDoCartao",
    id: "numero de cartao",
    type: "text",
    placeholder: "Número do cartão",
    autoComplete: "cc-number",
  },
  {
    name: "nome",
    id: "nome",
    type: "text",
    placeholder: "titular do cartão",
    autoComplete: "cc-name",
  },
  {
    name: "mes",
    id: "mes",
    type: "text",
    placeholder: "Mês",
    autoComplete: "cc-exp-month",
  },
  {
    name: "ano",
    id: "ano",
    type: "text",
    placeholder: "Ano",
    autoComplete: "cc-exp-year",
  },
  {
    name: "cvv",
    id: "cvv",
    type: "text",
    placeholder: "CVV",
    autoComplete: "cc-csc",
  },
];
export const optionsPaymentcard2: FieldPaymentPropsWithoutTotal[] = [
  {
    name: "parcelas",
    id: "parcelas2",
    type: "select",
    options: [
      { label: "Número de parcelas", value: "numero de parcelas" },
      { label: "1x", value: 1 },
      { label: "2x", value: 2 },
    ],
  },
  {
    name: "numeroDoCartao",
    id: "numero de cartao",
    type: "text",
    placeholder: "Número do cartão",
    autoComplete: "cc-number",
  },
  {
    name: "nome",
    id: "nome",
    type: "text",
    placeholder: "titular do cartão",
    autoComplete: "cc-name",
  },
  {
    name: "mes",
    id: "mes",
    type: "text",
    placeholder: "Mês",
    autoComplete: "cc-exp-month",
  },
  {
    name: "ano",
    id: "ano",
    type: "text",
    placeholder: "Ano",
    autoComplete: "cc-exp-year",
  },
  {
    name: "cvv",
    id: "cvv",
    type: "text",
    placeholder: "CVV",
    autoComplete: "cc-csc",
  },
];

export const optionsPaymentcard3: FieldPaymentPropsWithoutTotal[] = [
  {
    name: "parcelas",
    id: "parcelas3",
    type: "select",
    options: [
      { label: "Número de parcelas", value: "numero de parcelas" },
      { label: "1x", value: 1 },
      { label: "2x", value: 2 },
      { label: "3x", value: 3 },
    ],
  },
  {
    name: "numeroDoCartao",
    id: "numero de cartao",
    type: "text",
    placeholder: "Número do cartão",
    autoComplete: "cc-number",
  },
  {
    name: "nome",
    id: "nome",
    type: "text",
    placeholder: "titular do cartão",
    autoComplete: "cc-name",
  },
  {
    name: "mes",
    id: "mes",
    type: "text",
    placeholder: "Mês",
    autoComplete: "cc-exp-month",
  },
  {
    name: "ano",
    id: "ano",
    type: "text",
    placeholder: "Ano",
    autoComplete: "cc-exp-year",
  },
  {
    name: "cvv",
    id: "cvv",
    type: "text",
    placeholder: "CVV",
    autoComplete: "cc-csc",
  },
];

export const optionsPaymentcard4: FieldPaymentPropsWithoutTotal[] = [
  {
    name: "parcelas",
    id: "parcelas4",
    type: "select",
    options: [
      { label: "Número de parcelas", value: "numero de parcelas" },
      { label: "1x", value: 1 },
      { label: "2x", value: 2 },
      { label: "3x", value: 3 },
      { label: "4x", value: 4 },
    ],
  },
  {
    name: "numeroDoCartao",
    id: "numero de cartao",
    type: "text",
    placeholder: "Número do cartão",
    autoComplete: "cc-number",
  },
  {
    name: "nome",
    id: "nome",
    type: "text",
    placeholder: "titular do cartão",
    autoComplete: "cc-name",
  },
  {
    name: "mes",
    id: "mes",
    type: "text",
    placeholder: "Mês",
    autoComplete: "cc-exp-month",
  },
  {
    name: "ano",
    id: "ano",
    type: "text",
    placeholder: "Ano",
    autoComplete: "cc-exp-year",
  },
  {
    name: "cvv",
    id: "cvv",
    type: "text",
    placeholder: "CVV",
    autoComplete: "cc-csc",
  },
];

export const optionsPaymentcard5: FieldPaymentPropsWithoutTotal[] = [
  {
    name: "parcelas",
    id: "parcelas5",
    type: "select",
    options: [
      { label: "Número de parcelas", value: "numero de parcelas" },
      { label: "1x", value: 1 },
      { label: "2x", value: 2 },
      { label: "3x", value: 3 },
      { label: "4x", value: 4 },
      { label: "5x", value: 5 },
    ],
  },
  {
    name: "numeroDoCartao",
    id: "numero de cartao",
    type: "text",
    placeholder: "Número do cartão",
    autoComplete: "cc-number",
  },
  {
    name: "nome",
    id: "nome",
    type: "text",
    placeholder: "titular do cartão",
    autoComplete: "cc-name",
  },
  {
    name: "mes",
    id: "mes",
    type: "text",
    placeholder: "Mês",
    autoComplete: "cc-exp-month",
  },
  {
    name: "ano",
    id: "ano",
    type: "text",
    placeholder: "Ano",
    autoComplete: "cc-exp-year",
  },
  {
    name: "cvv",
    id: "cvv",
    type: "text",
    placeholder: "CVV",
    autoComplete: "cc-csc",
  },
];

export const optionsPaymentcard6: FieldPaymentPropsWithoutTotal[] = [
  {
    name: "parcelas",
    id: "parcelas6",
    type: "select",
    options: [
      { label: "Número de parcelas", value: "numero de parcelas" },
      { label: "1x", value: 1 },
      { label: "2x", value: 2 },
      { label: "3x", value: 3 },
      { label: "4x", value: 4 },
      { label: "5x", value: 5 },
      { label: "6x", value: 6 },
    ],
  },
  {
    name: "numeroDoCartao",
    id: "numero de cartao",
    type: "text",
    placeholder: "Número do cartão",
    autoComplete: "cc-number",
  },
  {
    name: "nome",
    id: "nome",
    type: "text",
    placeholder: "titular do cartão",
    autoComplete: "cc-name",
  },
  {
    name: "mes",
    id: "mes",
    type: "text",
    placeholder: "Mês",
    autoComplete: "cc-exp-month",
  },
  {
    name: "ano",
    id: "ano",
    type: "text",
    placeholder: "Ano",
    autoComplete: "cc-exp-year",
  },
  {
    name: "cvv",
    id: "cvv",
    type: "text",
    placeholder: "CVV",
    autoComplete: "cc-csc",
  },
];
