import { Location, To } from "react-router-dom";

export const handleRedirectDefault = (location: Location): To => {
  if (!!location?.hash) {
    return location.hash.replace("#", "");
  }
  return location.pathname.slice(location.pathname.search(".html") + 5);
};

export const handleRedirectHotelLista = (location: Location): To => {
  console.log(location);
  // https://www.coobmais.com.br/hotel_lista.html#/identificacao/1/2E477866-BF3F-4CBF-8A75-A1812284AD5E
  if (!!location?.hash) {
    return location.hash.replace("#", "");
  }
  return location.pathname.slice(
    location.pathname.search("hotel_lista.html") + 16
  );
};

export const handleRedirectRedefinirSenha = (location: Location): To => {
  console.log(location);
  // https://www.coobmais.com.br/info.html#/redefinirSenha/90C3B0A0-CAC4-49F6-B733-056B50AE3C85
  if (!!location?.hash) {
    return location.hash.replace("#", "");
  }
  return location.pathname.slice(location.pathname.search("info.html") + 9);
};
