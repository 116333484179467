import Modal from "react-modal";
import { ReactComponent as IconFechar } from "../../assets/icones/icons dense (24px)/fechar.svg";
import {
  Body,
  ButtonCloseModal,
  Button,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderTitle,
  Text,
} from "./styles";
import { ModalAlertProps } from "./types";

export const ModalAlert = ({
  isOpen,
  onClose,
  message,
  textButtonFooter = "Fechar",
  onClickButtonFooter,
  maxWidth = "482px",
}: ModalAlertProps) => {

  return (
    <Modal
      isOpen={isOpen}
      style={{
        content: {
          width: "95%",
          maxWidth,
          padding: "0",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "hidden",
          zIndex: 999,
          borderRadius: "20px",
          height: "fit-content",
        },
        overlay: {
          zIndex: 999,
          backgroundColor: "rgba(0,0,0,0.48)",
        },
      }}
      ariaHideApp={false}
      id="Modal"
    >
      <ModalContent id="ModalContent">
        <ModalHeader>
          <ButtonCloseModal
            type="button"
            title={message?.title || "Fechar"}
            onClick={onClose}
          >
            <IconFechar />
          </ButtonCloseModal>
          <ModalHeaderTitle>{message?.title || "Fechar"}</ModalHeaderTitle>
        </ModalHeader>

        <ModalBody>
          {!!message?.body && (
            <Body>
              <Text>{message.body}</Text>
            </Body>
          )}
          {!!message?.htmlBody && (
            <Body dangerouslySetInnerHTML={{ __html: message.htmlBody }} />
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={!onClickButtonFooter ? onClose : onClickButtonFooter}
          >
            {textButtonFooter}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
