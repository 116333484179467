import { Form } from "@unform/web";
import styled from "styled-components";

export const FormItem = styled(Form)`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 514px;
`;
